import {
  Box,
  Center,
  Grid,
  Group,
  Skeleton,
  Text,
  ThemeIcon
} from '@mantine/core';
import {
  IconFileMinus,
  IconFileSearch,
  IconReceiptRefund
} from '@tabler/icons-react';
import { useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { useComputedParameters } from '@/core/stores/parameters-store';
import Button from '@/fine-tune/components/button/button';
import { useDataEdits } from '@/fine-tune/hooks/query-hooks/use-data-edits/use-data-edits';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';
import { EditAction } from '@/fine-tune/stores/data-edits-store/data-edits.store.types';

import Counter from './counter/counter';
import { NerSearchContent, NerTableContent } from './ner-content/ner-content';
import { TcSearchContent, TcTableContent } from './tc-content/tc-content';

export const GOLD_COL = 3;
export const SAMPLE_COL = 9;
export const BORDER_SHADE = 3;

const SKELETON_ARRAY = Array.from({ length: 15 }, (i: number, idx: number) => (
  <Skeleton height={25} key={idx} my='xs' />
));

/**
 * DataEditsCartModal
 *
 *
 *
 * @returns {React.Component} DataEditsCartModal
 */
const DataEditsCartModal = ({ id }: { id: string }) => {
  const isNer = useComputedParameters('isNer');

  const { closeModal } = useModals();

  const { samples, ids } = useDataEdits();
  const { createDataEditMutation } = useEditCreate();

  const replaceTerm = useDataEditsStore((state) => state.replaceTerm);
  const searchTerm = useDataEditsStore((state) => state.searchTerm);

  const { clearState } = useDataEditsStore((state) => state.actions);

  useEffect(() => {
    clearState();
  }, []);

  const isFetching = samples?.isFetching || ids?.isFetching;

  const { sample_count, data_metrics } = samples?.data?.split_run_results || {};

  const dataExists = data_metrics?.length;

  const border = `1px solid var(--mantine-color-gray-3)`;

  const handleDataEditCreate = (editAction: EditAction = 'update_text') => {
    // TODO: Updating typing
    const sampleIds = data_metrics?.map(({ id }: { id: number }) => id) ?? [];

    createDataEditMutation.mutate(
      {
        editAction,
        sampleIds
      },
      { onSuccess: () => closeModal(id) }
    );
  };

  return (
    <Box
      data-testid='data-edits-cart-modal'
      style={{
        height: 650
      }}
    >
      <Grid
        gutter={0}
        style={{
          height: 579
        }}
      >
        <Grid.Col
          p='md'
          span={4}
          style={{
            backgroundColor: 'var(--mantine-color-gray-0)',
            height: 650
          }}
        >
          {isNer ? (
            <NerSearchContent onEditCreate={handleDataEditCreate} />
          ) : (
            <TcSearchContent />
          )}

          <Group justify='space-between' mt='sm' px={4}>
            <Counter />
            {!isNer && (
              <Button
                copy={replaceTerm ? 'Replace' : 'Remove'}
                disabled={!dataExists || !searchTerm}
                leftSection={
                  replaceTerm ? (
                    <IconReceiptRefund size={16} />
                  ) : (
                    <IconFileMinus size={16} />
                  )
                }
                onClick={() => handleDataEditCreate()}
              />
            )}
          </Group>
        </Grid.Col>
        <Grid.Col p={0} span={8}>
          <Grid
            gutter={0}
            px={6}
            style={{
              backgroundColor: 'var(--mantine-color-gray-0)',
              borderBottom: border
            }}
          >
            {isNer ? (
              <Grid.Col p={4}>Sample</Grid.Col>
            ) : (
              <>
                <Grid.Col
                  p={4}
                  span={SAMPLE_COL}
                  style={{
                    borderRight: border
                  }}
                >
                  <Text c='dimmed' size='sm'>
                    {`Samples ${
                      sample_count && sample_count > 50
                        ? '- Previewing first 50 matching samples'
                        : ''
                    }`}
                  </Text>
                </Grid.Col>
                <Grid.Col p={4} span={GOLD_COL}>
                  <Text c='dimmed' size='sm'>
                    Ground Truth
                  </Text>
                </Grid.Col>
              </>
            )}
          </Grid>

          {isFetching && (
            <div data-testid='data-edits-skeleton'>{SKELETON_ARRAY}</div>
          )}

          {!isFetching && dataExists && (
            <Virtuoso
              data={data_metrics ?? []}
              itemContent={(index, data) => {
                return (
                  <Grid
                    gutter={0}
                    px={6}
                    style={{
                      borderBottom: border
                    }}
                  >
                    {isNer ? (
                      <NerTableContent data={data} />
                    ) : (
                      <TcTableContent data={data} />
                    )}
                  </Grid>
                );
              }}
              overscan={50}
              style={{ height: '90%' }}
            />
          )}

          {!isFetching && !dataExists && (
            <Center className='flex-column' mt={50}>
              <ThemeIcon color='gray' size='xl' variant='light'>
                <IconFileSearch size={50} />
              </ThemeIcon>
              <Text c='dimmed'>No data for search term</Text>
            </Center>
          )}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default DataEditsCartModal;
