import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { TaskType } from '@/core/constants/tasks-and-frameworks.constants';
import { PROJECT_TYPE, Run } from '@/core/types/projects.types';
import { parseDate } from '@/core/utils/parse-date/parse-date';
import taskTypeToProjectType from '@/core/utils/task-type-to-project-type/task-type-to-project-type';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import { toHumanReadableTaskType } from '@/core/utils/to-human-readable-task-type/to-human-readable-task-type';
import useStore from '@/fine-tune/stores/store';

import { RunsWithProjectName } from '../filter-runs/filter-runs';

const createName = (creator?: Run['creator']) => {
  if (!creator) {
    return STRING_PLACEHOLDER;
  }

  const { first_name, last_name } = creator;

  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  }
};

interface RunData {
  id: string;
  run: Run;
  keywords: string[];
  projectType: string;
  title: string;
  description: string;
  onClick: () => void;
}
/**
 * createActions
 *
 */
export const createActions = (runs: RunsWithProjectName[] = []) => {
  if (!runs.length) {
    return [];
  }

  const resetStore = useStore.getState().actions.resetStore;

  const actions: Record<string, RunData[]> = {};

  runs.forEach((run) => {
    const date = parseDate(run.created_at ?? '');

    const numSamples = `${toHumanReadableNumber(run.num_samples)} samples`;
    const taskType = toHumanReadableTaskType(
      run.task_type?.toString() as TaskType
    );
    const projectType =
      taskTypeToProjectType(run.task_type?.toString() as TaskType) || '';

    const isObserve = PROJECT_TYPE.OBSERVE === projectType;
    const isPrompt = PROJECT_TYPE.PROMPT_EVALUATION === projectType;

    const descriptionArray = [
      numSamples,
      createName(run.creator),
      date,
      taskType
    ];

    if (isObserve || isPrompt) {
      descriptionArray.shift();
    }

    const runData: RunData = {
      id: isObserve ? run.project_id || '' : run.id,
      run: run,
      keywords: [run.name as string],
      projectType,
      title: run.name as string,
      description: descriptionArray.join(' | '),

      onClick: () => {
        resetStore();
      }
    };

    if (!(run.project_name in actions)) {
      actions[run.project_name] = [];
    }

    actions[run.project_name].push(runData);
  });

  return Object.entries(actions).map(([projectName, runs]) => ({
    group: projectName,
    actions: runs
  }));
};
