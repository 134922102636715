import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

const POST_INSIGHTS_ERRORS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/errors';

/**
 * useInsightsErrors
 */
export const useInsightsErrors = () => {
  const { runId, projectId } = usePathParameters();

  const { split, comparedTo } = useParametersStore((s) => ({
    split: s.split,
    comparedTo: s.comparedTo
  }));

  const { isInference } = useComputedParameters();

  const filter_params = useFilterParams();

  const fetchErrors = async () => {
    const res = await api.POST(POST_INSIGHTS_ERRORS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body: {
        filter_params,
        compare_to: comparedTo
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [POST_INSIGHTS_ERRORS, { projectId, runId, split, filter_params }],
    fetchErrors,
    { enabled: Boolean(projectId && runId) && !isInference }
  );

  return response;
};
