import { Box, Text } from '@mantine/core';
import { IconChartLine, IconTerminal } from '@tabler/icons-react';

import {
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { ComparedToOptions } from '@/core/stores/parameters-store/parameters.store.types';
import Select from '@/fine-tune/components/select/select';
import { useInferenceNames } from '@/fine-tune/hooks/query-hooks/use-inference-names/use-inference-names';
import { useSplits } from '@/fine-tune/hooks/query-hooks/use-splits/use-splits';

interface NewParams {
  comparedTo: ComparedToOptions;
  isDrifted?: boolean;
}

const SELECT_WIDTH = 125;

/**
 * InferenceSelects
 *
 *
 *
 * @returns {React.Component} InferenceSelects
 */
const InferenceSelects = () => {
  const { inferenceName, comparedTo } = useParametersStore((s) => ({
    inferenceName: s.inferenceName,
    comparedTo: s.comparedTo
  }));
  const { setParameters } = useParametersStoreActions();

  const inferenceNames = useInferenceNames();
  const comparison = useSplits();

  const handleComparedToChange = (newComparedTo: string | null) => {
    const newParams: NewParams = {
      comparedTo: newComparedTo as ComparedToOptions
    };

    if (!newComparedTo) {
      newParams.isDrifted = false;
    }

    setParameters(newParams);
  };

  return (
    <Box className='flex'>
      <Box
        className='align-center'
        style={{
          border: `1px dashed var(--mantine-color-brand-3)`,
          borderRadius: 4,
          padding: 4
        }}
      >
        <Select
          data={inferenceNames?.data?.inference_names ?? []}
          data-testid='inference-select'
          icon={<IconTerminal size={16} />}
          tooltipCopy={<Text size='sm'>Current Run</Text>}
          value={inferenceName}
          width={SELECT_WIDTH}
          onChange={(newInferenceRun) =>
            setParameters({ inferenceName: newInferenceRun as string })
          }
        />
        <Text c='dimmed' mx={4} size='xs'>
          vs.
        </Text>
        <Select
          data={comparison?.comparisonSplits}
          data-testid='baseline-select'
          disabled={!comparison?.isFetched}
          icon={<IconChartLine size={16} />}
          tooltipCopy={
            <Text size='sm' tt='capitalize'>
              Baseline Run
            </Text>
          }
          value={comparedTo}
          width={SELECT_WIDTH}
          onChange={handleComparedToChange}
        />
      </Box>

      {/* There's very likely a better way to do this with CSS alone, soft TODO: */}
      <Box
        style={{
          width: 8,
          height: 1,
          borderTop: `1px dashed var(--mantine-color-brand-3)`,
          alignSelf: 'center'
        }}
      />
      <Box
        style={{
          width: 5,
          height: 5,
          backgroundColor: 'var(--mantine-color-brand-3)',
          borderRadius: '50%',
          alignSelf: 'center'
        }}
      />
    </Box>
  );
};

export default InferenceSelects;
