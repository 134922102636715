/**
 * NOTE: leaving this hook in place so we can add A/B flags in the future easier
 */
// EXAMPLE A/B flag setup:
// const [myFlag, setMyFlag] = useSessionStorage({
//   key: 'myFlag',
//   defaultValue: false
// });
// return {
//   myFlag: {
//     description: 'Enables My Flag.',
//     isEnabled: Boolean(myFlag),
//     tag: [FlagTags.feature],
//     toggle: () => setMyFlag(!myFlag)
//   }
// };

export const useABFlags = () => {
  return {};
};
