import {
  ActionIcon,
  Box,
  Card,
  Center,
  Flex,
  Group,
  Text
} from '@mantine/core';
import { IconArrowsShuffle } from '@tabler/icons-react';
import React, { useState } from 'react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import { TruncatedGroup } from '@/core/components/atoms/truncated-group/truncated-group';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import { useOverlappingLabels } from '@/fine-tune/hooks/query-hooks/use-overlapping-labels/use-overlapping-labels';
import useStore from '@/fine-tune/stores/store';

const TopOverlappingLabels = () => {
  // Hooks
  const { data: overlappingLabelsData, isFetching } = useOverlappingLabels();
  const groups = overlappingLabelsData?.co_occurrences || [];
  const percentage = overlappingLabelsData?.percent_samples;
  const total = overlappingLabelsData?.num_samples;

  // Global Store
  const { setMlcTaskAndIds } = useStore((state) => state.actions);
  const mlcTaskAndIds = useStore((state) => state.mlcTaskAndIds);

  // State
  const [activePanel, setActivePanel] = useState<string>();

  // Computed Properties
  const percentEl = percentage ? `${percentage}%` : <DataNotAvailable />;
  const totalEl = total ? toHumanReadableNumber(total) : <DataNotAvailable />;

  // Event Handlers
  const handleClick = (starting_label: string, ids: number[]) => {
    if (mlcTaskAndIds.task === starting_label) {
      return setMlcTaskAndIds({
        task: '',
        ids: [],
        isCoOccurrence: false
      });
    }

    setMlcTaskAndIds({
      task: starting_label,
      ids: ids,
      isCoOccurrence: true
    });
  };

  // Helper functions
  const renderLabels = (
    coOccurrences: { label: string; co_occurrence: number }[],
    isTruncated: boolean
  ) => {
    const badges = coOccurrences.map((coOccurrence, i) => {
      const { label, co_occurrence } = coOccurrence;
      const percentEl = (
        <Text c='dimmed'>{parsePercentage(co_occurrence)}</Text>
      );
      return (
        <LabelBadge
          key={`${label}-${co_occurrence}-${i}`}
          maxWidth={200}
          rightSection={percentEl}
          value={label}
        />
      );
    });

    return (
      <Group gap='sm'>
        <TruncatedGroup
          elements={badges}
          isTruncated={isTruncated}
          labels={coOccurrences?.map((occurence) => occurence?.label)}
        />
      </Group>
    );
  };

  const togglePanel = (label: string) => {
    if (label === activePanel) {
      setActivePanel('');
    } else {
      setActivePanel(label);
    }
  };

  const headerEl = (
    <Text aria-level={3} component='span' role='heading'>
      Top Overlapping Labels
    </Text>
  );

  // MLTC & No Co_Occurrence
  if (!isFetching && !groups.length) {
    return (
      <SplitCard
        header={headerEl}
        mb='xs'
        mt='xs'
        subheader={<Text size='xs'>No overlapping labels detected</Text>}
      />
    );
  }

  return (
    <SplitCard
      header={headerEl}
      isLoading={isFetching}
      mb='xs'
      mt='xs'
      subheader={
        <Text size='xs'>
          Overall is
          <Text c='brand.4' component='span' fw={700} ml={2} mr={4}>
            {totalEl}
          </Text>
          <Text c='brand.4' component='span' fw={400} size='xs'>
            {percentEl}
          </Text>
        </Text>
      }
    >
      <Card.Section>
        {groups?.map((group) => {
          const { starting_label, labels, ids } = group || {};
          const isTruncated =
            starting_label !== mlcTaskAndIds?.task &&
            starting_label !== activePanel;
          const mappedOverlappingLabels = renderLabels(labels, isTruncated);
          const toggleText = isTruncated ? 'Show more labels' : 'Hide';

          if (!labels?.length) {
            return;
          }

          const toggleButton = labels?.length > 3 &&
            starting_label !== mlcTaskAndIds?.task && (
              <Center w='100%'>
                <ActionIcon
                  aria-label='toggle collapse'
                  w='100%'
                  onClick={() => togglePanel(starting_label)}
                >
                  <Text size='xs'>{toggleText}</Text>
                </ActionIcon>
              </Center>
            );

          return (
            <Box
              data-testid='overlappingLabelGroup'
              key={starting_label}
              style={{
                borderBottom: `1px solid var(--mantine-color-gray-2)`,
                cursor: 'pointer'
              }}
            >
              <Flex
                className='cursor-pointer'
                gap='sm'
                p='sm'
                onClick={() => handleClick(starting_label, ids)}
              >
                <Group gap='xs' wrap='nowrap'>
                  <LabelBadge value={starting_label} />
                  <Box c='gray.5' h={20}>
                    <IconArrowsShuffle size='20' />
                  </Box>
                </Group>
                <Group>{mappedOverlappingLabels}</Group>
              </Flex>
              {toggleButton}
            </Box>
          );
        })}
      </Card.Section>
    </SplitCard>
  );
};

export default TopOverlappingLabels;
