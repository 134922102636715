import {
  Badge,
  Box,
  Card,
  Collapse,
  Group,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import check from 'check-types';
import { MouseEventHandler } from 'react';

import {
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { components } from '@/core/types/api';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
import { useColors } from '@/fine-tune/hooks/use-colors/use-colors';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
type Cluster = components['schemas']['CartographCluster'];
interface ClusterCardProps {
  cluster: Cluster;
  getClusterMetricColor: (metricVal: number) => string;
}

const ClusterCard = ({ cluster, getClusterMetricColor }: ClusterCardProps) => {
  // Global store
  const setHoverCluster = useEmbeddingsStore((s) => s.actions.setHoverCluster);
  const clusterIds = useParametersStore((state) => state.clusterIds);
  const { setParameters } = useParametersStoreActions();

  // Local store
  const { data: percentageData } = usePercentageSample();
  const { getDepColor } = useColors();

  // Local state
  const [opened, { toggle }] = useDisclosure(true);

  const { percentage, sample_count } = percentageData || {};
  const totalSamples = (sample_count || 0) / (percentage || 1);

  const {
    cluster_id,
    cluster_description,
    cluster_summary,
    cluster_topic,
    cluster_size,
    average_f1,
    average_dep
  } = cluster;

  const percentageNumber = parsePercentage(cluster_size / totalSamples);
  const noAssociatedCluster = cluster_id === -1;
  const isSelected = clusterIds?.includes(cluster_id);

  const handleCollapse: MouseEventHandler = (e) => {
    e?.stopPropagation();
    toggle();
  };

  const setHoverClusterId = () => {
    setHoverCluster(cluster.cluster_id);
  };

  const unsetHoverClusterId = () => {
    setHoverCluster(null);
  };

  return (
    <Card
      mb='sm'
      shadow='sm'
      style={({ colors }) => ({
        cursor: 'pointer',
        border: `2px solid ${isSelected ? colors.brand[2] : 'transparent'}`,
        '&:hover': {
          border: `2px solid ${colors.brand[2]}`
        }
      })}
      onBlur={unsetHoverClusterId}
      onClick={() => {
        setParameters({ clusterIds: [cluster_id] });
      }}
      onFocus={setHoverClusterId}
      onMouseLeave={unsetHoverClusterId}
      onMouseOver={setHoverClusterId}
    >
      <Card.Section inheritPadding className='justify-space-between' py='sm'>
        <Text component='span' fw={700} size='sm'>
          {noAssociatedCluster
            ? 'No associated cluster'
            : `Cluster ${cluster_id}`}
        </Text>
        <Text c='dimmed' component='span' mr={16} size='sm' ta='right'>
          {`${toHumanReadableNumber(
            cluster_size
          )} (${percentageNumber} of all data)`}
        </Text>
        <Box
          style={{
            position: 'absolute',
            right: 4,
            top: 12
          }}
        >
          <UnstyledButton
            color='gray'
            data-testid='expand-cluster-data-button'
            mr={4}
            onClick={handleCollapse}
          >
            {opened ? (
              <IconChevronUp color='gray' size={20} />
            ) : (
              <IconChevronDown color='gray' size={20} />
            )}
          </UnstyledButton>
        </Box>
      </Card.Section>
      <Card.Section inheritPadding>
        <Collapse in={opened && !noAssociatedCluster}>
          <>
            {cluster_topic && (
              <>
                <Text c='dimmed' fw={600} mb={2} size='sm'>
                  Topic
                </Text>
                <Text c='dimmed' mb={6} size='xs'>
                  {cluster_topic}
                </Text>
              </>
            )}
            {cluster_summary && (
              <>
                <Text c='dimmed' fw={600} mb={2} size='sm'>
                  Summary
                </Text>
                <Text c='dimmed' mb={6} size='xs'>
                  {cluster_summary}
                </Text>
              </>
            )}
            {Boolean(cluster_description?.length) && (
              <>
                <Text c='dimmed' fw={600} mb={3} size='sm'>
                  Top common words
                </Text>
                <Group gap={4} mb={12}>
                  {cluster_description?.split(', ')?.map((word) => (
                    <Tooltip key={word} label={word}>
                      <Badge
                        bg='gray.1'
                        color='gray.6'
                        radius='xs'
                        style={{ textTransform: 'none' }}
                        variant='light'
                      >
                        {word}
                      </Badge>
                    </Tooltip>
                  ))}
                </Group>
              </>
            )}
          </>
        </Collapse>

        {!noAssociatedCluster && (
          <Group gap='sm' pb='sm'>
            {check.number(average_dep) && (
              <Text c='dimmed' size='sm'>
                Average DEP{' '}
                <Text
                  color={getDepColor(average_dep as number)}
                  component='span'
                  fw={600}
                >
                  {(average_dep as number).toFixed(2)}
                </Text>
              </Text>
            )}
            {check.number(average_f1) && (
              <Text c='dimmed' size='sm'>
                Average F1{' '}
                <Text
                  color={getClusterMetricColor(average_f1 as number)}
                  component='span'
                  fw={600}
                >
                  {(average_f1 as number).toFixed(2)}
                </Text>
              </Text>
            )}
          </Group>
        )}
      </Card.Section>
    </Card>
  );
};

export default ClusterCard;
