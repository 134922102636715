import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

// Hardcoded timezone for testing purposes
// istanbul ignore next
const tz = process.env.TEST_ENV_TZ || dayjs.tz.guess();

export const parseDate = (
  date: string,
  format?: string,
  withoutTz?: boolean
): string => {
  let _format = format || 'MMM D, h:mma';

  if (!withoutTz) {
    // https://day.js.org/docs/en/plugin/advanced-format
    format = `${_format} z`;
  }

  const parsedDate = dayjs.utc(date).tz(tz).format(format);

  const isValidDate = parsedDate !== 'Invalid Date';

  return isValidDate ? parsedDate : STRING_PLACEHOLDER;
};
