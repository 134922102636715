import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';

export const PROJECT_KEY = 'PROJECTS';

export const useProjects = (type?: ProjectType) => {
  // Feature Flags
  const { prompt, observe } = useFeatureFlags();
  let enabled = true;

  if (type === PROJECT_TYPE.OBSERVE && !prompt.isEnabled) {
    enabled = false;
  }

  if (type === PROJECT_TYPE.PROMPT_EVALUATION && !observe.isEnabled) {
    enabled = false;
  }

  const path = '/projects/all';

  const fetchProjects = async () => {
    // We want this hook to fire and resolve to an empty array, otherwise it will infinitely load
    if (!enabled) return Promise.resolve([]);

    const res = await api.GET(path, {
      params: {
        query: {
          type
        }
      }
    });

    return res.data;
  };

  const response = useQuery([PROJECT_KEY, { type, enabled }], fetchProjects, {
    onError: () => {}
  });

  return {
    ...response,
    projectSelectData: response?.data?.map((proj) => ({
      label: proj.name,
      value: proj.id
    }))
  };
};
