import { ActionIcon, Tooltip } from '@mantine/core';
import { IconLock, IconLockOpen } from '@tabler/icons-react';

import {
  DEV_DOMAIN,
  ENTERPRISE_DOMAIN,
  LOCAL_DOMAIN,
  PREVIEW_DOMAIN,
  SANDBOX_DOMAIN
} from '@/core/constants/domains.constants';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { useUpdateProject } from '@/core/hooks/query-hooks/use-update-project/use-update-project';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { isDomain } from '@/core/utils/is-domain/is-domain';

/**
 * ProjectLockButton
 *
 * @returns {React.Component} ProjectLockButton
 */
const ProjectLockButton = () => {
  // Query Hooks
  const { data: projectData } = useCurrentProject();
  const updateProject = useUpdateProject();

  // Hooks
  const { isGalileoUser } = useFeatureFlags();

  // Feature Flags
  const featureFlag =
    isGalileoUser.isEnabled &&
    isDomain([
      LOCAL_DOMAIN,
      DEV_DOMAIN,
      ENTERPRISE_DOMAIN,
      PREVIEW_DOMAIN,
      SANDBOX_DOMAIN
    ]);

  // Computed
  const isPublic = projectData?.is_public ?? false;
  const lockIcon = isPublic ? <IconLockOpen /> : <IconLock />;
  const tooltipLabel = isPublic
    ? 'Make project private'
    : 'Make project public';

  // Event Handlers
  const handleClick = () => {
    return updateProject.mutate({
      is_public: !isPublic
    });
  };

  if (!featureFlag) {
    return null;
  }

  return (
    <Tooltip label={tooltipLabel}>
      <ActionIcon
        c='gray.6'
        data-testid='project-lock-button'
        variant='subtle'
        onClick={handleClick}
      >
        {lockIcon}
      </ActionIcon>
    </Tooltip>
  );
};

export default ProjectLockButton;
