import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Radio,
  ScrollArea,
  Text,
  ThemeIcon,
  UnstyledButton
} from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import {
  IconAlertTriangle,
  IconArrowRightCircle,
  IconTrash
} from '@tabler/icons-react';
import { useState } from 'react';

import Avatar from '@/core/components/atoms/avatar/avatar';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useDeleteUser } from '@/core/hooks/query-hooks/use-delete-user/use-delete-user';
import { useTransferData } from '@/core/hooks/query-hooks/use-transfer-runs/use-transfer-data';
import { useUsers } from '@/core/hooks/query-hooks/use-users/use-users';

interface TransferUserDataProps extends ContextModalProps {
  innerProps: {
    user: any;
  };
}

/**
 * TransferUserData
 *
 *
 *
 * @returns {React.Component} TransferUserData
 */
const TransferUserData = ({ innerProps }: TransferUserDataProps) => {
  const { data: userData } = useUsers() as any;

  const firstUserId = userData?.[0]?.id ?? '';

  const [selectedUser, setSelectedUser] = useState(firstUserId);

  const deleteUser = useDeleteUser();
  const transferUserData = useTransferData();
  const { closeAll } = useModals();

  const removeUser = () => {
    deleteUser.mutate(innerProps.user.id, {
      onSuccess: () => {
        closeAll();
      }
    });
  };

  const transferAndRemove = async () => {
    await transferUserData.mutateAsync({
      fromId: innerProps.user.id,
      toId: selectedUser
    });

    removeUser();
  };

  return (
    <>
      <Grid data-testid='transfer-user-data-modal'>
        <Grid.Col span={1}>
          <ThemeIcon color='red' variant='light'>
            <IconAlertTriangle size={18} />
          </ThemeIcon>
        </Grid.Col>

        <Grid.Col span={10}>
          <Text fw={700}>
            Before removing{' '}
            {innerProps?.user?.first_name ||
              innerProps?.user?.email ||
              STRING_PLACEHOLDER}
            , do you want to transfer their runs to another user? Select a user:
          </Text>

          <Text c='dimmed'>{`You can't change this role to user because you need to have at least one admin.`}</Text>

          <ScrollArea.Autosize mah={250} mt='xs' p='xs'>
            {userData
              ?.filter((user: any) => user.id !== innerProps.user.id)
              ?.map((user: any, idx: number) => {
                const isSelected = selectedUser
                  ? user.id === selectedUser
                  : idx === 0;

                return (
                  <UnstyledButton
                    className='w-100'
                    key={user.id}
                    mb='xs'
                    p='xs'
                    style={{
                      border: `1px solid var(--mantine-color-contrast-7)`,
                      borderRadius: 4,
                      backgroundColor: isSelected
                        ? 'var(--mantine-color-brand-1)'
                        : 'inherit'
                    }}
                    onClick={() => setSelectedUser(user.id)}
                  >
                    <Group justify='space-between'>
                      <Box className='flex'>
                        <Avatar name={user.first_name} />
                        <Box className='flex-column' ml='xs'>
                          <Text>{user.first_name}</Text>
                          <Text c='dimmed'>{user.email}</Text>
                        </Box>
                      </Box>
                      <Radio checked={isSelected} />
                    </Group>
                  </UnstyledButton>
                );
              })}
          </ScrollArea.Autosize>
        </Grid.Col>
      </Grid>

      <Divider color='contrast.7' my='xs' />

      <Group justify='space-between' wrap='nowrap'>
        <Button leftSection={<IconTrash size={18} />} onClick={removeUser}>
          Remove without transferring
        </Button>
        <Button
          leftSection={<IconArrowRightCircle size={18} />}
          onClick={transferAndRemove}
        >
          Transfer and remove user
        </Button>
      </Group>
    </>
  );
};

export default TransferUserData;
