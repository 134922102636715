import { useComputedParameters } from '@/core/stores/parameters-store';
import { useInsightsStore } from '@/fine-tune/stores/insights-store/insights.store';

export const INSIGHT_CARD_HEIGHT = 160; //px
export const METRICS_HEADER_HEIGHT = 215; //px
export const OBJECT_DETECTION_METRICS_HEADER_HIGHT = 300;

export const useInsightsColumnHeight = (extra = 0) => {
  const activeInsightsCard = useInsightsStore((s) => s.activeInsightsCard);
  const { isOd, isSS, isNer } = useComputedParameters();

  let headerSizeInPixels =
    isOd || isSS
      ? OBJECT_DETECTION_METRICS_HEADER_HIGHT
      : METRICS_HEADER_HEIGHT;

  if (isNer) {
    headerSizeInPixels += 20;
  }

  if (activeInsightsCard) {
    const insightsCardHeight = document?.getElementById(
      'selected-insight-card-wrapper'
    )?.clientHeight;

    headerSizeInPixels += (insightsCardHeight || INSIGHT_CARD_HEIGHT) - 40;
  }

  return `calc(100vh - ${headerSizeInPixels + extra}px)`;
};

export const useDataframeHeight = () => {
  const { isOd } = useComputedParameters();

  let headerSizeInPixels = 215;

  if (isOd) {
    headerSizeInPixels += 75;
  }

  return `calc(100vh - ${headerSizeInPixels}px)`;
};
