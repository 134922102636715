import { toHumanReadableNumber } from '../to-human-readable-number/to-human-readable-number';

interface Config {
  toHumanReadableNumber?: boolean;
}

export const roundUpToNearest = (
  num: number,
  nearestPlace = 1000,
  config?: Config
) => {
  const rounded = Math.ceil(num / nearestPlace) * nearestPlace;

  if (config?.toHumanReadableNumber) {
    return toHumanReadableNumber(rounded);
  }

  return rounded;
};

export const roundDownToNearest = (num: number, nearestPlace = 0.05) => {
  return Number((Math.floor(num / nearestPlace) * nearestPlace).toPrecision(2));
};
