import { Group, HoverCard, ScrollArea, Text } from '@mantine/core';
import check from 'check-types';
import React from 'react';

import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
interface MessageFields {
  [key: string]: string | number | string[];
}

const pythonTemplateLiteralRegex = /{(.*?)}/g;

export const parsePythonString = (
  str: string,
  messageFields: MessageFields
) => {
  const split = str.split(pythonTemplateLiteralRegex);

  return (split || []).map((fragment, i) => {
    if (!fragment) {
      return '';
    }

    let field = messageFields?.[fragment];

    if (field === undefined) {
      // fragment is just a simple string
      return <span key={fragment}>{fragment.replace("'", '')}</span>;
    }

    if (check.array(field)) {
      return (
        <span key={field.toString()}>{parseArrayField(field as string[])}</span>
      );
    }

    if (typeof field === 'number' && !check.integer(field)) {
      field = (field as number).toFixed(2);
    }

    return (
      <Text component='span' fw={700} key={i}>
        {field}
      </Text>
    );
  });
};

const parseArrayField = (field: string[]) => {
  const groupOfLabels = (
    <Group
      gap={4}
      style={{
        display: 'inline-block'
      }}
    >
      {field.map((item) => (
        // @ts-expect-error
        <LabelBadge inline key={item} ml={2} value={item} />
      ))}
    </Group>
  );

  if (field.join('').length < 100) {
    return groupOfLabels;
  } else {
    return (
      <HoverCard withinPortal position='top' shadow='md' width={350}>
        <HoverCard.Target>
          <Text c='indigo.5' component='span' fw={600}>
            from this list
          </Text>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <ScrollArea.Autosize mah={200} mt='xs'>
            {groupOfLabels}
          </ScrollArea.Autosize>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }
};
