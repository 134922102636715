import {
  ActionIcon,
  Box,
  Center,
  Group,
  Loader,
  ScrollArea,
  Select,
  Text
} from '@mantine/core';

import { useComputedParameters } from '@/core/stores/parameters-store';
import { useCartographClusters } from '@/fine-tune/hooks/query-hooks/use-cartograph-clusters/use-cartograph-clusters';
import {
  getColorByThreshold,
  getColorThresholds
} from '@/fine-tune/hooks/use-colors/use-colors';
import { useInsightsColumnHeight } from '@/fine-tune/hooks/use-element-height/use-element-height';
import { useSortBy } from '@/fine-tune/hooks/use-sort-by/use-sort-by';

import ClusterCard from './cluster-card';

const SORT_OPTIONS = [
  { value: 'cluster_id', label: 'Cluster ID' },
  { value: 'cluster_size', label: 'Cluster Size' },
  { value: 'average_f1', label: 'F1' },
  { value: 'average_dep', label: 'DEP' }
];

interface ModifiedSortProps {
  sortBy?: string | undefined;
  sortDir?: 'asc' | 'desc' | undefined;
}
/**
 * ClusterColumnContent
 *
 *
 *
 * @returns {React.Component} ClusterColumnContent
 */
const ClusterColumnContent = () => {
  const { isS2S } = useComputedParameters();
  const { data: clusterData, isFetching } = useCartographClusters();
  const clusters = clusterData?.cartograph_clusters;

  const { highThresh, lowThresh } = getColorThresholds(
    clusters?.map((c) => c.average_f1 || 0) || []
  );

  const maxHeight = useInsightsColumnHeight(30);

  const { sortProps, setSortProps, sortIcon } = useSortBy(
    'average_dep',
    'desc'
  );
  const { sortBy, sortDir } = sortProps;

  const onSortChange = (sortBy?: string) => {
    const newProps: ModifiedSortProps = sortBy
      ? { sortBy }
      : { sortDir: sortDir === 'asc' ? 'desc' : 'asc' };
    setSortProps((oldProps) => ({ ...oldProps, ...newProps }));
  };

  const getClusterMetricColor = (metric: number) =>
    getColorByThreshold(metric, highThresh, lowThresh);

  const sortedClusters = [...(clusters || [])].sort((a, b) => {
    // @ts-expect-error
    const aVal = a?.[sortBy] || 0;
    // @ts-expect-error
    const bVal = b?.[sortBy] || 0;

    return sortDir === 'asc' ? aVal - bVal : bVal - aVal;
  });

  const _sortOptions = SORT_OPTIONS.filter(
    (opt) => !isS2S || opt.value !== 'average_f1'
  );

  return (
    <>
      {isFetching ? (
        <Center data-testid='loader' mt='25vh'>
          <Loader />
        </Center>
      ) : (
        <Box>
          <Group gap='xs' justify='flex-end' mb='xs'>
            <Text c='gray.7' size='xs'>
              Sort By
            </Text>
            <Select
              data={_sortOptions}
              radius='md'
              size='xs'
              style={{
                width: 120
              }}
              styles={({ colors }) => ({
                input: { borderColor: colors.gray[2] }
              })}
              value={sortBy}
              onChange={(sortBy) => onSortChange(sortBy as string)}
            />
            <ActionIcon
              color='gray'
              variant='subtle'
              onClick={() => onSortChange()}
            >
              {sortIcon}
            </ActionIcon>
          </Group>
          <ScrollArea.Autosize mah={maxHeight}>
            <>
              {sortedClusters?.map((cluster) => (
                <ClusterCard
                  cluster={cluster}
                  getClusterMetricColor={getClusterMetricColor}
                  key={cluster.cluster_id}
                />
              ))}
            </>
          </ScrollArea.Autosize>
        </Box>
      )}
    </>
  );
};

export default ClusterColumnContent;
