import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

export const usePercentageSample = (fixedParams = {}) => {
  const { isInference, isMltc, isOoc } = useComputedParameters();
  const { runId, projectId } = usePathParameters();
  const { inferenceName, split, task, comparedTo } = useParametersStore(
    (s) => ({
      inferenceName: s.inferenceName,
      split: s.split,
      task: s.task,
      comparedTo: s.comparedTo
    })
  );

  const filter_params = { ...useFilterParams(), ...fixedParams };

  const path =
    '/projects/{project_id}/runs/{run_id}/split/{split}/insights/percentage/sample';

  let enabled = Boolean(projectId && runId);

  if (isInference) {
    enabled = Boolean(enabled && inferenceName);
  }

  if (isMltc) {
    enabled = Boolean(enabled && task);
  }

  const fetchPercentageSample = async () => {
    const res = await api.POST(path, {
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!,
          split
        },
        query: {
          inference_name: inferenceName
        }
      },
      body: {
        task: task ?? null,
        filter_params,
        compare_to: comparedTo
      }
    });

    return res.data;
  };

  const response = useQuery(
    [
      path,
      {
        projectId,
        runId,
        split,
        comparedTo,
        task,
        inferenceName,
        isOoc,
        ...filter_params
      }
    ],
    fetchPercentageSample,
    {
      enabled
    }
  );

  return response;
};
