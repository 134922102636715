import {
  MantineThemeColorsOverride,
  ScrollArea,
  Space,
  useMantineTheme
} from '@mantine/core';

import BarChart from '@/core/components/atoms/bar-chart';
import { OnClickReturn } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import { DEFAULT_BAR_CHART_COLOR } from '@/core/constants/colors.constants';
import { useParametersStore } from '@/core/stores/parameters-store';
import {
  useActiveFilters,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { useInsightsIds } from '@/fine-tune/hooks/query-hooks/use-insights-ids/use-insights-ids';
import { useRunMetrics } from '@/fine-tune/hooks/query-hooks/use-run-metrics/use-run-metrics';
import { useSortBy } from '@/fine-tune/hooks/use-sort-by/use-sort-by';
import useStore from '@/fine-tune/stores/store';

import ChartHeader from '../grouped-by-charts/chart-header';

export const getSortedChartData = (
  labels: string[],
  data: number[],
  sortDir?: string
) => {
  const mergedData: [string, number][] = labels.map((label, index) => [
    label,
    data[index]
  ]);

  const sortedData = mergedData.sort((a, b) => {
    if (!sortDir) return 0;

    const [, aData] = a;
    const [, bData] = b;

    if (sortDir === 'asc') {
      return aData - bData;
    }

    return bData - aData;
  });

  return sortedData.reduce(
    (acc, [label, data]) => {
      acc.labels.push(label);
      acc.data.push(data);

      return acc;
    },
    { labels: [] as string[], data: [] as number[] }
  );
};

const ModelPerformanceByTaskChart = () => {
  // Store values
  const setMlcTaskAndIds = useStore((state) => state.actions.setMlcTaskAndIds);
  const metric = useParametersStore((state) => state.metric);
  const task = useParametersStore((state) => state.task);
  const { clearAndUpdateParameters } = useParametersStoreActions();

  // Hooks
  const activeFilters = useActiveFilters();
  const insightsIds = useInsightsIds();
  const runMetrics = useRunMetrics();

  const { sortProps, setSortProps, sortIcon } = useSortBy();

  const theme: MantineThemeColorsOverride = useMantineTheme();

  const { colors } = theme;

  const multi_label_task_metrics = runMetrics?.data;

  // @ts-expect-error TODO: Types
  const labels = multi_label_task_metrics?.labels || [];

  const backgroundColors = labels?.map((taskName: string) =>
    taskName === task
      ? colors.brand[5]
      : colors[DEFAULT_BAR_CHART_COLOR.color][DEFAULT_BAR_CHART_COLOR.shade]
  );

  const handleMlcBarClick = ({ label }: OnClickReturn) => {
    if (activeFilters.length) {
      const { task: taskName, ids } = insightsIds?.data || {};
      // @ts-expect-error TODO: typing
      setMlcTaskAndIds({ task: taskName, ids });
    }

    clearAndUpdateParameters({ task: label ?? '' }, false, ['split']);
  };

  const handleSortChange = () => {
    const { sortDir } = sortProps || {};
    if (!sortDir) {
      setSortProps({
        sortBy: undefined,
        sortDir: 'asc'
      });
      return;
    }

    setSortProps({
      sortBy: undefined,
      sortDir: sortDir === 'asc' ? 'desc' : undefined
    });
  };

  // @ts-expect-error
  const barChartData = (multi_label_task_metrics?.[metric] as number[]) || [];

  const chartData = getSortedChartData(
    labels,
    barChartData,
    sortProps?.sortDir
  );

  return (
    <SplitCard
      header={
        <ChartHeader
          copy='Model Performance By Task'
          sortIcon={sortIcon}
          onSortClick={handleSortChange}
        />
      }
      mt='sm'
    >
      <Space h='sm' />
      <ScrollArea>
        <BarChart
          backgroundColor={backgroundColors}
          data={chartData.data}
          isLoading={runMetrics?.isFetching}
          labels={chartData.labels}
          onClick={handleMlcBarClick}
        />
      </ScrollArea>
    </SplitCard>
  );
};

export default ModelPerformanceByTaskChart;
