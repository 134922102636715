import { useInfiniteQuery } from '@tanstack/react-query';
import { snakeCase } from 'lodash';

import api from '@/core/api';
import { MetricsColumn } from '@/core/types/metrics-table.types';

import { MetricsGroup } from '../use-metrics/utils';

export const METRIC_GROUPS = '/prompts/rows/groups';

export type MetricGroup = {
  label: string;
  name: string;
  columns: MetricsColumn[];
  description: string;
};

export const useMetricGroups = () => {
  // Query
  const queryKey = [METRIC_GROUPS];

  const queryFn = async () => {
    const res = await api.GET(METRIC_GROUPS);

    return res.data;
  };

  // Response
  const response = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled: true,
    onError: () => {}
  });

  const groups: MetricsGroup[] | undefined = response.data?.pages[0];

  return {
    ...response,
    groups: groups?.map((group) => ({
      ...group,
      columns: group.columns.map((c) => snakeCase(c))
    }))
  };
};
