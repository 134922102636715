import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';

const GET_INSIGHTS_ALERTS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/alerts';
import { components } from '@/core/types/api';

export type InsightsAlertResponse = components['schemas']['XRayCardDB'];

export const useInsightAlerts = () => {
  const { runId, projectId } = usePathParameters();

  const { mapThreshold, split, comparedTo, inferenceName } = useParametersStore(
    (s) => ({
      comparedTo: s.comparedTo,
      mapThreshold: s.mapThreshold,
      split: s.split,
      inferenceName: s.inferenceName
    })
  );

  const { isOd, isMltc, isInference } = useComputedParameters();

  const enabled = Boolean(projectId && runId && !isMltc);

  const fetchAlerts = async () => {
    const res = await api.GET(GET_INSIGHTS_ALERTS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: split
        },
        query: {
          map_threshold: isOd ? mapThreshold?.toString() : undefined
        }
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [
      GET_INSIGHTS_ALERTS,
      { projectId, runId, split, mapThreshold, isOd, inferenceName }
    ],
    fetchAlerts,
    { enabled }
  );

  // We shouldn't display drifted alerts if we are comparing to none
  const removeDriftedAlerts = (alerts: InsightsAlertResponse[]) => {
    return alerts.filter(
      (alert) => !(isInference && !comparedTo && alert.xray_class === 12)
    );
  };

  const filteredAlerts = removeDriftedAlerts(response.data ?? []);

  return {
    ...response,
    data: response?.data?.length ? filteredAlerts : null,
    alertCount: filteredAlerts?.length ?? 0
  };
};
