import {
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending
} from '@tabler/icons-react';
import { useState } from 'react';

interface SortProps {
  sortBy: string | undefined;
  sortDir: 'asc' | 'desc' | undefined;
}

export const getSortIcon = (sortDir: 'asc' | 'desc' | undefined) => {
  let sortIcon = <IconArrowsSort size={20} />;

  if (sortDir) {
    sortIcon =
      sortDir === 'asc' ? (
        <IconSortAscending size={20} />
      ) : (
        <IconSortDescending size={20} />
      );
  }

  return sortIcon;
};

export const useSortBy = (
  initialSortBy?: string,
  initialSortDir?: 'asc' | 'desc' | undefined
) => {
  const [sortProps, setSortProps] = useState<SortProps>({
    sortBy: initialSortBy,
    sortDir: initialSortDir
  });

  return { sortProps, setSortProps, sortIcon: getSortIcon(sortProps.sortDir) };
};
