import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

export const GET_PROMPT_RUN = '/projects/{project_id}/prompts/runs/{run_id}';

export const fetchPromptRun = async (projectId: string, runId: string) => {
  const res = await api.GET(GET_PROMPT_RUN, {
    params: {
      path: {
        project_id: projectId!,
        run_id: runId!
      }
    }
  });

  return res.data;
};

export const useCurrentPromptRun = () => {
  const { projectId, runId } = usePathParameters();

  const enabled = [projectId, runId].every(Boolean);

  return useQuery(
    [GET_PROMPT_RUN, { projectId, runId }],
    () => fetchPromptRun(projectId!, runId!),
    {
      enabled
    }
  );
};
