import { closeAllModals } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { showNotification } from '@/core/utils/show-notification/show-notification';

interface TransferIds {
  fromId: string;
  toId: string;
}

export const useTransferData = () => {
  const handler = async (values: TransferIds) => {
    const res = await api.POST('/users/{user_id}/transfer', {
      params: {
        path: {
          user_id: values.fromId
        },
        query: {
          new_user_id: values.toId
        }
      }
    });

    return res?.data;
  };

  const mutation = useMutation(handler, {
    onSuccess: () => {
      showNotification({
        type: 'success',
        title: 'Data transferred and user removed!'
      });
      closeAllModals();
    },
    onError: (res: Response) =>
      defaultErrorHandler(res, 'Error transferring runs')
  });

  return mutation;
};
