import { Box, Grid, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';

import SplitCard from '@/core/components/atoms/split-card/split-card';
import { useParametersStoreActions } from '@/core/stores/parameters-store';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import { getTopFiveMisclassified } from '@/fine-tune/data-parsers/get-top-five-misclassified/get-top-five-misclassified';
import { useTopInsights } from '@/fine-tune/hooks/query-hooks/use-top-insights/use-top-insights';

const GOLD_AND_PRED_SPAN = 5;
const COUNT_SPAN = 2;
const DEFAULT_BADGE_WIDTH = 100;

const TopMisclassifiedLabelPairs = () => {
  const { setParameters } = useParametersStoreActions();

  const { data } = useTopInsights() || {};
  const topMisclassifiedPairs =
    data &&
    getTopFiveMisclassified({
      // @ts-expect-error - API is returning wrong types
      top_misclassified_pairs: data.top_misclassified_pairs,
      top_misclassified_pairs_percentage:
        data.top_misclassified_pairs_percentage
    });

  const { rows, overall, percentage } = topMisclassifiedPairs || {};

  const _onClick = (labels: [string, string]) => {
    setParameters({
      goldFilter: [labels?.[0]],
      predFilter: [labels?.[1]]
    });
  };

  const { ref, width } = useElementSize();

  if (!rows?.length) {
    return null;
  }

  return (
    <SplitCard
      header='Top Misclassified Pairs'
      mt='xs'
      subheader={
        <Text size='xs'>
          Overall is
          <Text c='brand.4' component='span' fw={400} ml={2} mr={2} size='xs'>
            {overall || '--'}
          </Text>
          <Text c='brand.4' component='span' fw={300} size='xs'>
            ({percentage || '--'})
          </Text>
        </Text>
      }
    >
      {/* "Table" Headers */}
      <Grid className='text-center' data-testid='top-misclassified-label-pairs'>
        <Grid.Col span={GOLD_AND_PRED_SPAN}>
          <Text ref={ref} size='xs'>
            Ground Truth
          </Text>
        </Grid.Col>
        <Grid.Col span={GOLD_AND_PRED_SPAN}>
          <Text size='xs'>Prediction</Text>
        </Grid.Col>
        <Grid.Col span={COUNT_SPAN}>
          <Text size='xs'>Count</Text>
        </Grid.Col>
      </Grid>

      {/* "Table" Body */}
      <Grid className='text-center'>
        {rows.map((row, index) => (
          <Box
            className='flex w-100'
            data-testid='top-misclassified-pairs-row-wrapper'
            key={index}
            onClick={() => _onClick(row.classes)}
          >
            <Grid.Col
              className='cursor-pointer'
              span={GOLD_AND_PRED_SPAN}
              style={{
                borderBottom: `1px solid var(--mantine-color-gray-2)`
              }}
            >
              <LabelBadge
                maxWidth={width || DEFAULT_BADGE_WIDTH}
                value={row?.classes?.[0]}
              />
            </Grid.Col>
            <Grid.Col
              className='cursor-pointer'
              span={GOLD_AND_PRED_SPAN}
              style={{
                borderBottom: `1px solid var(--mantine-color-gray-2)`
              }}
            >
              <LabelBadge
                maxWidth={width || DEFAULT_BADGE_WIDTH}
                value={row?.classes?.[1]}
              />
            </Grid.Col>
            <Grid.Col
              className='cursor-pointer'
              data-testid='top-misclassified-pairs-count'
              span={COUNT_SPAN}
              style={{
                borderBottom: `1px solid var(--mantine-color-gray-2)`
              }}
              onClick={() => _onClick(row.classes)}
            >
              {toHumanReadableNumber(row.count)}
            </Grid.Col>
          </Box>
        ))}
      </Grid>
    </SplitCard>
  );
};

export default TopMisclassifiedLabelPairs;
