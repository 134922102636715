export enum FlagTags {
  feature = 'feature',
  external = 'external',
  tool = 'devTools'
}

export type FeatureFlag = {
  [key: string]: {
    description: string;
    isEnabled: boolean;
    tag: FlagTags[];
    toggle: () => void;
  };
};
