import {
  ActionIcon,
  Box,
  Center,
  Checkbox,
  Grid,
  Group,
  ScrollArea,
  Stack,
  Switch,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import {
  IconHandStop,
  IconHome,
  IconMinus,
  IconPlus
} from '@tabler/icons-react';
import _debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch';

import DefaultErrorBoundary from '@/core/components/atoms/default-error-boundary/default-error-boundary';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  useActiveFilters,
  useMetaFilters
} from '@/core/stores/parameters-store';
import { getFileName } from '@/core/utils/get-file-name/get-file-name';
import DepOrConfidence from '@/fine-tune/components/dep-or-confidence/dep-or-confidence';
import FeatureInsightsCard from '@/fine-tune/components/feature-insights/feature-insights-card/feature-insights-card';
import FiltersCheckbox from '@/fine-tune/components/filters-checkbox/filters-checkbox';
import SsErrors from '@/fine-tune/components/ss-errors/ss-errors';
import SsImage from '@/fine-tune/components/ss-image/ss-image';
import { SS_ERROR_MAPPING } from '@/fine-tune/constants/semantic-segmentation.constants';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useLabelsToggle } from '@/fine-tune/hooks/use-labels-toggle/use-labels-toggle';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import { ImageType } from '@/fine-tune/stores/computer-vision-store/computer-vision.store.types';
import { SemanticRow } from '@/fine-tune/types/query.types';

const IMAGES = {
  [ImageType.GOLD]: 'Ground Truth',
  [ImageType.PRED]: 'Predicted',
  [ImageType.RAW]: 'Raw Image',
  [ImageType.DEP]: 'DEP'
};

const convertVhToPx = (vh: number) => {
  const oneVhInPx = window.innerHeight / 100;
  return oneVhInPx * vh;
};

const getImageSize = (hiddenAmount: number) => {
  const viewPortProportion = window.innerHeight / 42;
  let imageProportion = 1.2;
  if (hiddenAmount === 3) imageProportion = 2.4;
  if (hiddenAmount === 2) imageProportion = 2;
  return convertVhToPx(viewPortProportion) * imageProportion;
};

const SSModal = () => {
  // State
  const [isTransparent, setIsTransparent] = useState<boolean>(true);
  const [hiddenImages, setHiddenImages] = useState<number[]>([]);
  const [hideFilteredPolygons, setHideFilteredPolygons] = useState(true);

  const [mode, setMode] = useState<string>();
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState([0, 0]);

  const rawRef = useRef<ReactZoomPanPinchRef | null>(null);
  const depRef = useRef<ReactZoomPanPinchRef | null>(null);
  const predRef = useRef<ReactZoomPanPinchRef | null>(null);
  const goldRef = useRef<ReactZoomPanPinchRef | null>(null);

  const refsArray = [goldRef, predRef, rawRef, depRef];

  // Global state
  const { setModalTitle, modalIndex } = useComputerVisionStore((s) => ({
    modalIndex: s.modalIndex,
    setModalTitle: s.actions.setModalTitle
  }));

  // Hooks
  const activeFilters = useActiveFilters();
  const metaFilters = useMetaFilters();

  const hasFilters = Boolean(activeFilters?.length || metaFilters?.length);

  const { data, fetchNextPage, hasNextPage } = useInsightsRows();
  const samples = data?.pages?.flatMap((page) => page?.data_rows) || [];
  // @ts-expect-error - FIXME
  const sample = samples[modalIndex] as SemanticRow;

  useEffect(() => {
    refsArray.forEach((ref) => {
      const instance = ref.current?.instance;

      if (instance?.transformState) {
        ref?.current?.setTransform(
          instance.transformState.positionX,
          instance.transformState.positionY,
          scale
        );
      }
    });
  }, [scale]);

  useEffect(() => {
    refsArray.forEach((ref) => {
      const instance = ref.current?.instance;

      if (instance?.transformState) {
        const [x, y] = position;
        ref?.current?.setTransform(x, y, instance.transformState.scale);
      }
    });
  }, [position]);

  // Hooks
  const { hiddenLabels, toggleAllLabels, objects, toggleError, hiddenErrors } =
    useLabelsToggle(
      sample,
      Object.keys(SS_ERROR_MAPPING),
      false,
      hideFilteredPolygons
    );

  // Handlers
  const handleReset = () => {
    refsArray.forEach((ref) => {
      if (ref?.current) {
        ref?.current?.resetTransform();
      }
    });
    setTimeout(() => {
      setScale(1);
      setPosition([0, 0]);
    }, 300);
  };

  const toggleIsTransparent = () => {
    setIsTransparent(!isTransparent);
  };

  const handleExpandCollapse = (index: number) => {
    setHiddenImages(
      hiddenImages.length === 3 ? [] : [0, 1, 2, 3].filter((i) => i !== index)
    );
    handleReset();
  };

  const toggleImage = (index: number) => {
    if (hiddenImages.includes(index)) {
      setHiddenImages(hiddenImages.filter((img) => img !== index));
    } else {
      setHiddenImages([...hiddenImages, index]);
    }
  };

  const toggleHideFilteredPolygons = () => {
    setHideFilteredPolygons(!hideFilteredPolygons);
  };

  // Effects
  useEffect(() => {
    const fileName =
      (sample?.image && getFileName(sample.image)) || STRING_PLACEHOLDER;
    setModalTitle(fileName);
    if (modalIndex === samples.length - 1 && hasNextPage) {
      fetchNextPage();
    }
  }, [modalIndex, sample?.image]);

  if (!sample) {
    return <DefaultErrorBoundary />;
  }

  const getColumnSpan = (index: number) => {
    if (hiddenImages.includes(index)) return 0;

    return hiddenImages.length === 3 ? 12 : 6;
  };

  const zoomHandlers = [
    {
      icon: <IconHome size={16} />,
      tooltipText: 'Reset',
      onClick: () => handleReset()
    },
    {
      icon: <IconPlus size={16} />,
      tooltipText: 'Zoom in',
      onClick: () => setScale((old) => old + 0.2)
    },
    {
      icon: <IconMinus size={16} />,
      tooltipText: 'Zoom out',
      onClick: () => setScale((old) => old - 0.2)
    },
    {
      icon: <IconHandStop size={16} />,
      mode: 'pan',
      tooltipText: 'Pan',
      onClick: () => setMode((oldMode) => (oldMode ? undefined : 'pan')),
      isActive: mode === 'pan'
    }
  ];

  const debouncedSetPosition = _debounce((e: ReactZoomPanPinchRef) => {
    setPosition([e.state.positionX, e.state.positionY]);
  }, 100);

  const size = getImageSize(hiddenImages.length);

  return (
    <Grid gutter='xl'>
      <Grid.Col pt={8} span={10}>
        <Grid gutter='xs'>
          {Object.entries(IMAGES).map(([type, label], index) => {
            if (hiddenImages.includes(index)) return null;
            const applyTransparency =
              isTransparent &&
              [ImageType.GOLD, ImageType.PRED].includes(type as ImageType);

            const _isExpanded =
              hiddenImages.length === 3 && !hiddenImages.includes(index);

            return (
              <Grid.Col key={index} span={getColumnSpan(index)}>
                <TransformWrapper
                  alignmentAnimation={{ disabled: true }}
                  doubleClick={{ disabled: true }}
                  limitToBounds={true}
                  panning={{ disabled: mode !== 'pan', velocityDisabled: true }}
                  pinch={{ disabled: true }}
                  ref={refsArray[index]}
                  wheel={{ disabled: true }}
                  onPanningStop={debouncedSetPosition}
                >
                  <FeatureInsightsCard
                    displayShowAllButton={false}
                    isExpanded={_isExpanded}
                    setHeight={false}
                    title={label}
                    onClick={() => handleExpandCollapse(index)}
                  >
                    <Center
                      h='50%'
                      pos='relative'
                      style={{
                        background: '#D3D1DB',
                        cursor: mode === 'pan' ? 'grab' : 'default'
                      }}
                      w='100%'
                    >
                      <TransformComponent>
                        <SsImage
                          isHoverable
                          height={size}
                          hiddenErrors={hiddenErrors}
                          hiddenLabels={hiddenLabels}
                          hideFilteredPolygons={hideFilteredPolygons}
                          isTransparent={applyTransparency}
                          sample={sample}
                          type={type as ImageType}
                          width={size}
                        />
                      </TransformComponent>
                    </Center>
                  </FeatureInsightsCard>
                </TransformWrapper>
              </Grid.Col>
            );
          })}
        </Grid>
      </Grid.Col>
      <Grid.Col span={2}>
        {hasFilters && (
          <FiltersCheckbox
            checked={hideFilteredPolygons}
            onChange={toggleHideFilteredPolygons}
          />
        )}
        <Stack mt='xs' pb='xs'>
          <Text c='dimmed' size='xs' tt='uppercase'>
            Errors
          </Text>
          <Stack pb='md'>
            <SsErrors
              withLabels
              hiddenErrors={hiddenErrors}
              hideFilteredPolygons={hideFilteredPolygons}
              sample={sample}
              onToggleError={toggleError}
            />
          </Stack>
        </Stack>
        <Stack>
          <Group justify='space-between'>
            <Text c='dimmed' size='xs' tt='uppercase'>
              Classes
            </Text>
            <UnstyledButton
              color='gray.6'
              style={{ fontSize: '14px', color: '#706C89' }}
              onClick={toggleAllLabels}
            >
              {hiddenLabels.length === 0 ? 'Hide' : 'Show'} all polygons
            </UnstyledButton>
          </Group>

          <ScrollArea h={250}>
            <Stack>{objects}</Stack>
          </ScrollArea>
        </Stack>
      </Grid.Col>

      <Grid.Col py={0} span={10}>
        <Group justify='space-between'>
          <Box w={140} />
          <Box
            style={{
              border: `1px solid var(--mantine-color-contrast-7)`,
              borderRadius: 8,
              padding: 2,
              display: 'flex'
            }}
          >
            {zoomHandlers.map(({ icon, onClick, tooltipText, isActive }) => (
              <Tooltip
                withArrow
                withinPortal
                key={tooltipText}
                label={tooltipText}
                openDelay={300}
              >
                <ActionIcon
                  aria-label={tooltipText}
                  mb={2}
                  variant={isActive ? 'light' : 'subtle'}
                  onClick={onClick}
                >
                  {icon}
                </ActionIcon>
              </Tooltip>
            ))}
          </Box>
          <Switch
            checked={isTransparent}
            label='Transparent'
            mb='xs'
            onChange={toggleIsTransparent}
          />
        </Group>
      </Grid.Col>
      <Grid.Col span={10}>
        <Group justify='space-between'>
          <Group gap='xl'>
            <DepOrConfidence
              component='div'
              sample={sample as SemanticRow}
              variant='filled'
            />
          </Group>
          <Group gap='xl'>
            {Object.values(IMAGES).map((label, i) => (
              <Checkbox
                checked={!hiddenImages.includes(i)}
                key={i}
                label={label}
                onChange={() => toggleImage(i)}
              />
            ))}
          </Group>
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default SSModal;
