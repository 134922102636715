export type ListConversion<OptionsType = undefined> = (
  value: any[],
  options?: OptionsType
) => any[];

export type ListConversionMap = {
  list: {
    unique: ListConversion;
  };
  // Add other conversions here...
};

// Type guarding is done in the constructor of the Unit class.
export const listConversions: ListConversionMap = {
  list: {
    unique: (value) => {
      return Array.from(new Set(value));
    }
  }
};

export class ListUnit<InputUnitType extends keyof ListConversionMap = 'list'> {
  private readonly initialValue: any[];
  private value: any[];

  private unitType: InputUnitType;

  /**
   *
   * @param {Array<any>} value - The value to convert
   * @param {InputUnitType} unitType - The unit type of the value to convert
   */
  constructor(value: any[], unitType: InputUnitType = 'list' as InputUnitType) {
    // Store the initial value for error messages.
    this.initialValue = value;

    // Set the unitType of the value.
    this.unitType = unitType;

    // Set the initial value.
    this.value = this.typeGuard(value);
  }

  // Method to convert the value to a different unit type.
  // ex. new ListUnit([1,1,1,2]).to('unique') => [1,2]
  public to<
    OutputUnitType extends keyof ListConversionMap[InputUnitType],
    OptionsType
  >(unit: OutputUnitType, options?: OptionsType) {
    const conversionFunction = listConversions[this.unitType][
      unit
    ] as ListConversion<OptionsType>;
    this.value = conversionFunction(this.value as any[], options);
    return this;
  }

  // Method to convert the value to a string.
  // ex. new ListUnit([1,2,3]).toString({{ style: 'long', type: 'conjunction' }}) => '1, 2, and 3'
  // ex. new ListUnit([1,2,2,2,3]).to('unique').toString({{ style: 'long', type: 'conjunction' }}) => '1, 2, and 3'
  public toString(
    options?: Intl.ListFormatOptions,
    fallback: string = 'None'
  ): string {
    if (Array.isArray(this.value) && this.value.length > 0) {
      this.value = this.value.map((item) => item.toString());

      return new Intl.ListFormat('en-US', options).format(this.value);
    }

    return fallback;
  }

  // Method to type guard the initial value.
  // This is used in the constructor to ensure that the initial value is properly guarded.
  private typeGuard(value: unknown) {
    if (!Array.isArray(value)) {
      this.throw(
        `Invalid value: Cannot convert value '${value}' to type '${this.unitType}'`
      );
      return this.initialValue;
    }

    return value as any[];
  }

  private throw(msg?: string) {
    // eslint-disable-next-line no-console
    console.warn(
      msg ??
        `Invalid value: Cannot convert value '${this.initialValue}' to unit type '${this.unitType}'`
    );
  }
}
