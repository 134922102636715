import {
  Group,
  ScrollArea,
  SegmentedControl,
  Space,
  Switch,
  Text,
  useMantineTheme
} from '@mantine/core';
import React, { useState } from 'react';

import BarChart from '@/core/components/atoms/bar-chart';
import { OnClickReturn } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import {
  useComputedParameters,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import {
  NER_OPTIONS,
  NER_SORT_KEYS
} from '@/fine-tune/constants/metric-and-grouped-selects.constants';
import { SCROLLBAR_SIZE } from '@/fine-tune/constants/misc.constants';
import { OD_ERROR_MAP } from '@/fine-tune/constants/object-detection.constants';
import { useInsightsErrors } from '@/fine-tune/hooks/query-hooks/use-insights-errors/use-insights-errors';
import { useMap } from '@/fine-tune/hooks/query-hooks/use-map/use-map';
/**
 * Error Types Chart
 *
 */
const ErrorTypesChart = () => {
  // Hooks
  const { colors } = useMantineTheme();
  const { setMetaParameters } = useParametersStoreActions();
  const { data: insightsErrorsData, isFetching: isInsightsFetching } =
    useInsightsErrors();
  const { isNer, isOd, isSS } = useComputedParameters();
  const { data: mapData, isFetching: isMapFetching } = useMap();
  const { error_scores } = mapData || {};

  // State
  const [showNone, setShowNone] = useState(isOd || isSS ? false : true);
  const [segmentControlVal, setSegmentControlVal] = useState('impact');

  // Computed
  const chartTitle = isOd ? 'Error Types' : 'Error Distributions';
  const isLoading =
    segmentControlVal === 'count' || !isOd ? isInsightsFetching : isMapFetching;

  const buildLabels = () => {
    if (isOd) {
      return segmentControlVal === 'count'
        ? insightsErrorsData?.labels || []
        : error_scores?.labels || [];
    }

    return (
      insightsErrorsData?.labels?.map(
        (lbl) => NER_OPTIONS?.find((op) => op.value === lbl)?.label || lbl
      ) || []
    );
  };

  const sortChartData = (labels: string[], chartData: number[]) => {
    let sortKeys: string[] = [];
    const sortOrder: number[] = [];

    const sortedLabels: string[] = [];
    const sortedChartData: number[] = [];

    if (isOd) {
      sortKeys = Object.keys(OD_ERROR_MAP);
    }

    if (isNer) {
      sortKeys = NER_SORT_KEYS;
    }

    sortKeys.forEach((key) => {
      sortOrder.push(labels.indexOf(key));
    });

    sortOrder.forEach((index) => {
      sortedLabels.push(labels[index]);
      sortedChartData.push(chartData[index]);
    });

    return {
      labels: sortedLabels.filter((x) => typeof x === 'string'),
      chartData: sortedChartData.filter((x) => typeof x === 'number')
    };
  };

  const getChartData = () => {
    const allLabels = buildLabels();

    let labels = allLabels;
    let chartData = insightsErrorsData?.values || [];

    if (isOd && segmentControlVal === 'impact') {
      chartData = error_scores?.values || [];
    }

    if (!showNone) {
      const noneIndex = allLabels?.findIndex((lbl) => lbl === 'None');

      labels = allLabels.filter((_, i) => i !== noneIndex);
      chartData = chartData?.filter((_, i) => i !== noneIndex);
    }

    // Sort labels and chart data by keys if isOd || isNer
    if (isOd || isNer) {
      return sortChartData(labels, chartData);
    }

    return {
      labels,
      chartData
    };
  };

  const { labels, chartData } = getChartData();

  // Methods
  const handleBarClick = ({ label }: OnClickReturn) => {
    const value =
      isOd || isSS
        ? label
        : NER_OPTIONS.find((opt) => opt.label === label)?.value || '';

    setMetaParameters([{ name: 'galileo_error_type', isin: [value] }]);
  };

  return (
    <>
      <SplitCard
        header={
          <Group justify='space-between'>
            <Text>{chartTitle}</Text>
            {!isSS &&
              (isOd ? (
                <SegmentedControl
                  data={[
                    { label: 'Count', value: 'count' },
                    { label: 'Impact on mAP', value: 'impact' }
                  ]}
                  radius='md'
                  value={segmentControlVal}
                  onChange={setSegmentControlVal}
                />
              ) : (
                <Switch
                  checked={showNone}
                  label='Display none'
                  size='xs'
                  onChange={() => setShowNone(!showNone)}
                />
              ))}
          </Group>
        }
        mb='sm'
        mt='sm'
        testId='error-types-chart'
      >
        <Space h='sm' />
        <ScrollArea scrollbarSize={SCROLLBAR_SIZE}>
          <BarChart
            backgroundColor={colors.red[4]}
            data={chartData || []}
            isLoading={isLoading}
            labels={labels}
            onClick={handleBarClick}
          />
        </ScrollArea>
      </SplitCard>
    </>
  );
};

export default ErrorTypesChart;
