import { Box, MenuProps, ThemeIcon, Transition } from '@mantine/core';
import { IconDots, IconTrash } from '@tabler/icons-react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { PopoverMenu } from '@/core/components/molecules/popover-menu/popover-menu';
import { FeedbackType } from '@/core/types/human-feedback.types';

interface RatingConfigurationPopoverProps extends MenuProps {
  configuration: FeedbackTemplate<FeedbackType>;
  isHovered: boolean;
  onDelete?: () => void;
}

export const RatingConfigurationPopover = ({
  isHovered,
  onDelete
}: RatingConfigurationPopoverProps) => {
  return (
    <PopoverMenu
      data={[
        {
          disabled: typeof onDelete !== 'function',
          color: 'red.5',
          icon: (
            <ThemeIcon c='red.5' size='sm' variant='transparent'>
              <IconTrash />
            </ThemeIcon>
          ),
          label: 'Delete',
          onClick: onDelete
        }
      ]}
      menuLabel='Actions'
      target={
        <Transition duration={100} mounted={isHovered} transition='fade'>
          {(styles) => (
            <Box data-testid='config-popover-trigger' style={styles}>
              {' '}
              <IconDots size='1.25rem' />
            </Box>
          )}
        </Transition>
      }
    />
  );
};
