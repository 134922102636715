import {
  Metric,
  NerMetric,
  S2SMetric,
  SSMetric
} from '@/core/stores/parameters-store/parameters.store.types';
import { InsightsGroupedBy } from '@/fine-tune/hooks/query-hooks/use-insights-grouped-by/use-insights-grouped-by';

const MAX_LABELS_COUNT = 500;

export type SortBy = 'metric' | 'support' | undefined;

export interface SortedData {
  labels: string[];
  data: number[] | number[][];
  support: number[];
  baselineData?: number[];
  baselineSupport?: number[];
}

export interface SortAndFilterParams {
  baselineData?: number[] | undefined;
  baselineGroupedBy?: InsightsGroupedBy | undefined;
  data: number[] | number[][];
  labels: (string | number | boolean)[];
  metric?: Metric | NerMetric | SSMetric | S2SMetric | undefined;
  searchTerm: string;
  sortBy: string | undefined;
  sortDir: 'asc' | 'desc' | undefined;
  support: number[];
}

export const sortAndFilterData = ({
  baselineData,
  baselineGroupedBy,
  data,
  labels,
  metric,
  searchTerm,
  sortBy,
  sortDir,
  support
}: SortAndFilterParams): SortedData => {
  if ((!sortBy || !sortDir) && !searchTerm) {
    return {
      data: data.slice(0, MAX_LABELS_COUNT),
      labels: labels.slice(0, MAX_LABELS_COUNT).map((l) => l.toString()),
      support: support.slice(0, MAX_LABELS_COUNT),
      baselineData: baselineData?.slice(0, MAX_LABELS_COUNT),
      baselineSupport: baselineGroupedBy?.support?.slice(0, MAX_LABELS_COUNT)
    };
  }

  const isSortingByMetric = sortBy === 'metric';
  const isSortingByTotalErrors = isSortingByMetric && metric === 'total_errors';

  const baseArray = isSortingByMetric ? data : support;
  const secondaryArray = isSortingByMetric ? support : data;
  const sortArray: number[] = isSortingByTotalErrors
    ? (data as number[][]).map((arr) => arr.reduce((acc, val) => acc + val, 0))
    : (baseArray as number[]);
  const mergedData: Record<
    string,
    [number, number | number[], number | number[], number, number]
  > = labels.reduce((acc, l, i) => {
    if (!l.toString().includes(searchTerm)) return acc;
    return {
      ...acc,
      [l.toString()]: [
        sortArray[i],
        baseArray[i],
        secondaryArray[i],
        baselineData?.[i],
        baselineGroupedBy?.support?.[i]
      ]
    };
  }, {});

  const sorted = Object.entries(mergedData).sort((a, b) => {
    if (sortDir === 'asc') {
      return a[1][0] - b[1][0];
    }
    return b[1][0] - a[1][0];
  });

  const defaultObject: SortedData = {
    data: [],
    labels: [],
    support: [],
    baselineData: [],
    baselineSupport: []
  };

  // @ts-expect-error - FIXME
  return sorted.reduce((acc, [l, [, base, sec, bd, bs]], i) => {
    if (i > MAX_LABELS_COUNT) {
      return acc;
    }
    return {
      data: [...acc.data, isSortingByMetric ? base : sec],
      labels: [...acc.labels, l],
      support: [...acc.support, isSortingByMetric ? sec : base],
      baselineData: [...(acc.baselineData || []), bd],
      baselineSupport: [...(acc.baselineSupport || []), bs]
    };
  }, defaultObject);
};
