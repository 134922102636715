import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';

import { GET_CURRENT_PROJECT } from '../use-current-project/use-current-project';
import { PROJECT_KEY } from '../use-projects/use-projects';

export const useUpdateProject = () => {
  const queryClient = useQueryClient();

  // Parameters
  const { projectId } = usePathParameters();

  // API Path
  const path = '/projects/{project_id}';

  const handleUpdateProject = (
    body: components['schemas']['ProjectUpdate']
  ) => {
    return api.PUT(path, {
      body,
      params: {
        path: {
          project_id: projectId!
        }
      }
    });
  };

  const mutation = useMutation(handleUpdateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries([PROJECT_KEY]);
      queryClient.invalidateQueries([GET_CURRENT_PROJECT]);
    },
    onError: (res: Response) =>
      defaultErrorHandler(res, 'Project could not be updated')
  });

  return mutation;
};
