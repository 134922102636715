import { showNotification } from '@mantine/notifications';
import { useRouter } from 'next/router';

import { Tab } from '@/core/constants/query-params.constants';
import { Paths } from '@/core/constants/routes.constants';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { PROJECT_TYPE } from '@/core/types/projects.types';
import { isWindowDefined } from '@/core/utils/is-window-defined/is-window-defined';
import { useInsightsStore } from '@/fine-tune/stores/insights-store/insights.store';
import useStore from '@/fine-tune/stores/store';

export const useFineTuneHotkeys = () => {
  // Hooks
  const { setParameters } = useParametersStoreActions();

  const { runId, projectId } = usePathParameters();

  const { tab, isRegex } = useParametersStore((s) => ({
    tab: s.tab,
    isRegex: s.isRegex
  }));

  const { setIsInsightColumnVisible } = useInsightsStore((s) => s.actions);
  const isInsightColumnVisible = useInsightsStore(
    (s) => s.isInsightsColumnVisible
  );

  const { setIsActionPaneVisible, setBaselineRunId } = useStore(
    (s) => s.actions
  );
  const isActionPaneVisible = useStore((s) => s.isActionPaneVisible);

  const { openModal } = useModals();

  const { data: project } = useCurrentProject();

  const router = useRouter();

  // Computed
  const isFineTuneProject = project?.type === PROJECT_TYPE.TRAINING_INFERENCE;

  let config = [
    {
      id: 'toggle-insights-column',
      title: 'Toggle Insights Column',
      description: 'Open or close the Insights Column',
      binding: 'mod+i',
      callback: () => setIsInsightColumnVisible(!isInsightColumnVisible)
    },
    {
      id: 'regex-search',
      title: 'Toggle Regex Text Search',
      description:
        'Toggle between RegEx and plain text search for dataframe and embeddings',
      binding: 'mod+shift+u',
      callback: () => setParameters({ isRegex: !isRegex })
    },
    {
      id: 'export-modal',
      title: 'Open Export Modal',
      description: 'Open modal with dataframe export options',
      binding: 'mod+shift+e',
      callback: () => openModal(Modals.EXPORTS)
    },
    {
      id: 'open-cart',
      title: 'Open Cart',
      description: 'Open Edits or Labels Cart to see changes for current run',
      binding: 'mod+shift+2',
      callback: () => openModal(Modals.EDITS_CART)
    },
    {
      id: 'filter-controls',
      title: 'Open Filters Controls',
      description:
        'Open modal to add or update current filters, or save filters as a slice',
      binding: 'mod+ArrowUp',
      callback: () => openModal(Modals.CREATE_SLICE)
    },
    {
      id: 'action-pane',
      title: 'Toggle Action Pane',
      description: 'Open or close the action pane for current selection',
      binding: 'mod+j',
      callback: () => setIsActionPaneVisible(!isActionPaneVisible)
    },
    {
      id: 'switch-tab',
      title: 'Switch Tab',
      description: 'Switch between dataframe and embeddings tabs',
      binding: 'mod+shift+1',
      callback: () =>
        setParameters({
          tab: tab === Tab.DATAFRAME ? Tab.EMBEDDINGS : Tab.DATAFRAME
        })
    },
    {
      id: 'text-search',
      title: 'Text Search',
      description: 'Quickly access the search bar for dataframe and embeddings',
      binding: 'mod+k',
      callback: () => {
        const queryStr = `${tab}-text-search-bar`;
        const textSearchBar = document
          .getElementById(queryStr)
          ?.closest('input');

        textSearchBar?.focus();
      }
    },
    {
      id: 'compare',
      title: 'Go to Compare Runs for Current Project',
      description: 'Navigate to compare runs page for current project',
      binding: 'mod+shift+c',
      callback: () => {
        if (projectId) {
          setBaselineRunId(runId);
          router.push({ pathname: Paths.COMPARE_RUNS });
        }
      }
    },
    {
      id: 'undo',
      title: 'Undo filter',
      description: 'Remove last filter applied',
      binding: 'mod+z',
      callback: () => {
        router.back();
        showNotification({
          title: 'Last filter removed!',
          message: 'Undo action via cmd+z'
        });
      }
    },
    {
      id: 'redo',
      title: 'Redo filter',
      description: 'Reapply last filter removed',
      binding: 'mod+shift+z',
      callback: () => {
        if (isWindowDefined()) {
          window.history.forward();
        }
        showNotification({
          title: 'Last filter reapplied!',
          message: 'Redo action via cmd+shift+z'
        });
      }
    }
  ];

  if (!isFineTuneProject) {
    config = [];
  }

  return {
    hotkeys: config
  };
};
