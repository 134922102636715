import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

const POST_TOP_INSIGHTS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/top';

/**
 * useTopInsights
 *
 */
export const useTopInsights = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);
  const task = useParametersStore((state) => state.task);

  const isPrompt = useComputedParameters('isPrompt');

  const filter_params = useFilterParams();

  const enabled = Boolean(projectId && runId && !isPrompt);

  const fetchTopInsights = async () => {
    const res = await api.POST(POST_TOP_INSIGHTS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body: {
        filter_params
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [POST_TOP_INSIGHTS, { projectId, runId, split, filter_params, task }],
    fetchTopInsights,
    { enabled }
  );

  return response;
};
