import { Pill, PillsInput, Tooltip } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';

interface CustomPillsProps {
  value: string[];
  errors?: Record<string, string>;
  defaultValue?: string[];
  label?: string;
  description?: string;
  onSearchChange?: (value: string) => void;
  maxTags?: number;
  onOptionSubmit: (option: string) => void;
  onRemove: (tag: string) => void;
  placeholder: string;
  defaultSearchValue?: string;
  searchValue?: string;
}

function splitTags(splitChars: string[] | undefined, value: string) {
  if (!splitChars) return [value];

  return value
    .split(new RegExp(`[${splitChars.join('')}]`))
    .map((tag) => tag.trim())
    .filter((tag) => tag !== '');
}

interface GetSplittedTagsInput {
  splitChars: string[] | undefined;
  maxTags: number | undefined;
  value: string;
  currentTags: string[];
}

export function getSplittedTags({
  splitChars,
  maxTags,
  value,
  currentTags
}: GetSplittedTagsInput) {
  const splitted = splitTags(splitChars, value);
  const merged = [...new Set([...currentTags, ...splitted])];

  return maxTags ? merged.slice(0, maxTags) : merged;
}

const splitChars = [',', ';', ' '];

const CustomPillsInput = ({
  description,
  label,
  value,
  errors,
  onSearchChange,
  searchValue,
  defaultSearchValue,
  maxTags = 10,
  onOptionSubmit,
  placeholder,
  onRemove
}: CustomPillsProps) => {
  const [_searchValue, setSearchValue] = useUncontrolled({
    value: searchValue,
    defaultValue: defaultSearchValue,
    finalValue: '',
    onChange: onSearchChange
  });

  const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = _searchValue.trim();
    const { length } = inputValue;
    if (splitChars!.includes(event.key) && length > 0) {
      const splitted = getSplittedTags({
        splitChars,
        maxTags,
        value: _searchValue,
        currentTags: value
      });
      onOptionSubmit?.(splitted[splitted.length - 1]);
      setSearchValue('');
      event.preventDefault();
    }

    if (event.key === 'Enter' && length > 0 && !event.nativeEvent.isComposing) {
      event.preventDefault();
      const isDuplicate = value.some(
        (tag) => tag.toLowerCase() === inputValue.toLowerCase()
      );

      if (!isDuplicate && value.length < maxTags!) {
        onOptionSubmit?.(inputValue);
        setSearchValue('');
      }
    }

    if (
      event.key === 'Backspace' &&
      length === 0 &&
      value.length > 0 &&
      !event.nativeEvent.isComposing
    ) {
      onRemove(value[value.length - 1]);
    }
  };

  return (
    <PillsInput
      data-testid='custom-pills-input'
      description={description}
      label={label}
    >
      <Pill.Group>
        {value.map((tag) => {
          const errorMessage = errors?.[tag];
          const isErrored = Boolean(errorMessage);
          return (
            <Tooltip
              multiline
              disabled={!errorMessage}
              key={tag}
              label={errorMessage}
              maw={300}
              zIndex={99999}
            >
              <Pill
                withRemoveButton
                styles={{
                  root: {
                    ...(isErrored && {
                      backgroundColor: 'var(--mantine-color-red-1)'
                    })
                  },
                  label: {
                    ...(isErrored && { color: 'var(--mantine-color-red-6)' })
                  },
                  remove: {
                    ...(isErrored && { color: 'var(--mantine-color-red-6)' })
                  }
                }}
                onRemove={() => {
                  onRemove(tag);
                }}
              >
                {tag}
                {isErrored && (
                  <IconAlertTriangle
                    size={14}
                    style={{ marginBottom: -2, marginLeft: 2 }}
                  />
                )}
              </Pill>
            </Tooltip>
          );
        })}
        <PillsInput.Field
          placeholder={placeholder}
          value={_searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          onKeyDown={handleInputKeydown}
        />
      </Pill.Group>
    </PillsInput>
  );
};

export default CustomPillsInput;
