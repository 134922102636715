import {
  ActionIcon,
  Box,
  Skeleton,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import { IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

interface EditableLabelProps {
  initialLabel: string;
  isLoading?: boolean;
  editable?: boolean;
  color?: string;
  withSeparator?: boolean;
  onChange: (label: string) => void;
}

const EditableLabel = ({
  initialLabel,
  color,
  isLoading,
  editable,
  withSeparator = false,
  onChange
}: EditableLabelProps) => {
  // Local State
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState<string>(initialLabel);

  // Computed
  const inputLength = initialLabel?.length || 0;
  const MAX_WIDTH = 500;
  const MIN_WIDTH = 250;
  const inputWidth = Math.min(
    Math.max(inputLength * 8.5, MIN_WIDTH),
    MAX_WIDTH
  ); // Get the length of the input. Min width is 250, max width is 500

  // Handlers
  const handleSaveLabel = () => {
    onChange(label);

    setIsEditing(false);
  };

  useEffect(() => {
    if (initialLabel) {
      setLabel(initialLabel);
    }
  }, [initialLabel]);

  // Render
  if (isLoading) {
    return <Skeleton data-testid='loading-skeleton' height={25} width={250} />;
  }

  if (isEditing) {
    return (
      <>
        <Box w={inputWidth}>
          <TextInput
            autoFocus
            defaultValue={label}
            w='auto'
            onChange={(e) => setLabel(e.currentTarget.value)}
            onKeyDown={(e) => {
              e.key === 'Enter' && handleSaveLabel();
            }}
          />
        </Box>
        <ActionIcon
          aria-label='save name'
          variant='subtle'
          onClick={handleSaveLabel}
        >
          <IconCheck size={16} />
        </ActionIcon>
        <ActionIcon
          variant='subtle'
          onClick={() => {
            setLabel(initialLabel);
            setIsEditing(false);
          }}
        >
          <IconX size={16} />
        </ActionIcon>
      </>
    );
  } else {
    return (
      <>
        {withSeparator && <Text>/</Text>}
        <Tooltip multiline label={label}>
          <Text
            truncate
            c={color}
            data-testid='label-text'
            fw={700}
            maw={650}
            size='sm'
          >
            {label}
          </Text>
        </Tooltip>
        {editable && (
          <ActionIcon
            aria-label='edit name'
            variant='subtle'
            onClick={() => setIsEditing(true)}
          >
            <IconEdit size={16} />
          </ActionIcon>
        )}
      </>
    );
  }
};

export default EditableLabel;
