import { useMutation } from '@tanstack/react-query';

import api from '@/core/api';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/strings.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import { useExportFilterParams } from '@/fine-tune/hooks/use-export-filter-params/use-export-filter-params';

interface LabelstudioExportValues {
  project: string;
  labels: string[];
  workspace: string;
}

export const useLabelstudioExport = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((s) => s.split);

  const parsedFilterParams = useExportFilterParams();

  const path = '/integrations/labelstudio/export';

  const handler = async (values: LabelstudioExportValues) => {
    const body = {
      project_id: projectId!,
      run_id: runId!,
      split,
      project_name: values.project,
      labels: values.labels,
      workspace: values.workspace,
      filter_params: parsedFilterParams as any
    };

    return api
      .POST(path, {
        body
      })
      .then((resp) => {
        showNotification({
          title: 'Export successful!'
        });
        return resp.data;
      })
      .catch((error) => {
        showNotification({
          title: 'Error exporting data',
          message: error?.response?.data?.detail || GENERIC_ERROR_MESSAGE,
          type: 'error'
        });
      });
  };

  const mutation = useMutation(handler);

  return mutation;
};
