import { Box, Divider, Group, Paper, Portal, Text } from '@mantine/core';

import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';

import { BuiltSegment } from './token-level-score';

interface HoverTooltipProps {
  x: number;
  y: number;
  seg: BuiltSegment;
}
const HoverTooltip = ({ x, y, seg }: HoverTooltipProps) => (
  <Portal>
    <div
      data-testid='hover-tooltip'
      style={{
        borderRadius: '16px',
        top: `${y + 10}px`,
        left: `${x}px`,
        overflow: 'hidden',
        position: 'fixed',
        transitionDuration: '100ms',
        transitionProperty: 'all',
        transitionTimingFunction: 'linear',
        zIndex: 99999
      }}
    >
      <Paper
        withBorder
        maw={700}
        miw={150}
        p={0}
        radius='lg'
        shadow='xl'
        w={150}
      >
        <Box
          bg='gray.1'
          mb={6}
          px={10}
          py={8}
          style={{
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16
          }}
        >
          <Group justify='space-between'>
            <Text c='gray.8' fw={600} size='xs'>
              {seg.textToHighlight}
            </Text>
            <Text c='gray.8' fw={600} size='xs'>
              {parsePercentage(seg.segProbability)}
            </Text>
          </Group>
        </Box>

        {seg.kTokens?.map((token, index) => (
          <Group justify='space-between' key={index} px={10}>
            <Text
              color={index === 0 ? '#644DF9' : '#706C89'}
              fw={index === 0 ? 600 : 500}
              size='xs'
            >
              {token.token}
            </Text>
            <Text
              color={index === 0 ? '#644DF9' : '#706C89'}
              fw={index === 0 ? 600 : 500}
              size='xs'
            >
              {parsePercentage(token.prob)}
            </Text>
          </Group>
        ))}
        <Divider color='gray.2' my={2} />
        <Group justify='space-between' mt={4} px={10} py={6}>
          <Text c='gray.7' size='sm'>
            {seg.isUncertainty ? 'Uncertainty' : 'DEP'}
          </Text>
          <Text c={seg.color} fw={600} size='sm'>
            {seg.value?.toFixed(3)}
          </Text>
        </Group>
      </Paper>
    </div>
  </Portal>
);

export default HoverTooltip;
