import { Badge, Divider, Group, Tabs } from '@mantine/core';
import {
  IconAlertTriangle,
  IconChartCircles,
  IconChartHistogram
} from '@tabler/icons-react';
import { useEffect } from 'react';

import classes from './insights-column-tabs.module.css';

import { Tab } from '@/core/constants/query-params.constants';
import {
  useComputedParameters,
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import {
  ALERTS_COLUMN_KEY,
  CLUSTERS_COLUMN_KEY,
  InsightsTabOptions,
  METRICS_COLUMN_KEY
} from '@/core/stores/parameters-store/parameters.store.types';
import { useCartographClusters } from '@/fine-tune/hooks/query-hooks/use-cartograph-clusters/use-cartograph-clusters';
import { useInsightAlerts } from '@/fine-tune/hooks/query-hooks/use-insights-alerts/use-insights-alerts';
import { useInsightsStore } from '@/fine-tune/stores/insights-store/insights.store';

import AlertColumnContent from '../alert-column-content/alert-column-content';
import ClusterColumnContent from '../cluster-column-content/cluster-column-content';
import MetricsColumnContent from '../metrics-column-content/metrics-column-content';
/**
 * InsightsColumnTabs
 *
 *
 *
 * @returns {React.Component} InsightsColumnTab
 */

const InsightColumnTabs = () => {
  // Hooks
  const { alertCount } = useInsightAlerts();
  const { disableClustersTab, clustersAmount } = useCartographClusters();

  // Parameters Store
  const { setParameters } = useParametersStoreActions();
  const insightsTab = useParametersStore((state) => state.insightsTab);

  // Insights Store
  const activeInsightsCard = useInsightsStore((s) => s.activeInsightsCard);

  // Computed Params
  const { isMltc, isTextClassification, isS2S, isSD, isSS } =
    useComputedParameters();

  // Effects
  useEffect(() => {
    if (activeInsightsCard) {
      setParameters({ insightsTab: METRICS_COLUMN_KEY });
    } else {
      setParameters({ insightsTab: ALERTS_COLUMN_KEY });
    }
  }, [activeInsightsCard]);

  useEffect(() => {
    if (isMltc) {
      setParameters({ insightsTab: METRICS_COLUMN_KEY });
    }
  }, [isMltc]);

  // Event handlers
  const handleTabChange = (tab: InsightsTabOptions) => {
    let newParams: { insightsTab: InsightsTabOptions; tab?: Tab } = {
      insightsTab: tab
    };

    const showModelView = !isSD && !isSS && !isS2S;

    if (tab === CLUSTERS_COLUMN_KEY) {
      newParams = {
        ...newParams,
        tab: showModelView ? Tab.EMBEDDINGS : Tab.DATA_VIEW
      };
    }

    setParameters(newParams);
  };

  const showClustersTab = isTextClassification || isMltc || isS2S;

  return (
    <Tabs
      classNames={classes}
      color='brand.2'
      data-testid='insights-column-tabs'
      p='xs'
      styles={{
        tab: {
          height: 34
        }
      }}
      value={insightsTab}
      variant='pills'
      onChange={(tab) => handleTabChange(tab as InsightsTabOptions)}
    >
      <Tabs.List py={0}>
        <Tabs.Tab
          disabled={isMltc}
          leftSection={<IconAlertTriangle size={16} />}
          p={4}
          py={0}
          size={7}
          value='alerts'
        >
          <Group align='center' gap={6}>
            Alerts
            <Badge
              color='red'
              radius='sm'
              size='md'
              style={{
                backgroundColor: 'var(--mantine-color-red-2)',
                color: 'var(--mantine-color-red-8)',
                padding: '2px 4px'
              }}
            >
              {alertCount}
            </Badge>
          </Group>
        </Tabs.Tab>
        <Tabs.Tab
          leftSection={<IconChartHistogram size={16} />}
          p={4}
          value='metrics'
        >
          Metrics
        </Tabs.Tab>

        {showClustersTab && (
          <Tabs.Tab
            disabled={disableClustersTab}
            leftSection={<IconChartCircles size={16} />}
            p={8}
            value={CLUSTERS_COLUMN_KEY}
          >
            Clusters
            {!disableClustersTab && (
              <Badge
                color='red'
                data-testid='clusters-amount-badge'
                ml={2}
                radius='sm'
                size='lg'
                style={{
                  backgroundColor: 'var(--mantine-color-red-2)',
                  color: 'var(--mantine-color-red-8)',
                  padding: '2px 4px'
                }}
              >
                {clustersAmount}
              </Badge>
            )}
          </Tabs.Tab>
        )}
      </Tabs.List>

      <Divider color='contrast.7' mt='xs' />
      <Tabs.Panel pt='xs' value={ALERTS_COLUMN_KEY}>
        <AlertColumnContent />
      </Tabs.Panel>

      <Tabs.Panel pt='xs' value={METRICS_COLUMN_KEY}>
        <MetricsColumnContent />
      </Tabs.Panel>

      <Tabs.Panel pt='xs' value={CLUSTERS_COLUMN_KEY}>
        <ClusterColumnContent />
      </Tabs.Panel>
    </Tabs>
  );
};

export default InsightColumnTabs;
