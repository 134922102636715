import { Menu, MenuProps, Text, UnstyledButton } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import React, { useRef, useState } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

export type PopoverMenuItem = {
  color?: string;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  withDivider?: boolean;
};

interface PopoverMenuProps extends MenuProps {
  data: PopoverMenuItem[];
  menuLabel: string;
  target: React.ReactNode;
}

export const PopoverMenu = ({
  data = [],
  menuLabel,
  target,
  ...others
}: PopoverMenuProps) => {
  // Local State
  const [opened, setOpened] = useState(false);

  // Hooks
  const { width } = useViewportSize();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Computed
  const { right } = buttonRef?.current?.getBoundingClientRect?.() || {
    right: 0
  };
  const flipMenuPosition = right > width - 30;
  const position = flipMenuPosition ? 'bottom-end' : 'bottom-start';

  // Handlers
  const handleItemClick = (e: React.MouseEvent, item: PopoverMenuItem) => {
    e.preventDefault();
    e.stopPropagation();
    item?.onClick?.();
  };

  const handleOpenMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    !opened && setOpened(true);
  };

  return (
    <Menu
      withinPortal
      opened={opened}
      position={position}
      shadow='md'
      zIndex={Z_INDEX.TOOLTIPS}
      onClose={() => setOpened(false)}
      {...others}
    >
      <Menu.Target>
        <UnstyledButton
          aria-label='Open popover menu'
          data-testid='popover-menu-trigger'
          ref={buttonRef}
          onClick={handleOpenMenu}
        >
          {target}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{menuLabel}</Menu.Label>
        {data.map((item) => {
          const color = item.color || 'contrast.3';
          return (
            <React.Fragment key={item.label}>
              <Menu.Item
                closeMenuOnClick={true}
                disabled={item.disabled}
                leftSection={item.icon}
                onClick={(e) => handleItemClick(e, item)}
              >
                <Text c={item.disabled ? 'contrast.5' : color} size='sm'>
                  {item.label}
                </Text>
              </Menu.Item>
              {item?.withDivider && <Menu.Divider />}
            </React.Fragment>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
