import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';

import { useFilterParams } from '../../use-filters-params/use-filter-params';

const POST_SIMILAR_TO_IDS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/ids';

export const useSimilarToIds = () => {
  const { runId, projectId } = usePathParameters();

  const { split, slice } = useParametersStore((s) => ({
    split: s.split,
    slice: s.slice
  }));

  const allRowsSelected = useStore((state) => state.allRowsSelected);

  const filterParams = useFilterParams();

  const body = {
    include_emb: false,
    hard_easy_threshold: false,
    filter_params: filterParams
  };

  const fetchSimilarIds = async () => {
    const res = await api.POST(POST_SIMILAR_TO_IDS, {
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!,
          split
        }
      },
      body
    });

    return res.data;
  };

  const response = useQuery(
    [
      POST_SIMILAR_TO_IDS,
      {
        projectId,
        runId,
        split,
        slice,
        ...filterParams
      }
    ],
    () => fetchSimilarIds(),
    { enabled: allRowsSelected }
  );

  return response;
};
