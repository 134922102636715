import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  Input,
  MultiSelect,
  NumberInput,
  ScrollArea,
  Switch,
  Text
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import {
  IconCalendarEvent,
  IconClipboard,
  IconDatabase,
  IconFolder,
  IconMathGreater,
  IconUser
} from '@tabler/icons-react';
import _debounce from 'lodash/debounce';

import {
  TASK_TYPE,
  TaskType
} from '@/core/constants/tasks-and-frameworks.constants';
import { useCollaborators } from '@/core/hooks/query-hooks/use-collaborators/use-collaborators';
import { useProjects } from '@/core/hooks/query-hooks/use-projects/use-projects';
import useTaskType from '@/core/hooks/use-task-type/use-task-type';
import { useAdvancedRunSearchStore } from '@/core/stores/advanced-run-search-store/advanced-run-search.store';
import { Splits } from '@/core/stores/parameters-store/parameters.store.types';

/**
 * ActionsWrapper
 *
 *
 *
 * @returns {React.Component} ActionsWrapper
 */
const ActionsWrapper = () => {
  const { projectSelectData } = useProjects();
  const { data: collaboratorsData } = useCollaborators();

  const {
    computed,
    inProjects,
    isAdvancedSearchOpened,
    excludeZeroSampleRuns,
    bySplitType,
    byCollaborator,
    actions,
    isQueryPresent,
    noMatchingRuns
  } = useAdvancedRunSearchStore((s) => ({
    actions: s.actions,
    byCollaborator: s.byCollaborator,
    computed: s.computed,
    inProjects: s.inProjects,
    isAdvancedSearchOpened: s.isAdvancedSearchOpened,
    excludeZeroSampleRuns: s.excludeZeroSampleRuns,
    bySplitType: s.bySplitType,
    noMatchingRuns: s.noMatchingRuns,
    isQueryPresent: s.isQueryPresent
  }));

  const creatorData =
    collaboratorsData?.creators?.map(
      ({ first_name, last_name, email, id }) => ({
        label: first_name ? `${first_name} ${last_name ?? ''}` : email ?? '',
        value: id
      })
    ) ?? [];

  const debouncedNumSampleSetter = _debounce(
    (num: number) => actions.setNumSamples(num ?? null),
    500
  );

  // NOTE: don't include NLI or PROMPT_CHAINS
  const tcTaskType = useTaskType(TASK_TYPE.MCTC);
  const nerTaskType = useTaskType(TASK_TYPE.NER);
  const mltcTaskType = useTaskType(TASK_TYPE.MLTC);
  const icTaskType = useTaskType(TASK_TYPE.IC);
  const odTaskType = useTaskType(TASK_TYPE.OD);
  const sdTaskType = useTaskType(TASK_TYPE.SD);
  const ssTaskType = useTaskType(TASK_TYPE.SS);
  const peTaskType = useTaskType(TASK_TYPE.PE);
  const s2sTaskType = useTaskType(TASK_TYPE.S2S);
  const pmTaskType = useTaskType(TASK_TYPE.PM);

  const taskTypeSelectData = [
    { label: tcTaskType.label, value: TASK_TYPE.MCTC },
    { label: nerTaskType.label, value: TASK_TYPE.NER },
    { label: mltcTaskType.label, value: TASK_TYPE.MLTC },
    { label: icTaskType.label, value: TASK_TYPE.IC },
    { label: odTaskType.label, value: TASK_TYPE.OD },
    { label: sdTaskType.label, value: TASK_TYPE.SD },
    { label: ssTaskType.label, value: TASK_TYPE.SS },
    { label: peTaskType.label, value: TASK_TYPE.PE },
    { label: s2sTaskType.label, value: TASK_TYPE.S2S },
    { label: pmTaskType.label, value: TASK_TYPE.PM }
  ];

  return (
    <>
      <Box data-testid='action-wrapper-advanced-search' px='xs'>
        <Button
          p={0}
          size='xs'
          variant='subtle'
          onClick={() => actions.toggleAdvancedSearch()}
        >
          {isAdvancedSearchOpened
            ? 'Hide advanced search'
            : 'Show advanced search'}
        </Button>
        <Collapse in={isAdvancedSearchOpened}>
          <Card padding={4} shadow='sm'>
            <ScrollArea h={150} mb='xs' type='auto'>
              <Grid w='97%'>
                <Grid.Col className='align-self-center' span={6}>
                  <Switch
                    checked={excludeZeroSampleRuns as boolean}
                    label='Exclude Runs without Samples'
                    onChange={(e) =>
                      actions.setExcludeZeroSampleRuns(e.currentTarget.checked)
                    }
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <MultiSelect
                    clearable
                    searchable
                    comboboxProps={{ zIndex: 10000 }}
                    // @ts-expect-error
                    data={projectSelectData ?? []}
                    icon={<IconFolder />}
                    label='In project(s)'
                    nothingFoundMessage='No matching project found'
                    placeholder='Search within project(s)'
                    value={inProjects}
                    zIndex={10000}
                    onChange={(val) => actions.setInProjects(val)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DatePickerInput
                    allowSingleDateInRange
                    clearable
                    label='Created within'
                    leftSection={<IconCalendarEvent />}
                    placeholder='Search by date created range'
                    popoverProps={{ zIndex: 10000, withinPortal: true }}
                    type='range'
                    onChange={actions.setWithinRange}
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <MultiSelect
                    clearable
                    searchable
                    comboboxProps={{ zIndex: 10000 }}
                    data={taskTypeSelectData}
                    label='By Task Type(s)'
                    leftSection={<IconClipboard />}
                    placeholder='Search for runs by task type'
                    onChange={(types) =>
                      actions.setByTaskTypes(types as TaskType[])
                    }
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <Input.Wrapper label='By Number of Samples'>
                    <NumberInput
                      hideControls
                      leftSection={<IconMathGreater size={16} />}
                      placeholder='Search by samples (greater than)'
                      onChange={(val) =>
                        debouncedNumSampleSetter(val as number)
                      }
                    />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={6}>
                  <MultiSelect
                    clearable
                    searchable
                    comboboxProps={{ zIndex: 10000 }}
                    data={creatorData}
                    disabled={!creatorData.length}
                    label='Search by Creator'
                    leftSection={<IconUser />}
                    placeholder='Search by run creator'
                    value={byCollaborator}
                    onChange={actions.setByCollaborator}
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <MultiSelect
                    clearable
                    searchable
                    comboboxProps={{ zIndex: 10000 }}
                    data={[
                      { label: 'Training', value: 'training' },
                      { label: 'Validation', value: 'validation' },
                      { label: 'Test', value: 'test' },
                      { label: 'Inference', value: 'inference' }
                    ]}
                    label='Search by Split Type'
                    leftSection={<IconDatabase />}
                    placeholder='Search by runs with split type'
                    value={bySplitType}
                    onChange={(vals) =>
                      actions.setBySplitType(vals as Splits[])
                    }
                  />
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Card>
        </Collapse>

        <Text mt='xs'>
          {computed?.isDefaultState?.()
            ? 'Runs in Current Project'
            : 'Top Matching Runs'}
        </Text>
      </Box>

      {noMatchingRuns && !isQueryPresent && (
        <Box
          p={5}
          style={{
            borderTop: `1px solid var(--mantine-color-gray-2)`
          }}
        >
          <Text c='dimmed' py='md' size='lg' ta='center'>
            No runs matching query
          </Text>
        </Box>
      )}
    </>
  );
};

export default ActionsWrapper;
