import {
  Box,
  Card,
  CSSProperties,
  Grid,
  Group,
  ScrollArea,
  Select,
  SelectStylesNames,
  Text
} from '@mantine/core';
import { useEffect } from 'react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import { useParametersStoreActions } from '@/core/stores/parameters-store';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import OdLabelBadge from '@/fine-tune/components/label-badge/od-label-badge/od-label-badge';
import { useInsightsErrors } from '@/fine-tune/hooks/query-hooks/use-insights-errors/use-insights-errors';
import { useInsightsErrorsDistribution } from '@/fine-tune/hooks/query-hooks/use-insights-errors-distribution/use-insights-errors-distribution';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';

const TopGroupedByChart = () => {
  // CV Store
  const { actions, errorDistributionError, errorDistributionCol } =
    useComputerVisionStore((s) => ({
      errorDistributionError: s.errorDistributionError,
      errorDistributionCol: s.errorDistributionCol,
      actions: s.actions
    }));

  // Filter Param Store
  const { setParameters } = useParametersStoreActions();

  // Hooks
  const { data: errorData, isFetching: isInsightsErrorsFetching } =
    useInsightsErrors();
  const {
    data: errorDistributionData,
    isFetching: isDistributionDataFetching
  } = useInsightsErrorsDistribution();

  // Computed
  const isFetching = isInsightsErrorsFetching || isDistributionDataFetching;

  const zippedDistributions =
    errorDistributionData?.labels?.map((label, i) => {
      const count = errorDistributionData?.counts?.[i] || 0;
      return {
        label,
        count
      };
    }) || [];

  const zippedErrorData =
    errorData?.labels?.map((label, i) => {
      const count = errorData?.values?.[i] || 0;
      return {
        label,
        count
      };
    }) || [];

  const errorCount = zippedDistributions
    .filter((x) => x.label !== 'None')
    .reduce((x, y) => x + y.count, 0);

  const errorSelectData =
    zippedErrorData
      .sort((obj) => obj?.count)
      .filter((obj) => obj?.label !== 'None')
      .map((obj) => ({
        label: `${obj?.label?.replaceAll('_', ' ')} Error`,
        value: obj.label
      })) || [];

  const groupedBySelectData: string[] = ['class'];

  const selectStyles: Partial<Record<SelectStylesNames, CSSProperties>> = {
    input: { textTransform: 'capitalize' },
    option: { textTransform: 'capitalize' }
  };

  // Effects
  useEffect(() => {
    if (!errorDistributionError) {
      setError(errorSelectData?.[0]?.value);
    }

    if (!errorDistributionCol) {
      setColumn('class');
    }
  }, [errorSelectData]);

  // Event Handlers
  const setColumn = (column: string) =>
    column && actions.setErrorDistributionCol(column);
  const setError = (error: string) =>
    error && actions.setErrorDistributionError(error);

  return (
    <SplitCard
      header={
        <Grid>
          <Grid.Col span={{ lg: 7, xs: 12 }}>
            <Text size='sm'>Top</Text>
            <Select
              className='text-capitalize suffix-error'
              data={errorSelectData}
              radius='md'
              size='xs'
              styles={selectStyles}
              value={errorDistributionError}
              width={275}
              onChange={(val) => setError(val as string)}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: 5, xs: 12 }}>
            <Text size='sm'>grouped by</Text>
            <Select
              className='justify-flex-start'
              data={groupedBySelectData}
              radius='md'
              size='xs'
              styles={selectStyles}
              value={errorDistributionCol}
              w={100}
              onChange={(val) => setColumn(val as string)}
            />
          </Grid.Col>
        </Grid>
      }
      isLoading={isFetching}
      mt='xs'
      subheader={
        <Text size='sm'>
          <Text c='brand.4' component='span' fw={700} ml={2} mr={2}>
            {errorCount ? (
              toHumanReadableNumber(errorCount)
            ) : (
              <DataNotAvailable />
            )}
          </Text>
          <Text component='span' fw={300}>
            (
            {parsePercentage(errorDistributionData?.percentage) ?? (
              <DataNotAvailable />
            )}{' '}
            of all{' '}
            {errorDistributionError === 'missed' ? 'labels' : 'predictions'})
          </Text>
        </Text>
      }
    >
      <Card.Section>
        <Grid
          grow
          p='sm'
          style={{
            backgroundColor: 'var(--mantine-color-gray-0)',
            borderBottom: `1px solid var(--mantine-color-gray-2)`
          }}
        >
          <Grid.Col span={{ md: 6 }}>
            <Text size='xs'>Classes</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text size='xs' ta='center'>
              Percentage
            </Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text size='xs' ta='center'>
              Object Count
            </Text>
          </Grid.Col>
        </Grid>
      </Card.Section>
      <Card.Section>
        <ScrollArea.Autosize mah={600} mt='xs'>
          {zippedDistributions.map((distribution, i) => {
            const { label, count } = distribution;

            return (
              <Box
                key={label}
                style={{
                  borderBottom:
                    i === zippedDistributions.length - 1
                      ? 'unset'
                      : `1px solid var(--mantine-color-gray-2)`
                }}
              >
                <Grid
                  data-testid='error-grouped-by-row'
                  p='0px 12px'
                  style={{
                    '&:hover': {
                      backgroundColor: 'var(--mantine-color-contrast-8)',
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => {
                    setParameters({
                      classFilter: [label],
                      metaFilter: [
                        {
                          name: 'galileo_error_type',
                          isin: [errorDistributionError]
                        }
                      ]
                    });
                  }}
                >
                  <Grid.Col span={6} style={{ cursor: 'pointer' }}>
                    <Group gap='xs'>
                      <OdLabelBadge key={label} label={label} />
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text ta='center'>
                      {parsePercentage(count / errorCount)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text ta='center'>{count}</Text>
                  </Grid.Col>
                </Grid>
              </Box>
            );
          })}
        </ScrollArea.Autosize>
      </Card.Section>
    </SplitCard>
  );
};

export default TopGroupedByChart;
