import { Badge, MantineSize } from '@mantine/core';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';

export const getPillProps = (status: string) => {
  switch (status) {
    case 'TIMED_OUT':
      return { color: 'yellow', variant: 'light' };
    case 'ERROR':
      return { color: 'red', variant: 'outline' };
    case 'TRIGGERED':
      return { color: 'red', variant: 'light' };
    default:
      return { color: 'gray', variant: 'light' };
  }
};

const ProtectStatusPill = ({
  status,
  size = 'md'
}: {
  status: string;
  size?: MantineSize;
}) => {
  const { color, variant } = getPillProps(status);

  return (
    <Badge
      color={color}
      data-testid='protect-status-pill'
      radius='md'
      size={size}
      tt='none'
      variant={variant}
    >
      {_startCase(_camelCase(status))}
    </Badge>
  );
};

export default ProtectStatusPill;
