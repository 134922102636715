import {
  Button,
  ButtonProps,
  ColorSwatch,
  Group,
  Loader,
  Text
} from '@mantine/core';

interface RatingStackConfigurationItemProps extends ButtonProps {
  isDraft: boolean;
  isSaving: boolean;
  isSelected: boolean;
  label: string;
}

export const RatingStackConfigurationItem = ({
  isDraft,
  isSaving,
  isSelected,
  label,
  ...props
}: RatingStackConfigurationItemProps) => {
  const bg = isSelected ? 'contrast.8' : undefined;
  const c = isSelected ? 'gray.8' : 'gray.7';
  const dotBg = isSelected
    ? 'var(--mantine-color-gray-6)'
    : 'var(--mantine-color-gray-4)';
  const fs = isDraft ? 'italic' : 'unset';
  const fw = isSelected ? 600 : 500;

  return (
    <Button
      bg={bg}
      c={c}
      data-testid='rating-config-item'
      fs={fs}
      justify='space-between'
      maw='185px'
      variant='subtle'
      {...props}
    >
      <Group gap='xs' maw='100%' wrap='nowrap'>
        <Text fw={fw} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {label}
        </Text>
        {isDraft && (
          <ColorSwatch
            color={dotBg}
            data-testid='draft-badge'
            size={7}
            withShadow={false}
          />
        )}
        {isSaving && (
          <Loader data-testid='config-item-loader' size='xs' type='dots' />
        )}
      </Group>
    </Button>
  );
};
