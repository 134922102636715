import { Modals } from '@/core/hooks/use-modals/use-modals';
import CreateSliceModal from '@/fine-tune/modals/create-slice-modal/create-slice-modal';
import CvModal from '@/fine-tune/modals/cv-modal/cv-modal';
import DataEditsCartModal from '@/fine-tune/modals/data-edits-cart-modal/data-edits-cart-modal';
import EditsCartModal from '@/fine-tune/modals/edits-cart-modal/edits-cart-modal';
import ExportsModal from '@/fine-tune/modals/export-modal/export-modal';
import GuideVideoModal from '@/fine-tune/modals/guide-video-modal/guide-video-modal';
import OdModal from '@/fine-tune/modals/od-modal/od-modal';
import RelabelModal from '@/fine-tune/modals/relabel-modal/relabel-modal';
import RemoveOrSelectModal from '@/fine-tune/modals/remove-or-select-modal/remove-or-select-modal';
import ReplaceWithGoldModal from '@/fine-tune/modals/replace-with-gold-modal/replace-with-gold-modal';
import ReplaceWithPredictedModal from '@/fine-tune/modals/replace-with-predicted-modal/replace-with-predicted-modal';
import S2SRowDetailModal from '@/fine-tune/modals/s2s-row-detail-modal/s2s-row-detail-modal';
import SendToLabelerModal from '@/fine-tune/modals/send-to-labeler-modal/send-to-labeler-modal';
import SetLabelModal from '@/fine-tune/modals/set-label-modal/set-label-modal';
import SSModal from '@/fine-tune/modals/ss-modal/ss-modal';
import UpgradeModal from '@/fine-tune/modals/upgrade-modal/upgrade-modal';

export const modals = {
  [Modals.CREATE_SLICE]: CreateSliceModal,
  [Modals.CV]: CvModal,
  [Modals.DATA_EDITS_CART]: DataEditsCartModal,
  [Modals.EDITS_CART]: EditsCartModal,
  [Modals.EXPORTS]: ExportsModal,
  [Modals.GUIDE_VIDEO]: GuideVideoModal,
  [Modals.OBJECT_DETECTION]: OdModal,
  [Modals.SEMANTIC_SEGMENTATION]: SSModal,
  [Modals.S2S_ROW_DETAIL]: S2SRowDetailModal,
  [Modals.RELABEL]: RelabelModal,
  [Modals.REMOVE_OR_SELECT]: RemoveOrSelectModal,
  [Modals.REPLACE_WITH_GOLD]: ReplaceWithGoldModal,
  [Modals.REPLACE_WITH_PREDICTED]: ReplaceWithPredictedModal,
  [Modals.SEND_TO_LABELER]: SendToLabelerModal,
  [Modals.SET_LABEL]: SetLabelModal,
  [Modals.UPGRADE]: UpgradeModal
};
