import { Box, Card, Grid, Group, Text } from '@mantine/core';
import { useEffect } from 'react';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { SSMetric } from '@/core/stores/parameters-store/parameters.store.types';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import Trend from '@/fine-tune/components/trend/trend';
import {
  OD_MAP_THRESHOLDS,
  SS_METRICS
} from '@/fine-tune/constants/metric-and-grouped-selects.constants';
import { useMap } from '@/fine-tune/hooks/query-hooks/use-map/use-map';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
import { useSemsegMetrics } from '@/fine-tune/hooks/query-hooks/use-semseg-metrics/use-semseg-metrics';

import MetricSelect from '../../metric-select/metric-select';

const CVInsightsColumnStats = () => {
  // Hooks
  const { data: sampleData } = usePercentageSample();
  const { data: mapData } = useMap();
  const { data: baseMapData } = useMap({ isBaseMetrics: true });
  const { data: ioUData } = useSemsegMetrics();

  // Parameter Store
  const { setParameters, mapThreshold, metric } = useParametersStore((s) => ({
    setParameters: s.actions.setParameters,
    mapThreshold: s.mapThreshold,
    metric: s.metric
  }));

  const { isSS } = useComputedParameters();

  useEffect(() => {
    if (isSS) {
      setParameters({
        metric: SS_METRICS[1].value
      });
    }
  }, []);

  // Computed Properties
  const {
    gold_box_count,
    gold_box_percentage,
    percentage,
    pred_box_count,
    pred_box_percentage,
    sample_count,
    gold_polygon_count,
    pred_polygon_count,
    gold_polygon_percentage,
    pred_polygon_percentage
  } = sampleData || {};

  const { map } = mapData || {};

  // Utils
  const updateMetric = (val: string) => {
    if (isSS) {
      setParameters({
        metric: val as SSMetric
      });
    } else {
      setParameters({
        mapThreshold: parseFloat(val)
      });
    }
  };

  const getDelta = () => {
    let delta;

    if (baseMapData?.map && map) {
      delta = map - (baseMapData?.map as number);
    }

    return typeof delta === 'number' ? delta : 0;
  };

  const entityName = isSS ? 'Polygons' : 'Objects';
  const goldCount = isSS ? gold_polygon_count : gold_box_count;
  const predCount = isSS ? pred_polygon_count : pred_box_count;
  const goldPercentage = isSS ? gold_polygon_percentage : gold_box_percentage;
  const predPercentage = isSS ? pred_polygon_percentage : pred_box_percentage;

  const displayValue = isSS ? ioUData?.[metric as SSMetric] : map;
  const displayValueString =
    typeof displayValue === 'number'
      ? displayValue.toString()
      : STRING_PLACEHOLDER;

  const cardStyles = {
    background: 'transparent',
    borderColor: `var(--mantine-color-contrast-7) !important`
  };

  return (
    <Grid data-testid='fixed-section' gutter='xs'>
      <Grid.Col className='flex' span={5}>
        <Card
          withBorder
          py='5px'
          style={{
            flex: 1,
            ...cardStyles
          }}
        >
          <Text c='gray.6' fw={700} size='xs'>
            DATASET SIZE
          </Text>

          <Group gap='xs'>
            <Text c='contrast.2' fw={700} size='md'>
              {toHumanReadableNumber(sample_count)}
            </Text>
            <Text c='dimmed' size='xs'>
              {parsePercentage(percentage)} of all data
            </Text>
          </Group>
        </Card>
      </Grid.Col>
      <Grid.Col span={7}>
        <Card withBorder mb='xs' px='sm' py={2} style={cardStyles}>
          <Group justify='space-between' wrap='nowrap'>
            <Text c='dimmed' fw={700} size='xs'>
              Ground Truth {entityName}
            </Text>

            <Group gap='xs' wrap='nowrap'>
              <Text c='contrast.2' fw={700} size='sm'>
                {toHumanReadableNumber(goldCount)}
              </Text>
              <Text c='dimmed' size='sm'>
                {parsePercentage(goldPercentage)}
              </Text>
            </Group>
          </Group>
        </Card>
        <Card withBorder px='sm' py={2} style={cardStyles}>
          <Group justify='space-between' wrap='nowrap'>
            <Text c='dimmed' fw={700} size='xs'>
              Predicted {entityName}
            </Text>

            <Group gap='xs' wrap='nowrap'>
              <Text c='contrast.2' fw={700} size='sm'>
                {toHumanReadableNumber(predCount)}
              </Text>
              <Text c='dimmed' size='sm'>
                {parsePercentage(predPercentage)}
              </Text>
            </Group>
          </Group>
        </Card>
      </Grid.Col>
      <Grid.Col className='flex' span={12}>
        <Card
          withBorder
          p='xs'
          style={{
            flex: 1,
            ...cardStyles
          }}
        >
          <Group justify='space-between'>
            <Text c='contrast.5' fw={700} size='xs'>
              METRIC
            </Text>
            {!isSS && (
              <Trend
                invertColors={false}
                tooltipCopy='Metric delta of filtered samples to base samples'
                value={getDelta()}
              />
            )}
          </Group>

          <Box className='align-items-baseline'>
            <MetricSelect
              data={isSS ? SS_METRICS : OD_MAP_THRESHOLDS}
              displayValue={displayValueString}
              value={isSS ? metric : mapThreshold.toString()}
              onChange={updateMetric}
            />
          </Box>
        </Card>
      </Grid.Col>
    </Grid>
  );
};

export default CVInsightsColumnStats;
