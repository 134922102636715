import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { refetchIntervalHandler } from '@/core/hooks/query-hooks/use-latest-job/refetch-interval-handler/refetch-interval-handler';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { MetricStatusType } from '@/core/types/metric-status.types';
import { isInProgressJobStatus } from '@/core/utils/get-job-status-state/get-job-status-state';

export type JobStatus =
  | components['schemas']['JobStatus']
  | MetricStatusType['status_type']
  | null
  | undefined;

export const useLatestJob = () => {
  const { runId, projectId } = usePathParameters();

  const path = '/projects/{project_id}/runs/{run_id}/jobs/latest';

  const enabled = Boolean(projectId && runId);

  const fetchLatestJob = async () => {
    const res = await api.GET(path, {
      params: { path: { project_id: projectId!, run_id: runId! } }
    });

    return res.data;
  };

  return useQuery([path, { projectId, runId }], fetchLatestJob, {
    enabled,
    refetchInterval: (data) => {
      if (data) {
        return refetchIntervalHandler(
          isInProgressJobStatus(data?.status as JobStatus)
        );
      }
      return false;
    }
  });
};
