import { Flex, Skeleton, SkeletonProps } from '@mantine/core';

interface SkeletonLoaderProps extends SkeletonProps {
  length: number;
  orientation?: 'vertical' | 'horizontal';
}

const SkeletonLoader = ({
  length,
  mb = 'xs',
  orientation = 'horizontal',
  ...others
}: SkeletonLoaderProps) => {
  const isVertical = orientation === 'horizontal';

  return (
    <Flex
      data-testid='skeleton-loader'
      direction={isVertical ? 'column' : 'row'}
      m='auto'
    >
      {Array.from({ length }).map((_, index) => (
        <Skeleton key={index} {...others} mb={mb} />
      ))}
    </Flex>
  );
};

export default SkeletonLoader;
