import { Box, Flex, Group } from '@mantine/core';

import NavBar from '@/core/components/organisms/nav-bar/nav-bar';
import HeaderBar from '@/evaluate/page-components/header-bar/header-bar';

interface PromptLayoutProps {
  children: React.ReactNode;
}

/**
 * PromptLayout
 *
 *
 *
 * @returns {React.Component} PromptLayout
 */
const PromptLayout = ({ children }: PromptLayoutProps) => {
  return (
    <Group data-testid='prompt-layout' gap={0} wrap='nowrap'>
      <Flex h='100vh' w={65}>
        <NavBar />
      </Flex>
      <Flex direction='column' h='100vh' style={{ overflow: 'auto' }} w='100vw'>
        <Box mih='55px'>
          <HeaderBar />
        </Box>
        {children}
      </Flex>
    </Group>
  );
};

export default PromptLayout;
