import { Button, Group, useMantineTheme } from '@mantine/core';
import { IconPlus, IconUserCheck } from '@tabler/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import ProjectName from '@/core/components/atoms/project-name/project-name';
import RunName from '@/core/components/atoms/run-name/run-name';
import { ModalLauncher } from '@/core/components/molecules/modal-launcher/modal-launcher';
import { Paths } from '@/core/constants/routes.constants';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { useCurrentRun } from '@/core/hooks/query-hooks/use-current-run/use-current-run';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useCurrentPromptRun } from '@/evaluate/hooks/query-hooks/use-current-prompt-run/use-current-prompt-run';
import { usePromptExport } from '@/evaluate/hooks/query-hooks/use-prompt-export/use-prompt-export';
import { usePromptEdit } from '@/evaluate/hooks/use-prompt-edit/use-prompt-edit';
import { usePromptRunParameters } from '@/evaluate/hooks/use-prompt-run-parameters/use-prompt-run-parameters';
import { ConfigureRatingsModal } from '@/evaluate/modals/configure-ratings-modal/configure-ratings-modal';
import { PromptRun } from '@/evaluate/types/prompt.types';

/**
 * HeaderBar
 *
 *
 *
 * @returns {React.Component} HeaderBar
 */
const HeaderBar = () => {
  // Parameters Store
  const { compareIds } = useParametersStore((s) => ({
    compareIds: s.compareIds,
    tab: s.tab
  }));

  // Path Parameters
  const { projectId } = usePathParameters();

  // Query Hooks
  const { data: runData, isFetching: runIsFetching } = useCurrentRun();
  const { data: currentRun } = useCurrentPromptRun();
  const { data: projectData, isFetching: projectIsFetching } =
    useCurrentProject();
  const exportPrompt = usePromptExport();

  const { handlePromptEdit } = usePromptEdit();

  const { runToParameters } = usePromptRunParameters();

  // Utility Hooks
  const { openModal } = useModals();
  const router = useRouter();
  const path = router?.route;
  const { colors } = useMantineTheme();

  // Computed
  const isPrompt = useComputedParameters('isPrompt');
  const isChains = path?.includes('/prompt/chains');
  const isFetchingNames = runIsFetching || projectIsFetching;
  const isAllChainsRunsPage = path === '/prompt/chains/[projectId]';
  const isAllRunsPage = path === '/prompt/[projectId]';
  const isCreateRunPage = path?.includes('create-run');
  const isPromptsPage = path === '/prompt/[projectId]/[runId]';
  const isChainsPage = path === '/prompt/chains/[projectId]/[runId]';
  const isComparePromptsPage = path?.includes('compare');

  const url = isPrompt
    ? {
        pathname: isChains ? Paths.PROMPT_CHAINS_RUNS : Paths.PROMPT_RUNS,
        query: {
          projectId: projectId as string
        }
      }
    : undefined;

  const project = projectData?.name || STRING_PLACEHOLDER;
  let run = STRING_PLACEHOLDER;

  if (isCreateRunPage) {
    run = 'New Run';
  }

  if (isAllRunsPage || isAllChainsRunsPage) {
    run = 'All Runs';
  }

  if (isComparePromptsPage) {
    run = 'Compare Runs';
  }

  if ((isPromptsPage || isChainsPage) && runData?.name) {
    run = runData?.name;
  }

  // Handlers
  const handleEditRun = () => {
    if (!currentRun) return;

    const promptRunParams = runToParameters(currentRun as PromptRun);
    handlePromptEdit(promptRunParams);
  };

  const handleCompareRunsExport = () => {
    compareIds?.forEach((id) => {
      exportPrompt.mutate({ run_id: id });
    });
  };

  // Render
  return (
    <Group
      data-testid='header-bar'
      h='100%'
      justify='space-between'
      px='xs'
      style={{ borderBottom: `1px solid ${colors.contrast[7]}` }}
      w='100%'
    >
      <Group gap={4} wrap='nowrap'>
        <ProjectName isLoading={isFetchingNames} label={project} url={url} />
        <RunName
          editable={isPromptsPage || isChainsPage}
          isLoading={isFetchingNames}
          label={run}
        />
      </Group>
      <Group gap='xs' justify='flex-end' px='xs' wrap='nowrap'>
        <ModalLauncher
          color='gray.8'
          leftSection={<IconUserCheck size={18} />}
          modal={ConfigureRatingsModal}
          style={{ border: `1px solid ${colors.gray[2]}` }}
          variant='outline'
        >
          Configure Human Ratings
        </ModalLauncher>
        {isAllRunsPage && !isComparePromptsPage && (
          <>
            {/* Disabling chart view until new chart direction is decided */}
            {/* <SegmentedControl
              data={[
                {
                  label: (
                    <Center>
                      <IconList size='1rem' />
                      <Box ml={10}>List View</Box>
                    </Center>
                  ),
                  value: Tab.PROMPT_LIST_VIEW
                },
                {
                  label: (
                    <Center>
                      <IconChartArea size='1rem' />
                      <Box ml={10}>Chart View</Box>
                    </Center>
                  ),
                  value: Tab.PROMPT_CHART_VIEW
                }
              ]}
              size='sm'
              value={tab || Tab.PROMPT_LIST_VIEW}
              onChange={(val) => setParameters({ tab: val as Tab })}
            /> */}
            <Link
              href={{
                pathname: Paths.PROMPT_CREATE_RUN,
                query: {
                  projectId: projectId as string
                }
              }}
            >
              <Button
                component='div'
                leftSection={<IconPlus size={14} />}
                size='sm'
              >
                Create Run
              </Button>
            </Link>
          </>
        )}
        {isPromptsPage && (
          <>
            <Button variant='light' onClick={handleEditRun}>
              Edit Run
            </Button>
            <Button
              variant='light'
              onClick={() =>
                openModal(Modals.PROMPT_EXPORT, {
                  name: run,
                  templateId: currentRun?.template_id || undefined,
                  metrics: Object.keys(currentRun?.metrics || {})
                })
              }
            >
              Export Prompt Data
            </Button>
          </>
        )}
        {isComparePromptsPage && (
          <Button variant='light' onClick={handleCompareRunsExport}>
            Export Runs
          </Button>
        )}
      </Group>
    </Group>
  );
};

export default HeaderBar;
