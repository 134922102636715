import { Box, BoxProps, ButtonProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { CSSProperties } from 'react';

import { HoverCopyButton } from '../../atoms/hover-copy-button/hover-copy-button';

type HoverCopyButtonBoxProps = BoxProps &
  React.PropsWithChildren<{
    /** Text to add to clipboard when the copy button is clicked */
    copyText: string | null | undefined;
    boxStyle?: CSSProperties;
    buttonProps?: ButtonProps;
  }>;

const HoverCopyButtonBox = ({
  boxStyle,
  buttonProps,
  children,
  copyText,
  ...boxProps
}: HoverCopyButtonBoxProps) => {
  const { ref, hovered } = useHover();
  return (
    <Box
      data-testid='hover-copy-button-box'
      ref={ref}
      style={{ position: 'relative', ...boxStyle }}
      {...boxProps}
    >
      <HoverCopyButton
        isVisible={Boolean(hovered)}
        pos='absolute'
        px='xs'
        radius={4}
        right='1rem'
        styles={{
          section: {
            marginRight: '0.25rem'
          },
          ...buttonProps?.styles
        }}
        top='.375rem'
        value={copyText ?? ''}
        {...buttonProps}
      />
      {children}
    </Box>
  );
};

export default HoverCopyButtonBox;
