import { Space, useMantineTheme } from '@mantine/core';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import SplitCard from '@/core/components/atoms/split-card/split-card';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * NoiseTypeChart
 *
 *
 *
 * @returns {React.Component} NoiseTypeChart
 */
const NoiseTypeChart = () => {
  const { colors } = useMantineTheme();
  const sample = usePercentageSample();
  const { label_noise_pct = 0, feature_noise_pct = 0 } = sample?.data || {};

  const nonePercentage =
    100 - ((label_noise_pct as number) + (feature_noise_pct as number)) * 100;

  return (
    <SplitCard header='Noise Type' mt='sm' testId='noise-type-chart'>
      <Space h='sm' />
      <Pie
        data={{
          labels: ['Feature noise', 'Label noise', 'None'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [
                (feature_noise_pct as number) * 100,
                (label_noise_pct as number) * 100,
                nonePercentage
              ],
              backgroundColor: [
                colors.grape?.[5],
                colors.red?.[5],
                colors.orange?.[4]
              ],
              hoverOffset: 4
            }
          ]
        }}
        options={{
          maintainAspectRatio: true,
          plugins: {
            // @ts-expect-error
            crosshair: false,
            legend: { display: false }
          }
        }}
      />
    </SplitCard>
  );
};

export default NoiseTypeChart;
