import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

// Function types for the conversion functions.
type NumberConversionFunction<OptionsType = undefined> = (
  value: number,
  options?: OptionsType
) => number;

// These are the options that can be passed to the conversion functions.
// They have to be explicitly defined here so that TypeScript can infer the options.
interface DecimalOptions {
  places: number;
}

interface PercentageOptions extends DecimalOptions {
  asDecimal: boolean;
}

// Top-level keys are the input unit types
// Second-level keys are the output unit types
// ex. const foo = new NumberUnit(1, 'number').to('dollars').toNumber() => 1.00
// ex. const foo = new Unit(1, 'number').to('dollars', {places: 4}).toNumber() => 1.0000
// ex. const foo = new Unit(1, 'number').to('dollars').toString({ style: 'currency', currency: 'USD' }); => '$1.00'
type NumberConversionMap = {
  number: {
    bleu: NumberConversionFunction;
    dollars: NumberConversionFunction<DecimalOptions>;
    float: NumberConversionFunction<DecimalOptions>;
    percentage: NumberConversionFunction<PercentageOptions>;
    rouge: NumberConversionFunction<DecimalOptions>;
    wholeNumber: NumberConversionFunction;
  };
  milliseconds: {
    seconds: NumberConversionFunction<DecimalOptions>;
    nanoseconds: NumberConversionFunction<DecimalOptions>;
  };
  seconds: {
    milliseconds: NumberConversionFunction<DecimalOptions>;
    nanoseconds: NumberConversionFunction<DecimalOptions>;
  };
  nanoseconds: {
    milliseconds: NumberConversionFunction<DecimalOptions>;
    seconds: NumberConversionFunction<DecimalOptions>;
  };
  // Add other conversions here...
};

// Type guarding is done in the constructor of the Unit class.
export const numberConversions: NumberConversionMap = {
  number: {
    bleu: (value) => {
      return numberConversions.number.wholeNumber(value);
    },

    dollars: (value, options = { places: 2 }) => {
      return parseFloat(value?.toFixed(options.places));
    },

    float: (value, options = { places: 3 }) => {
      return parseFloat(value?.toFixed(options.places));
    },

    percentage: (value, options = { places: 2, asDecimal: false }) => {
      const float = parseFloat(value?.toFixed(options.places));
      return options.asDecimal ? float : float * 100;
    },

    rouge: (value, options = { places: 2 }) => {
      return parseFloat(value?.toFixed(options.places));
    },

    wholeNumber: (value) => {
      return Math?.round(value);
    }
  },
  milliseconds: {
    seconds: (value, options = { places: 2 }) => {
      return parseFloat((value / 1_000)?.toFixed(options.places));
    },
    nanoseconds: (value, options = { places: 0 }) => {
      return parseFloat((value * 1_000_000)?.toFixed(options.places));
    }
  },
  seconds: {
    milliseconds: (value, options = { places: 0 }) => {
      return parseFloat((value * 1_000)?.toFixed(options.places));
    },
    nanoseconds: (value, options = { places: 0 }) => {
      return parseFloat((value * 1_000_000_000)?.toFixed(options.places));
    }
  },
  nanoseconds: {
    milliseconds: (value, options = { places: 0 }) => {
      return parseFloat((value / 1_000_000)?.toFixed(options.places));
    },
    seconds: (value, options = { places: 2 }) => {
      return parseFloat((value / 1_000_000_000)?.toFixed(options.places));
    }
  }
};

export class NumberUnit<
  InputUnitType extends keyof NumberConversionMap = 'number'
> {
  private readonly initialValue: number | string | undefined;
  private value: number | string | undefined;

  private unitType: InputUnitType;

  /**
   *
   * @param {number} value - The value to convert
   * @param {InputUnitType} unitType - The unit type of the value to convert
   */
  constructor(
    value: number | string,
    unitType: InputUnitType = 'number' as InputUnitType
  ) {
    // Store the initial value for error messages.
    this.initialValue = value;

    // Set the unitType of the value.
    this.unitType = unitType;

    // Set the initial value.
    this.value = this.typeGuard(value);
  }

  // Method to convert the value to a different unit type.
  // ex. new Unit(1, 'number').to('dollars') => 1.00
  // ex. new Unit(1, 'number').to('dollars', {places: 4}) => 1.0000
  public to<
    OutputUnitType extends keyof NumberConversionMap[InputUnitType],
    OptionsType
  >(unit: OutputUnitType, options?: OptionsType) {
    const conversionFunction = numberConversions[this.unitType][
      unit
    ] as NumberConversionFunction<OptionsType>;

    this.value = conversionFunction(this.value as number, options);
    return this;
  }

  // Method to convert the value to a number.
  // ex. new Unit(1, 'number').to('dollars').toNumber() => 1.00
  public toNumber() {
    return this.convertToNumber(this.value);
  }

  // Method to convert the value to a string.
  // ex. new Unit(1, 'number').to('dollars').toString({style: 'currency', currency: 'USD'}) => '$1.00'
  // ex. new Unit(1, 'number').to('dollars', {places: 4}).toString() => '$1.0000'
  public toString(
    options?: Intl.NumberFormatOptions,
    fallback: string = STRING_PLACEHOLDER
  ): string {
    if (isNaN(this.value as number)) {
      this.throw(`Invalid number: Cannot convert number '${this.value}'`);
      return fallback;
    }

    return new Intl.NumberFormat('en-US', options).format(this.value as number);
  }

  // Method to type guard the initial value.
  // This is used in the constructor to ensure that the initial value is properly guarded.
  private typeGuard(value: unknown) {
    if (value == null) {
      this.throw(
        `Invalid value: Cannot convert value '${value}' to type '${this.unitType}'`
      );
      return undefined;
    }
    const convertedValue = this.convertToNumber(value) as number;

    if (!isNaN(convertedValue)) {
      return convertedValue;
    }

    this.throw(`Invalid number: Cannot convert number '${value}'`);
  }

  // Private method to convert the value to a number.
  private convertToNumber(value: unknown) {
    if (typeof value === 'number') {
      return value;
    }

    if (typeof value === 'string') {
      return parseFloat(value);
    }
  }

  private throw(msg?: string) {
    // eslint-disable-next-line no-console
    console.warn(
      msg ??
        `Invalid value: Cannot convert value '${this.initialValue}' to unit type '${this.unitType}'`
    );
  }
}
