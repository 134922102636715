import Gleap from 'gleap';
import { ReactNode, useEffect } from 'react';

import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';

export const GleapProvider = ({ children }: { children: ReactNode }) => {
  const { gleap } = useFeatureFlags();
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (gleap.isEnabled && process.env.NEXT_PUBLIC_GLEAP_API_KEY) {
      Gleap.initialize(process.env.NEXT_PUBLIC_GLEAP_API_KEY);
    }
  }, [gleap.isEnabled]);

  useEffect(() => {
    if (!gleap.isEnabled) {
      return;
    }

    if (user?.id) {
      Gleap.identify(user.id, {
        email: user.email ?? 'unknown',
        name: user.name
      });
    } else {
      Gleap.clearIdentity();
    }
  }, [user, gleap.isEnabled]);

  return children;
};
