import check from 'check-types';

import { GENERIC_ERROR_MESSAGE } from '@/core/constants/strings.constants';
import { showNotification } from '@/core/utils/show-notification/show-notification';

export const defaultErrorHandler = async (
  res: Response | null | undefined,
  title: string
) => {
  const errorMessage =
    res != null ? await parseApiErrorMessage(res) : undefined;
  showNotification({
    title,
    message: errorMessage || GENERIC_ERROR_MESSAGE,
    type: 'error'
  });
};

export const parseApiErrorMessage = async (res: Response) => {
  let error: any;
  try {
    error = await res.json();
  } catch {
    error = { detail: GENERIC_ERROR_MESSAGE };
  }

  const errorMessage = check.string(error?.detail)
    ? error?.detail
    : check.string(error?.detail?.[0].msg)
      ? error?.detail?.[0].msg
      : GENERIC_ERROR_MESSAGE;

  return errorMessage;
};
