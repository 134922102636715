import {
  TASK_TYPE,
  TaskType
} from '@/core/constants/tasks-and-frameworks.constants';
import { PROJECT_TYPE } from '@/core/types/projects.types';

export default function taskTypeToProjectType(taskType: TaskType) {
  const insights: TaskType[] = [
    TASK_TYPE.IC,
    TASK_TYPE.MCTC,
    TASK_TYPE.MLTC,
    TASK_TYPE.NER,
    TASK_TYPE.NLI,
    TASK_TYPE.OD,
    TASK_TYPE.S2S,
    TASK_TYPE.SD,
    TASK_TYPE.SS
  ];
  const promptEval: TaskType[] = [TASK_TYPE.PE, TASK_TYPE.PROMPT_CHAINS];
  const observe: TaskType[] = [TASK_TYPE.PM];

  if (insights.includes(taskType)) {
    return PROJECT_TYPE.TRAINING_INFERENCE;
  }

  if (promptEval.includes(taskType)) {
    return PROJECT_TYPE.PROMPT_EVALUATION;
  }

  if (observe.includes(taskType)) {
    return PROJECT_TYPE.OBSERVE;
  }
}
