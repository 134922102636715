import {
  Box,
  MantineThemeColorsOverride,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import { IconTrendingDown, IconTrendingUp } from '@tabler/icons-react';

import { ExtendedCustomColors } from '@/core/themes/module';

interface TrendProps {
  value: number;
  invertColors?: boolean;
  size?: number;
  tooltipCopy?: string;
}

// Exported for testing only
export const generateIconAndColor = (value: number, invertColors?: boolean) => {
  // TODO: Update typing
  let Icon: null | any = null;
  let color = '';

  const roundedVal = Number(value?.toFixed(2));

  if (roundedVal > 0) {
    Icon = IconTrendingUp;
    color = invertColors ? 'red' : 'green';
  } else if (roundedVal < 0) {
    Icon = IconTrendingDown;
    color = invertColors ? 'green' : 'red';
  }

  return { Icon, color };
};

/**
 * Trend
 *
 *
 */
const Trend = ({ value, invertColors, size = 16, tooltipCopy }: TrendProps) => {
  const theme: MantineThemeColorsOverride = useMantineTheme();

  const { Icon, color } = generateIconAndColor(value, invertColors);

  const textColor = color ? theme.colors[color][6] : '';
  const backgroundColor = color
    ? theme.colors[color as ExtendedCustomColors][2]
    : '';

  return (
    <>
      {Icon && color && (
        <Tooltip
          multiline
          withArrow
          withinPortal
          disabled={!tooltipCopy}
          label={tooltipCopy}
          w={235}
        >
          <Box
            className='align-center'
            style={{
              padding: 2,
              borderRadius: 4,
              backgroundColor
            }}
          >
            <Icon
              color={textColor}
              data-testid='trend-icon'
              height={size}
              width={16}
            />
            <Text
              color={textColor}
              data-testid='trend-copy'
              fw={700}
              ml={4}
              size='xs'
            >
              {value ? value?.toFixed(2) : ''}
            </Text>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default Trend;
