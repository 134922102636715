import { NumberUnit } from '@/core/utils/unit-conversions';

// Helper function to distribute the remaining space to the auto values
export const distributeRemainingSpace = (widths: ('auto' | number)[]) => {
  // Replace all non-'auto' and non-number values with 0;
  widths = widths.map((val) =>
    val !== 'auto' && typeof val !== 'number' ? 0 : val
  );

  const total = widths.reduce((acc, width) => {
    if (width === 'auto') {
      return acc;
    }

    return (acc as number) + width;
  }, 0);

  const remaining = 100 - (total as number);
  const autoCount = widths.filter((width) => width === 'auto').length;
  const autoWidth =
    autoCount > 0
      ? new NumberUnit(remaining / autoCount, 'number').to('float').toNumber()
      : 0;

  return widths.map((width) => (width === 'auto' ? autoWidth : width));
};

/**
   *** Math Notes: ***
  
   * We have to interpolate the value returned from useMove() into the resize range.
   * 0 is the minWidth, i.e. 0 == 10
   * 100 is the maxWidth i.e. 100 == 60
   * value is the value of the useMove handler, linear representation of the handler's x position. i.e. 30
   * 
   * value = 30;
   * range = [0, 100];
   * panelRange = [10, 60];
   * 
   * (30 - 0) * (60 - 10) / (100 - 0) + 10
   * (30) * (50) / (100) + 10
   * 1500 / 100 + 10
   * 15 + 10
   * **25**: This is the width of the panel, aka 25% of the parent width.
   * 
   * See Tests for more examples.
   */

export const valueToPanelWidth = (
  value: number,
  panelRange: [number, number] = [0, 100]
) => {
  const linearRange = [0, 100];

  const width =
    ((value - linearRange[0]) * (panelRange[1] - panelRange[0])) /
      (linearRange[1] - linearRange[0]) +
    panelRange[0];

  return width;
};

// This is the inverse of the above function. Interpolate the resize range value into the linear range.
export const panelWidthToValue = (
  width: number,
  panelRange: [minWidth: number, maxWidth: number] = [0, 100]
) => {
  const linearRange = [0, 100];
  const value =
    ((width - panelRange[0]) * (linearRange[1] - linearRange[0])) /
      (panelRange[1] - panelRange[0]) +
    linearRange[0];

  return value;
};
