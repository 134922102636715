import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { EVALUATE_COLUMNAR_RUNS_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';
import { MetricsGroupedColumns } from '@/core/types/metrics-table.types';
import { useMetricGroups } from '@/evaluate/hooks/query-hooks/use-metric-groups/use-metric-groups';

import {
  getMetricsGroupedColumns,
  getMetricsRows,
  getMetricsUngroupedColumns
} from '../utils';

export const useMetricsPromptRuns = (limit: number = 25) => {
  const { groups } = useMetricGroups();
  const { projectId } = usePathParameters();

  // Parameters
  let { sortBy, sortDirection, promptEvalFilters } = useParametersStore(
    (s) => ({
      sortBy: s.sortBy,
      sortDirection: s.sortDirection,
      promptEvalFilters: s.promptEvalFilters
    })
  );

  if (!sortBy || sortBy === 'data_error_potential') {
    sortBy = 'created_at';
  }

  // Dependencies
  const dependencies = [projectId];
  const enabled = dependencies.every(Boolean); // Only enable if all dependencies are truthy

  // Query
  const queryKey = [
    EVALUATE_COLUMNAR_RUNS_PATH,
    { projectId, sortBy, sortDirection, promptEvalFilters }
  ];

  const queryFn = async ({ pageParam = 0 }) => {
    const res = await api.POST(EVALUATE_COLUMNAR_RUNS_PATH, {
      params: {
        query: {
          starting_token: pageParam,
          limit,
          sort_by: sortBy,
          sort_ascending: sortDirection === 'asc'
        },
        path: {
          project_id: projectId!
        }
      },
      body: {
        filters: promptEvalFilters
      }
    });

    return res.data;
  };

  // Response
  const response = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled,
    getNextPageParam: (lastPage) => lastPage?.next_starting_token,
    onError: () => {}
  });

  const ungroupedColumns =
    getMetricsUngroupedColumns(response.data?.pages[0]?.columns ?? []) ?? [];

  const groupedColumns =
    getMetricsGroupedColumns(
      groups ?? [],
      response.data?.pages[0]?.columns ?? []
    ) ?? [];

  const rows =
    response.data?.pages.flatMap((page) =>
      getMetricsRows(page?.columns ?? [])
    ) ?? [];

  const runsDetails: MetricsGroupedColumns[] = [];

  const ragParameters = {
    ...groupedColumns?.find((group) => (group.name = 'rag_parameters'))
  } as MetricsGroupedColumns;

  if (ragParameters) {
    ragParameters.columns = ragParameters?.columns?.filter(
      (column) =>
        column.name !== 'model_alias' && column.name !== 'template_name'
    );
    ragParameters.label = 'RAG Parameters';
    runsDetails.push(ragParameters);
  }

  return {
    ...response,
    ungroupedColumns,
    groupedColumns,
    rows,
    runsDetails
  };
};
