import {
  ActionIcon,
  Box,
  Button,
  Group,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import {
  IconCheck,
  IconClipboardCheck,
  IconClipboardPlus
} from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';

import { parseApiErrorMessage } from '@/core/api/utils';
import CustomPillsInput from '@/core/components/atoms/custom-pills-input/custom-pills-input';
import { useSignupLink } from '@/core/hooks/query-hooks/use-signup-link/use-signup-link';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { isValidEmail } from '@/core/utils/is-valid-email/is-valid-email';

/**
 * InviteUserModal
 *
 * Modal for submitting feature requests or bug reports
 *
 */
const InviteUserModal = () => {
  const [url, setUrl] = useState('');
  const [displayResults, setDisplayResults] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [emails, setEmails] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const emailRef = useRef<HTMLInputElement | null>(null);

  const onError = async (res: Response, email: string) => {
    const response = await parseApiErrorMessage(res);
    setErrors((errors) => ({ ...errors, [email]: response }));
  };

  const inviteUser = useSignupLink(onError);

  const isAirgapped = useFeatureFlagsStore((state) => state.airgapped);

  const clipboard = useClipboard({ timeout: 2000 });

  const { closeAll } = useModals();

  const handleInvite = async () => {
    if (isAirgapped) {
      inviteUser.mutate(
        { email: emails[0] },
        {
          onSuccess: (data) => {
            const path = `${data?.signup_url}&email=${emails[0]}`;
            setUrl(path);
          }
        }
      );
    } else {
      setErrors({});
      setDisplayResults(false);
      const _emails = emails;
      if (searchValue && isValidEmail(searchValue)) {
        _emails.push(searchValue);
        setSearchValue('');
        setEmails(_emails);
      }
      const promises = _emails.map((email) => {
        inviteUser.mutateAsync({ email });
      });

      await Promise.all(promises);
      setTimeout(() => {
        setDisplayResults(true);
      }, 500);
    }
  };

  const getResultsText = () => {
    let text = '';
    const errorsLength = Object.keys(errors).length;
    const successful = emails.length - errorsLength;
    if (successful) {
      text += `${successful} invite(s) sent successfully. `;
    }
    if (errorsLength) {
      text += `${errorsLength} invite(s) failed`;
    }

    return text;
  };

  useEffect(() => {
    if (url && !emails[0]) {
      setUrl('');
    }

    setDisplayResults(false);
  }, [emails]);

  useEffect(() => {
    const timer = setTimeout(() => {
      emailRef.current?.focus?.();
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isAirgapped ? (
        <TextInput
          autoFocus
          required
          label='Email'
          ref={emailRef}
          rightSection={url ? <IconCheck color='green' size={16} /> : null}
          value={emails[0]}
          onChange={(event) => setEmails([event.currentTarget.value])}
        />
      ) : (
        <CustomPillsInput
          errors={errors}
          label='Email addresses'
          placeholder='user@example.com'
          searchValue={searchValue}
          value={emails}
          onOptionSubmit={(email) => setEmails([...emails, email])}
          onRemove={(email) => setEmails(emails.filter((e) => e !== email))}
          onSearchChange={setSearchValue}
        />
      )}

      <Box my='xs'>
        {url && (
          <TextInput
            disabled
            description='Copy link and send to user you wish to invite.'
            label='Sign Up Link'
            rightSection={
              <Tooltip withArrow label='Copy to clipboard'>
                <ActionIcon
                  autoFocus
                  color={clipboard.copied ? 'green' : 'gray'}
                  variant='filled'
                  onClick={() => clipboard.copy(url)}
                >
                  {clipboard.copied ? (
                    <IconClipboardCheck size={16} />
                  ) : (
                    <IconClipboardPlus size={16} />
                  )}
                </ActionIcon>
              </Tooltip>
            }
            value={url}
          />
        )}
      </Box>
      {displayResults && (
        <Text c='gray.7' mb={8} size='xs'>
          {getResultsText()}
        </Text>
      )}
      <Group justify='flex-end'>
        <Button color='gray' variant='outline' onClick={closeAll}>
          Close
        </Button>
        <Button onClick={handleInvite}>Send invites</Button>
      </Group>
    </>
  );
};

export default InviteUserModal;
