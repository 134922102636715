import { useUpdateRun } from '@/core/hooks/query-hooks/use-update-run/use-update-run';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import EditableLabel from '../editable-label/editable-label';

interface RunNameProps {
  label: string;
  isLoading?: boolean;
  editable?: boolean;
}

const RunName = ({ label, isLoading, editable }: RunNameProps) => {
  // Hooks
  const updateRun = useUpdateRun();

  // Handlers
  const handleSaveRunName = (newName: string) => {
    if (!newName) {
      return showNotification({
        type: 'error',
        message: 'Run name cannot be empty'
      });
    }

    updateRun.mutate({
      name: newName
    });
  };

  // Render
  return (
    <EditableLabel
      withSeparator
      editable={editable}
      initialLabel={label}
      isLoading={isLoading}
      onChange={handleSaveRunName}
    />
  );
};

export default RunName;
