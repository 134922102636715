import { Box } from '@mantine/core';

import { IPromptRunParameters } from '@/evaluate/hooks/use-prompt-run-parameters/use-prompt-run-parameters';

import { usePromptTemplateMenu } from './use-prompt-template-menu';
import { PopoverMenu } from '../../../core/components/molecules/popover-menu/popover-menu';

const PromptTemplateMenu = ({
  promptRunParams,
  target
}: {
  promptRunParams: IPromptRunParameters;
  target: React.ReactNode;
}) => {
  const { menuItems } = usePromptTemplateMenu({
    promptRunParams
  });

  return (
    <Box className='context-menu' data-testid='prompt-table-menu'>
      <PopoverMenu
        data={menuItems}
        menuLabel='For this Template'
        target={target}
      />
    </Box>
  );
};

export default PromptTemplateMenu;
