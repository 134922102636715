import { useMutation } from '@tanstack/react-query';

import api from '@/core/api';
import { useParametersStore } from '@/core/stores/parameters-store';
import { Splits } from '@/core/stores/parameters-store/parameters.store.types';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import useStore from '@/fine-tune/stores/store';

export const POST_SIMILAR =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/similar';

export const useSimilarFromOtherSplit = () => {
  const task = useParametersStore((state) => state.task);
  const projectId = useParametersStore((state) => state.projectId);
  const runId = useParametersStore((state) => state.runId);
  const split = useParametersStore((state) => state.split);
  const filter_params = useFilterParams();
  const setSimilarToQuantity = useStore(
    (state) => state.actions.setSimilarToQuantity
  );

  const fetchSimilarIds = async ({
    fromSplit,
    similarToIds,
    isInferenceName
  }: {
    fromSplit: string;
    similarToIds: number[];
    isInferenceName: boolean;
  }) => {
    filter_params.ids = similarToIds;

    const _split = isInferenceName ? 'inference' : (fromSplit as Splits);

    const res = await api.POST(POST_SIMILAR, {
      params: {
        path: {
          project_id: projectId,
          run_id: runId,
          split
        },
        query: {
          from_split: _split,
          from_inference_name: isInferenceName ? fromSplit : undefined
        }
      },
      body: { filter_params, task }
    });

    if (!filter_params.num_similar_to) {
      setSimilarToQuantity(res?.data?.similar_ids?.length);
    }

    return res.data;
  };

  return useMutation(fetchSimilarIds);
};
