import { Box, Button, Checkbox, Group, Paper } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { ContextModalProps } from '@mantine/modals';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { usePromptExport } from '@/evaluate/hooks/query-hooks/use-prompt-export/use-prompt-export';

type CustomContextModalProps = ContextModalProps & {
  innerProps?: {
    name: string;
  };
};

interface ListState {
  label: string;
  value: string;
  checked: boolean;
}

const checkboxStyles = {
  border: '1px solid #E9E8ED',
  padding: 8,
  borderRadius: 8
};

const EXPORT_COLUMNS = ['inputs', 'prompts', 'responses', 'metrics'];
/**
 *
 *
 * @returns
 */
const PromptExportModal = ({ innerProps }: CustomContextModalProps) => {
  // Query Hooks

  const exportPrompt = usePromptExport();

  // Utility Hooks
  const { closeAll } = useModals();

  const [exportColumns, handlers] = useListState<ListState>(
    EXPORT_COLUMNS.map((key) => ({
      label: key,
      value: key,
      checked: true
    }))
  );

  const handleCheckboxChange = (id: string, checked: boolean) => {
    handlers.applyWhere(
      (col) => col.value === id,
      (row) => ({
        ...row,
        checked
      })
    );
  };

  const handleExport = () => {
    const nonCheckedColumns = exportColumns
      .filter((col) => !col.checked)
      .map((col) => col.value);
    exportPrompt.mutate(
      { name: innerProps.name, excludeCols: nonCheckedColumns },
      {
        onSuccess: () => closeAll()
      }
    );
  };

  return (
    <>
      <Paper data-testid='prompt-export-modal' p='md'>
        {exportColumns.map(({ label, value, checked }) => (
          <Checkbox
            checked={checked}
            key={value}
            label={label}
            mb='xs'
            style={checkboxStyles}
            styles={{ icon: { width: 9 } }}
            onChange={(e) =>
              handleCheckboxChange(value, e.currentTarget.checked)
            }
          />
        ))}
      </Paper>

      <Box
        p='md'
        style={{
          background: 'var(--mantine-color-gray-1)',
          borderTop: `1px solid var(--mantine-color-gray-2)`
        }}
      >
        <Group justify='flex-end'>
          <Button
            data-testid='prompt-export-modal-cancel-btn'
            variant='subtle'
            onClick={() => closeAll()}
          >
            Cancel
          </Button>
          <Button
            data-testid='prompt-export-modal-export-btn'
            loading={exportPrompt.isLoading}
            radius='md'
            onClick={handleExport}
          >
            Export
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default PromptExportModal;
