import { useEffect, useRef } from 'react';

export const useFocusAndScroll = (
  guard: string,
  condition: boolean,
  handler: () => void
) => {
  const focusRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (condition && focusRef.current) {
      handler?.();
      timer = setTimeout(() => {
        focusRef.current?.focus();
      }, 200);
      focusRef.current?.scrollIntoView?.({
        block: 'center'
      });
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [guard, focusRef.current]);

  return focusRef;
};
