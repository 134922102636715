import { useABFlags } from './use-ab-flags/use-ab-flags';
import { useDevToolFlags } from './use-dev-tool-flags/use-dev-tool-flags';
import { useExternalFlags } from './use-external-flags/use-external-flags';

export const useFeatureFlags = () => {
  const ABFlags = useABFlags();
  const devTools = useDevToolFlags();
  const externalFeatureFlags = useExternalFlags();

  return {
    ...externalFeatureFlags,
    ...ABFlags,
    ...devTools
  };
};
