import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';

export const USERS_KEY = '/users/all';

export const useUsers = () => {
  const fetchUsers = async () => {
    const res = await api.GET(USERS_KEY);

    return res?.data;
  };

  const response = useQuery([USERS_KEY], fetchUsers);

  return response;
};
