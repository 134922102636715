import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ResponseType } from 'axios';
import moment from 'moment';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';
import { downloadFile } from '@/core/utils/download-file/download-file';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import { useExportFilterParams } from '@/fine-tune/hooks/use-export-filter-params/use-export-filter-params';

import {
  BodyGeneratorArgs,
  DatabricksExportBody,
  HandleExportArgs,
  RemoteExportBody,
  RemoteExportConfig
} from './use-export-to.types';
import { GET_EDITS, useEdits } from '../use-edits/use-edits';
import { useCurrentProject } from '../../../../core/hooks/query-hooks/use-current-project/use-current-project';
import { useCurrentRun } from '../../../../core/hooks/query-hooks/use-current-run/use-current-run';

const dateFormat = 'YYYYMMDD_HHMMSS';

// Endpoints
export const databricksExport = '/integrations/databricks/unity-catalog/export';
export const databricksEditsExport =
  '/integrations/databricks/unity-catalog/edits/export';
export const localExport =
  '/projects/{project_id}/runs/{run_id}/split/{split}/export';
export const remoteExport =
  '/projects/{project_id}/runs/{run_id}/split/{split}/export/remote';
export const localEditExport = '/edits/export';
export const remoteEditExport = '/edits/export/remote';

export const useExportTo = (rowId?: number) => {
  const { data: runData } = useCurrentRun();
  const { data: projectData } = useCurrentProject();
  const { runId, projectId } = usePathParameters();

  const { sortDirection, sortBy, dataframeColumns, split } = useParametersStore(
    (s) => ({
      split: s.split,
      sortDirection: s.sortDirection,
      sortBy: s.sortBy,
      dataframeColumns: s.dataframeColumns,
      slice: s.slice
    })
  );

  const parsedFilterParams = useExportFilterParams(rowId);

  const edits = useEdits();
  const ids = edits?.data?.map(({ id }: { id: string }) => id) || [];

  // Query cache
  const queryClient = useQueryClient();

  const getEndpoint = (
    remote: string | undefined,
    isEditsExport: boolean | undefined,
    isExportToDatabricks: boolean | undefined
  ) => {
    if (isExportToDatabricks && isEditsExport) {
      return databricksEditsExport;
    }

    if (isExportToDatabricks) {
      return databricksExport;
    }

    if (remote && isEditsExport) {
      return remoteEditExport;
    }

    if (remote) {
      return remoteExport;
    }

    if (isEditsExport) {
      return localEditExport;
    }

    return localExport;
  };

  const generateLocalBody = ({
    exportConfig,
    isEditsExport
  }: BodyGeneratorArgs) => {
    const {
      editIds,
      includeCols,
      colMapping,
      fileType,
      huggingFaceTaggingSchema
    } = exportConfig || {};

    const mutualBodyItems = {
      file_type: fileType || 'csv',
      col_mapping: colMapping,
      hf_format: Boolean(huggingFaceTaggingSchema),
      tagging_schema: huggingFaceTaggingSchema || null
    };

    if (isEditsExport) {
      const localDownloadEditsBody = {
        ...mutualBodyItems,
        edit_ids: editIds ?? ids,
        include_cols: includeCols
      };

      return localDownloadEditsBody;
    }

    const localDownloadBody = {
      ...mutualBodyItems,
      filter_params: parsedFilterParams,
      sort_ascending: sortDirection === 'asc',
      sort_by: sortBy || null,
      include_cols: includeCols || dataframeColumns,

      include_emb: false
    };

    return localDownloadBody;
  };

  const generateDatabricksBody = ({
    exportConfig,
    isEditsExport
  }: BodyGeneratorArgs) => {
    const {
      tableName,
      colMapping,
      includeCols,
      editIds,
      huggingFaceTaggingSchema
    } = (exportConfig as RemoteExportConfig) || {};

    var date = new Date();
    const dateTime = moment(date).format(dateFormat);
    const psl = `${dateTime}.parquet`;

    const body: DatabricksExportBody = {
      project_id: projectId!,
      run_id: runId!,
      split: split,
      catalog_name: projectData?.name || '',
      schema_name: runData?.name || '',
      table_name: tableName,
      psl_content_file_name: psl,
      col_mapping: colMapping,
      include_cols: includeCols,
      hf_format: Boolean(huggingFaceTaggingSchema),
      tagging_schema: huggingFaceTaggingSchema || null,
      filter_params: parsedFilterParams
    };

    if (isEditsExport) {
      body.edit_ids = editIds ?? ids;
    }

    return body;
  };

  const generateRemoteBody = ({
    exportConfig,
    isEditsExport,
    remote = 's3'
  }: BodyGeneratorArgs) => {
    const {
      bucketName,
      fileName,
      colMapping,
      includeCols,
      editIds,
      huggingFaceTaggingSchema
    } = (exportConfig as RemoteExportConfig) || {};

    const body: RemoteExportBody = {
      bucket_name: bucketName,
      object_name: fileName,
      export_to: remote,
      col_mapping: colMapping,
      include_cols: includeCols,
      hf_format: Boolean(huggingFaceTaggingSchema),
      tagging_schema: huggingFaceTaggingSchema || null,
      filter_params: parsedFilterParams
    };

    if (isEditsExport) {
      body.edit_ids = editIds ?? ids;
    }

    return body;
  };

  const handleExport = async ({
    remote,
    isEditsExport,
    exportConfig,
    isExportToDatabricks
  }: HandleExportArgs) => {
    let body;
    let responseType: ResponseType =
      remote || isExportToDatabricks ? 'json' : 'blob';

    if (remote) {
      body = generateRemoteBody({
        exportConfig,
        isEditsExport,
        remote
      });
    } else if (isExportToDatabricks) {
      body = generateDatabricksBody({ exportConfig, isEditsExport });
    } else {
      body = generateLocalBody({ exportConfig, isEditsExport });
    }

    body = {
      ...body,
      only_export_edited: exportConfig.onlyExportEditedRows,
      min_reviews: exportConfig.onlyExportReviewedEdits ? 1 : undefined
    };

    const path = getEndpoint(remote, isEditsExport, isExportToDatabricks);

    const res = await api.POST(path, {
      // @ts-expect-error
      body,
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: split
        }
      },
      parseAs: responseType
    });

    return res.data;
  };

  const exportTo = useMutation(handleExport, {
    onSuccess: (data, variables) => {
      if (!variables.remote && !variables.isExportToDatabricks) {
        // @ts-expect-error
        downloadFile(variables.exportConfig.fileName, data);
      }

      if (variables.isEditsExport) {
        queryClient.invalidateQueries([GET_EDITS]);
      }

      showNotification({
        title: 'Export successful!'
      });
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error exporting data')
  });

  return exportTo;
};
