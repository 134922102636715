import { Box, Button, Grid, Paper, Text, useMantineTheme } from '@mantine/core';
import {
  IconAlertTriangle,
  IconFileMinus,
  IconFilePlus,
  IconPencilPlus,
  IconReceiptRefund,
  IconSwitchHorizontal,
  IconTag,
  IconTrash,
  IconX
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';

import { useUserById } from '@/core/hooks/query-hooks/use-user-by-id/use-user-by-id';
import { useComputedParameters } from '@/core/stores/parameters-store';
import { EDIT_ACTIONS } from '@/fine-tune/constants/data-edits.constants';

import { ICartElement } from '../cart';

interface EditsLogItemProps {
  isSelected?: boolean;
  item: ICartElement;
  removeItem?: React.MouseEventHandler<HTMLButtonElement>;
  selectItem?: React.MouseEventHandler<HTMLDivElement>;
}

const {
  ADD_SPAN,
  CREATE_NEW_LABEL,
  DELETE,
  RELABEL,
  RELABEL_AS_PRED,
  SELECT_FOR_LABEL,
  SHIFT_SPAN,
  UPDATE_TEXT
} = EDIT_ACTIONS;

const EDIT_TYPE_DATA = {
  [ADD_SPAN]: { copy: 'added', icon: <IconFilePlus size={16} /> },

  [CREATE_NEW_LABEL]: {
    copy: 'assigned',
    icon: <IconPencilPlus size={16} />
  },
  [DELETE]: { copy: 'removed', icon: <IconTrash size={16} /> },

  [RELABEL]: {
    copy: 'relabeled',
    icon: <IconSwitchHorizontal size={16} />
  },
  [RELABEL_AS_PRED]: {
    copy: 'relabeled as pred',
    icon: <IconSwitchHorizontal size={16} />
  },
  [SELECT_FOR_LABEL]: { copy: 'added', icon: <IconTag size={16} /> },

  [SHIFT_SPAN]: {
    copy: 'shifted',
    icon: <IconReceiptRefund size={16} />
  },
  [UPDATE_TEXT]: {
    copy: 'edited',
    icon: <IconFileMinus size={16} />
  },

  text_replaced: { copy: 'replaced', icon: <IconReceiptRefund size={16} /> }
};

const HistoryLogItem = ({
  item,
  selectItem,
  removeItem,
  isSelected
}: EditsLogItemProps) => {
  const {
    created_at,
    created_by,
    edit_action,
    note,
    reviewers,
    sample_ids,
    text_replacement
  } = item;

  const firstReviewer = reviewers?.[0];
  // Local state
  const [isDeleting, setIsDeleting] = useState(false);

  // Hooks
  const { colors } = useMantineTheme();
  const { data } = useUserById(created_by);
  const { data: reviewerData } = useUserById(firstReviewer?.created_by || '');

  // Computed
  const { isInference, isNer, isOd } = useComputedParameters();

  const creatorName = data?.first_name;

  const isCreateNewLabel =
    isInference &&
    [CREATE_NEW_LABEL, RELABEL, RELABEL_AS_PRED].includes(edit_action);

  const amountModified = sample_ids?.length;
  let editAction = edit_action;

  if (text_replacement) {
    editAction = 'text_replaced';
  }

  if (isCreateNewLabel) {
    editAction = CREATE_NEW_LABEL;
  }

  let { icon, copy } = EDIT_TYPE_DATA[editAction];

  let entity = 'sample';

  if (isInference) {
    entity = 'label';
  }

  if (isNer) {
    entity = 'span';
  }

  if (isOd) {
    entity = 'annotation';

    if (editAction === 'relabel_as_pred') {
      copy = 'overwritten';
    }
  }

  return (
    <Paper
      withBorder
      className='cursor-pointer'
      data-testid='history-log-item'
      mb={8}
      p={2}
      radius='md'
      style={{
        border: isSelected
          ? `2px solid var(--mantine-color-brand-3)`
          : `1px solid var(--mantine-color-contrast-6)`,
        backgroundColor: isSelected
          ? 'var(--mantine-color-contrast-9)'
          : 'transparent',
        color: `var(--mantine-color-contrast-2)`,
        minWidth: 200
      }}
      onClick={selectItem}
    >
      <Grid p={4}>
        <Grid.Col
          span={1}
          style={{
            color: `var(--mantine-color-contrast-2)`
          }}
        >
          <Box mt={2}>
            {isDeleting ? <IconAlertTriangle color='red' size={16} /> : icon}
          </Box>
        </Grid.Col>
        <Grid.Col span={isDeleting ? 11 : 10}>
          <Box style={{ padding: '0 2px', backgroundColor: 'transparent' }}>
            {isDeleting ? (
              <Box>
                <Text c='black' fw={700} ml='xs' size='sm'>
                  Removing edit
                </Text>
                <Text c='gray' fw={400} ml='xs' size='sm'>
                  Are you sure you want to remove this edit from Edits Carts?
                </Text>

                <Box className='flex' mt='sm'>
                  <Button
                    color='gray'
                    data-testid='history-log-item-cancel'
                    variant='subtle'
                    onClick={() => setIsDeleting(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color='indigo'
                    data-testid='history-log-item-confirm'
                    variant='light'
                    onClick={removeItem}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Text size='sm'>
                  <Text component='span' fw={600} mr={4} size='sm'>
                    {amountModified}
                  </Text>
                  {isCreateNewLabel ? (
                    <Text component='span' fw={400} mr={4} size='sm'>
                      {entity}s {copy}
                    </Text>
                  ) : (
                    <Text
                      color='gray.7'
                      component='span'
                      fw={400}
                      mr={4}
                      size='sm'
                    >
                      {entity}s {isInference ? 'added to the cart' : copy}
                    </Text>
                  )}
                </Text>
                <Text c='gray.7' fw={400} mb={2} size='sm'>
                  {dayjs(created_at).format('MMM DD, YYYY')}
                  {creatorName && ` by ${creatorName}`}
                </Text>
                {firstReviewer && reviewerData?.first_name && (
                  <Text c='gray.7' fw={400} mb={3} size='sm'>
                    {reviewerData?.first_name} approved{' '}
                    {dayjs(firstReviewer.created_at).format('MMM DD, YYYY')}
                  </Text>
                )}
                {note && (
                  <>
                    <Text c={colors.gray[6]} fw={600} size='xs'>
                      NOTE
                    </Text>
                    <Text c={colors.gray[6]} fw={400} size='sm'>
                      {note}
                    </Text>
                  </>
                )}
              </>
            )}
          </Box>
        </Grid.Col>
        {!isDeleting && isSelected && (
          <Grid.Col px={0} span={1}>
            <IconX
              className='cursor-pointer'
              data-testid='history-log-item-x-icon'
              size={16}
              onClick={() => setIsDeleting(true)}
            />
          </Grid.Col>
        )}
      </Grid>
    </Paper>
  );
};

export default HistoryLogItem;
