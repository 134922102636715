import _snakeCase from 'lodash/snakeCase';
import _unique from 'lodash/uniq';

import { components } from '@/core/types/api';
import { MetricStatusType } from '@/core/types/metric-status.types';
import {
  MetricsColumn,
  MetricsGroupedColumns,
  MetricsRow
} from '@/core/types/metrics-table.types';
import { isSuccessMetricStatus } from '@/core/utils/is-metric-status/is-metric-status';
import { UnitFormatType } from '@/core/utils/unit-conversions';
import { UnitFormatMap } from '@/core/utils/unit-conversions/unit-format-mapping';
import { PromptEvalFilterType } from '@/evaluate/stores/prompt-store/prompt.store.types';

export interface MetricsGroup {
  group_label: string;
  group_name?: string | null | undefined;
  group_description?: string | null | undefined;
  group_icon?: string | undefined;
  columns: string[];
}

export interface FilterColumnConfig {
  name: string;
  label: string;
  formatter?: (value: any) => string | number | JSX.Element;
  filterType?: PromptEvalFilterType;
  values?: string[];
  sortable?: boolean;
  decimals?: number;
}

// Helpers to assemble table from endpoint metrics
export const getMetricsColumns = (
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const metrics = getMetricsWithParsedNames(_metrics);

  let uniqueMetrics = [
    ...new Map(metrics.map((metric) => [metric.name, metric])).values()
  ];

  return uniqueMetrics.map((metric) => {
    // Default to the metric data type
    let format:
      | components['schemas']['DataTypeOptions']
      | 'pii'
      | 'protect_status'
      | undefined = metric.data_type;

    if (metric.name === 'pii') {
      format = 'pii';
    }

    if (metric.name === 'protect_status') {
      format = 'protect_status';
    }

    const thresholdBuckets = metric?.metric_threshold?.buckets;

    const invertThreshold = Boolean(metric?.metric_threshold?.inverted);

    return {
      alignment: metric.name === 'id' ? 'left' : undefined,
      name: metric.name,
      label: metric.label ?? metric.name,
      description: metric.description,
      group_name: metric.group_name,
      format,
      threshold: {
        inverted: invertThreshold,
        high: invertThreshold ? thresholdBuckets?.[0] : thresholdBuckets?.[1],
        low: invertThreshold ? thresholdBuckets?.[1] : thresholdBuckets?.[0]
      },
      job_status: metric?.job_info?.[0]?.job_status,
      job_type: metric?.job_type,
      job_error_message: metric?.job_info?.[0]?.job_error_message,
      alert: {
        alert_title: metric.alert?.alert_title,
        alert_message: metric.alert?.alert_message
      },
      sortable: metric.sortable
    };
  }) as MetricsColumn[];
};

const categoricalColumns = [
  'input_pii',
  'output_pii',
  'input_tone',
  'output_tone'
];
const rangeColumns = [
  'average_uncertainty',
  'average_bleu',
  'average_rouge',
  'average_toxicity',
  'groundedness',
  'average_chunk_utilization',
  'average_completeness_gpt',
  'average_factuality',
  'average_hallucination'
];

const valueColumns = [
  'average_cost',
  'total_cost',
  'average_latency',
  'average_prompt_perplexity'
];

const valueTypes = [
  'floating_point',
  'integer',
  'milli_seconds',
  'percentage',
  'dollars'
];

const getColumnsNames = (columns: string[], isSingleRun?: boolean) => {
  return columns.map((column) =>
    isSingleRun ? column.replace('average_', '') : column
  );
};

const parsedColumnName = (
  column: components['schemas']['PromptRunColumn'],
  isSingleRun?: boolean
) =>
  isSingleRun && column.name.startsWith('_')
    ? column.name.substring(1)
    : column.name!;

export const getFilterType = (
  column: components['schemas']['PromptRunColumn'],
  isSingleRun?: boolean
): PromptEvalFilterType | undefined => {
  if (
    getColumnsNames(valueColumns, isSingleRun).includes(
      parsedColumnName(column, isSingleRun)
    )
  ) {
    return 'value';
  }

  if (
    getColumnsNames(rangeColumns, isSingleRun).includes(
      parsedColumnName(column, isSingleRun)
    )
  ) {
    return 'range';
  }

  if (
    getColumnsNames(categoricalColumns, isSingleRun).includes(
      parsedColumnName(column, isSingleRun)
    )
  ) {
    return 'category';
  }

  if (
    (isSingleRun && column.group_name === 'dataset') ||
    ['generic_parameters', 'rag_parameters'].includes(column.group_name!)
  ) {
    return 'text';
  }

  if (column.group_name === 'custom_metrics') {
    if (valueTypes.includes(column.data_type!)) {
      return 'value';
    }
    if (column.data_type === 'text') {
      return 'text';
    }
  }

  return undefined;
};

export const getMetricsColumnsConfig = (
  columns: components['schemas']['PromptRunColumn'][],
  isSingleRun?: boolean
): FilterColumnConfig[] => {
  return columns
    .map((column) => {
      const filterType = getFilterType(column, isSingleRun);
      const values = _unique(column.values?.filter((val) => val != null)).map(
        String
      );

      return {
        name: column.name,
        label: column.label ?? '',
        formatter: UnitFormatMap[column?.data_type as UnitFormatType],
        filterType,
        values,
        decimals: column.data_type === 'integer' ? 0 : 3,
        sortable: column.sortable
      };
    })
    .filter((column) => column.filterType && column.values?.length);
};

const getMetricsWithParsedNames = (
  metrics: components['schemas']['PromptRowColumn'][]
) => {
  // Don't mutate the original metrics as we need them for filtering
  return metrics.map((metric) => {
    let name;
    if (['human_rating', 'custom_metrics'].includes(metric.group_name!)) {
      // the name of a human rating metric is feedback template UUID,
      // so it should not be altered. Same for custom metrics.
      name = metric.name;
    } else {
      name = _snakeCase(metric.name);
    }

    return {
      ...metric,
      name
    };
  });
};

export const getMetricsGroupedColumns = (
  orderedGroups: MetricsGroup[],
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const metrics = getMetricsWithParsedNames(_metrics);
  //  Get metrics groups
  const groupKeys = [
    ...new Set(
      metrics
        .filter((metric) => !!metric.group_name)
        .map((metric) => metric.group_name)
    )
  ];

  // Get ordered metrics groups
  const filteredOrderedGroups =
    orderedGroups?.filter((orderedGroup) =>
      groupKeys.includes(orderedGroup.group_name)
    ) ?? [];

  const groups = filteredOrderedGroups.map((filteredOrderedGroup) => {
    const group = metrics.find(
      (metric) => metric.group_name === filteredOrderedGroup.group_name
    );

    // Get ordered metric group column order
    const groupedColumns = getMetricsColumns(metrics).filter(
      (metric) => metric.group_name === group?.group_name
    );

    const orderedGroupedColumns: MetricsColumn[] = [];

    filteredOrderedGroup.columns.forEach((orderedColumn) => {
      groupedColumns.forEach((column) => {
        if (orderedColumn === column.name) orderedGroupedColumns.push(column);
      });
    });

    // Get custom columns
    groupedColumns.forEach((column) => {
      if (!orderedGroupedColumns.includes(column))
        orderedGroupedColumns.push(column);
    });

    return {
      name: group?.group_name,
      label: group?.group_label,
      description: group?.group_description,
      columns: orderedGroupedColumns
    };
  }) as MetricsGroupedColumns[];

  return groups;
};

export const getMetricsUngroupedColumns = (
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const metrics = getMetricsWithParsedNames(_metrics);

  const filteredMetrics = metrics.filter((metric) => !metric.group_name);

  return getMetricsColumns(filteredMetrics);
};

export const getMetricsRows = (
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const requiredCells = [
    'id',
    'chain_id',
    'chain_root_id',
    'node_id',
    'node_name',
    'node_type',
    'step',
    'has_children'
  ];

  const metrics = getMetricsWithParsedNames(_metrics);
  const rows = metrics
    .find((metric) => metric.name === 'id')
    ?.metric_infos?.reduce((validRows: MetricsRow[], id, rowIndex) => {
      if (isSuccessMetricStatus(id)) {
        const row: MetricsRow = {
          id: id.value as string
        };

        metrics.forEach((metric) => {
          if (metric.name !== 'id') {
            let metricInfo = metric?.metric_infos?.[rowIndex];

            if (requiredCells.includes(metric.name)) {
              const value = isSuccessMetricStatus(metricInfo)
                ? metricInfo.value
                : undefined;
              row[metric.name] = value;
            } else {
              row[metric.name] = metricInfo;
            }
          }
        });

        validRows.push(row);
      }
      return validRows;
    }, []) as MetricsRow[];

  return rows;
};

export const getCellValue = (cell: MetricStatusType) =>
  isSuccessMetricStatus(cell) ? (cell.value as any) : undefined;
export const getCellString = (cell: MetricStatusType) =>
  isSuccessMetricStatus(cell) && typeof cell.value === 'string'
    ? (cell.value as string)
    : undefined;
export const getCellNumber = (cell: MetricStatusType) =>
  isSuccessMetricStatus(cell) && typeof cell.value === 'number'
    ? (cell.value as number)
    : undefined;
