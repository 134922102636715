import { Select, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';

import BarChart from '@/core/components/atoms/bar-chart';
import { HistogramData } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import {
  DEFAULT_BAR_CHART_COLOR,
  EASY_COLOR,
  HARD_COLOR,
  MISCLASSIFIED_COLOR
} from '@/core/constants/colors.constants';
import { useFocusAndScroll } from '@/core/hooks/use-focus-and-scroll/use-focus-and-scroll';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { roundDownToNearest } from '@/core/utils/round-to-nearest/round-to-nearest';
import { useInsightsDistribution } from '@/fine-tune/hooks/query-hooks/use-insights-distribution/use-insights-distribution';
import { useMetaColumns } from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';
import { useThresholds } from '@/fine-tune/hooks/query-hooks/use-thresholds/use-thresholds';
import { useDataframeColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';

const DEP_FILTER = { value: 'data_error_potential', label: 'DEP' };

const DistributionChart = () => {
  const thresholds = useThresholds();
  const theme = useMantineTheme();

  const { runId } = usePathParameters();
  const { isInference, isS2S } = useComputedParameters();
  const { setMetaParameters, setParameters } = useParametersStoreActions();

  const colorBy = useEmbeddingsStore((s) => s.colorBy);

  const [col, setCol] = useState(
    isInference ? 'confidence' : 'data_error_potential'
  );

  const insightsDistribution = useInsightsDistribution(col);
  const { isContinuous } = useDataframeColumns();
  const { continuousFilters = [] } = useMetaColumns();

  const focusRef = useFocusAndScroll(
    colorBy,
    isContinuous(colorBy) && !isS2S,
    () => {
      setCol(colorBy === 'dep' ? 'data_error_potential' : colorBy);
    }
  );

  const {
    easy_samples_threshold: easyThreshold,
    hard_samples_threshold: hardThreshold
  } = thresholds?.data || {};

  const { counts, bins } = insightsDistribution?.data || {};

  const labels = bins?.slice(0, -1) || [];

  const data = labels?.map((x, idx) => ({
    x,
    y: counts?.[idx] || 0
  }));

  const roundedThreshold = roundDownToNearest(hardThreshold as number);

  const barColors = bins?.map((binValue) => {
    if (col !== 'data_error_potential') {
      return theme.colors[DEFAULT_BAR_CHART_COLOR.color][
        DEFAULT_BAR_CHART_COLOR.shade
      ];
    }
    if (binValue <= (easyThreshold || 0)) {
      return theme.colors[EASY_COLOR.color][EASY_COLOR.shade + 1];
    }

    if (binValue < roundedThreshold) {
      return theme.colors[MISCLASSIFIED_COLOR.color][
        MISCLASSIFIED_COLOR.shade + 1
      ];
    }

    return theme.colors[HARD_COLOR.color][HARD_COLOR.shade + 1];
  });

  let selectData = isS2S ? [DEP_FILTER] : [...continuousFilters];

  if (isInference) {
    selectData.unshift({ value: 'confidence', label: 'Confidence' });
  } else {
    if (!isS2S) {
      selectData.unshift(DEP_FILTER);
    }
  }

  useEffect(() => {
    if (isInference) {
      setCol('confidence');
    } else {
      setCol('data_error_potential');
    }
  }, [isInference, runId]);

  const handleDragSelect = (start: number, end: number) => {
    const firstValue = bins?.[start] || 0;
    const lastValue = bins?.[end + 1] || 1;

    if (col === 'data_error_potential') {
      setParameters({
        depLow: firstValue,
        depHigh: lastValue
      });
    } else {
      setMetaParameters([
        {
          name: col,
          greater_than: firstValue,
          less_than: lastValue
        }
      ]);
    }
  };

  return (
    <SplitCard
      header={
        <Select
          searchable
          aria-label='distribution chart'
          data={selectData}
          size='sm'
          style={() => ({
            input: {
              fontSize: 16,
              color: 'var(--mantine-color-gray-6)'
            }
          })}
          value={col}
          variant='unstyled'
          onChange={(val) => {
            if (val) {
              setCol(val);
            }
          }}
        />
      }
      mt='xs'
      ref={focusRef}
    >
      <BarChart
        isHistogram
        backgroundColor={barColors}
        data={data as HistogramData[]}
        isLoading={insightsDistribution?.isLoading}
        labels={labels?.map((l) => `${l}`)}
        mt='sm'
        onHistogramDrag={handleDragSelect}
      />
    </SplitCard>
  );
};

export default DistributionChart;
