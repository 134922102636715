import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

interface UseRunMetricsArgs {
  isBaseMetrics?: boolean;
  getSingleMetric?: boolean;
}

const BASE_RUN_METRICS = 'BASE_RUN_METRICS';
const FILTERED_RUN_METRICS = 'FILTERED_RUN_METRICS';
const SINGLE_FILTERED_RUN_METRICS = 'SINGLE_FILTERED_RUN_METRICS';

export const useRunMetrics = (config?: UseRunMetricsArgs) => {
  const { runId, projectId } = usePathParameters();

  const { task, isMacro, inferenceName, split } = useParametersStore((s) => ({
    task: s.task,
    isMacro: s.isMacro,
    inferenceName: s.inferenceName,
    split: s.split
  }));

  const isMltc = useComputedParameters('isMltc');
  const isS2S = useComputedParameters('isS2S');

  const filterParams = useFilterParams();

  let queryKey = BASE_RUN_METRICS;
  let filter_params = {};

  if (!config?.isBaseMetrics) {
    queryKey = config?.getSingleMetric
      ? SINGLE_FILTERED_RUN_METRICS
      : FILTERED_RUN_METRICS;
    filter_params = { ...filterParams };
  }

  let enabled = !isS2S && Boolean(projectId && runId && split);

  if (isMltc) {
    enabled = Boolean(enabled && task);
  }

  const fetchRunMetrics = async () => {
    const body = {
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!,
          split
        },
        query: {
          macro: isMacro
        }
      },
      body: {
        filter_params
      }
    };

    if (isMltc && !config?.getSingleMetric) {
      const res = await api.POST(
        '/projects/{project_id}/runs/{run_id}/split/{split}/insights/metrics/tasks',
        body
      );

      return res.data;
    } else {
      const res = await api.POST(
        '/projects/{project_id}/runs/{run_id}/split/{split}/insights/metrics',
        body
      );

      return res.data;
    }
  };
  return useQuery(
    [
      queryKey,
      {
        projectId,
        runId,
        split,
        task,
        isMacro,
        inferenceName,
        ...filter_params
      }
    ],
    fetchRunMetrics,
    {
      enabled,
      onError: () => {}
    }
  );
};
