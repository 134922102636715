import { Center, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useUpdateProject } from '@/core/hooks/query-hooks/use-update-project/use-update-project';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import EditableLabel from '../editable-label/editable-label';

interface ProjectNameProps {
  isLoading?: boolean;
  label: string;
  url?: {
    pathname: string;
    query?: {
      [key: string]: string;
    };
  };
}

const ProjectName = ({ isLoading, label, url }: ProjectNameProps) => {
  const router = useRouter();
  const updateProject = useUpdateProject();

  // Handlers
  const handleSaveProjectName = (newName: string) => {
    if (!newName) {
      return showNotification({
        type: 'error',
        message: 'Project name cannot be empty'
      });
    }

    updateProject.mutate({
      name: newName
    });
  };

  if (url && router?.route !== url?.pathname) {
    return (
      <Link className='unstyled-anchor' data-testid='project-name' href={url}>
        <Text truncate c='brand' component='span' fw={700} size='sm'>
          <Center>
            <IconArrowLeft size={16} />
            {label}
          </Center>
        </Text>
      </Link>
    );
  }

  return (
    <EditableLabel
      color='brand'
      editable={true}
      initialLabel={label}
      isLoading={isLoading}
      onChange={handleSaveProjectName}
    />
  );
};

export default ProjectName;
