import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

// Path
export const GET_S2S_METRICS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/seq2seq/metrics';

interface UseS2SMetricsArgs {
  isBaseMetrics?: boolean;
}

export const useS2SMetrics = (config?: UseS2SMetricsArgs) => {
  // Parameters Store
  const { projectId, runId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  // Computed Parameters
  const { isS2S } = useComputedParameters();
  const filterParams = useFilterParams();

  let filter_params = {};

  if (!config?.isBaseMetrics) {
    filter_params = { ...filterParams };
  }

  // Hooks

  // Computed Properties
  const enabled = Boolean(projectId && runId && isS2S);

  // Fetcher
  const fetchMetrics = async () => {
    const res = await api.POST(GET_S2S_METRICS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: split
        }
      },
      body: { filter_params }
    });

    return res?.data;
  };

  // Response
  const response = useQuery(
    [GET_S2S_METRICS, { projectId, runId, split, ...filter_params }],
    fetchMetrics,
    {
      enabled,
      onError: () => {}
    }
  );

  return response;
};
