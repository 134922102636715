import { Box, Card, Grid, Group, Text } from '@mantine/core';
import _mean from 'lodash/mean';

import classes from './fixed-section.module.css';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import {
  Metric,
  NerMetric
} from '@/core/stores/parameters-store/parameters.store.types';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import Trend from '@/fine-tune/components/trend/trend';
import {
  AVG_DEP,
  IC_OPTIONS,
  INFERENCE_OPTIONS,
  METRIC_OPTIONS,
  NER_OPTIONS,
  S2S_OPTIONS
} from '@/fine-tune/constants/metric-and-grouped-selects.constants';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
import { useRunMetrics } from '@/fine-tune/hooks/query-hooks/use-run-metrics/use-run-metrics';
import { useS2SMetrics } from '@/fine-tune/hooks/query-hooks/use-s2s-metrics/use-s2s-metrics';

import MetricSelect from '../metric-select/metric-select';
/**
 * FixedSection
 *
 *
 *
 * @returns {React.Component} FixedSection
 */
const FixedSection = () => {
  // Hooks
  const { data: sampleData } = usePercentageSample();
  const { data: filteredMetrics } = useRunMetrics({ getSingleMetric: true });
  const { data: s2sMetrics } = useS2SMetrics();
  const { hasS2SExtraColumns } = useInsightsRows();

  const { data: baseMetrics } = useRunMetrics({ isBaseMetrics: true });
  const { data: baseS2sMetrics } = useS2SMetrics({ isBaseMetrics: true });

  // Parameter Store
  const { metric, actions, isMacro } = useParametersStore((s) => ({
    metric: s.metric,
    actions: s.actions,
    isMacro: s.isMacro
  }));
  // Computed Parameters
  const { isIc, isNer, isInference, isS2S } = useComputedParameters();

  // Computed Properties
  const { percentage, sample_count, span_count, span_percentage } =
    sampleData || {};

  const metricsData = isS2S ? s2sMetrics : filteredMetrics;
  // @ts-expect-error
  const activeMetric = metricsData?.[metric];

  const _baseMetrics = isS2S ? baseS2sMetrics : baseMetrics;

  const initialValue =
    // @ts-expect-error
    (_baseMetrics?.[metric] as number) || 0;

  const formattedMetric =
    typeof activeMetric === 'object'
      ? Number(_mean(activeMetric as number[])?.toFixed(2))
      : (activeMetric as number)?.toFixed(2) ?? '0';

  // Utils
  const getDelta = () => {
    let delta;
    if (initialValue && formattedMetric && formattedMetric !== '0') {
      delta = +formattedMetric - (initialValue as number);
    }

    return typeof delta === 'number' ? delta : 0;
  };

  const getMetricData = () => {
    let metrics = METRIC_OPTIONS;

    if (isInference) {
      return INFERENCE_OPTIONS;
    }

    if (isIc) {
      return IC_OPTIONS;
    }

    if (isS2S) {
      return hasS2SExtraColumns ? S2S_OPTIONS : [AVG_DEP];
    }

    if (isNer) {
      metrics = [...metrics, ...NER_OPTIONS];
    }

    return metrics;
  };

  const setMetric = (metric: string) => {
    const [metricName, isMacro] = metric.split('-');

    actions.setParameters({
      metric: metricName as Metric | NerMetric,
      isMacro: Boolean(isMacro)
    });
  };

  return (
    <Grid data-testid='fixed-section' gutter='xs' p={5}>
      <Grid.Col className='flex' h='100%' span={6}>
        <Card
          withBorder
          p='xs'
          style={{
            flex: 1,
            background: 'transparent',
            borderColor: 'var(--mantine-color-contrast-7)'
          }}
        >
          <Grid gutter={0}>
            <Grid.Col span={isNer ? 6 : 12}>
              <Text c='contrast.5' fw={700} mb={2} size='xs'>
                DATASET SIZE
              </Text>
            </Grid.Col>
            {isNer && (
              <Grid.Col span={6}>
                <Text c='contrast.5' fw={700} mb={2} ml={4} size='xs'>
                  SPANS SIZE
                </Text>
              </Grid.Col>
            )}
            <Grid.Col span={isNer ? 6 : 12}>
              <Group gap={0}>
                <Text
                  c='contrast.2'
                  className={classes.responsiveText}
                  fw={700}
                  mr={6}
                  size='xl'
                >
                  {toHumanReadableNumber(sample_count)}
                </Text>

                <Text c='dimmed' className='responsiveTextSm' size='xs'>
                  {parsePercentage(percentage)} of all{isNer ? '' : ' data'}
                </Text>
              </Group>
            </Grid.Col>
            {isNer && (
              <Grid.Col span={isNer ? 6 : 12}>
                <Group gap={0}>
                  <Text
                    c='contrast.2'
                    className={classes.responsiveText}
                    fw={700}
                    ml={4}
                    size='xl'
                  >
                    {toHumanReadableNumber(span_count)}
                  </Text>

                  <Text
                    c='dimmed'
                    className={classes.responsiveTextSm}
                    ml={4}
                    size='xs'
                  >
                    {parsePercentage(span_percentage)} of all
                  </Text>
                </Group>
              </Grid.Col>
            )}
          </Grid>
        </Card>
      </Grid.Col>
      <Grid.Col className='flex' span={6}>
        <Card
          withBorder
          p='xs'
          style={{
            flex: 1,
            background: 'transparent',
            borderColor: `var(--mantine-color-contrast-7) `
          }}
        >
          <Group p='apart'>
            <Text c='contrast.5' fw={700} size='xs'>
              METRIC
            </Text>
            <Trend
              invertColors={[
                'data_error_potential',
                'average_data_error_potential'
              ].includes(metric)}
              tooltipCopy='Metric delta of filtered samples to base samples'
              value={getDelta()}
            />
          </Group>

          <Box className='align-items-baseline'>
            <MetricSelect
              data={getMetricData()}
              displayValue={formattedMetric.toString() || STRING_PLACEHOLDER}
              value={`${metric}${isMacro ? '-macro' : ''}`}
              onChange={setMetric}
            />
          </Box>
        </Card>
      </Grid.Col>
    </Grid>
  );
};
export default FixedSection;
