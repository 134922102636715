import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';

import { USERS_KEY } from '../use-users/use-users';

const POST_INVITE_USER = '/signup_link';

export const useSignupLink = (
  onErrorCallback?: ((error: Response, email: string) => unknown) | undefined
) => {
  const queryClient = useQueryClient();

  const generateSignupLink = async ({
    email,
    sendEmail = true
  }: {
    email: string;
    sendEmail?: boolean;
  }) => {
    const res = await api.POST(POST_INVITE_USER, {
      params: {
        query: {
          user_email: email,
          send_email: sendEmail
        }
      }
    });

    return res?.data;
  };

  const mutation = useMutation(generateSignupLink, {
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_KEY]);
    },
    onError: (res: Response, vars) =>
      onErrorCallback
        ? onErrorCallback(res, vars?.email)
        : defaultErrorHandler(res, 'Error generating invite link')
  });

  return mutation;
};
