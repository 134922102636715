import check from 'check-types';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  AdvancedRunSearchStore,
  AdvancedRunSearchStoreValues
} from './advanced-run-search.store.types';
import { useParametersStore } from '../parameters-store';

const defaultValues: AdvancedRunSearchStoreValues = {
  byCollaborator: [],
  bySplitType: [],
  byTaskTypes: [],
  excludeZeroSampleRuns: true,
  inProjects: [],
  isAdvancedSearchOpened: true,
  isQueryPresent: false,
  numSamples: null,
  withinRange: [null, null],
  noMatchingRuns: false
};

export const useAdvancedRunSearchStore =
  createWithEqualityFn<AdvancedRunSearchStore>()(
    (set, get) => ({
      ...defaultValues,

      computed: {
        isDefaultState: () => {
          const {
            byCollaborator,
            bySplitType,
            byTaskTypes,
            inProjects,
            isQueryPresent,
            numSamples,
            withinRange,
            excludeZeroSampleRuns
          } = get();

          const [range1, range2] = withinRange;

          const bool =
            inProjects.length === 1 &&
            inProjects[0] === useParametersStore.getState().projectId &&
            check.all(
              [byCollaborator, bySplitType, byTaskTypes].map((arr) =>
                check.emptyArray(arr)
              )
            ) &&
            !isQueryPresent &&
            !numSamples &&
            !range1 &&
            !range2 &&
            !excludeZeroSampleRuns;

          return bool;
        }
      },

      actions: {
        setByCollaborator: (collabs) => set({ byCollaborator: collabs }),
        setBySplitType: (splits) => set({ bySplitType: splits }),
        setByTaskTypes: (types) => set({ byTaskTypes: types }),
        setExcludeZeroSampleRuns: (bool) =>
          set({ excludeZeroSampleRuns: bool }),
        setInProjects: (newProjects) => set({ inProjects: newProjects }),
        setIsQueryPresent: (bool) => set({ isQueryPresent: bool }),
        setNumSamples: (num) => set({ numSamples: num }),
        setWithinRange: (range) => set({ withinRange: range }),
        toggleAdvancedSearch: (bool) =>
          set((s) => ({
            isAdvancedSearchOpened: bool ?? !s.isAdvancedSearchOpened
          })),
        resetStore: () =>
          set(() => ({
            ...defaultValues,
            inProjects: []
          })),
        setNoMatchingRuns: (bool) =>
          set(() => ({
            noMatchingRuns: bool
          }))
      }
    }),
    shallow
  );
