import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { GlobalStore, GlobalStoreValues } from './global.store.types';

const defaultValues: GlobalStoreValues = {
  isProjectMenuOpen: false
};

export const useGlobalStore = createWithEqualityFn<GlobalStore>()(
  (set) => ({
    ...defaultValues,

    actions: {
      setIsProjectMenuOpen: (value) => set(() => ({ isProjectMenuOpen: value }))
    }
  }),
  shallow
);
