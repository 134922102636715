import { useSessionStorage } from '@mantine/hooks';
import { useEffect } from 'react';

import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { FlagTags } from '@/core/types/feature-flags.types';

// Flags coming from API.
export const useExternalFlags = () => {
  // Feature Flag Store
  const {
    getObserveAlertsFlag,
    getPromptFlag,
    getObserveFlag,
    getPromptLevelUncertaintyFlag,
    getCSVTrainingFlag,
    getGleapFlag,
    getHideCredentialsLoginFlag
  } = useFeatureFlagsStore((s) => s.computed);

  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);

  // TODO: Combine this with the above
  const [observeAlertsFlag, setObserveAlertsFlag] = useSessionStorage({
    key: 'observeAlertsFlag',
    defaultValue: getObserveAlertsFlag()
  });

  const [csvTrainingFlag, setCSVTrainingFlag] = useSessionStorage({
    key: 'csvTrainingFlag',
    defaultValue: getCSVTrainingFlag()
  });

  const [promptFlag, setPromptFlag] = useSessionStorage({
    key: 'promptFlag',
    defaultValue: getPromptFlag()
  });

  const [observeFlag, setObserveFlag] = useSessionStorage({
    key: 'observeFlag',
    defaultValue: getObserveFlag()
  });

  const [promptLevelUncertaintyFlag, setPromptLevelUncertaintyFlag] =
    useSessionStorage({
      key: 'promptLevelUncertaintyFlag',
      defaultValue: getPromptLevelUncertaintyFlag()
    });

  const [gleapFlag, setGleapFlag] = useSessionStorage({
    key: 'gleapFlag',
    defaultValue: getGleapFlag()
  });

  const [hideCredentialsLoginFlag, setHideCredentialsLoginFlag] =
    useSessionStorage({
      key: 'hideCredentialsLoginFlag',
      defaultValue: getHideCredentialsLoginFlag()
    });

  useEffect(() => {
    if (featureFlags) {
      setObserveAlertsFlag(getObserveAlertsFlag());
      setPromptFlag(getPromptFlag());
      setObserveFlag(getObserveFlag());
      setPromptLevelUncertaintyFlag(getPromptLevelUncertaintyFlag());
      setCSVTrainingFlag(getCSVTrainingFlag());
      setHideCredentialsLoginFlag(getHideCredentialsLoginFlag());
    }
  }, [featureFlags]);

  return {
    observeAlerts: {
      description: 'Alerts for Observe',
      isEnabled: observeAlertsFlag,
      toggle: () => setObserveAlertsFlag(!observeAlertsFlag),
      tag: [FlagTags.external]
    },
    prompt: {
      description: 'Prompt',
      isEnabled: promptFlag,
      toggle: () => setPromptFlag(!promptFlag),
      tag: [FlagTags.external]
    },
    promptLevelUncertainty: {
      description: 'Prompt Level Uncertainty',
      isEnabled: promptLevelUncertaintyFlag,
      toggle: () => setPromptLevelUncertaintyFlag(!promptLevelUncertaintyFlag),
      tag: [FlagTags.external]
    },
    observe: {
      description: 'Observe',
      isEnabled: observeFlag,
      toggle: () => setObserveFlag(!observeFlag),
      tag: [FlagTags.external]
    },
    csvTraining: {
      description: 'CSV Training',
      isEnabled: csvTrainingFlag,
      toggle: () => setCSVTrainingFlag(!csvTrainingFlag),
      tag: [FlagTags.external]
    },
    gleap: {
      description: 'Gleap',
      isEnabled: gleapFlag,
      toggle: () => setGleapFlag(!gleapFlag),
      tag: [FlagTags.external]
    },
    hideCredentialsLogin: {
      description: 'Hide Credentials Login',
      isEnabled: hideCredentialsLoginFlag,
      toggle: () => setHideCredentialsLoginFlag(!hideCredentialsLoginFlag),
      tag: [FlagTags.external]
    }
  };
};
