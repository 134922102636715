import { Box, Button, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

interface DefaultErrorBoundaryProps {
  resetErrorBoundary?: () => void;
}

/**
 * DefaultErrorBoundary
 */
const DefaultErrorBoundary = ({
  resetErrorBoundary
}: DefaultErrorBoundaryProps) => {
  return (
    <Box className='w-100 justify-center flex-column'>
      <Text component='div'>Something went wrong there</Text>
      <Text component='div' size='sm'>
        This error has been automatically logged and will be reviewed by the
        Galileo team
      </Text>
      <Button
        leftSection={<IconRefresh size={16} />}
        mt='md'
        size='sm'
        onClick={resetErrorBoundary}
      >
        Reload Page
      </Button>
    </Box>
  );
};

export default DefaultErrorBoundary;
