// @ts-nocheck

// Palette: https://www.figma.com/file/5GkoBQrSa65tIPoWBwU8tw/Galileo-enterprise-mocks-2?node-id=90%3A6762

import { DefaultMantineColor } from '@mantine/core';

// TODO: Ensure palette is consistent with figma/ up to date

interface ThemeObject {
  primaryColor: string;
  colors: Record<
    DefaultMantineColor | 'brand',
    [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ]
  >;
}

const light: ThemeObject = {
  primaryColor: 'brand',

  colors: {
    brand: [
      '#f6f5ff',
      '#eeebfe',
      '#ddd7fe',
      '#bab0fd',
      '#8674fb',
      '#644DF9',
      '#4a2ff9',
      '#1d06b2',
      '#130477',
      '#0a023b'
    ],
    contrast: [
      '#000000',
      '#1c1b22',
      '#272530',
      '#383645',
      '#545167',
      '#b1afc0',
      '#d3d1db',
      '#e9e8ed',
      '#f4f4f6',
      '#ffffff'
    ],
    gray: [
      '#F9F9FB',
      '#f4f4f6',
      '#e9e8ed',
      '#d3d1db',
      '#b1afc0',
      '#9b98ae',
      '#706c89',
      '#545167',
      '#383645',
      '#1c1b22'
    ],
    red: [
      '#fff5f5',
      '#feebeb',
      '#fed7d7',
      '#fdb0b0',
      '#fb7474',
      '#f94d4d',
      '#ed0707',
      '#b20606',
      '#770404',
      '#3b0202'
    ],
    green: [
      '#edfded',
      '#dafbda',
      '#b6f7b6',
      '#7ef17e',
      '#5aed5a',
      '#16ca16',
      '#12a512',
      '#0c6e0c',
      '#063706',
      '#F6FEF6'
    ],
    yellow: [
      '#FFFDF5',
      '#FFF8EB',
      '#FEF6D7',
      '#FDEEAF',
      '#FCE173',
      '#FBD84B',
      '#DCB104',
      '#B49104',
      '#785102',
      '#3C2801'
    ],
    blue: ['#E1F0FE', '#3999F9', '#B0D8FC'],
    alert: ['#E1EFFE', '#10479E'],
    charting: [
      '#8674FB',
      '#E8871A',
      '#BA26FF',
      '#F559B6',
      '#55008C',
      '#20795D',
      '#DE4B16',
      '#2F8DFA',
      '#10479E',
      '#1FD0BF',
      '#9791F3',
      '#81DC18',
      '#B6006E',
      '#DFBF03'
    ],
    chain: ['#8674FB', '#E8871A', '#E8871A', '#10479E', '#F559B6']
  }
};

const dark: ThemeObject = {
  ...light,

  colors: {
    ...light.colors,

    contrast: [
      '#ffffff',
      '#f4f4f6',
      '#e9e8ed',
      '#d3d1db',
      '#b1afc0',
      '#545167',
      '#383645',
      '#272530',
      '#1c1b22',
      '#000000'
    ],
    gray: [
      '#383645',
      '#1c1b22',
      '#272530',
      '#545167',
      '#d3d1db',
      '#b1afc0',
      '#9b98ae',
      '#706c89',
      '#383645',
      '#1c1b22'
    ],
    red: [
      '#000000',
      '#feebeb',
      '#fed7d7',
      '#fdb0b0',
      '#fb7474',
      '#fa4d4d',
      '#ed0707',
      '#b20606',
      '#770404',
      '#3b0202'
    ],
    green: [
      '#000000',
      '#dafbda',
      '#b6f7b6',
      '#7ef17e',
      '#5aed5a',
      '#16ca16',
      '#12a512',
      '#0c6e0c',
      '#063706',
      '#F6FEF6'
    ]
  }
};

const themes = {
  light,
  dark
};

export default themes;
