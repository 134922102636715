import { ActionIcon, MantineSize, TextInput } from '@mantine/core';
import { useDebouncedValue, useId, useInputState } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import { forwardRef, KeyboardEventHandler, useEffect } from 'react';

interface LiveSearchBarProps {
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  onChange?: (str: string) => void;
  onClear?: (str?: string) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  debounceTime?: number;
  size?: MantineSize;
  mb?: MantineSize;
  icon?: React.ReactNode;
  isVisible?: boolean;
  autoFocus?: boolean;
  rightSection?: React.ReactNode;
  disabled?: boolean;
  mt?: MantineSize;
  mr?: MantineSize;
}

const LiveSearchBar = forwardRef(
  (
    {
      defaultValue = '',
      label = '',
      placeholder = '',
      onChange,
      onClear,
      onKeyDown,
      debounceTime = 250,
      icon = null,
      size = 'md',
      isVisible = true,
      autoFocus = true,
      rightSection,
      disabled = false,
      mt,
      mr
    }: LiveSearchBarProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [value, setValue] = useInputState(defaultValue);
    const [debounced] = useDebouncedValue(value, debounceTime);

    const uuid = useId();

    const handleClearInput = () => {
      const input = document.getElementById(uuid);
      onClear?.(value);

      setValue('');
      input?.focus();
    };

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      onChange?.(debounced);
    }, [debounced]);

    useEffect(() => {
      if (isVisible) {
        const input = document.getElementById(uuid);

        input?.focus();
      }
    }, [isVisible]);

    return (
      <TextInput
        autoFocus={autoFocus}
        className='flex-1'
        disabled={disabled}
        id={uuid}
        label={label}
        leftSection={icon || <IconSearch size={16} />}
        mr={mr}
        mt={mt}
        placeholder={placeholder}
        ref={ref}
        rightSection={
          <>
            {rightSection}
            <ActionIcon
              aria-label='Clear search'
              color='gray'
              data-testid='clear-btn'
              mr='xs'
              size='xs'
              variant='subtle'
              onClick={handleClearInput}
            >
              <IconX size={16} />
            </ActionIcon>
          </>
        }
        size={size}
        styles={{
          wrapper: {
            flex: 1
          },
          input: {
            borderColor: '#E9E8ED'
          }
        }}
        value={value}
        onChange={setValue}
        onKeyDown={onKeyDown}
      />
    );
  }
);

export default LiveSearchBar;
