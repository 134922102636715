import { Box, Button, Card, Center, Checkbox } from '@mantine/core';
import React, { useState } from 'react';

import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { useComputedParameters } from '@/core/stores/parameters-store';
import ModalCarouselHeader from '@/fine-tune/components/modal-carousel-header/modal-carousel-header';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import useStore from '@/fine-tune/stores/store';
import { InsightsRow } from '@/fine-tune/types/query.types';

interface CvCardProps {
  body: JSX.Element;
  image: JSX.Element;
  modal: Modals;
  sample: InsightsRow;
  width?: number;
  headerHeight?: number;
  canSelect?: boolean;
}

const BaseCvCard = ({
  body,
  image,
  modal,
  sample,
  width = 275,
  headerHeight = 200,
  canSelect = true
}: CvCardProps) => {
  // Props
  const { id } = sample || {};

  // Local State
  const [isHovering, setIsHovering] = useState(false);

  // Global state
  const allRowsSelected = useStore((state) => state.allRowsSelected);
  const selectedObjects = useStore((state) => state.selectedObjects);
  const selectedRows = useStore((state) => state.selectedRows);
  const { setSelectedObjects, setSelectedRows } = useStore(
    (state) => state.actions
  );

  // Computer Vision State
  const setModalIndex = useComputerVisionStore((s) => s.actions.setModalIndex);

  // Hooks
  const { openModal } = useModals();
  const { data } = useInsightsRows();

  const samples = data?.pages?.flatMap((page) => page?.data_rows) || [];

  // Computed
  const isSelected = selectedRows?.includes(id);
  const isChecked = allRowsSelected ? !isSelected : isSelected;
  const { isOd, isSS } = useComputedParameters();

  const activeBoxes = sample?.boxes?.filter((box) => box.is_active);
  const currentIndex = samples.findIndex((sample) => sample?.id === id);

  // Event Handlers
  const handleCloseModal = () => {};

  const handleOpenModal = () => {
    setModalIndex(currentIndex);
    openModal(
      modal,
      {},
      handleCloseModal,
      <ModalCarouselHeader width={isOd || isSS ? '85vw' : '55vw'} />
    );
  };

  const handleToggleCheckbox = () => {
    const isPresent = selectedRows.includes(id);
    let updatedRows: number[] = [];
    let updatedObjects: number[] = [];

    if (isPresent) {
      updatedRows = selectedRows.filter((selected) => id !== selected);
      updatedObjects = selectedObjects.filter(
        (selected) => !activeBoxes?.some((box) => box.id === selected)
      );
    } else {
      updatedRows = [...selectedRows, id];
      updatedObjects = [
        ...selectedObjects,
        ...(activeBoxes?.map((box) => box.id) || [])
      ];
    }

    setSelectedRows(updatedRows);

    if (isOd) {
      setSelectedObjects(updatedObjects);
    }
  };

  return (
    <Card withBorder data-testid='cv-card' pb={0} pt={0} radius='md' w={width}>
      <>
        <Card.Section>
          <Box
            data-testid='cv-card-header'
            h={headerHeight}
            style={({ colors }) => ({
              background: colors.gray[1],
              position: 'relative'
            })}
            tabIndex={0}
            w='100%'
            onFocus={() => setIsHovering(true)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Tab' && e.shiftKey) {
                setIsHovering(false);
              }
            }}
            onMouseLeave={() => setIsHovering(false)}
            onMouseOver={() => setIsHovering(true)}
          >
            {canSelect && (
              <Center
                style={{
                  backgroundColor: 'rgba(100, 77, 249, .3)',
                  backgroundFilter: '',
                  display: isHovering || isChecked ? 'flex' : 'none',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: headerHeight + 2,
                  width: '100%',
                  zIndex: 2
                }}
              >
                <Checkbox
                  aria-label={`${isChecked ? 'deselect' : 'select'} sample`}
                  checked={isChecked}
                  color='brand.5'
                  pos='absolute'
                  right={15}
                  top={15}
                  onChange={() => null}
                  onClick={handleToggleCheckbox}
                />
                <Button
                  variant='default'
                  onClick={handleOpenModal}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Tab' && !e.shiftKey) {
                      setIsHovering(false);
                    }
                  }}
                >
                  Open Image
                </Button>
              </Center>
            )}
            {image}
          </Box>
        </Card.Section>
        {body}
      </>
    </Card>
  );
};

export default BaseCvCard;
