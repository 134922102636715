import {
  Box,
  Card,
  ColorSwatch,
  Grid,
  ScrollArea,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import check from 'check-types';
import cx from 'classix';

import {
  DRIFTED_COLOR,
  EASY_COLOR,
  HARD_COLOR,
  MISCLASSIFIED_COLOR,
  MISLABELED_COLOR,
  ON_THE_BOUNDARY_COLOR
} from '@/core/constants/colors.constants';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useCurrentRun } from '@/core/hooks/query-hooks/use-current-run/use-current-run';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import {
  useComputedParameters,
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
import { useSplits } from '@/fine-tune/hooks/query-hooks/use-splits/use-splits';
import { useThresholds } from '@/fine-tune/hooks/query-hooks/use-thresholds/use-thresholds';
import { useInsightsColumnHeight } from '@/fine-tune/hooks/use-element-height/use-element-height';

import AveragePrecisionChart from '../average-precision-chart/average-precision-chart';
import DistributionChart from '../distribution-chart/distribution-chart';
import ErrorTypesChart from '../error-types-chart/error-types-chart';
import GroupedByCharts from '../grouped-by-charts/grouped-by-charts';
import IoUChart from '../iou-chart/iou-chart';
import ModelPerformanceByTaskChart from '../model-performance-by-task-chart/model-performance-by-task-chart';
import MostFrequentHighDEPWords from '../most-frequent-high-dep-words/most-frequent-high-dep-words';
import NoiseTypeChart from '../noise-type-chart/noise-type-chart';
import OverlappingClasses from '../overlapping-classes/overlapping-classes';
import SeqToSeqDistributions from '../seq-to-seq-distributions/seq-to-seq-distributions';
import TopGroupedByChart from '../top-grouped-by-chart/top-grouped-by-chart';
import TopMisclassifiedLabelPairs from '../top-misclassified-label-pairs/top-misclassified-label-pairs';
import TopOverlappingLabels from '../top-overlapping-labels/top-overlapping-labels';

interface RenderArray {
  label: string;
  value: string;
  color: string;
  tooltipCopy: string | null;
  onClick: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
}

/**
 * MetricsColumnContent
 *
 *
 *
 * @returns {React.Component} MetricsColumnContent
 */
const MetricsColumnContent = () => {
  const theme = useMantineTheme();
  const { runHasTrainingSplit } = useCurrentRun();

  const sample = usePercentageSample();
  const thresholds = useThresholds();
  const { withDrifted } = useSplits();

  const { computed } = useFeatureFlagsStore();

  const { isInference, isNer, isMltc, isOd, isSD, isSS, isS2S } =
    useComputedParameters();

  const split = useParametersStore((s) => s.split);

  const {
    depHigh,
    depLow,
    misclassifiedOnly,
    likelyMislabelled,
    dataframeColumns,
    isDrifted,
    isOnTheBoundary
  } = useParametersStore((s) => ({
    depHigh: s.depHigh,
    depLow: s.depLow,
    misclassifiedOnly: s.misclassifiedOnly,
    groupedBy: s.groupedBy,
    likelyMislabelled: s.likelyMislabelled,
    dataframeColumns: s.dataframeColumns,
    isDrifted: s.isDrifted,
    isOnTheBoundary: s.isOnTheBoundary
  }));

  const { setParameters } = useParametersStoreActions();

  const maxHeight = useInsightsColumnHeight();

  const {
    sample_easy_percentage,
    sample_hard_percentage,
    sample_misclassified_percentage,
    sample_otb_percentage,
    sample_drifted_percentage,
    sample_mislabeled_percentage
  } = sample?.data || {};

  const { hard_samples_threshold: hard, easy_samples_threshold: easy } =
    thresholds?.data || {};

  const showOOCChip = split !== 'training' && runHasTrainingSplit;

  const percentageArray = [
    {
      label: 'Hard for the model',
      value: parsePercentage(sample_hard_percentage),
      color: theme.colors[HARD_COLOR.color][HARD_COLOR.shade],
      onClick: () => setParameters({ depHigh: 1, depLow: hard || 0 }),
      isSelected: depLow == hard,
      tooltipCopy: 'Click to see samples hard for the model'
    },
    showOOCChip
      ? {
          label: 'Out of coverage',
          value: parsePercentage(sample_drifted_percentage),
          color: theme.colors[DRIFTED_COLOR.color][DRIFTED_COLOR.shade],
          onClick: () =>
            setParameters({
              isDrifted: true,
              sortBy: 'drift_score',
              sortDirection: 'desc',
              dataframeColumns: [...dataframeColumns, 'drift_score']
            }),
          isSelected: isDrifted,
          isDisabled: !runHasTrainingSplit,
          tooltipCopy: 'Click to out of coverage samples'
        }
      : {
          label: 'Easy for the model',
          value: parsePercentage(sample_easy_percentage),
          color: theme.colors[EASY_COLOR.color][EASY_COLOR.shade],
          onClick: () => setParameters({ depHigh: easy || 1, depLow: 0 }),
          isSelected: depHigh == easy,
          tooltipCopy: 'Click to see samples easy for the model'
        },
    {
      label: 'Misclassified',
      value: parsePercentage(sample_misclassified_percentage),
      color: theme.colors[MISCLASSIFIED_COLOR.color][MISCLASSIFIED_COLOR.shade],
      onClick: () => setParameters({ misclassifiedOnly: true }),
      isSelected: misclassifiedOnly,
      isDisabled: isS2S,
      tooltipCopy: 'Click to see samples misclassified samples'
    },
    {
      label: 'Likely Mislabeled',
      value: parsePercentage(sample_mislabeled_percentage),
      color: theme.colors[MISLABELED_COLOR.color][MISLABELED_COLOR.shade],
      onClick: () =>
        setParameters({ likelyMislabelled: true, lmPercentile: 100 }),
      isDisabled: isMltc || isS2S,
      isSelected: likelyMislabelled,
      tooltipCopy: 'Click to see samples easy for the model'
    }
  ];

  const inferencePercentageArray = [
    {
      label: 'Drifted',
      value: withDrifted
        ? parsePercentage(sample_drifted_percentage)
        : STRING_PLACEHOLDER,

      color: withDrifted
        ? theme.colors[DRIFTED_COLOR.color][DRIFTED_COLOR.shade]
        : theme.colors.gray[2],

      tooltipCopy: withDrifted
        ? null
        : 'A training run must be logged to see drifted inference data',
      onClick: () =>
        setParameters({
          isDrifted: true,
          sortBy: 'drift_score',
          sortDirection: 'desc',
          dataframeColumns: [...dataframeColumns, 'drift_score']
        }),
      isSelected: isDrifted,
      isDisabled: !runHasTrainingSplit
    },
    {
      label: 'On the Boundary',
      value: parsePercentage(sample_otb_percentage),
      color:
        theme.colors[ON_THE_BOUNDARY_COLOR.color][ON_THE_BOUNDARY_COLOR.shade],
      onClick: () => setParameters({ isOnTheBoundary: true }),
      isSelected: isOnTheBoundary,
      tooltipCopy: null
    }
  ];

  const renderArray: RenderArray[] = isInference
    ? inferencePercentageArray
    : percentageArray;

  const CHARTS = {
    AveragePrecisionChart: <AveragePrecisionChart />,
    DistributionChart: <DistributionChart />,
    ErrorTypesChart: <ErrorTypesChart />,
    GroupedByCharts: <GroupedByCharts />,
    IoUChart: <IoUChart />,
    ModelPerformanceByTaskChart: <ModelPerformanceByTaskChart />,
    MostFrequentHighDEPWords: <MostFrequentHighDEPWords />,
    NoiseTypeChart: <NoiseTypeChart />,
    OverlappingClasses: split === 'training' && <OverlappingClasses />,
    SeqToSeqDistributions: <SeqToSeqDistributions />,
    TopGroupedByChart: <TopGroupedByChart />,
    TopOverlappingLabels: computed.getCoOccurrenceFlag() && (
      <TopOverlappingLabels />
    ),
    TopMisclassifiedLabelPairs: <TopMisclassifiedLabelPairs />
  };

  const buildCharts = () => {
    const defaultCharts = [
      CHARTS.GroupedByCharts,
      CHARTS.OverlappingClasses,
      CHARTS.TopMisclassifiedLabelPairs,
      CHARTS.DistributionChart
    ];

    if (isS2S) {
      return [
        CHARTS.MostFrequentHighDEPWords,
        CHARTS.GroupedByCharts,
        CHARTS.DistributionChart,
        CHARTS.SeqToSeqDistributions
      ];
    }

    if (isOd) {
      return [
        CHARTS.ErrorTypesChart,
        CHARTS.AveragePrecisionChart,
        CHARTS.GroupedByCharts,
        CHARTS.TopGroupedByChart,
        CHARTS.OverlappingClasses,
        CHARTS.TopMisclassifiedLabelPairs,
        CHARTS.DistributionChart
      ];
    }
    if (isNer) {
      return [
        CHARTS.MostFrequentHighDEPWords,
        CHARTS.ErrorTypesChart,
        CHARTS.GroupedByCharts,
        CHARTS.OverlappingClasses,
        CHARTS.TopMisclassifiedLabelPairs,
        CHARTS.DistributionChart
      ];
    }

    if (isMltc) {
      return [
        CHARTS.ModelPerformanceByTaskChart,
        CHARTS.TopOverlappingLabels,
        CHARTS.GroupedByCharts,
        CHARTS.TopMisclassifiedLabelPairs,
        CHARTS.DistributionChart
      ];
    }

    if (isSS) {
      return [
        CHARTS.ErrorTypesChart,
        CHARTS.IoUChart,
        CHARTS.GroupedByCharts,
        CHARTS.TopGroupedByChart,
        CHARTS.OverlappingClasses,
        CHARTS.TopMisclassifiedLabelPairs,
        CHARTS.DistributionChart
      ];
    }

    if (isSD) {
      return [CHARTS.NoiseTypeChart, ...defaultCharts];
    }

    return defaultCharts;
  };

  const charts = buildCharts();

  return (
    <ScrollArea
      pb='sm'
      style={{
        height: maxHeight
      }}
    >
      <Grid gutter={0}>
        {renderArray?.map(
          (
            {
              label,
              value,
              color,
              tooltipCopy,
              onClick,
              isDisabled,
              isSelected
            },
            idx
          ) => {
            if (isDisabled) {
              return;
            }

            return (
              <Grid.Col
                key={label}
                mb='xs'
                pl={idx % 2 !== 0 ? 4 : 0}
                pr={idx % 2 === 0 ? 4 : 0}
                span={6}
              >
                <Card
                  withBorder
                  className='flex-1 w-100'
                  p={4}
                  style={{
                    border: isSelected
                      ? `1px dashed var(--mantine-color-brand-3)`
                      : ''
                  }}
                  onClick={onClick}
                >
                  <Tooltip
                    withArrow
                    disabled={!tooltipCopy}
                    label={tooltipCopy}
                  >
                    <Box
                      className={cx(
                        'align-center w-100',
                        check.function(onClick) && 'cursor-pointer'
                      )}
                      data-testid='dataset-size-card'
                    >
                      <ColorSwatch
                        color={color}
                        radius='xs'
                        style={{
                          width: 4,
                          minWidth: 4
                        }}
                        styles={{
                          shadowOverlay: {
                            boxShadow: 'none'
                          }
                        }}
                      />
                      <Text
                        c='dimmed'
                        className='w-100 truncate'
                        fw={400}
                        ml='xs'
                        size='sm'
                      >
                        {label}
                      </Text>
                      <Text className='white-space-nowrap' fw={700} size='sm'>
                        {value}
                      </Text>
                    </Box>
                  </Tooltip>
                </Card>
              </Grid.Col>
            );
          }
        )}
      </Grid>
      {charts.map((chart, idx) => {
        return <div key={idx}>{chart}</div>;
      })}
    </ScrollArea>
  );
};

export default MetricsColumnContent;
