import { Button, ButtonProps, ModalProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';

interface ModalLauncherProps extends ButtonProps {
  modal: React.ComponentType<ModalProps>;
}

export const ModalLauncher = ({
  modal: ModalComponent,
  ...props
}: ModalLauncherProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <ModalComponent opened={opened} onClose={close} />
      <Button onClick={open} {...props} />
    </>
  );
};
