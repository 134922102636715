import { Group, GroupProps, Text } from '@mantine/core';

import { SuccessMetricStatus } from '@/core/types/metric-status.types';
import { UnitFormatMap } from '@/core/utils/unit-conversions/unit-format-mapping';

import MetricExplanationBody from '../metric-explanation-body/metric-explanation-body';
import { MetricPopover } from '../metric-popover/metric-popover';
import { BaseSuccessMetricStatusProps } from '../metric-status/metric-status';
import Metric from '../../atoms/metric/metric';
import ScorerTypePill from '../../atoms/scorer-type-pill/scorer-type-pill';

const VALUES_LIMIT = 1;

type ArrayMetricProps = BaseSuccessMetricStatusProps<{
  columnTypeLabel: string;
  groupProps?: GroupProps;
  /** Used to apply custom formatting when displaying individual values from the array */
  formatLabel?: (
    value: SuccessMetricStatus['value']
  ) => string | number | JSX.Element;
}>;

const ArrayMetric = ({
  columnTypeLabel,
  cost,
  dataTestId,
  explanation,
  formatLabel,
  groupProps,
  popoverProps,
  rationale,
  scorer_type,
  value,
  ...metricProps
}: ArrayMetricProps) => {
  const valueAsArray = value != null && Array.isArray(value) ? value : [];

  let contentElements: JSX.Element[] = [
    <Metric
      color='gray'
      key='default-array-metric'
      value={<Text fw='800'>None</Text>}
      variant='subtle'
      {...metricProps}
    />
  ];
  let truncatedContentElements = [...contentElements];

  if (valueAsArray.length > 0) {
    contentElements = valueAsArray.map((item, index) => (
      <Metric
        color='red'
        key={`${item}-${index}`}
        value={
          <Text truncate fw='800'>
            {formatLabel != null ? formatLabel(item) : `${item}`}
          </Text>
        }
        {...metricProps}
      />
    ));

    truncatedContentElements = [...contentElements].slice(0, VALUES_LIMIT);
    if (valueAsArray.length > VALUES_LIMIT) {
      truncatedContentElements.push(
        <Metric
          color='gray'
          key='show-more-array-metric'
          value={
            <Text
              truncate
              fw='800'
            >{`+${valueAsArray.length - VALUES_LIMIT}`}</Text>
          }
          {...metricProps}
        />
      );
    }
  }

  return (
    <MetricPopover
      body={
        <>
          <Group p='sm'>{contentElements}</Group>
          <MetricExplanationBody
            cost={cost}
            explanation={explanation}
            formatCost={UnitFormatMap['parsed__dollars']}
            rationale={rationale}
          />
        </>
      }
      disabled={
        explanation == null &&
        rationale == null &&
        cost == null &&
        contentElements.length === 0
      }
      header={
        <Group
          gap='md'
          justify='space-between'
          pr='0.5rem'
          w='100%'
          wrap='nowrap'
        >
          <Metric
            color='white'
            value={`Detected ${valueAsArray.length} ${columnTypeLabel} type(s)`}
            variant='subtle'
          />
          {scorer_type != null && <ScorerTypePill type={scorer_type} />}
        </Group>
      }
      {...popoverProps}
    >
      <Group
        className='truncate'
        data-testid={dataTestId ?? 'array-metric'}
        wrap='nowrap'
        {...groupProps}
      >
        {truncatedContentElements}
      </Group>
    </MetricPopover>
  );
};

export default ArrayMetric;
