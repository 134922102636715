import { Button, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClipboard } from '@mantine/hooks';
import { ContextModalProps } from '@mantine/modals';
import { IconClipboardCheck, IconClipboardPlus } from '@tabler/icons-react';
import check from 'check-types';
import { useEffect, useRef, useState } from 'react';

import api from '@/core/api';
import { showNotification } from '@/core/utils/show-notification/show-notification';

type CustomContextModalProps = ContextModalProps & {
  innerProps?: {
    email?: string;
  };
};

const ResetPasswordModal = ({ innerProps }: CustomContextModalProps) => {
  const [resetUrl, setResetUrl] = useState<string | null>(null);
  const clipboard = useClipboard({ timeout: 2000 });

  const emailInputRef = useRef<HTMLInputElement>(null);

  const form = useForm({
    initialValues: {
      email: innerProps?.email || ''
    },
    validate: {
      email: (value) => (check.emptyString(value) ? 'Please enter email' : null)
    }
  });

  const handleReset = async (email: string) => {
    const res = await api
      .GET('/generate_password_reset', {
        params: { query: { user_email: email } }
      })
      .catch(({ response }) => {
        showNotification({
          title: 'Error generating password reset link',
          message: response?.data?.detail || 'Please try again',
          type: 'error'
        });
      });

    if (res?.response.status === 200) {
      const [, token] = res?.data?.pathname?.split('reset_token=') || [];

      const url = `${window.location.host}/reset-password?resetToken=${token}&email=${email}`;

      setResetUrl(url);
    }
  };

  useEffect(() => {
    emailInputRef?.current?.focus();
  }, []);

  return (
    <form
      onSubmit={form.onSubmit(({ email }) => {
        handleReset(email);
      })}
    >
      <TextInput
        label='Email'
        placeholder='Email of account needing password reset'
        ref={emailInputRef}
        {...form.getInputProps('email')}
      />

      {resetUrl && (
        <TextInput
          label={`Password Reset Link for ${form?.values?.email}`}
          mt='sm'
          rightSection={
            <Tooltip
              withArrow
              label='Copied to your clipboard!'
              opened={clipboard.copied}
            >
              <Button
                color={clipboard.copied ? 'green' : 'brand'}
                size='xs'
                onClick={() => clipboard.copy(resetUrl)}
              >
                {clipboard.copied ? (
                  <IconClipboardCheck size={16} />
                ) : (
                  <IconClipboardPlus size={16} />
                )}
              </Button>
            </Tooltip>
          }
          rightSectionWidth={50}
          value={resetUrl}
          variant='filled'
        />
      )}

      <Button mt='md' type='submit'>
        Reset Password
      </Button>
    </form>
  );
};

export default ResetPasswordModal;
