import { Box, ScrollArea, Select, Space } from '@mantine/core';
import capitalize from 'lodash/capitalize';
import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import BarChart from '@/core/components/atoms/bar-chart';
import { OnClickReturn } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import { useFocusAndScroll } from '@/core/hooks/use-focus-and-scroll/use-focus-and-scroll';
import {
  useActiveFilters,
  useComputedParameters,
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import {
  Metric,
  NerMetric,
  ParametersValues,
  SSMetric
} from '@/core/stores/parameters-store/parameters.store.types';
import { NER_OPTIONS } from '@/fine-tune/constants/metric-and-grouped-selects.constants';
import { SCROLLBAR_SIZE } from '@/fine-tune/constants/misc.constants';
import { parseMetaColumnName } from '@/fine-tune/data-parsers/parse-meta-column-name/parse-meta-column-name';
import { useInsightsGroupedBy } from '@/fine-tune/hooks/query-hooks/use-insights-grouped-by/use-insights-grouped-by';
import { useMetaColumns } from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';
import { useDataframeColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { useSortBy } from '@/fine-tune/hooks/use-sort-by/use-sort-by';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import useStore from '@/fine-tune/stores/store';
import { colorMappings } from '@/fine-tune/utils/color-mappings/color-mappings';

import ChartHeader from './chart-header';
import {
  sortAndFilterData,
  SortBy,
  SortedData
} from './grouped-by-charts.utils';

interface NameMap {
  [key: string]: string;
}

const NAME_MAP: NameMap = {
  gold: 'Ground Truth',
  pred: 'Prediction'
};

/**
 * GroupedByCharts
 *
 */
const GroupedByCharts = () => {
  const { isInference, isMltc, isOoc, isOd, isSS, isS2S } =
    useComputedParameters();

  const { metric, groupedBy, comparedTo, isDrifted } = useParametersStore(
    (s) => ({
      metric: s.metric,
      groupedBy: s.groupedBy,
      comparedTo: s.comparedTo,
      isDrifted: s.isDrifted
    })
  );

  const { setParameters, setMetaParameters } = useParametersStoreActions();

  const { metaColumnNames } = useMetaColumns();
  const { categoricalColumns, isCategorical, isContinuous } =
    useDataframeColumns();

  const activeFilters = useActiveFilters();

  const { data: groupedByData, isFetching: groupedByIsFetching } =
    useInsightsGroupedBy();

  const { data: baselineGroupedBy } = useInsightsGroupedBy('training');

  const colorMap = useStore((s) => s.colorMap);
  const colorBy = useEmbeddingsStore((s) => s.colorBy);

  const focusRef = useFocusAndScroll(colorBy, isCategorical(colorBy), () =>
    setParameters({ groupedBy: colorBy })
  );

  const { sortProps, setSortProps, sortIcon } = useSortBy();
  const { sortBy, sortDir } = sortProps;

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Since bar chart is calculated inside the component, we need to force a re calculation of the width
    // when the search term changes
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (
      isS2S &&
      ['gold', 'pred'].includes(groupedBy) &&
      categoricalColumns?.length
    ) {
      setParameters({ groupedBy: categoricalColumns[0]?.accessor || '' });
    }
  }, [groupedBy, isS2S, categoricalColumns?.length]);

  const firstChart = useRef<HTMLDivElement>(null);
  const secondChart = useRef<HTMLDivElement>(null);

  const { labels = [], support = [] } = groupedByData || {};

  const isGroupingByGoldOrPred = ['gold', 'pred'].includes(groupedBy);

  const colorSchema = isGroupingByGoldOrPred
    ? colorMap
    : colorMappings([...labels]?.sort());

  const getFilterKey = (): keyof ParametersValues => {
    if (isGroupingByGoldOrPred) {
      if (isMltc) {
        return `${groupedBy as 'gold' | 'pred'}Filter`;
      }

      return isInference ? 'predFilter' : 'classFilter';
    }

    return groupedBy as keyof ParametersValues;
  };

  const handleBarClick = ({ label }: OnClickReturn) => {
    if (metaColumnNames?.includes(groupedBy)) {
      if (isContinuous(groupedBy) && /^\d+$/.test(label)) {
        setMetaParameters([{ name: groupedBy, is_equal: +label }]);
      } else {
        setMetaParameters([{ name: groupedBy, isin: [label] }]);
      }
    } else {
      const filterKey = getFilterKey();

      const currentFilter = activeFilters?.find(
        ([filterName]) => filterName === filterKey
      );

      const newLabel = currentFilter?.[0] === label ? '' : label;

      setParameters({ [filterKey]: [newLabel] });
    }
  };

  const onScrollPositionChange = (
    isScrollingFirstChart: boolean,
    newPos: { x: number }
  ) => {
    if (!firstChart.current || !secondChart.current) {
      return null;
    }

    if (isScrollingFirstChart) {
      secondChart.current.scrollLeft = newPos.x;
    } else {
      firstChart.current.scrollLeft = newPos.x;
    }
  };

  const name: keyof NameMap = parseMetaColumnName(groupedBy);

  const formattedGroupBy = NAME_MAP?.[name?.toLowerCase()] || name;

  const metricName =
    NER_OPTIONS.find(({ value }) => value === metric)?.label || metric;

  const parsedMetric = capitalize(metricName?.replaceAll('_', ' '));

  const headerCopy = isInference ? 'Model Confidence' : parsedMetric;

  const renderBaselineData = Boolean(
    (isInference && comparedTo) || isDrifted || isOoc
  );

  let dataKey = metric as Metric | NerMetric | SSMetric;
  if (isInference) {
    dataKey = 'confidence';
  }

  // Workaround to support image classification
  if (metric === 'accuracy') {
    dataKey = 'recall';
  }

  // @ts-expect-error - FIXME
  const data = groupedByData?.[dataKey] || [];

  const baselineData = renderBaselineData
    ? // @ts-expect-error - FIXME
      (baselineGroupedBy?.[dataKey] as number[])
    : undefined;

  let groupedBySelectData = categoricalColumns.map(
    ({ accessor, label, uniqueValuesCount, uniqueValues }) => ({
      value: accessor,
      label: label as string,
      disabled: uniqueValuesCount > 0 && !uniqueValues?.length
    })
  );

  const tooltipOffset = firstChart.current?.scrollLeft;

  const handleSortChange = (chart: SortBy) => {
    const { sortBy, sortDir } = sortProps || {};
    if (!sortBy || !sortDir) {
      setSortProps({
        sortBy: chart,
        sortDir: 'asc'
      });
      return;
    }

    setSortProps({
      sortBy: chart,
      sortDir: sortDir === 'asc' ? 'desc' : undefined
    });
  };

  const sortedData: SortedData = useMemo(
    () =>
      sortAndFilterData({
        baselineData,
        baselineGroupedBy,
        data,
        labels,
        metric,
        searchTerm,
        sortBy,
        sortDir,
        support
      }),
    [data, sortProps, labels, searchTerm, baselineData, support]
  );

  const backgroundColors = sortedData.labels?.map(
    (label) => colorSchema?.[label]?.bar
  );

  const handleTermChange = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <Box
      p='sm'
      style={({ colors }) => ({
        backgroundColor: colors.gray[2],
        borderRadius: 6
      })}
    >
      <Select
        searchable
        aria-label='Grouped By'
        className='minimal-select no-border justify-flex-start'
        data={groupedBySelectData}
        label='Grouped By'
        nothingFoundMessage='No options found.'
        p={3}
        pl={12}
        styles={{
          root: {
            backgroundColor: 'white',
            borderRadius: 6,
            maxWidth: 300
          }
        }}
        value={groupedBy}
        variant='unstyled'
        onChange={(val) => {
          setParameters({ groupedBy: val || '' });
        }}
      />
      {!(isOd || isSS || isS2S) && (
        <SplitCard
          header={
            <ChartHeader
              copy={headerCopy}
              searchTerm={searchTerm}
              sortIcon={sortIcon}
              onSortClick={() => handleSortChange('metric')}
              onTermChange={handleTermChange}
            />
          }
          mb='sm'
          mt='sm'
          ref={focusRef}
        >
          <Space h='sm' />
          <ScrollArea
            scrollbarSize={SCROLLBAR_SIZE}
            viewportRef={firstChart}
            onScrollPositionChange={(newPos) =>
              onScrollPositionChange(true, newPos)
            }
          >
            <BarChart
              backgroundColor={backgroundColors}
              baselineData={
                renderBaselineData ? sortedData.baselineData : undefined
              }
              data={sortedData.data}
              isLoading={isLoading || groupedByIsFetching}
              labels={sortedData.labels}
              stackedLabels={NER_OPTIONS.slice(1).map((option) => option.label)}
              tooltipOffset={tooltipOffset}
              onClick={handleBarClick}
            />
          </ScrollArea>
        </SplitCard>
      )}

      <SplitCard
        header={
          <ChartHeader
            copy={
              isInference
                ? `Class Distribution`
                : `${isSS ? 'Polygon' : 'Sample'} count by ${formattedGroupBy}`
            }
            searchTerm={searchTerm}
            sortIcon={sortIcon}
            onSortClick={() => handleSortChange('support')}
            onTermChange={setSearchTerm}
          />
        }
        mb='sm'
        mt={isOd ? 'lg' : 'xs'}
      >
        <Space h='sm' />
        <ScrollArea
          scrollbarSize={SCROLLBAR_SIZE}
          viewportRef={secondChart}
          onScrollPositionChange={(newPos) =>
            onScrollPositionChange(false, newPos)
          }
        >
          <BarChart
            backgroundColor={backgroundColors}
            baselineData={
              renderBaselineData ? sortedData?.baselineSupport : undefined
            }
            data={sortedData.support}
            isLoading={isLoading || groupedByIsFetching}
            labels={sortedData.labels}
            tooltipOffset={tooltipOffset}
            onClick={handleBarClick}
          />
        </ScrollArea>
      </SplitCard>
    </Box>
  );
};

export default GroupedByCharts;
