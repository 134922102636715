import { ComboboxItem, Select, useMantineTheme } from '@mantine/core';
import { useState } from 'react';

import BarChart from '@/core/components/atoms/bar-chart';
import { HistogramData } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import { DEFAULT_BAR_CHART_COLOR } from '@/core/constants/colors.constants';
import { useParametersStoreActions } from '@/core/stores/parameters-store';
import { useInsightsDistribution } from '@/fine-tune/hooks/query-hooks/use-insights-distribution/use-insights-distribution';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useMetaColumns } from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';

const SeqToSeqDistributions = () => {
  // State
  const [firstCol, setFirstCol] = useState('bleu');
  const [secondCol, setSecondCol] = useState('perplexity');

  // Theme
  const theme = useMantineTheme();

  // Hooks
  const { setMetaParameters } = useParametersStoreActions();
  const { data: data1, isLoading: isLoading1 } =
    useInsightsDistribution(firstCol);
  const { data: data2, isLoading: isLoading2 } =
    useInsightsDistribution(secondCol);
  const { continuousFilters = [] } = useMetaColumns();
  const { hasUncertainty } = useInsightsRows();

  // Computed
  const { counts, bins } = data1 || {};
  const { counts: counts2, bins: bins2 } = data2 || {};

  const firstLabels = bins?.slice(0, -1) || [];
  const secondLabels = bins2?.slice(0, -1) || [];

  const firstData = firstLabels?.map((x, idx) => ({
    x,
    y: counts?.[idx] || 0
  }));

  const secondData = secondLabels?.map((x, idx) => ({
    x,
    y: counts2?.[idx] || 0
  }));

  const barColors =
    theme.colors[DEFAULT_BAR_CHART_COLOR.color][DEFAULT_BAR_CHART_COLOR.shade];

  let firstSelectData: ComboboxItem[] = [
    { value: 'bleu', label: 'BLEU' },
    { value: 'rouge', label: 'ROUGE-1' }
  ];
  let secondSelectData: ComboboxItem[] = [
    { value: 'perplexity', label: 'Perplexity' }
  ];

  if (hasUncertainty) {
    secondSelectData.push({
      value: 'generated_uncertainty',
      label: 'Uncertainty'
    });
  }

  continuousFilters.forEach((filter) => {
    if (!['bleu', 'rouge'].includes(filter.value)) {
      secondSelectData.push(filter);
    }
  });

  const handleDragSelect = (isFirst: boolean, start: number, end: number) => {
    const _bins = isFirst ? bins : bins2;
    const col = isFirst ? firstCol : secondCol;
    const firstValue = _bins?.[start] || 0;
    const lastValue = _bins?.[end + 1] || 1;

    setMetaParameters([
      {
        name: col,
        greater_than: firstValue,
        less_than: lastValue
      }
    ]);
  };

  return (
    <>
      {!isLoading1 && !data1 ? null : (
        <SplitCard
          header={
            <Select
              searchable
              aria-label='seq-2-seq-first-distribution-chart'
              data={firstSelectData}
              size='sm'
              styles={{
                input: {
                  fontSize: 16,
                  color: 'var(--mantine-color-gray-6)'
                }
              }}
              value={firstCol}
              variant='unstyled'
              onChange={(val) => {
                if (val) {
                  setFirstCol(val);
                }
              }}
            />
          }
          mt='xs'
        >
          <BarChart
            isHistogram
            backgroundColor={barColors}
            data={firstData as HistogramData[]}
            isLoading={isLoading1}
            labels={firstLabels?.map((l) => `${l}`)}
            mt='sm'
            onHistogramDrag={(...args) => handleDragSelect(true, ...args)}
          />
        </SplitCard>
      )}
      {!isLoading2 && !data2 ? null : (
        <SplitCard
          header={
            <Select
              searchable
              aria-label='seq-2-seq-second-distribution-chart'
              data={secondSelectData}
              size='sm'
              styles={{
                input: {
                  fontSize: 16,
                  color: 'var(--mantine-color-gray-6)'
                }
              }}
              value={secondCol}
              variant='unstyled'
              onChange={(val) => {
                if (val) {
                  setSecondCol(val);
                }
              }}
            />
          }
          mt='xs'
        >
          <BarChart
            isHistogram
            backgroundColor={barColors}
            data={secondData as HistogramData[]}
            isLoading={isLoading2}
            labels={secondLabels?.map((l) => `${l}`)}
            mt='sm'
            onHistogramDrag={(...args) => handleDragSelect(false, ...args)}
          />
        </SplitCard>
      )}
    </>
  );
};

export default SeqToSeqDistributions;
