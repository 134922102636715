import check from 'check-types';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { splitFilters } from '@/core/utils/parse-query-param-arrays/parse-query-param-arrays';

import { useMetaColumns } from '../query-hooks/use-meta-columns/use-meta-columns';
import { useSimilarToText } from '../query-hooks/use-similar-to-text/use-similar-to-text';

export const generateMetaColHeader = (accessor = '') =>
  accessor
    .replace(/galileo|_/gi, ' ')
    .replace(/Id/gi, 'ID')
    .replace(/pii/gi, 'PII')
    .trim();

export const truncateArray = (
  str: string | (string | number | boolean | undefined | null)[]
) => {
  const array = check.string(str) ? splitFilters(str as string) : str;

  if (array?.length) {
    return `${array[0]}${
      array.length > 1 ? ` + ${array.length - 1} more` : ''
    }`;
  }
  return STRING_PLACEHOLDER;
};

export const FILTER_TYPES = {
  MULTI: 'multi',
  TEXT: 'text',
  NUMBER: 'number'
};

export const useActiveFilterDictionary = (): ((
  label: string,
  value: string | number[] | string
) => {
  parsedLabel: string;
  parsedValue: string | number[] | null;
  width: number | 'auto';
  filterType?: string;
}) => {
  const { isIc, isNer, isOd, isS2S } = useComputedParameters();
  const { isRegex, isSpanRegex, fromSplit, split } = useParametersStore(
    (s) => ({
      isRegex: s.isRegex,
      isSpanRegex: s.isSpanRegex,
      fromSplit: s.fromSplit,
      split: s.split
    })
  );

  const similarToResponse = useSimilarToText();
  const { isContinuousMeta } = useMetaColumns();

  let entity = 'samples';
  if (isNer) {
    entity = 'spans';
  }
  if (isOd) {
    entity = 'objects';
  }
  const getParsedSimilarToValue = (value: number[]) => {
    if (value.length > 1) {
      return `${value.length} ${entity}`;
    }
    const { text_sample, span_start, span_end, input } =
      similarToResponse?.data?.embedding_data?.[0] || {};

    if (isIc) {
      return 'selected samples';
    }

    if (isOd) {
      return 'selected objects';
    }

    if (isS2S) {
      return input || STRING_PLACEHOLDER;
    }

    // TODO: persist similar from text sample across splits
    if (isNer && fromSplit !== split) {
      return '';
    }

    if (isNer) {
      return (
        text_sample?.substring(span_start ?? 0, span_end ?? 0) ||
        STRING_PLACEHOLDER
      );
    }

    return text_sample || STRING_PLACEHOLDER;
  };

  const getActiveFilterData = (label: string, value: string | number[]) => {
    let parsedValue: string | number[] | null = value;
    let width: number | 'auto' = 'auto';
    let parsedLabel;
    let filterType;

    switch (label) {
      case 'ids':
        parsedLabel = 'Selected Data Points';
        parsedValue = value?.length?.toString();
        break;
      case 'misclassifiedOnly':
        parsedLabel = 'Misclassified only';
        parsedValue = null;
        break;
      case 'likelyMislabelled':
        parsedLabel = 'Likely mislabelled';
        parsedValue = null;
        break;
      case 'correctlyClassified':
        parsedLabel = 'Correctly classified';
        parsedValue = null;
        break;
      case 'searchTerm':
        filterType = FILTER_TYPES.TEXT;
        parsedLabel = isRegex ? 'Text Search (Regex)' : 'Text Search';
        break;
      case 'spanSearch':
        parsedLabel = isSpanRegex ? 'Span Search (Regex)' : 'Span Search';
        parsedValue = value.toString();
        break;
      case 'depHigh':
        filterType = FILTER_TYPES.NUMBER;
        parsedLabel = 'DEP less than';
        parsedValue = Number(value).toFixed(3);
        break;
      case 'depLow':
        filterType = FILTER_TYPES.NUMBER;
        parsedLabel = 'DEP greater than';
        parsedValue = Number(value).toFixed(3);
        break;
      case 'goldFilter':
        filterType = FILTER_TYPES.MULTI;
        parsedLabel = 'Ground Truth';
        parsedValue = truncateArray(value as string);
        break;
      case 'predFilter':
        filterType = FILTER_TYPES.MULTI;
        parsedLabel = 'Predicted';
        parsedValue = truncateArray(value as string);
        break;
      case 'classFilter':
        filterType = FILTER_TYPES.MULTI;
        parsedLabel = 'Classes';
        parsedValue = truncateArray(value as string);
        break;
      case 'similarTo':
        parsedLabel = fromSplit ? `Similar from ${fromSplit}` : 'Similar To';
        parsedValue = getParsedSimilarToValue(value as number[]);
        width = isNer || isIc || isOd ? 'auto' : 350;
        break;
      case 'drift_percentile':
        parsedLabel = 'Drift %ile';
        parsedValue = `${value}%`;
        filterType = FILTER_TYPES.NUMBER;
        break;

      case 'lmPercentile':
        parsedLabel = 'LM %ile';
        parsedValue = `${value}%`;
        break;

      case 'isDrifted':
        parsedLabel = useParametersStore.getState().computed.isOoc()
          ? 'Out of Coverage Samples'
          : 'Drifted Samples';
        parsedValue = null;
        break;
      case 'isOnTheBoundary':
        parsedLabel = 'On the boundary Samples';
        parsedValue = null;
        break;
      case 'clusterIds':
        parsedLabel = 'Cluster ID';
        parsedValue = truncateArray(value);
        break;
      case 'galileo_error_type':
        parsedLabel = 'Error Types';
        parsedValue = truncateArray(value);
        break;

      case 'noise_type':
        parsedLabel = 'Noise Type';
        parsedValue = truncateArray(value);
        break;

      default:
        // meta filters
        parsedLabel =
          label === 'confidence' ? 'Confidence' : generateMetaColHeader(label);

        if (isContinuousMeta(label)) {
          parsedValue = value;
        } else {
          parsedValue = truncateArray(value);
        }

        break;
    }

    return { parsedLabel, parsedValue, width, filterType };
  };

  return getActiveFilterData;
};
