import { DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { TaskType } from '@/core/constants/tasks-and-frameworks.constants';
import { useAdvancedRunSearchStore } from '@/core/stores/advanced-run-search-store/advanced-run-search.store';
import { Splits } from '@/core/stores/parameters-store/parameters.store.types';
import { components } from '@/core/types/api';
import { Run } from '@/core/types/projects.types';

export interface RunsWithProjectName extends Run {
  project_name: string;
}

dayjs.extend(isBetween);

export const filterRuns = (
  projectsData?: components['schemas']['ProjectDBThin'][],
  query?: string
) => {
  if (!projectsData) {
    return [];
  }

  const {
    excludeZeroSampleRuns,
    inProjects,
    withinRange,
    byTaskTypes,
    numSamples,
    bySplitType,
    byCollaborator
  } = useAdvancedRunSearchStore.getState();

  const failsZeroRunsCheck = (samples: number, task_type: number) => {
    return excludeZeroSampleRuns && ![7, 9].includes(task_type) && samples <= 0;
  };

  const failsInProjectsCheck = (id: string) => {
    return inProjects?.length && !inProjects.includes(id);
  };

  const failsDateRangeCheck = (
    range: DatesRangeValue,
    created_at: string | null
  ) => {
    return (
      range[1] &&
      created_at &&
      !dayjs(created_at).isBetween(withinRange[0], withinRange[1])
    );
  };

  const failsTaskTypeCheck = (taskType?: number) => {
    if (!byTaskTypes.length) {
      return false;
    }
    return !byTaskTypes.includes(taskType?.toString() as TaskType);
  };

  const failsNumSamplesCheck = (samples: number) => {
    return typeof numSamples === 'number' && samples <= numSamples;
  };

  const failsSplitTypeCheck = (loggedSplits: Splits[] = []) => {
    if (!bySplitType.length) {
      return false;
    }

    return !loggedSplits.some((split) => bySplitType.includes(split));
  };

  const failsCollaboratorCheck = (createdById: string) => {
    if (!byCollaborator.length) {
      return false;
    }

    return !byCollaborator.includes(createdById);
  };

  const failsQueryCheck = (runName: string) => {
    if (!query) {
      return false;
    }

    return !runName?.toLowerCase().includes(query.toLowerCase());
  };

  const filtered =
    projectsData?.flatMap((proj) =>
      proj.runs.map((run) => ({ ...run, project_name: proj.name }))
    ) ?? [];

  return filtered.reduce<RunsWithProjectName[]>((acc, curr) => {
    const {
      num_samples = 0,
      project_id = '',
      created_at = null,
      task_type,
      // @ts-expect-error TODO: Fix this
      logged_splits,
      created_by,
      name
    } = curr;

    // Check to see if any condition fails, if so, return accumulator
    if (
      failsZeroRunsCheck(num_samples, task_type as number) ||
      failsInProjectsCheck(project_id as string) ||
      failsDateRangeCheck(withinRange, created_at) ||
      failsTaskTypeCheck(task_type as number) ||
      failsNumSamplesCheck(num_samples) ||
      failsSplitTypeCheck(logged_splits) ||
      failsCollaboratorCheck(created_by) ||
      failsQueryCheck(name!)
    ) {
      return acc;
    }

    // If passes all checks, push to accumulator
    // @ts-expect-error TODO: Fix this
    acc.push(curr);

    return acc;
  }, []);
};
