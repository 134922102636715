import { Flex, FlexProps, Text, useMantineTheme } from '@mantine/core';
import React, { forwardRef } from 'react';

import { STATUS } from '@/core/constants/job-status.constants';
import { JobStatus } from '@/core/hooks/query-hooks/use-latest-job/use-latest-job';
import { TablerIconsProps } from '@/core/types/icons.types';
import { getJobStatusLabel } from '@/core/utils/get-job-status-label/get-job-status-label';
import {
  isFailedJobStatus,
  isInProgressJobStatus
} from '@/core/utils/get-job-status-state/get-job-status-state';

export type MetricColor =
  | 'green'
  | 'red'
  | 'brand'
  | 'blue'
  | 'gray'
  | 'white'
  | 'yellow'
  | 'dark'
  | 'default';

export interface MetricProps extends FlexProps {
  color?: MetricColor;
  compact?: boolean;
  maxWidth?: number;
  status?: JobStatus;
  value?: string | number | JSX.Element;
  variant?: 'default' | 'filled' | 'outline' | 'subtle';
  icon?: (props: TablerIconsProps) => React.ReactNode;
}

const Metric = forwardRef(
  (
    {
      color = 'default',
      compact = false,
      icon,
      maxWidth,
      status = STATUS.COMPLETED,
      value,
      variant = 'default',
      ...others
    }: MetricProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    // Defaults `null` to completed (just like `undefined`)
    status = status || STATUS.COMPLETED;

    const { colors } = useMantineTheme();

    const primary = {
      default: colors.gray[6],
      brand: colors.brand[6],
      blue: colors.blue[1],
      green: colors.green[6],
      red: colors.red[5],
      gray: colors.gray[6],
      white: 'white',
      yellow: colors.yellow[6],
      dark: 'white'
    };

    const secondary = {
      default: 'white',
      brand: colors.brand[1],
      blue: colors.gray[1],
      green: colors.green[1],
      red: colors.red[1],
      gray: colors.gray[1],
      white: 'white',
      yellow: colors.yellow[1],
      dark: colors.gray[7]
    };

    let VALUE_STYLE = {};

    if (variant === 'filled') {
      VALUE_STYLE = {
        backgroundColor: secondary?.[color] ?? colors.gray[1],
        padding: compact ? '2px 8px' : '3px 10px',
        borderRadius: 8,
        color: primary[color],
        fontWeight: 700
      };
    }

    if (variant === 'outline') {
      VALUE_STYLE = {
        border: `1px solid ${colors.gray[2]}`,
        borderRadius: 4,
        padding: compact ? '2px 6px' : '2px 4px',
        color: primary[color],
        backgroundColor: 'white'
      };
    }

    if (variant === 'subtle') {
      VALUE_STYLE = {
        borderRadius: 8,
        padding: compact ? '2px 8px' : '3px 10px',
        color: primary[color],
        backgroundColor: 'transparent'
      };
    }

    const isInProgress = isInProgressJobStatus(status);
    const isError = isFailedJobStatus(status);

    const size = compact ? 'xs' : 'sm';

    others.style = others?.style
      ? {
          ...VALUE_STYLE,
          ...others.style
        }
      : VALUE_STYLE;

    const Icon = icon;

    return (
      <Flex
        align='center'
        data-testid='metric'
        gap='0.25rem'
        justify='flex-start'
        ref={ref}
        style={{ ...VALUE_STYLE }}
        {...others}
      >
        {Icon && <Icon size='1rem' />}
        {isInProgress ? (
          <Text
            c='dimmed'
            className='computing-metric'
            component='span'
            size='xs'
          >
            {getJobStatusLabel(status)}
          </Text>
        ) : (
          <Text
            c={primary[color]}
            component='span'
            fw={700}
            m='auto'
            maw={maxWidth}
            size={size}
            truncate={Boolean(maxWidth)}
          >
            {isError ? getJobStatusLabel(status) : value}
          </Text>
        )}
        {others.children}
      </Flex>
    );
  }
);

export default Metric;
