import { ActionIcon, Box, Group, Title } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import classes from './insights-column.module.css';

import { useLatestJob } from '@/core/hooks/query-hooks/use-latest-job/use-latest-job';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import InsightsCard from '@/fine-tune/components/insights-card/insights-card';
import { useInsightAlerts } from '@/fine-tune/hooks/query-hooks/use-insights-alerts/use-insights-alerts';
import { useInsightsStore } from '@/fine-tune/stores/insights-store/insights.store';

import CVInsightsColumnStats from './insights-column-components/fixed-section/cv-insights-column-stats/cv-insights-column-stats';
import FixedSection from './insights-column-components/fixed-section/fixed-section';
import InsightColumnTabs from './insights-column-components/insights-column-tabs/insight-column-tabs';
/**
 *
 *
 *
 *
 * @returns {React.Component}
 */
const InsightsColumn = () => {
  const { actions, activeInsightsCard, isInsightsColumnVisible } =
    useInsightsStore((s) => ({
      actions: s.actions,
      activeInsightsCard: s.activeInsightsCard,
      isInsightsColumnVisible: s.isInsightsColumnVisible
    }));

  const taskType = useParametersStore((state) => state.taskType);

  const { isOd, isSS, isPrompt } = useComputedParameters();

  const { data: insightsAlertsData } = useInsightAlerts();
  const { data: latestJobStatus } = useLatestJob();

  const selectedInsight = insightsAlertsData?.find(
    (alert) => alert.id === activeInsightsCard?.id
  );

  if (isPrompt) return null;

  // Don't show the column if the job is not completed or the task is not loaded yet
  // otherwise it will call inappropriate endpoints that will return 500
  const showColumnContent =
    latestJobStatus?.status === 'completed' && taskType != null;

  return (
    <Box
      className={classes.insightsColumn}
      h='100%'
      mah='100vh'
      style={{
        display: isInsightsColumnVisible ? 'flex' : 'none'
      }}
    >
      {selectedInsight ? (
        <Box
          data-testid='selected-insight-card-wrapper'
          id='selected-insight-card-wrapper'
          p='sm'
        >
          <InsightsCard withClose insight={selectedInsight} />
        </Box>
      ) : (
        <Group justify='space-between' p='sm'>
          <Title order={4} size={20}>
            Insights
          </Title>
          <ActionIcon color='gray' variant='subtle'>
            <IconX
              size={20}
              onClick={() => actions.setIsInsightColumnVisible(false)}
            />
          </ActionIcon>
        </Group>
      )}
      {showColumnContent && (
        <>
          {isOd || isSS ? <CVInsightsColumnStats /> : <FixedSection />}
          <InsightColumnTabs />
        </>
      )}
    </Box>
  );
};

export default InsightsColumn;
