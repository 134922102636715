import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

export const MAP = 'MAP';

export interface MapResponse {
  map: number;
  map_threshold: number;
  error_scores: {
    labels: string[];
    values: number[];
  };
  ap_per_class: {
    labels: string[];
    values: number[];
  };
}
interface UseMapArgs {
  isBaseMetrics?: boolean;
}

export const useMap = (config?: UseMapArgs) => {
  // Parameters Store
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);
  const mapThreshold = useParametersStore((state) => state.mapThreshold);

  // Computed Parameters
  const { isOd } = useComputedParameters();

  // Hooks
  const filter_params = useFilterParams();
  const path =
    '/projects/{project_id}/runs/{run_id}/split/{split}/insights/map';

  // Computed Properties
  const enabled = Boolean(projectId && runId && isOd);

  const body = {
    map_threshold: mapThreshold ?? 0.5,
    filter_params: config?.isBaseMetrics ? {} : filter_params
  };

  // Fetcher
  const fetchMap = async () => {
    const res = await api.POST(path, {
      body,
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!,
          split
        }
      }
    });

    return res.data;
  };

  // Response
  const response = useQuery(
    [
      MAP,
      {
        projectId,
        runId,
        split,
        ...filter_params,
        isBaseMetrics: config?.isBaseMetrics
      }
    ],
    fetchMap,
    {
      enabled
    }
  );

  return response;
};
