import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

export const GET_SEMSEG_METRICS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/semseg/metrics';

export const useSemsegMetrics = () => {
  // Parameters Store
  const { projectId, runId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  // Computed Parameters
  const { isSS } = useComputedParameters();

  // Hooks
  const filter_params = useFilterParams();

  // Computed Properties
  const enabled = Boolean(projectId && runId && isSS);

  // Fetcher
  const fetchMetrics = async () => {
    const res = await api.POST(GET_SEMSEG_METRICS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: split
        }
      },
      body: { filter_params }
    });

    return res?.data;
  };

  // Response
  const response = useQuery(
    [GET_SEMSEG_METRICS, { projectId, runId, split, ...filter_params }],
    fetchMetrics,
    {
      enabled
    }
  );

  return response;
};
