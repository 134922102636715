import {
  NerMetric,
  SSMetric
} from '@/core/stores/parameters-store/parameters.store.types';

export const NER_OPTIONS: NerOption[] = [
  { value: 'total_errors', label: 'Total Errors' },
  { value: 'missed_label', label: 'Missed Spans' },
  { value: 'span_shift', label: 'Span Shift' },
  { value: 'wrong_tag', label: 'Wrong Tag' },
  { value: 'ghost_span', label: 'Ghost Spans' }
];

export const NER_SORT_KEYS: string[] = [
  'Span Shift',
  'Wrong Tag',
  'Missed Spans',
  'Ghost Spans',
  'None'
];

interface NerOption {
  value: NerMetric;
  label: string;
}

export const METRIC_OPTIONS = [
  { label: 'F1 (weighted)', value: 'f1' },
  { label: 'F1 (macro)', value: 'f1-macro' },
  { label: 'Precision (weighted)', value: 'precision' },
  { label: 'Precision (macro)', value: 'precision-macro' },
  { label: 'Recall (weighted)', value: 'recall' },
  { label: 'Recall (macro)', value: 'recall-macro' },
  { label: 'DEP', value: 'data_error_potential' }
];

export const INFERENCE_OPTIONS = [{ label: 'Confidence', value: 'confidence' }];

export const IC_OPTIONS = [
  { value: 'accuracy', label: 'Accuracy' },
  ...METRIC_OPTIONS
];

export const AVG_DEP = {
  value: 'average_data_error_potential',
  label: 'Avg DEP'
};

export const S2S_OPTIONS = [
  { value: 'average_rouge', label: 'ROUGE-1' },
  { value: 'average_bleu', label: 'BLEU' },
  AVG_DEP
];

export const OD_MAP_THRESHOLDS = [
  { label: 'mAP (0.5)', value: '0.5' },
  { label: 'mAP (0.7)', value: '0.7' },
  { label: 'mAP (0.9)', value: '0.9' }
];

interface MetricOption {
  label: string;
  value: SSMetric;
}

export const SS_METRICS: MetricOption[] = [
  { label: 'Boundary IoU', value: 'boundary_iou' },
  { label: 'Mean IoU', value: 'mean_iou' },
  { label: 'Dice Coefficient', value: 'dice_coefficient' }
];
