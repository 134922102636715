import _startCase from 'lodash/startCase';

import ProtectStatusPill from '@/core/components/atoms/protect-status-pill/protect-status-pill';
import UserName from '@/core/components/atoms/user-name/user-name';
import ArrayMetric from '@/core/components/molecules/array-metric/array-metric';
import MetricTemplateLabel from '@/core/components/molecules/metric-template-label/metric-template-label';
import { AggregateScoreRating } from '@/core/components/molecules/score-rating/aggregate-score-rating/aggregate-score-rating';
import { ScoreRating } from '@/core/components/molecules/score-rating/score-rating';
import { AggregateStarRating } from '@/core/components/molecules/star-rating/aggregate-star-rating/aggregate-star-rating';
import { StarRating } from '@/core/components/molecules/star-rating/star-rating';
import { AggregateTagsRating } from '@/core/components/molecules/tags-rating/aggregate-tags-rating/aggregate-tags-rating';
import { TagsRating } from '@/core/components/molecules/tags-rating/tags-rating';
import { TextRating } from '@/core/components/molecules/text-rating/text-rating';
import { AggregateThumbRating } from '@/core/components/molecules/thumb-rating/aggregate-thumb-rating/aggregate-thumb-rating';
import { ThumbRating } from '@/core/components/molecules/thumb-rating/thumb-rating';
import TokenLevelScore, {
  Segment
} from '@/core/components/molecules/token-level-score/token-level-score';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { components } from '@/core/types/api';
import { SuccessMetricStatus } from '@/core/types/metric-status.types';
import { IPromptRunParameters } from '@/evaluate/hooks/use-prompt-run-parameters/use-prompt-run-parameters';
import PromptTemplateMenu from '@/evaluate/page-components/prompt-template-menu/prompt-template-menu';

import { DateUnit, ListUnit, NumberUnit, StringUnit, UnitFormatType } from '.';
import { getValueAsNumber } from '../get-value-as-number/get-value-as-number';

type Conversion = (value: any, ...args: any) => string | number | JSX.Element;

const defaultFormatter = (value: unknown) => {
  if (typeof value === 'string') {
    return new StringUnit(value as string).to('title_case').toString();
  }

  if (typeof value === 'number') {
    return new NumberUnit(value as number).toString({
      maximumFractionDigits: 4
    });
  }

  if (Array.isArray(value)) {
    return new ListUnit(value).toString({
      style: 'long',
      type: 'conjunction'
    });
  }

  return STRING_PLACEHOLDER;
};

export const UnitFormatMap: Record<UnitFormatType, Conversion> = {
  hallucination_segments: defaultFormatter,
  unknown: defaultFormatter,

  text: (
    metric: string | { explanation: string | null; rating: { value: string } }
  ) => {
    if (typeof metric === 'object') {
      const value = metric?.rating?.value;
      const explanation = metric?.explanation ?? undefined;

      if (value) {
        return (
          <TextRating explanation={explanation} maxWidth={200} rating={value} />
        );
      }

      return JSON.stringify(metric);
    }

    return new StringUnit(metric).toString();
  },

  label: (value: string) => {
    return new StringUnit(value as string).to('title_case').toString();
  },

  // TODO implement this formatter
  text_offsets: (value: any) => {
    return value;
  },

  floating_point: (value: number) => {
    return new NumberUnit(value).to('float').toString();
  },

  integer: (value: number) => {
    return new NumberUnit(value).to('wholeNumber').toString();
  },

  timestamp: (value: string) => {
    return new DateUnit(value).toString({
      dateStyle: 'short',
      timeStyle: 'short'
    });
  },

  // milli_seconds are returned as nanoseconds for prompt.
  milli_seconds: (value: number) => {
    return new NumberUnit(value, 'nanoseconds').to('milliseconds').toString({
      style: 'unit',
      unit: 'millisecond'
    });
  },

  boolean: (value: string | Boolean | undefined) => {
    return value ? 'True' : 'False';
  },

  uuid: (value: string) => {
    return new StringUnit(value).toString();
  },

  percentage: (value: number) => {
    return new NumberUnit(value)
      .to('percentage', { places: 2, asDecimal: true })
      .toString({
        style: 'percent'
      });
  },

  dollars: (value: number) => {
    const dollars = new NumberUnit(value).to('dollars', { places: 4 });

    if ((dollars.toNumber() ?? 0) === 0) return '< $0.0001';

    return dollars.toString({
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 4
    });
  },

  array: (value: any[]) => {
    return new ListUnit(value).toString({
      style: 'long',
      type: 'conjunction'
    });
  },

  protect_status: (value: string) => {
    return value ? <ProtectStatusPill size='lg' status={value} /> : ' N/A';
  },

  segments: (segments: Segment[], output, isTruncated, showTokens) => {
    return (
      <TokenLevelScore
        isTruncated={isTruncated}
        response={output}
        segments={segments}
        showTokens={showTokens}
      />
    );
  },

  score_rating: (metric: {
    explanation: string | null;
    rating: { value: number | null };
  }) => {
    const value = metric?.rating?.value ?? 0;
    const formattedScore = new NumberUnit(value).toString({
      maximumFractionDigits: 2
    });
    const explanation = metric?.explanation ?? undefined;

    return (
      <ScoreRating
        explanation={explanation}
        score={formattedScore}
        title='Score Rating'
      />
    );
  },

  score_rating_aggregate: (metric: {
    aggregate: { average: number; unrated_count: number };
  }) => {
    const value = metric?.aggregate?.average ?? 0;
    const formattedScore = new NumberUnit(value).toString({
      maximumFractionDigits: 2
    });

    return <AggregateScoreRating score={formattedScore} title='Score Rating' />;
  },

  star_rating: (metric: {
    explanation: string | null;
    rating: { value: number | null };
  }) => {
    const rating = metric?.rating?.value ?? 0;
    const explanation = metric?.explanation ?? undefined;

    return <StarRating explanation={explanation} rating={rating} />;
  },

  star_rating_aggregate: (
    value: {
      aggregate: components['schemas']['StarAggregate'];
    },
    column
  ) => {
    const average = new NumberUnit(
      value?.aggregate?.average,
      'number'
    ).toNumber();

    const counts = value?.aggregate?.counts ?? {};
    const countsWithUnrated = {
      ...counts,
      unrated: value?.aggregate?.unrated_count ?? 0
    };

    return (
      <AggregateStarRating
        average={average ?? 0}
        // @ts-expect-error
        counts={countsWithUnrated}
        explanation=''
        singular={false}
        title={column?.label}
      />
    );
  },

  tags_rating: (
    metric: {
      explanation: string | null;
      rating: { value: string[] | null };
    },
    column
  ) => {
    const tags = metric?.rating?.value ?? [];
    const explanation = metric?.explanation ?? undefined;

    return (
      <TagsRating explanation={explanation} tags={tags} title={column?.label} />
    );
  },

  thumb_rating: (metric: {
    explanation: string | null;
    rating: { value: boolean | undefined };
  }) => {
    const rating = metric?.rating?.value;
    const highlightValue = rating === false;
    const explanation = metric?.explanation ?? undefined;

    return (
      <ThumbRating
        explanation={explanation}
        highlightResult={highlightValue}
        rating={rating}
      />
    );
  },

  thumb_rating_aggregate: (
    value: {
      aggregate: {
        feedbackType: 'like_dislike';
        dislike_count: number;
        like_count: number;
        unrated_count: number;
      };
    },
    column
  ) => {
    const { dislike_count, like_count, unrated_count } = value?.aggregate ?? {};
    const total = dislike_count + like_count + unrated_count;
    const likeRatio = like_count / total;
    const dislikeRatio = dislike_count / total;
    const unratedRatio = unrated_count / total;

    return (
      <AggregateThumbRating
        dislikeRatio={dislikeRatio}
        likeRatio={likeRatio}
        title={column?.label}
        unratedRatio={unratedRatio}
      />
    );
  },
  tags_rating_aggregate: (
    value: {
      aggregate: { counts: { [key: string]: number }; unrated_count: number };
    },
    column
  ) => {
    const counts = value?.aggregate?.counts;

    return <AggregateTagsRating counts={counts} title={column?.label} />;
  },

  thumb_rating_percentage: (
    value: number,
    columnName: 'like_ratio' | 'dislike_ratio' | 'unrated_ratio'
  ) => {
    let rating: undefined | 0 | 1 = undefined;

    if (columnName === 'dislike_ratio') {
      rating = 0;
    }

    if (columnName === 'like_ratio') {
      rating = 1;
    }

    const percentage = new NumberUnit(value)
      .to('percentage', { places: 2, asDecimal: true })
      .toNumber();

    return (
      <ThumbRating
        highlightResult={rating === 0}
        rating={rating}
        ratio={percentage}
      />
    );
  },

  user_id: (value: string) => {
    return <UserName id={value} />;
  },

  template_label: (promptRunParams: IPromptRunParameters) => {
    const template =
      promptRunParams?.configuration?.['Template'] ?? STRING_PLACEHOLDER;
    const templateVersionSelected = Boolean(
      promptRunParams?.internal?.templateVersionSelected
    );

    return (
      <PromptTemplateMenu
        promptRunParams={promptRunParams}
        target={
          <MetricTemplateLabel
            templateName={template}
            templateVersionSelected={templateVersionSelected}
          />
        }
      />
    );
  },

  // =====================================================================================
  // PARSED UNIT FORMAT TYPES: formatters that parse data upfront and handle nullish data
  // =====================================================================================
  // TODO: figure out how we want to handle parsing versus formatting
  // TODO: extract common formatting with APIDataTypes i.e. dollars

  // $0.0000
  parsed__dollars: (value: string | number | null | undefined) => {
    const valueAsNumber = getValueAsNumber(value);
    if (isNaN(valueAsNumber)) {
      return STRING_PLACEHOLDER;
    }
    return new NumberUnit(valueAsNumber).to('dollars', { places: 4 }).toString({
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 4,
      minimumFractionDigits: 4
    });
  },

  // 1200 ms
  parsed__milliseconds: (value: string | number | null | undefined) => {
    const valueAsNumber = getValueAsNumber(value);
    if (isNaN(valueAsNumber)) {
      return STRING_PLACEHOLDER;
    }
    return new NumberUnit(valueAsNumber, 'milliseconds').toString({
      style: 'unit',
      unit: 'millisecond'
    });
  },

  parsed__score: (value: string | number | null | undefined) => {
    const valueAsNumber = getValueAsNumber(value);
    if (isNaN(valueAsNumber)) {
      return STRING_PLACEHOLDER;
    }
    return new NumberUnit(valueAsNumber).toString({
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  },

  // MM/DD/YY
  parsed__date: (value: string | number | null | undefined) => {
    const valueAsDate = new Date(value ?? 'invalid');
    if (isNaN(valueAsDate.getTime())) {
      return STRING_PLACEHOLDER;
    }
    return new DateUnit(valueAsDate).toString({
      dateStyle: 'short'
    });
  },

  // MM/DD/YY, HH:MM:SS PM EDT
  parsed__datetime: (value: string | number | null | undefined) => {
    const valueAsDate = new Date(value ?? 'invalid');
    if (isNaN(valueAsDate.getTime())) {
      return STRING_PLACEHOLDER;
    }
    return new DateUnit(valueAsDate).toString({
      dateStyle: 'short',
      timeStyle: 'long'
    });
  },

  // Start Case Label
  parsed__startCase: (value: SuccessMetricStatus['value']) => {
    return typeof value === 'string' ? _startCase(value) : `${value}`;
  },

  // HH:MM:SS PM EDT
  parsed__time: (value: string | number | null | undefined) => {
    const valueAsDate = new Date(value ?? 'invalid');
    if (isNaN(valueAsDate.getTime())) {
      return STRING_PLACEHOLDER;
    }
    return new DateUnit(valueAsDate).toString({
      timeStyle: 'long'
    });
  },

  parsed__whole_number: (value: string | number | null | undefined) => {
    const valueAsNumber = getValueAsNumber(value);
    if (isNaN(valueAsNumber)) {
      return STRING_PLACEHOLDER;
    }
    return new NumberUnit(valueAsNumber).to('wholeNumber').toString();
  },

  parsed__number: (value: string | number | null | undefined) => {
    const valueAsNumber = getValueAsNumber(value);
    if (isNaN(valueAsNumber)) {
      return STRING_PLACEHOLDER;
    }
    return new NumberUnit(valueAsNumber).toString();
  },

  pii: (value: any) => {
    return (
      <ArrayMetric
        columnTypeLabel='PII'
        formatLabel={(value) => UnitFormatMap['parsed__startCase'](value)}
        value={value}
      />
    );
  }
};
