import { useInfiniteQuery } from '@tanstack/react-query';
import check from 'check-types';

import { ASC } from '@/core/constants/query-params.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import useStore from '@/fine-tune/stores/store';
import { FilterParams } from '@/fine-tune/types/query.types';

import { useInsightsRowsQueryKey } from './use-insights-rows-query-key';
import { useMetaColumns } from '../use-meta-columns/use-meta-columns';

export const INSIGHTS_ROWS = 'INSIGHTS_ROWS';
import api from '@/core/api';
const POST_INSIGHTS_ROWS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/rows';
interface Body {
  page_num: number;
  filter_params: Partial<FilterParams>;
  sort_ascending: boolean;
  sort_by: string | null;
  include_emb: boolean;
  meta_cols: string[] | undefined;
}

export const useInsightsRows = (
  config: { includeEmb?: boolean; pointIds?: number[] } = {}
) => {
  let queryKey = INSIGHTS_ROWS;
  if (config?.pointIds) {
    queryKey.concat(config.pointIds.join(''));
  }

  const insightsRowsQueryKey = useInsightsRowsQueryKey(queryKey);
  const _metaColumns = useMetaColumns();

  const similarFromIds = useStore((state) => state.similarFromIds);
  const setSimilarToQuantity = useStore(
    (state) => state.actions.setSimilarToQuantity
  );
  const { runId, projectId } = usePathParameters();

  const { inferenceName, sortBy, sortDirection, task, split } =
    useParametersStore((s) => ({
      inferenceName: s.inferenceName,
      sortBy: s.sortBy,
      sortDirection: s.sortDirection,
      task: s.task,
      split: s.split
    }));
  const { isMltc, isInference, isPrompt } = useComputedParameters();

  const params = useFilterParams();
  const filter_params = config?.pointIds
    ? {
        ids: [...config.pointIds]
      }
    : params;

  // In order to load table with correct meta columns, we need to first fetch the meta cols,
  // then fetch summary insights
  let enabled = Boolean(
    projectId && runId && !_metaColumns.isLoading && !isPrompt
  );

  if (isMltc) {
    enabled = Boolean(enabled && task);
  }

  if (isInference) {
    enabled = Boolean(enabled && inferenceName);
  }

  const fetchInsightsSummary = async ({ pageParam = 0 }) => {
    const body: Body = {
      page_num: pageParam,
      filter_params,
      sort_ascending: sortDirection === ASC,
      sort_by: sortBy ?? null,
      include_emb: config?.includeEmb || false,
      meta_cols: _metaColumns?.data?.meta?.map(
        ({ name }: { name: string }) => name
      )
    };

    const res = await api.POST(POST_INSIGHTS_ROWS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        },
        query: {
          // @ts-expect-error
          sort_by_id_list: similarFromIds?.length ? 'true' : undefined
        }
      },
      body
    });

    if (params.similar_to && !params.num_similar_to) {
      setSimilarToQuantity(res?.data?.data_rows?.length);
    }

    return res?.data;
  };

  const response = useInfiniteQuery(
    insightsRowsQueryKey,
    fetchInsightsSummary,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        lastPage?.has_next_page ? pages.length : undefined
    }
  );

  const firstElement = response?.data?.pages[0]?.data_rows?.[0];

  return {
    ...response,
    hasS2SExtraColumns: !!firstElement?.generated_output,
    hasUncertainty: check.number(firstElement?.generated_uncertainty)
  };
};
