import { ActionIcon, Box, Stack, Text, Tooltip } from '@mantine/core';
import { spotlight } from '@mantine/spotlight';
import {
  IconBarbell,
  IconHeartRateMonitor,
  IconPlus,
  IconPrompt,
  IconSearch
} from '@tabler/icons-react';
import axios from 'axios';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import ModKbd from '@/core/components/atoms/mod-kbd/mod-kbd';
import MenuAvatar from '@/core/components/molecules/menu-avatar/menu-avatar';
import NavActionIcon from '@/core/components/molecules/nav-action-icon/nav-action-icon';
import { Logo } from '@/core/constants/galileo-logos.constants';
import { Paths } from '@/core/constants/routes.constants';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { useGlobalHotkeys } from '@/core/hooks/use-global-hotkeys/use-global-hotkeys';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import { DevToolsDrawer } from '@/core/page-components/dev-tools/dev-tools-drawer/dev-tools-drawer';
import AdvancedSearch from '@/core/providers/spotlight-provider/spotlight-provider';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';
import { API_URL } from '@/pages/api/auth/[...nextauth]';

/**
 * NavBar
 * @returns {React.Component} NavBar
 */
const NavBar = () => {
  const router = useRouter();
  let { prompt, observe } = useFeatureFlags();
  const setFeatureFlags = useFeatureFlagsStore(
    (state) => state.actions.setFeatureFlags
  );

  const isProjectMenuOpen = useGlobalStore((s) => s.isProjectMenuOpen);
  const { setIsProjectMenuOpen } = useGlobalStore((s) => s.actions);

  const { getBinding } = useGlobalHotkeys();

  const handleToggleProjectSearch = (type: ProjectType) => {
    setIsProjectMenuOpen(isProjectMenuOpen === type ? false : type);
  };

  const trainingProjectType = useProjectType(PROJECT_TYPE.TRAINING_INFERENCE);
  const evaluateProjectType = useProjectType(PROJECT_TYPE.PROMPT_EVALUATION);
  const observeProjectType = useProjectType(PROJECT_TYPE.OBSERVE);
  const is404Page =
    router.asPath === '/404' ||
    router.asPath === Paths.NOT_FOUND ||
    router.pathname === '/404';

  useEffect(() => {
    // 404 page is a static page so it can't use "getStaticProps" to get the feature flags
    if (is404Page) {
      const fetchFlags = async () => {
        const response = await axios.get(`${API_URL}/healthcheck/config`);
        setFeatureFlags(response.data);
      };
      fetchFlags();
    }
  }, [is404Page]);

  return (
    <Box
      className='flex-column'
      data-testid='nav-bar'
      h='100%'
      miw={65}
      py='sm'
      style={{
        backgroundColor: 'var(--mantine-color-contrast-8)',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRight: `1px solid var(--mantine-color-gray-2)`,
        zIndex: 201
      }}
      w={65}
    >
      <Stack>
        <Tooltip withArrow label='Go to latest runs' position='right-end'>
          <Link passHref href={Paths.ROOT}>
            <ActionIcon
              aria-label='Go to latest runs'
              size='xl'
              variant='transparent'
            >
              <Logo />
            </ActionIcon>
          </Link>
        </Tooltip>
        <Link
          passHref
          data-testid='new-project-button'
          href={Paths.CREATE_NEW_PROJECT}
        >
          <NavActionIcon
            hoverColor='#E9E8ED'
            icon={<IconPlus color='black' />}
            label='Create new project'
          />
        </Link>
        <NavActionIcon
          data-testid='spotlight-search'
          hoverColor='#E9E8ED'
          icon={<IconSearch color='black' />}
          label={
            <Box className='justify-center flex-column'>
              <Text>Search projects</Text>
            </Box>
          }
          onClick={() => spotlight.open()}
        />
        <NavActionIcon
          data-testid='project-search'
          hoverColor='#E9E8ED'
          icon={<IconBarbell color='black' />}
          label={
            <Box className='justify-center flex-column'>
              <Text>{`${trainingProjectType.label} projects`}</Text>
              <ModKbd binding={getBinding('project-menu')} />
            </Box>
          }
          onClick={() =>
            handleToggleProjectSearch(PROJECT_TYPE.TRAINING_INFERENCE)
          }
        />
        {prompt.isEnabled && (
          <NavActionIcon
            data-testid='prompt-search'
            hoverColor='#E9E8ED'
            icon={<IconPrompt color='black' />}
            label={
              <Box className='justify-center flex-column'>
                <Text>{`${evaluateProjectType.label} projects`}</Text>
                <ModKbd binding={getBinding('prompt-menu')} />
              </Box>
            }
            onClick={() =>
              handleToggleProjectSearch(PROJECT_TYPE.PROMPT_EVALUATION)
            }
          />
        )}
        {observe.isEnabled && (
          <NavActionIcon
            data-testid='observe-search'
            hoverColor='#E9E8ED'
            icon={<IconHeartRateMonitor color='black' />}
            label={
              <Box className='justify-center flex-column'>
                <Text>{`${observeProjectType.label} projects`}</Text>
                <ModKbd binding={getBinding('observe-menu')} />
              </Box>
            }
            onClick={() => handleToggleProjectSearch(PROJECT_TYPE.OBSERVE)}
          />
        )}
      </Stack>
      <AdvancedSearch />
      <Stack>
        <DevToolsDrawer />
        <MenuAvatar position='right-end' />
      </Stack>
    </Box>
  );
};

export default NavBar;
