import { Group, MantineSize, Text } from '@mantine/core';

import { UnitFormatMap } from '@/core/utils/unit-conversions/unit-format-mapping';

import MetricExplanationBody from '../metric-explanation-body/metric-explanation-body';
import { MetricPopover } from '../metric-popover/metric-popover';
import { BaseSuccessMetricStatusProps } from '../metric-status/metric-status';
import Metric, { MetricColor } from '../../atoms/metric/metric';
import ScorerTypePill from '../../atoms/scorer-type-pill/scorer-type-pill';

type ScoreMetricProps = BaseSuccessMetricStatusProps<{
  color: MetricColor | undefined;
  label: string | undefined;
  size?: MantineSize;
}>;

const ScoreMetric = ({
  color,
  cost,
  dataTestId,
  explanation,
  label,
  popoverProps,
  rationale,
  scorer_type,
  size,
  value,
  ...metricProps
}: ScoreMetricProps) => {
  const valueAsNumber: number | undefined =
    value != null && typeof value === 'number' && !isNaN(value)
      ? value
      : undefined;

  const hasLabel = label != null;

  let contentElement = (
    <Text c={color} data-testid='metric-score' fw={700} size={size} ta='center'>
      {hasLabel ? label : UnitFormatMap['parsed__score'](valueAsNumber)}
    </Text>
  );

  return (
    <MetricPopover
      body={
        <MetricExplanationBody
          cost={cost}
          explanation={explanation}
          formatCost={UnitFormatMap['parsed__dollars']}
          rationale={rationale}
        />
      }
      disabled={explanation == null && rationale == null && cost == null}
      header={
        <Group
          gap='md'
          justify='space-between'
          pr='0.5rem'
          w='100%'
          wrap='nowrap'
        >
          <Metric
            color={color}
            value={
              <Group>
                {contentElement}
                {hasLabel
                  ? UnitFormatMap['parsed__score'](valueAsNumber)
                  : null}
              </Group>
            }
            variant='subtle'
          />
          {scorer_type != null && <ScorerTypePill type={scorer_type} />}
        </Group>
      }
      {...popoverProps}
    >
      <Metric
        color={color}
        data-testid={dataTestId ?? 'score-metric'}
        value={contentElement}
        {...metricProps}
      />
    </MetricPopover>
  );
};

export default ScoreMetric;
