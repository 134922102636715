import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import api from '@/core/api';
import { components } from '@/core/types/api';

import {
  GET_CURRENT_USER,
  useCurrentUser
} from '../use-current-user/use-current-user';
import { USERS_KEY } from '../use-users/use-users';

export const useUpdateUser = () => {
  // TEMP! TODO: Refactor _app.tsx into more defined resposiblities, and lift this session call up so it's only called once
  const { data: session } = useSession();
  const userId = session?.userId;
  // END TEMP

  const queryClient = useQueryClient();

  const currentUser = useCurrentUser();

  const handleUpdateUser = async (
    updatedUserProps: components['schemas']['UserUpdate']
  ) => {
    const body = { ...(currentUser?.data || {}), ...updatedUserProps };

    return api.PUT(`/users/{user_id}`, {
      params: {
        path: {
          user_id: userId!
        }
      },
      body
    });
  };

  const mutation = useMutation(handleUpdateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CURRENT_USER]);
      queryClient.invalidateQueries([USERS_KEY]);
    }
  });

  return mutation;
};
