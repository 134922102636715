import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE } from '@/core/types/projects.types';
import { isFirefox } from '@/core/utils/is-firefox/is-firefox';

export const useObserveHotkeys = () => {
  // Hooks
  const { isProjectMenuOpen } = useGlobalStore((s) => s);
  const { setIsProjectMenuOpen } = useGlobalStore((s) => s.actions);
  const { observe } = useFeatureFlags();

  let config = [
    {
      id: 'observe-menu',
      title: 'Toggle Observe Menu',
      description:
        'Open or close the left hand drawer with all Observe projects',
      binding: isFirefox() ? 'alt+shift+m' : 'mod+shift+m',
      callback: () =>
        setIsProjectMenuOpen(
          isProjectMenuOpen === PROJECT_TYPE.OBSERVE
            ? false
            : PROJECT_TYPE.OBSERVE
        )
    }
  ];

  if (!observe.isEnabled) {
    config = config.filter((c) => c.id !== 'observe-menu');
  }

  return {
    hotkeys: config
  };
};
