import {
  Box,
  Button,
  Checkbox,
  Group,
  Radio,
  Rating,
  ScrollArea,
  Stack,
  TagsInput,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  useMantineTheme
} from '@mantine/core';
import { IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';
import React, { useEffect, useRef } from 'react';

import classes from './configure-ratings-modal.module.css';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { RadioCard } from '@/core/components/atoms/radio-card/radio-card';
import {
  FeedbackType,
  FeedbackTypeLabel
} from '@/core/types/human-feedback.types';

interface RatingsFormProps {
  configuration: FeedbackTemplate<any>;
  maxHeight: number;
  templateNames: string[];
  onChange: (template: FeedbackTemplate<any>) => void;
  onSave: () => void;
}

const DEFAULT_MIN_SCORE = 0;
const DEFAULT_MAX_SCORE = 10;

export const ConfigureRatingsTemplateForm = ({
  configuration,
  maxHeight,
  templateNames,
  onChange,
  onSave
}: RatingsFormProps) => {
  // Refs
  const nameInputRef = useRef<HTMLInputElement>(null);
  const scoreInputRef = useRef<HTMLInputElement>(null);

  // Hooks
  const { colors } = useMantineTheme();

  // Computed
  const isInProd = configuration.isInProduction();

  let nameError;
  if (!configuration.name) {
    nameError = 'Name is required';
  } else if (
    configuration.name &&
    templateNames.includes(configuration.name.toLowerCase()) &&
    !configuration.isInProduction()
  ) {
    nameError = 'Name must be unique';
  } else {
    nameError = null;
  }

  const categoryError =
    configuration.constraints.feedback_type === FeedbackType.Tags &&
    (configuration?.constraints?.tags?.length == null ||
      configuration?.constraints?.tags?.length === 0)
      ? 'At least one category is required'
      : null;

  const saveEnabled =
    nameError == null &&
    categoryError == null &&
    (!isInProd || configuration.isModified());

  // Handlers
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    configuration.name = e.target.value;

    onChange(configuration);
  };

  const handleRatingTypeChange = (value: string) => {
    // Do not allow changing the rating type in production
    if (isInProd) {
      return;
    }

    value = value as FeedbackType;

    configuration.constraints.feedback_type = value;

    if (value === FeedbackType.Score) {
      configuration.constraints.min = DEFAULT_MIN_SCORE;
      configuration.constraints.max =
        scoreInputRef.current?.valueAsNumber ?? DEFAULT_MAX_SCORE;
    }

    onChange(configuration);
  };

  const handleConstraintValueChange = (constraints: { [key: string]: any }) => {
    const keys = Object.keys(constraints);
    keys.forEach((key) => {
      configuration.constraints[key] = constraints[key];
    });

    onChange(configuration);
  };

  const handleCriteriaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value === '' || e.target.value == null) {
      configuration.criteria = undefined;
    } else {
      configuration.criteria = e.target.value;
    }

    onChange(configuration);
  };

  const handleInputRationaleToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    configuration.withExplanation = e.target.checked;

    onChange(configuration);
  };

  // Effects
  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current?.focus();
    }
  }, [nameInputRef.current, configuration]);

  return (
    <Box>
      <ScrollArea h={maxHeight}>
        <Stack gap={20} p='lg' pb={0}>
          <TextInput
            classNames={classes}
            error={nameError}
            label={
              <Text c='gray.8' fw={700} size='sm'>
                Name
              </Text>
            }
            mb='sm'
            ref={nameInputRef}
            value={configuration.name}
            onChange={handleNameChange}
          />
          <Radio.Group
            label={
              <>
                <Text c='gray.8' fw={700} size='sm'>
                  Rating Format
                </Text>
                <Text>
                  Once you save the format type, you will not be able to switch
                  it later.
                </Text>
              </>
            }
            mb='sm'
            styles={{
              label: { color: colors.gray[6], fontWeight: 700 }
            }}
            value={configuration.constraints.feedback_type}
            onChange={handleRatingTypeChange}
          >
            <Stack gap={8}>
              {[
                FeedbackType.LikeDislike,
                FeedbackType.Star,
                FeedbackType.Score,
                FeedbackType.Tags,
                FeedbackType.Text
              ].map((type) => {
                return (
                  <RadioCard
                    key={type}
                    radioProps={{
                      checked: configuration.constraints.feedback_type === type,
                      disabled: isInProd,
                      label: FeedbackTypeLabel[type],
                      value: type
                    }}
                    onRadioClick={handleRatingTypeChange}
                  >
                    {type === FeedbackType.Tags && (
                      <TagsInput
                        data={configuration.constraints.tags || []}
                        disabled={isInProd}
                        error={categoryError}
                        p={0}
                        placeholder='Enter a category'
                        radius={8}
                        size='xs'
                        value={configuration.constraints.tags || []}
                        onChange={(tags) =>
                          handleConstraintValueChange({ tags })
                        }
                      />
                    )}
                    {type === FeedbackType.Score && (
                      <Group>
                        <Text c='gray.6' size='sm'>
                          A number out of
                        </Text>
                        <TextInput
                          aria-label='Max score'
                          classNames={classes}
                          disabled={isInProd}
                          ref={scoreInputRef}
                          size='xs'
                          type='number'
                          value={
                            configuration.constraints.max ?? DEFAULT_MAX_SCORE
                          }
                          w={50}
                          width={50}
                          onChange={(e) =>
                            handleConstraintValueChange({
                              min: DEFAULT_MIN_SCORE,
                              max: e.target.valueAsNumber
                            })
                          }
                        />
                      </Group>
                    )}
                    {type === FeedbackType.Star && (
                      <Rating readOnly size='sm' />
                    )}
                    {type === FeedbackType.LikeDislike && (
                      <Group c='gray.6' gap={2}>
                        <ThemeIcon
                          bg='transparent'
                          color='gray.3'
                          size={22}
                          variant='light'
                        >
                          <IconThumbUpFilled />
                        </ThemeIcon>
                        <ThemeIcon
                          bg='transparent'
                          color='gray.3'
                          size={22}
                          variant='light'
                        >
                          <IconThumbDownFilled />
                        </ThemeIcon>
                      </Group>
                    )}
                  </RadioCard>
                );
              })}
            </Stack>
          </Radio.Group>
          <Box>
            <Textarea
              classNames={classes}
              label={
                <Text c='gray.8' fw={700}>
                  Rating Criteria{' '}
                  <Text component='span' fw={500}>
                    (optional)
                  </Text>
                </Text>
              }
              mb='sm'
              placeholder='Provide some guidelines for the annotator on how to perform the rating.'
              rows={4}
              value={configuration.criteria ?? ''}
              onChange={handleCriteriaChange}
            />
            <Checkbox
              checked={configuration.withExplanation}
              disabled={isInProd}
              label='Show the annotator a textbox for them to note their rationale.'
              size='xs'
              styles={{
                label: {
                  color: colors.gray[8],
                  paddingLeft: '8px',
                  fontSize: 14
                }
              }}
              onChange={handleInputRationaleToggle}
            />
          </Box>
        </Stack>
      </ScrollArea>
      <Group justify='flex-end' mt='lg'>
        <Button
          disabled={!saveEnabled}
          pt={0}
          px='sm'
          size='sm'
          onClick={onSave}
        >
          <Text fw='600' size='sm'>
            Save
          </Text>
        </Button>
      </Group>
    </Box>
  );
};
