import { useSessionStorage } from '@mantine/hooks';
import { useEffect } from 'react';

import { SANDBOX_DOMAIN } from '@/core/constants/domains.constants';
import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { FlagTags } from '@/core/types/feature-flags.types';
import { isDomain } from '@/core/utils/is-domain/is-domain';

// Flags for Dev Tools.
export const useDevToolFlags = () => {
  // Hooks
  const currentUser = useCurrentUser();

  // Toggle Galileo User flag
  const [isGalileoUserFlag, setIsGalileoUserFlag] = useSessionStorage({
    key: 'isGalileoUser',
    defaultValue: false
  });

  // Toggle Admin User flag
  const [isAdminFlag, setIsAdminFlag] = useSessionStorage({
    key: 'isAdmin',
    defaultValue: false
  });

  // Toggle Sandbox flag
  const [isSandboxFlag, setIsSandboxFlag] = useSessionStorage({
    key: 'isSandbox',
    defaultValue: isDomain(SANDBOX_DOMAIN)
  });

  useEffect(() => {
    if (currentUser.data) {
      setIsGalileoUserFlag(
        Boolean(currentUser?.data?.email?.match(/@rungalileo.io/))
      );
      setIsAdminFlag(Boolean(currentUser?.data?.is_admin));
    }
  }, [currentUser?.dataUpdatedAt]);

  return {
    isAdmin: {
      description: 'Toggle Admin User',
      isEnabled: isAdminFlag,
      tag: [FlagTags.tool],
      toggle: () => setIsAdminFlag(!isAdminFlag)
    },
    isGalileoUser: {
      description: 'Toggle Galileo User',
      isEnabled: isGalileoUserFlag,
      tag: [FlagTags.tool],
      toggle: () => setIsGalileoUserFlag(!isGalileoUserFlag)
    },
    isSandbox: {
      description: 'Toggle Sandbox',
      isEnabled: isSandboxFlag,
      tag: [FlagTags.tool],
      toggle: () => setIsSandboxFlag(!isSandboxFlag)
    }
  };
};
