import { useMutation } from '@tanstack/react-query';

import api from '@/core/api';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/strings.constants';
import { Error } from '@/core/types/response.types';
import { showNotification } from '@/core/utils/show-notification/show-notification';

export const useProjectShare = () => {
  const path = '/projects/{project_id}/add_users';

  const shareProject = async ({
    userIds,
    projectId
  }: {
    userIds: string[];
    projectId: string;
  }) => {
    const body = userIds?.map((userId: string) => ({ user_id: userId }));

    return api
      .POST(path, {
        params: {
          path: {
            project_id: projectId
          }
        },
        body
      })
      .catch((err: Error) => {
        showNotification({
          title: 'Error sharing project',
          message: err?.response?.data?.detail || GENERIC_ERROR_MESSAGE,
          type: 'error'
        });
      });
  };

  const mutation = useMutation(shareProject);

  return mutation;
};
