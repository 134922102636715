import { ActionIcon, Group, Text } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { ReactNode, useState } from 'react';

import SearchBar from '@/fine-tune/components/search-bar/search-bar';

interface ChartHeaderProps {
  copy: string;
  onSortClick: () => void;
  sortIcon: ReactNode;
  searchTerm?: string;
  onTermChange?: (str: string) => void;
}
/**
 * ChartHeader
 *
 *
 *
 * @returns {React.Component} ChartHeader
 */
const ChartHeader = ({
  copy,
  onSortClick,
  sortIcon,
  searchTerm,
  onTermChange
}: ChartHeaderProps) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const spanRef = useClickOutside<HTMLDivElement>(() => {
    const { value } = spanRef?.current?.querySelector('input') || {};
    if (!value) {
      setIsSearchVisible(false);
    }
  }, ['mouseup', 'touchend', 'focusout']);

  const allowSearch = Boolean(onTermChange);

  return (
    <Group
      justify='space-between'
      style={{
        position: 'relative'
      }}
    >
      <Text truncate maw='80%'>
        {copy}
      </Text>
      <Group
        gap={0}
        style={{
          position: 'absolute',
          right: 0
        }}
      >
        {allowSearch &&
          (isSearchVisible ? (
            <SearchBar
              aria-label={searchTerm}
              defaultValue={searchTerm}
              mr='xs'
              ref={spanRef as React.Ref<HTMLDivElement> | undefined}
              onEnter={(newValue) => onTermChange?.(newValue)}
            />
          ) : (
            <ActionIcon onClick={() => setIsSearchVisible(true)}>
              <IconSearch size={20} />
            </ActionIcon>
          ))}
        <ActionIcon onClick={onSortClick}>{sortIcon}</ActionIcon>
      </Group>
    </Group>
  );
};

export default ChartHeader;
