import { CodeHighlight } from '@mantine/code-highlight';
import { Box, Group, Select, Switch, Text, TextInput } from '@mantine/core';

import { useComputedParameters } from '@/core/stores/parameters-store';
import {
  getExportTypes,
  OdExportTypes
} from '@/fine-tune/constants/export-options.constants';
import { useCreateDqCodeSnippet } from '@/fine-tune/hooks/use-create-dq-code-snippet/use-create-dq-code-snippet';
import { ExportDestinations } from '@/fine-tune/types/export.types';

import DatabricksExportForm from './databricks-export-form';

interface ExportModalFormProps {
  form: any;
  destination: ExportDestinations;
  fromEditsCart: boolean;
}
const ExportModalForm = ({
  form,
  destination,
  fromEditsCart
}: ExportModalFormProps) => {
  const { isNer, isOd } = useComputedParameters();

  const snippet = useCreateDqCodeSnippet(form.values, fromEditsCart);

  const isExportToS3 = destination === 's3';
  const isExportDataframe = destination === 'dataframe';
  const isExportToDatabricks = destination === 'databricks';
  const isHfNer = isNer && form.values.isHuggingFaceFormat;
  const showNameField = !isExportDataframe && !isExportToDatabricks;

  return (
    <form>
      {isNer && (
        <Group
          justify='space-between'
          style={{
            height: 55
          }}
        >
          <Switch
            label='Export as Hugging Face format'
            size='sm'
            {...form.getInputProps('isHuggingFaceFormat', {
              type: 'checkbox'
            })}
          />
          {form.values.isHuggingFaceFormat && (
            <Select
              searchable
              comboboxProps={{ withinPortal: false }}
              data={['BIO', 'BIOES', 'BILOU']}
              label='Choose tagging schema'
              mt={6}
              required={form.values.isHuggingFaceFormat}
              size='sm'
              {...form.getInputProps('huggingFaceTaggingSchema')}
            />
          )}
        </Group>
      )}

      {isExportDataframe && (
        <CodeHighlight code={snippet} language='python' mt='xs' />
      )}
      {isExportToS3 && (
        <TextInput
          autoFocus
          required
          label='Bucket Name'
          {...form.getInputProps('bucketName')}
        />
      )}
      {isExportToDatabricks && <DatabricksExportForm form={form} />}
      {showNameField && (
        <>
          <Box className='align-center' mt='sm'>
            <TextInput
              required
              className='w-100'
              label={isExportToS3 ? 'Object Name' : 'File Name'}
              {...form.getInputProps('objectName')}
            />
            {isOd ? (
              <Select
                searchable
                className='w-20'
                comboboxProps={{ withinPortal: false }}
                data={OdExportTypes}
                label='Label Format'
                {...form.getInputProps('labelFormat')}
              />
            ) : (
              <Select
                searchable
                className='w-20'
                comboboxProps={{ withinPortal: false }}
                data={getExportTypes(isHfNer)}
                label='File Type'
                {...form.getInputProps('fileType')}
              />
            )}
          </Box>
          {!isOd && (
            <Text c='dimmed' mb='md' mt='xs' px='md' size='xs'>
              Exported as {`${form.values.objectName}.${form.values.fileType}`}
            </Text>
          )}
        </>
      )}
    </form>
  );
};

export default ExportModalForm;
