import check from 'check-types';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  useActiveFilters,
  useMetaFilters
} from '@/core/stores/parameters-store';
import { useActiveFilterDictionary } from '@/fine-tune/hooks/use-active-filters-dictionary/use-active-filters-dictionary';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import useStore from '@/fine-tune/stores/store';
import { MetaFilter } from '@/fine-tune/types/query.types';

const filterConverter = (
  filters?: MetaFilter[]
): [string, string | (string | number | boolean | null)[]][] => {
  if (!filters) {
    return [];
  }

  return filters.map(({ name, isin, greater_than, less_than, is_equal }) => {
    let parsedValue = isin || STRING_PLACEHOLDER;

    if (check.number(greater_than) && check.number(less_than)) {
      parsedValue = `${greater_than} - ${less_than}`;
    } else if (check.number(greater_than)) {
      parsedValue = `> ${greater_than}`;
    } else if (check.number(less_than)) {
      parsedValue = `< ${less_than}`;
    } else if (check.number(is_equal)) {
      parsedValue = `${is_equal}`;
    }

    return [name, parsedValue];
  });
};

export const useParsedFilters = () => {
  const similarToIds = useStore((state) => state.similarToIds);
  const shapeSelection = useEmbeddingsStore((state) => state.shapeSelection);

  const activeFilters = useActiveFilters();
  const metaFilters = useMetaFilters();
  const activeFiltersDictionary = useActiveFilterDictionary();

  const totalFilters = [...activeFilters, ...filterConverter(metaFilters)];

  if (similarToIds?.length) {
    totalFilters.push(['similarTo', similarToIds]);
  }

  if (shapeSelection) {
    totalFilters.push(['Chart selection', '']);
  }

  return totalFilters.map(([label, value]) => ({
    ...activeFiltersDictionary(label, value),
    label,
    value
  }));
};
