const nonAlphanumericString = /[^A-Za-z0-9]/;

export const parseRegexSearch = (search?: string) => {
  const specialCharacterRegex = new RegExp(nonAlphanumericString);

  let parsedSearch = '';
  let isSpanRegex = false;

  if (search) {
    if (specialCharacterRegex.test(search)) {
      parsedSearch = search;
    } else {
      parsedSearch = `\\b${search}\\b`;
      isSpanRegex = true;
    }
  }

  return { spanSearch: parsedSearch, isSpanRegex };
};

export const stripRegexSearch = (search?: string): string => {
  if (!search) {
    return '';
  }
  return search.replace(/\\B|\\b|\\/g, '');
};
