import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useMetaFilters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { components } from '@/core/types/api';
import { useDataframeColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import useStore from '@/fine-tune/stores/store';

import { useMetaColumns } from '../use-meta-columns/use-meta-columns';

export type InsightsGroupedBy = components['schemas']['GroupByMetrics'];

const POST_INSIGHTS_GROUPED_BY =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/groupby';

export const useInsightsGroupedBy = (baseline?: string) => {
  const _metaColumns = useMetaColumns();

  const shapeSelection = useEmbeddingsStore((state) => state.shapeSelection);
  const mlcTaskAndIds = useStore((state) => state.mlcTaskAndIds);
  const { runId, projectId } = usePathParameters();

  const {
    split,
    inferenceName,
    slice,
    task,
    groupedBy,
    dataframeColumns,
    isDrifted,
    comparedTo
  } = useParametersStore((s) => ({
    split: s.split,
    inferenceName: s.inferenceName,
    slice: s.slice,
    task: s.task,
    groupedBy: s.groupedBy,
    dataframeColumns: s.dataframeColumns,
    isDrifted: s.isDrifted,
    comparedTo: s.comparedTo
  }));
  const { isMltc, isNer, isInference, isOoc, isS2S } = useComputedParameters();

  const metaFilters = useMetaFilters();

  const { metaColumnAccessors } = useDataframeColumns();

  const filter_params = useFilterParams();

  let isEnabled = Boolean(
    projectId && runId && !_metaColumns.isLoading && groupedBy
  );

  if (isMltc) {
    isEnabled = Boolean(projectId && runId && task && !_metaColumns.isLoading);
  }

  if (isInference) {
    isEnabled = Boolean(isEnabled && inferenceName);
  }

  if (isS2S) {
    isEnabled = Boolean(isEnabled && !['gold', 'pred'].includes(groupedBy));
  }

  if (baseline) {
    const shouldRenderBaselineData =
      (isInference && comparedTo) || isDrifted || isOoc;
    // If we trigger a similar search to another slice it will fail

    isEnabled = Boolean(
      isEnabled && !filter_params.similar_to && shouldRenderBaselineData
    );
  }

  const fetchInsightsGroupedBy = async () => {
    const res = await api.POST(POST_INSIGHTS_GROUPED_BY, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: baseline ? 'training' : split
        },
        query: {
          inference_name: baseline ? undefined : inferenceName,
          groupby_col: groupedBy
        }
      },
      body: {
        filter_params,
        meta_cols: dataframeColumns?.filter((accessor: string) =>
          metaColumnAccessors.includes(accessor)
        )
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [
      POST_INSIGHTS_GROUPED_BY,
      {
        projectId,
        runId,
        split,
        inferenceName,
        slice,
        shapeSelection,
        metaFilters,
        task,
        mlcTaskAndIds,
        groupedBy,
        ...filter_params
      }
    ],
    fetchInsightsGroupedBy,
    {
      enabled: isEnabled,
      onError: (res: Response) => defaultErrorHandler(res, 'No data found.')
    }
  );

  if (isNer) {
    const responseData = response?.data;

    if (responseData) {
      // @ts-expect-error - We are overriding the response data
      responseData.total_errors = responseData?.missed_label?.map(
        (value: number, index: number) => [
          value,
          responseData.span_shift?.[index],
          responseData.wrong_tag?.[index],
          responseData.ghost_span?.[index]
        ]
      );
    }
  }

  return response;
};
