import {
  ActionIcon,
  Card,
  Container,
  Drawer,
  Space,
  Switch,
  Tabs,
  Text
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAB2, IconFlask2Filled, IconTools } from '@tabler/icons-react';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { FlagTags } from '@/core/types/feature-flags.types';

export const DevToolsDrawer = () => {
  // Local State
  const [open, handlers] = useDisclosure(false);

  // Hooks
  const allFlags = useFeatureFlags();
  const currentUser = useCurrentUser();

  // Computed
  let devFeatureFlags = [];
  let externalFeatureFlags = [];
  let devTools = [];

  for (let flagKey in allFlags) {
    const flag = allFlags[flagKey as keyof typeof allFlags];

    if (flag.tag.includes(FlagTags.feature)) {
      devFeatureFlags.push({
        name: flagKey,
        ...flag
      });
    }

    if (flag.tag.includes(FlagTags.external)) {
      externalFeatureFlags.push({
        name: flagKey,
        ...flag
      });
    }

    if (flag.tag.includes(FlagTags.tool)) {
      devTools.push({
        name: flagKey,
        ...flag
      });
    }
  }

  // Separate out this variable so we don't lose access to flags if we toggle `isGalileoUser`
  const _isGalileoUser = Boolean(
    currentUser?.data?.email?.match(/@rungalileo.io/)
  );

  if (!_isGalileoUser) {
    return null;
  }

  return (
    <>
      <ActionIcon
        c='brand'
        m='auto'
        opacity='.4'
        radius='xl'
        size='lg'
        variant='subtle'
        onClick={handlers.open}
      >
        <IconFlask2Filled />
      </ActionIcon>

      <Drawer
        bg='gray.0'
        opened={open}
        position='right'
        size='80vw'
        title='Galileo Dev Tools'
        zIndex={Z_INDEX.MODALS}
        onClose={handlers.close}
      >
        <Tabs defaultValue='dev' orientation='vertical' variant='outline'>
          <Tabs.List>
            <Tabs.Tab leftSection={<IconAB2 />} value='dev'>
              <Text fw={500}>Feature Flags</Text>
            </Tabs.Tab>
            <Tabs.Tab leftSection={<IconTools />} value='internal'>
              <Text fw={500}>Internal Features</Text>
            </Tabs.Tab>
            <Tabs.Tab leftSection={<IconFlask2Filled />} value='env'>
              <Text fw={500}>Dev Tools</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Container>
            <Tabs.Panel value='dev'>
              <PanelHeader
                subTitle='UI Feature toggles for Galileo users to test in development work'
                title='Feature Flags'
              />
              <Tabs defaultValue='dev' variant='outline'>
                <Tabs.List>
                  <Tabs.Tab value='dev'>A/B Flags</Tabs.Tab>
                  <Tabs.Tab value='external'>External Flags</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel my='sm' value='dev'>
                  {devFeatureFlags.map(
                    ({ description, isEnabled, name, toggle }) => (
                      <FeatureFlagCard
                        description={description}
                        enabled={isEnabled}
                        key={name}
                        label={name}
                        onToggle={toggle}
                      />
                    )
                  )}
                </Tabs.Panel>

                <Tabs.Panel my='sm' value='external'>
                  {externalFeatureFlags.map(
                    ({ description, isEnabled, name, toggle }) => (
                      <FeatureFlagCard
                        description={description}
                        enabled={isEnabled}
                        key={name}
                        label={name}
                        onToggle={toggle}
                      />
                    )
                  )}
                </Tabs.Panel>
              </Tabs>
            </Tabs.Panel>

            <Tabs.Panel value='internal'>
              <PanelHeader
                subTitle='UI Feature toggles for Galileo users in dev and sandbox'
                title='Feature Flags'
              />
            </Tabs.Panel>

            <Tabs.Panel value='env'>
              <PanelHeader
                subTitle='Dev Tools to simulate certain environments and conditions'
                title='Dev Tools'
              />
              {devTools.map(({ description, isEnabled, name, toggle }) => (
                <FeatureFlagCard
                  description={description}
                  enabled={isEnabled}
                  key={name}
                  label={name}
                  onToggle={toggle}
                />
              ))}
            </Tabs.Panel>
          </Container>
        </Tabs>
      </Drawer>
    </>
  );
};

const PanelHeader = ({
  title,
  subTitle
}: {
  title: string;
  subTitle: string;
}) => (
  <>
    <Text fw={600} size='md'>
      {title}
    </Text>
    <Text c='dimmed' size='sm'>
      {subTitle}
    </Text>
  </>
);

const FeatureFlagCard = ({
  label,
  description,
  enabled,
  onToggle
}: {
  label: string;
  description: string;
  enabled: boolean;
  onToggle: (bool: boolean) => void;
}) => {
  return (
    <Card withBorder mb='sm' shadow='md'>
      <Text fw={600} size='sm'>
        {label}
      </Text>
      <Text c='dimmed' size='xs'>
        {description}
      </Text>
      <Space my='sm' />
      <Switch
        checked={enabled}
        onChange={(e) => onToggle(e.currentTarget.checked)}
      />
    </Card>
  );
};
