import { components } from '@/core/types/api';

type ClassCommunity = components['schemas']['CommunityResponse'];

export const getTopCommunities = (communities?: ClassCommunity[]) => {
  if (!communities || !Array.isArray(communities)) {
    return {};
  }

  const overall = communities?.reduce((acc, community) => {
    const { num_samples } = community;
    if (!num_samples || typeof num_samples !== 'number') {
      return acc;
    }

    return acc + num_samples;
  }, 0);

  const rows =
    communities
      ?.sort((a, b) => {
        return a?.score > b?.score ? -1 : 1;
      })
      ?.map((row) => {
        return {
          percentage: row?.num_samples / overall,
          // @ts-expect-error - TODO: Fix types
          id: row?.id,
          labels: row?.labels,
          num_samples: row?.num_samples,
          score: row?.score?.toFixed(2)
        };
      }) || [];

  return {
    rows,
    overall
  };
};
