import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';

const POST_INSIGHTS_ERRORS_DISTRIBUTION =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/errors/{error_name}/distribution';

export const useInsightsErrorsDistribution = () => {
  // Parameter Store
  const { projectId, runId } = usePathParameters();
  const split = useParametersStore((s) => s.split);

  const { isOd, isSS } = useComputedParameters();

  // Computer Vision Store
  const { errorDistributionError, errorDistributionCol } =
    useComputerVisionStore((s) => ({
      errorDistributionError: s.errorDistributionError,
      errorDistributionCol: s.errorDistributionCol
    }));

  // Filter Params
  const filter_params = useFilterParams();

  // Computed
  let enabled = Boolean(
    projectId &&
      runId &&
      (isOd || isSS) &&
      errorDistributionCol &&
      errorDistributionError
  );

  // Request Body
  const body = {
    filter_params
  };

  const fetchErrorsDistribution = async () => {
    const res = await api.POST(POST_INSIGHTS_ERRORS_DISTRIBUTION, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split,
          error_name: errorDistributionError as string
        },
        query: { column_name: errorDistributionCol as string }
      },
      body
    });

    return res?.data;
  };

  return useQuery(
    [
      POST_INSIGHTS_ERRORS_DISTRIBUTION,
      {
        projectId,
        runId,
        split,
        errorDistributionCol,
        errorDistributionError,
        ...filter_params
      }
    ],
    fetchErrorsDistribution,
    { enabled }
  );
};
