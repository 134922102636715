import { Select, Text } from '@mantine/core';

import classes from './metric-select.module.css';

export interface MetricSelectProps {
  data: { label: string; value: string }[];
  value: string;
  onChange: (val: string) => void;
  displayValue: string;
}

const MetricSelect = ({
  data,
  value,
  onChange,
  displayValue
}: MetricSelectProps) => {
  return (
    <>
      <Select
        searchable
        aria-label='Metric'
        className={classes.responsiveSelect}
        data={data}
        data-testid='metric-select'
        nothingFoundMessage='No options found.'
        style={{
          flex: 1
        }}
        styles={{
          section: {
            width: 'unset'
          }
        }}
        value={value}
        variant='unstyled'
        onChange={(val) => onChange(val as string)}
      />
      <Text
        className={`white-space-nowrap ${classes.metricValue}`}
        data-testid='metric-value'
        fw={700}
        size='xl'
      >
        {displayValue}
      </Text>
    </>
  );
};

export default MetricSelect;
