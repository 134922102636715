import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { useIntegration } from '@/core/hooks/query-hooks/use-integration/use-integration';

const LABELSTUDIO_WORKSPACES = '/integrations/labelstudio/workspaces';

export const useLabelstudioWorkspaces = () => {
  const { data } = useIntegration('labelstudio');

  const fetchWorkspaces = async () => {
    const res = await api.GET(LABELSTUDIO_WORKSPACES);

    return res.data;
  };

  return useQuery([LABELSTUDIO_WORKSPACES], fetchWorkspaces, {
    enabled: Boolean(data?.name)
  });
};
