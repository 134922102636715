import { Anchor, Box, Button, Group, MultiSelect, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useEffect, useRef, useState } from 'react';

import { Paths } from '@/core/constants/routes.constants';
import { useProjectShare } from '@/core/hooks/query-hooks/use-project-share/use-project-share';
import { useUsers } from '@/core/hooks/query-hooks/use-users/use-users';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { showNotification } from '@/core/utils/show-notification/show-notification';

const ShareProjectModal = ({
  id,
  innerProps: { projectId }
}: ContextModalProps<{ projectId: string }>) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { closeModal } = useModals();

  const users = useUsers();

  const projectShare = useProjectShare();

  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      emailInputRef?.current?.focus();
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  const handleShare = () => {
    projectShare.mutate(
      { userIds: selectedUsers, projectId },
      {
        onSuccess: () => {
          closeModal(id);
          showNotification({
            title: 'Success',
            message: `Project successfully shared!`
          });
        }
      }
    );
  };

  const usersData = (users?.data as any)?.map(
    (user: { email: string; id: string }) => ({
      label: user.email,
      value: user.id
    })
  );

  return (
    <Box>
      <Text mb='sm' size='xs'>
        Share this project with your team members. If you cannot find them in
        the dropdown, it means they’re not added to Galileo. You can first
        invite them{' '}
        <Anchor c='brand.6' href={Paths.COMMAND_CENTER} target='_blank'>
          here
        </Anchor>{' '}
      </Text>
      <MultiSelect
        searchable
        comboboxProps={{ zIndex: 10000 }}
        data={usersData || []}
        label='Team members'
        placeholder='Users emails'
        ref={emailInputRef}
        value={selectedUsers}
        onChange={setSelectedUsers}
      />

      <Group justify='flex-end' mt='md'>
        <Button variant='light' onClick={() => closeModal(id)}>
          Cancel
        </Button>
        <Button
          data-testid='share-project-btn'
          disabled={!selectedUsers.length}
          loading={users?.isLoading}
          onClick={handleShare}
        >
          Add
        </Button>
      </Group>
    </Box>
  );
};

export default ShareProjectModal;
