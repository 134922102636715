import { Box, Popover, Text, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import check from 'check-types';
import cx from 'classix';
import { MouseEvent } from 'react';

import { useComputedParameters } from '@/core/stores/parameters-store';

interface LabelledWordProps {
  color?: string;
  spanId: number;
  isActive?: boolean;
  isBordered?: boolean;
  isFilled?: boolean;
  isCenteredSpan?: boolean;
  isSelected?: boolean;
  isTruncated?: boolean;
  label?: string | number | null;
  oldLabel?: string | null;
  onClick?: (id: number) => void;
  size?: string;
  word?: string | React.ReactNode;
  minTruncatedWidth?: number;
  popoverDropdown?: React.ReactNode;
}

const LabelledWord = ({
  word = '',
  label = '',
  oldLabel,
  spanId,
  color = '',
  onClick,
  isFilled = false,
  isSelected = false,
  isCenteredSpan = false,
  isBordered = false,
  isActive = true,
  isTruncated = false,
  minTruncatedWidth = 350,
  popoverDropdown
}: LabelledWordProps) => {
  const { colors } = useMantineTheme();

  const { hovered, ref } = useHover();

  const isInferenceNer = useComputedParameters('isInferenceNer');

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (isActive) {
      onClick?.(spanId);
    }
  };

  const cursorStyle = () => {
    if (check.not.function(onClick) || !isActive) {
      return 'default';
    }

    return 'pointer';
  };

  let labelColor = color;
  let borderColor = color;
  let background = 'inherit';
  let wordColor = 'inherit';

  if (isFilled && !isInferenceNer) {
    const redShade = isCenteredSpan ? colors.red[3] : colors.red[1];
    const border = isBordered ? color : redShade;

    wordColor = 'inherit';
    labelColor = colors.red[6];
    background = redShade;
    borderColor = border;
  }

  if (isSelected) {
    labelColor = colors.brand[0];
    wordColor = colors.brand[0];
    background = colors.brand[5];
    borderColor = colors.brand[5];
  }

  if (!isActive) {
    labelColor = colors.gray[6];
    wordColor = colors.gray[6];
    background = colors.gray[1];
    borderColor = colors.gray[1];
  }

  return (
    <Popover
      withArrow
      withinPortal
      opened={Boolean(popoverDropdown && hovered)}
      position='top'
    >
      <Popover.Target>
        <Box
          className='inline-flex flex-column'
          data-testid='labelled-word'
          my={1}
          p={2}
          ref={ref}
          style={() => ({
            border: `1.5px solid ${borderColor}`,
            background,
            borderRadius: 4,
            cursor: cursorStyle()
          })}
          onClick={handleClick}
        >
          <Text
            className={cx(isTruncated && 'truncate')}
            color={wordColor}
            style={{
              maxWidth: isTruncated ? minTruncatedWidth : '100%'
            }}
          >
            {word}
          </Text>
          <Text
            c={labelColor}
            fw={600}
            mt={-5}
            size='xs'
            style={() => ({
              fontSize: 10,
              lineHeight: '10px',
              minHeight: 10
            })}
            ta='center'
            tt='lowercase'
          >
            {oldLabel && (
              <Text
                c='red'
                component='span'
                mr={4}
                style={{
                  fontSize: 10,
                  lineHeight: '10px',
                  minHeight: 10
                }}
                td='line-through'
              >
                {oldLabel}
              </Text>
            )}
            {label}
          </Text>
        </Box>
      </Popover.Target>
      <Popover.Dropdown>{popoverDropdown}</Popover.Dropdown>
    </Popover>
  );
};

export default LabelledWord;
