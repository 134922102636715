import { Flex, Group } from '@mantine/core';

import NavBar from '@/core/components/organisms/nav-bar/nav-bar';

interface ObserveLayoutProps {
  children: React.ReactNode;
}

const ObserveLayout = ({ children }: ObserveLayoutProps) => {
  return (
    <Group data-testid='observe-layout' gap={0} wrap='nowrap'>
      <Flex h='100vh' w={65}>
        <NavBar />
      </Flex>
      <Flex direction='column' h='100vh' w='100vw'>
        {children}
      </Flex>
    </Group>
  );
};

export default ObserveLayout;
