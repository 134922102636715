import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE } from '@/core/types/projects.types';
import { isFirefox } from '@/core/utils/is-firefox/is-firefox';

export const useEvaluateHotkeys = () => {
  // Hooks
  const { isProjectMenuOpen } = useGlobalStore((s) => s);
  const { setIsProjectMenuOpen } = useGlobalStore((s) => s.actions);
  const { prompt } = useFeatureFlags();

  let config = [
    {
      id: 'evaluate-menu',
      title: 'Toggle Evaluate Menu',
      description:
        'Open or close the left hand drawer with all Evaluate projects',
      binding: isFirefox() ? 'alt+shift+e' : 'mod+shift+e',
      callback: () =>
        setIsProjectMenuOpen(
          isProjectMenuOpen === PROJECT_TYPE.PROMPT_EVALUATION
            ? false
            : PROJECT_TYPE.PROMPT_EVALUATION
        )
    }
  ];

  if (!prompt.isEnabled) {
    config = config.filter((c) => c.id !== 'evaluate-menu');
  }

  return {
    hotkeys: config
  };
};
