import { Group, Text } from '@mantine/core';
import { useModals as useMantineModals } from '@mantine/modals';
import { OpenConfirmModal } from '@mantine/modals/lib/context';
import { IconUsers } from '@tabler/icons-react';
import React from 'react';

import { ModalInnerProps, ModalProps } from './use-modals.types';

export enum Modals {
  AWS_INTEGRATIONS = 'aws-integrations-modal',
  CREATE_SLICE = 'create-slice-modal',
  CREATE_API_KEY = 'create-api-key-modal',
  CV = 'cv-modal',
  DATA_EDITS_CART = 'data-edits-cart-modal',
  EDITS_CART = 'edits-cart-modal',
  EDIT_SLICE = 'edit-slice-modal',
  EXPORTS = 'export-modal',
  GET_STARTED = 'get-started-modal',
  GUIDE_VIDEO = 'guide-video-modal',
  INTEGRATIONS = 'integrations-modal',
  INVITE_USER = 'invite-user-modal',
  KEYBOARD_SHORTCUTS = 'keyboard-shortcuts-modal',
  OBSERVE_EXPORT = 'observe-export-modal',
  OBJECT_DETECTION = 'od-modal',
  PROMPT_EXPORT = 'prompt-export-modal',
  OBSERVE_HELP = 'observe-help-modal',
  SEMANTIC_SEGMENTATION = 'ss-modal',
  RELABEL = 'relabel-modal',
  REMOVE_OR_SELECT = 'remove-or-select-modal',
  REPLACE_WITH_GOLD = 'replace-with-gold-modal',
  REPLACE_WITH_PREDICTED = 'replace-with-predicted-modal',
  REQUEST = 'request-modal',
  RESET_PASSWORD = 'reset-password-modal',
  SEND_TO_LABELER = 'send-to-labeler',
  SET_LABEL = 'set-label-modal',
  SHARE_PROJECT = 'share-project-modal',
  S2S_ROW_DETAIL = 's2s-row-detail-modal',
  TEMPLATE_CODE = 'template-code-modal',
  TRANSFER_USER_DATA = 'transfer-user-data-modal',
  UPGRADE = 'upgrade-modal',
  WELCOME = 'welcome-modal'
}

const WIDE_MODAL = 880;
const EXTRA_WIDE_MODAL = 1200;

// Modal Props - title, size, classNames
const MODAL_PROPS: ModalProps = {
  [Modals.AWS_INTEGRATIONS]: {
    className: 'bordered-header no-padding-modal large-height-modal',
    title: 'Integration Settings',
    size: 512
  },
  [Modals.CREATE_SLICE]: {
    title: 'Update Filters',
    size: 512
  },
  [Modals.OBSERVE_EXPORT]: {
    className: 'bordered-header no-padding-modal',
    title: 'Export Data',
    size: 540
  },
  [Modals.CV]: {
    className: 'cv-modal no-padding-modal',
    title: 'Sample'
  },
  [Modals.DATA_EDITS_CART]: {
    title: 'Edit Data',
    className: 'no-padding-modal bordered-header',
    size: EXTRA_WIDE_MODAL
  },
  [Modals.CREATE_API_KEY]: {
    title: 'Create new API Key',
    className: 'no-padding-modal bordered-header',
    size: 512
  },
  [Modals.EDITS_CART]: {
    className: 'no-padding-modal bordered-header no-header-modal',
    size: EXTRA_WIDE_MODAL
  },
  [Modals.REMOVE_OR_SELECT]: {
    className: 'no-header-modal'
  },
  [Modals.EXPORTS]: {
    title: 'Export',
    className: 'no-padding-modal bordered-header',
    size: 'lg'
  },
  [Modals.PROMPT_EXPORT]: {
    title: 'Choose columns to export',
    className: 'no-padding-modal bordered-header',
    size: 'lg'
  },
  [Modals.INVITE_USER]: { title: 'Invite new Users' },
  [Modals.KEYBOARD_SHORTCUTS]: {
    title: 'Keyboard Shortcuts',
    size: WIDE_MODAL
  },
  [Modals.OBSERVE_HELP]: {
    title: 'How to get started',
    size: '70vw'
  },
  [Modals.S2S_ROW_DETAIL]: {
    title: 'Row Detail',
    size: '70vw'
  },
  [Modals.RELABEL]: {
    title: 'Change Ground Truth'
  },
  [Modals.REPLACE_WITH_PREDICTED]: { title: 'Overwrite Ground Truth' },
  [Modals.REQUEST]: {
    title: 'Bug Report or Feature Request'
  },
  [Modals.RESET_PASSWORD]: { title: 'Reset User Password' },

  [Modals.SHARE_PROJECT]: {
    size: 512,
    // @ts-expect-error
    title: (
      <Group>
        <IconUsers color='#6C6C6C' size={14} />
        <Text c='gray.6' fw={600} size='sm'>
          Share Project
        </Text>
      </Group>
    )
  },

  [Modals.UPGRADE]: { title: 'Upgrade Account' },
  [Modals.WELCOME]: { title: '', className: 'no-header-modal' },
  [Modals.GET_STARTED]: {
    className: 'no-padding-modal no-header-modal',
    size: WIDE_MODAL
  },
  [Modals.SEND_TO_LABELER]: {
    title: 'Send To Labeler'
  },
  [Modals.SET_LABEL]: {
    title: 'Set Assigned Label'
  },
  [Modals.GUIDE_VIDEO]: {
    size: 'xl'
  },
  [Modals.INTEGRATIONS]: {
    title: 'Integration Settings',
    size: 'lg'
  },
  [Modals.TRANSFER_USER_DATA]: {
    size: 'lg'
  },
  [Modals.OBJECT_DETECTION]: {
    title: 'Object Detection',
    className: 'od-modal'
  },
  [Modals.SEMANTIC_SEGMENTATION]: {
    title: 'Semantic segmentation',
    className: 'ss-modal'
  },
  [Modals.TEMPLATE_CODE]: {
    title: 'Template code',
    className: 'template-code-modal with-zindex'
  }
};

/**
 *
 *
 */
export const useModals = () => {
  const mantineModals = useMantineModals();

  const closeModal = (id: string) => {
    // This is to prevent odd error where id is defined but mantineModals.closeModal(id) was throwing error on id
    try {
      mantineModals.closeModal(id);
    } catch {
      mantineModals.closeAll();
    }
  };

  const openModal = (
    id: Modals,
    innerProps: ModalInnerProps = {},
    onClose?: (id: Modals) => void,
    title?: string | React.ReactNode
  ) => {
    mantineModals.openContextModal(id, {
      title: title ?? MODAL_PROPS[id]?.title ?? '',
      size: MODAL_PROPS[id]?.size ?? 'md',
      className: MODAL_PROPS[id]?.className ?? '',
      onClose: () => (onClose ? onClose(id) : closeModal(id)),
      closeOnClickOutside: true,
      innerProps
    });
  };

  const openConfirmModal = (props: OpenConfirmModal) => {
    return mantineModals.openConfirmModal({ centered: true, ...props });
  };

  return {
    openModal,
    closeModal,
    closeAll: mantineModals.closeAll,
    openConfirmModal
  };
};
