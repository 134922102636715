import { Badge, Group, ScrollArea, Skeleton, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import SplitCard from '@/core/components/atoms/split-card/split-card';
import {
  useComputedParameters,
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { PartialParameters } from '@/core/stores/parameters-store/parameters.store.types';
import {
  parseRegexSearch,
  stripRegexSearch
} from '@/core/utils/parse-regex-search/parse-regex-search';
import { useTopInsights } from '@/fine-tune/hooks/query-hooks/use-top-insights/use-top-insights';

const MostFrequentHighDEPWords = () => {
  const spanSearch = useParametersStore((s) => s.spanSearch);
  const searchTerm = useParametersStore((s) => s.searchTerm);
  const { isInferenceNer, isS2S } = useComputedParameters();
  const { setParameters } = useParametersStoreActions();
  const { data, isLoading } = useTopInsights();

  const topErroneousWords = Object.entries(data?.top_erroneous_words || {});
  const sortedByCount = [...topErroneousWords].sort((a, b) => b[1] - a[1]);

  const spanWithoutSpecialChars = stripRegexSearch(spanSearch);

  const [selected, setSelected] = useState(
    isS2S ? searchTerm : spanWithoutSpecialChars
  );

  const handleFilterByWord = (word: string) => {
    if (isS2S) {
      setParameters({ searchTerm: word });
    } else {
      const parsedSpanParameters: PartialParameters = parseRegexSearch(word);

      setParameters(parsedSpanParameters);
    }
  };

  const headerCopy = isInferenceNer
    ? 'Most Frequent Low Confidence Words'
    : 'Most Frequent High DEP Words';

  useEffect(() => {
    setSelected(spanWithoutSpecialChars);
  }, [spanSearch, spanWithoutSpecialChars]);

  return (
    <SplitCard
      header={headerCopy}
      mb='xs'
      testId='most-frequent-high-dep-words'
    >
      <ScrollArea.Autosize mah={180} mt='xs'>
        {isLoading ? (
          <>
            <Skeleton data-testid='skeleton' height={20} mb='xs' />
            <Skeleton data-testid='skeleton' height={20} my='xs' />
            <Skeleton data-testid='skeleton' height={20} my='xs' />
            <Skeleton data-testid='skeleton' height={20} my='xs' />
            <Skeleton data-testid='skeleton' height={20} my='xs' />
            <Skeleton data-testid='skeleton' height={20} mt='xs' />
          </>
        ) : (
          <Group gap='xs'>
            {!topErroneousWords?.length ? (
              <Text c='#9B98AE'>
                <IconInfoCircle
                  size={16}
                  style={{
                    marginBottom: -2,
                    marginRight: 4
                  }}
                />
                <Text component='span' size='sm'>
                  There are no high DEP words for your given datasets and
                  filters
                </Text>
              </Text>
            ) : (
              sortedByCount?.map(([span, amount]) => {
                const isActive = span === selected;

                return (
                  <Badge
                    color={isActive ? 'brand' : 'gray'}
                    key={span}
                    radius='md'
                    rightSection={amount}
                    style={{
                      backgroundColor: isActive
                        ? ''
                        : 'var(--mantine-color-gray-1)',
                      color: isActive
                        ? 'var(--mantine-color-gray-1)'
                        : 'var(--mantine-color-contrast-4)',
                      cursor: 'pointer',

                      textTransform: 'none'
                    }}
                    styles={{
                      section: {
                        color: isActive
                          ? 'var(--mantine-color-gray-2)'
                          : 'var(--mantine-color-violet-5)'
                      }
                    }}
                    variant={isActive ? 'filled' : 'light'}
                    onClick={() => handleFilterByWord(span)}
                  >
                    {span}
                  </Badge>
                );
              })
            )}
          </Group>
        )}
      </ScrollArea.Autosize>
    </SplitCard>
  );
};

export default MostFrequentHighDEPWords;
