import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';

import { useCurrentUser } from '../use-current-user/use-current-user';

export const GET_COLLABORATORS = '/users/{user_id}/project_creators';

export const useCollaborators = () => {
  const { data: user } = useCurrentUser();

  const userId = user?.id;

  const fetchCollaborators = async () => {
    const res = await api.GET(GET_COLLABORATORS, {
      params: {
        path: {
          user_id: userId || ''
        }
      }
    });

    return res.data;
  };

  const response = useQuery(
    [GET_COLLABORATORS, { userId }],
    fetchCollaborators,
    {
      enabled: Boolean(userId)
    }
  );

  return response;
};
