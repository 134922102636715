import { Box, FloatingPosition, MantineSize, Menu, Text } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import {
  IconKey,
  IconLogout,
  IconNotes,
  IconPuzzle,
  IconRocket,
  IconUserSearch
} from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useState } from 'react';

import Avatar from '@/core/components/atoms/avatar/avatar';
import { Paths } from '@/core/constants/routes.constants';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useUpdateUser } from '@/core/hooks/query-hooks/use-update-user/use-update-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';

import UIVersion from '../ui-version/ui-version';

interface Options {
  id: string;
  isLabel?: boolean;
  value?: string | React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  withDivider?: boolean;
  color?: string;
  isDisabled?: boolean;
  hidden?: boolean;
  href?: string;
  target?: '_blank';
  rightSection?: React.ReactNode;
}

interface MenuAvatarProps {
  isLoading?: boolean;
  options?: Options[];
  mr?: MantineSize;
  position?: FloatingPosition;
}

/**
 * MenuAvatar
 *
 * Avatar as trigger for menu
 *
 */
const MenuAvatar = ({
  isLoading = false,
  mr,
  position = 'bottom-end'
}: MenuAvatarProps) => {
  // Router
  const router = useRouter();

  // Feature Flags
  const { isGalileoUser } = useFeatureFlags();

  // Local State
  const [dropdown, setDropdown] = useState<HTMLElement | null>(null);
  const [control, setControl] = useState<HTMLElement | null>(null);
  const [opened, handlers] = useDisclosure(false);

  // Handlers
  useClickOutside(() => handlers.toggle(), null, [
    control as HTMLElement,
    dropdown as HTMLElement
  ]);

  // Hooks
  const user = useCurrentUser();
  const updateUser = useUpdateUser();

  // Computed
  const username = user?.data?.name || user?.data?.email || STRING_PLACEHOLDER;

  const defaultOptions: Options[] = [
    {
      id: 'logged-in-as',
      icon: <Avatar name={username} />,
      isLabel: true,
      value: (
        <>
          <Text c='gray.6' size='sm'>
            Signed in as
          </Text>
          <Text c='gray.8' fw={600} size='sm'>
            {user?.isLoading ? STRING_PLACEHOLDER : username}
          </Text>
        </>
      )
    },
    {
      id: 'ui-version',
      isLabel: true,
      value: <UIVersion />
    },
    {
      id: 'docs',
      value: 'Documentation & Tutorials',
      href: Paths.EXTERNAL_GALILEO_DOCS,
      target: '_blank',
      icon: <IconNotes color='#9B98AE' size={20} />
    },
    {
      id: 'command',
      value: 'Command Center',
      href: Paths.COMMAND_CENTER,
      icon: <IconUserSearch color='#9B98AE' size={20} />
    },
    {
      id: 'integrations',
      value: 'Integrations',
      href: Paths.INTEGRATIONS,
      icon: <IconPuzzle color='#9B98AE' size={20} />
    },
    {
      id: 'api_keys',
      value: 'API Keys',
      href: Paths.API_KEYS,
      icon: <IconKey color='#9B98AE' size={20} />
    },
    {
      id: 'galileo-only',
      value: 'Internal Testing Tools',
      isLabel: true,
      hidden: !isGalileoUser.isEnabled
    },
    {
      id: 'welcome-flow-test',
      icon: <IconRocket size={16} />,
      value: 'Test Welcome Modal Flow',
      onClick: () => {
        updateUser.mutate(
          { show_welcome_modal: true },
          {
            onSuccess: () => {
              router.push({ pathname: Paths.ROOT });
            }
          }
        );
      },
      hidden: !isGalileoUser.isEnabled
    },
    {
      id: 'sign-out',
      value: 'Sign Out',
      color: 'red.5',
      icon: <IconLogout size={20} />,
      onClick: () => {
        signOut({
          callbackUrl: Paths.SIGN_IN
        });
      }
    }
  ];

  return (
    <Menu
      withArrow
      withinPortal
      arrowOffset={12}
      opened={opened}
      position={position}
      shadow='lg'
      width={280}
      zIndex={9999}
    >
      <Menu.Target>
        <Avatar
          isLoading={username ? isLoading : user?.isLoading}
          name={username || STRING_PLACEHOLDER}
          ref={setControl}
          onClick={() => handlers.toggle()}
        />
      </Menu.Target>

      <Menu.Dropdown mr={mr}>
        <Box ref={setDropdown}>
          {defaultOptions?.map(
            ({
              id,
              isLabel,
              value,
              icon,
              onClick,
              withDivider,
              color,
              hidden,
              href,
              target,
              rightSection
            }) => {
              if (hidden) {
                return null;
              }

              return (
                <Box key={id}>
                  {isLabel ? (
                    <>
                      <Menu.Label p='xs'>{value}</Menu.Label>
                      <Menu.Divider />
                    </>
                  ) : (
                    <>
                      <Box>
                        <Menu.Item
                          color={color}
                          component={href ? 'a' : 'button'}
                          href={href}
                          id={id}
                          leftSection={icon}
                          px={10}
                          rightSection={rightSection}
                          target={target}
                          onClick={() => onClick?.()}
                        >
                          {value}
                        </Menu.Item>
                      </Box>
                      {withDivider && <Menu.Divider />}
                    </>
                  )}
                </Box>
              );
            }
          )}
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
};

export default MenuAvatar;
