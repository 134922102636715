import axios from 'axios';
import type { NextApiRequest, NextApiResponse } from 'next';
import NextAuth, { Account, User } from 'next-auth';
import { JWT } from 'next-auth/jwt';
import AzureADProvider from 'next-auth/providers/azure-ad';
import CredentialsProvider from 'next-auth/providers/credentials';
import GithubProvider from 'next-auth/providers/github';
import GoogleProvider from 'next-auth/providers/google';
import OktaProvider from 'next-auth/providers/okta';

import {
  CURRENT_USER_PATH,
  LOGIN_PATH,
  SOCIAL_LOGIN_PATH
} from '@/core/constants/api.constants';
import { Paths } from '@/core/constants/routes.constants';

export const API_URL =
  process.env.NEXT_PRIVATE_API_URL || process.env.NEXT_PUBLIC_API_URL;

interface JwtArgs {
  token: JWT;
  user?: User;
  account?: Account | null;
}

const fetchLogin = async ({
  username = '',
  password = '',
  access_token = ''
}: {
  username?: string;
  password?: string;
  access_token: string;
}) => {
  let user;
  if (access_token) {
    user = { access_token: access_token };
  } else {
    const data = new URLSearchParams({ username, password });

    const res = await axios.post(`${API_URL}${LOGIN_PATH}`, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    user = res?.data;
  }

  if (user.access_token) {
    const res = await axios.get(`${API_URL}${CURRENT_USER_PATH}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    });

    return {
      status: 'success',
      data: {
        ...user,
        uid: res?.data?.id,
        isAdmin: res?.data?.is_admin,
        username: res?.data?.username
      }
    };
  }
};

const fetchSocialSignIn = async ({ id_token, provider }: Account) => {
  const socialLoginPath = `${API_URL}${SOCIAL_LOGIN_PATH}`;
  const body = {
    id_token,
    provider
  };
  let socialLogin = await axios.post(socialLoginPath, body, {
    validateStatus: () => true
  });

  if (socialLogin?.status === 200) {
    return buildSocialSessionData(socialLogin?.data);
  }
};

const buildSocialSessionData = async (user: { access_token: string }) => {
  if (user.access_token) {
    const res = await axios.get(`${API_URL}${CURRENT_USER_PATH}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    });

    return {
      status: 'success',
      data: {
        ...user,
        uid: res?.data?.id,
        isAdmin: res?.data?.is_admin,
        username: res?.data?.email
      }
    };
  }
};

const TWO_DAYS = 2 * 60 * 60 * 30;
export default async function auth(req: NextApiRequest, res: NextApiResponse) {
  // eslint-disable-next-line no-return-await
  return await NextAuth(req, res, {
    secret: process.env.SECRET,
    session: {
      strategy: 'jwt',
      maxAge: TWO_DAYS
    },
    pages: {
      signIn: Paths.SIGN_IN,
      signOut: Paths.SIGN_IN
    },
    providers: [
      GithubProvider({
        clientId: process.env.GALILEO_GITHUB_CLIENT_ID ?? '',
        clientSecret: process.env.GALILEO_GITHUB_SECRET ?? ''
      }),
      GoogleProvider({
        clientId: process.env.GALILEO_GOOGLE_CLIENT_ID ?? '',
        clientSecret: process.env.GALILEO_GOOGLE_SECRET ?? '',
        authorization: {
          params: {
            prompt: 'consent',
            access_type: 'offline',
            response_type: 'code'
          }
        }
      }),
      OktaProvider({
        clientId: process.env.GALILEO_OKTA_CLIENT_ID!,
        clientSecret: process.env.GALILEO_OKTA_SECRET!,
        issuer: process.env.GALILEO_OKTA_ISSUER!
      }),
      AzureADProvider({
        clientId: process.env.GALILEO_AZURE_AD_CLIENT_ID!,
        clientSecret: process.env.GALILEO_AZURE_AD_SECRET!,
        tenantId: process.env.GALILEO_AZURE_AD_TENANT_ID!
      }),
      CredentialsProvider({
        name: 'Credentials',
        credentials: {
          username: { label: 'Username', type: 'text' },
          password: { label: 'Password', type: 'password' }
        },
        // @ts-expect-error FIXME:
        authorize: async (credentials, req) => {
          const token: string = req?.query?.access_token || '';
          const login = await fetchLogin({
            ...credentials,
            access_token: token
          });
          return login;
        }
      })
    ],

    callbacks: {
      async jwt({ token, user, account }: JwtArgs) {
        if (user?.data) {
          token.accessToken = user.data.access_token;
          token.userId = user.data.uid;
          token.username = user.data.username;
          token.isAdmin = user.data.isAdmin;
        } else if (account?.id_token) {
          // Social login
          const { data } = (await fetchSocialSignIn(account)) ?? {};
          token.accessToken = data?.access_token;
          token.userId = data?.uid;
          token.username = data?.username;
        }

        return token;
      },

      async signIn({ account }) {
        if (account && account?.provider !== 'credentials') {
          const socialLoginPath = `${API_URL}${SOCIAL_LOGIN_PATH}`;
          const body = {
            id_token: account.id_token,
            provider: account.provider
          };

          let socialLogin = await axios.post(socialLoginPath, body, {
            validateStatus: () => true
          });

          if (socialLogin?.status === 401) {
            try {
              const newSocialUser = await axios.post(
                `${API_URL}/users/social`,
                body
              );

              if (newSocialUser?.status !== 200) {
                return `/sign-up?errorDetail=${newSocialUser.data?.detail}`;
              }
            } catch (error) {
              const errorResponse =
                error?.response?.data?.detail || 'unknown error';
              const errorDetail =
                typeof errorResponse === 'string'
                  ? errorResponse
                  : JSON.stringify(errorResponse);

              return `/sign-up?errorDetail=${errorDetail}`;
            }
          } else if (socialLogin?.status === 422) {
            return `/sign-in?errorDetail=${socialLogin.data?.detail || ''}`;
          }
        }

        return true;
      },

      async session({ session, token }) {
        const { accessToken, userId, username, isAdmin } = token || {};

        session.accessToken = accessToken as string;
        session.userId = userId as string;
        session.username = username as string;
        session.isAdmin = isAdmin as boolean;

        return session;
      }
    }
  });
}
