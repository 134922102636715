import {
  Metric,
  NerMetric,
  S2SMetric,
  SSMetric
} from '@/core/stores/parameters-store/parameters.store.types';

export const getInitialMetric = (
  isInference: boolean,
  isIc: boolean,
  isSS: boolean,
  isS2S?: boolean
): Metric | SSMetric | NerMetric | S2SMetric => {
  if (isS2S) {
    return 'average_data_error_potential';
  }
  if (isInference) {
    return 'confidence';
  }
  if (isIc) {
    return 'accuracy';
  }
  if (isSS) {
    return 'mean_iou';
  }
  return 'f1';
};
