import { Divider, Group, Text, useMantineTheme } from '@mantine/core';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  MetricStatusType,
  SuccessMetricStatus
} from '@/core/types/metric-status.types';
import { getJobStatusLabel } from '@/core/utils/get-job-status-label/get-job-status-label';

import HoverCopyButtonBox from '../hover-copy-button-box/hover-copy-button-box';
import {
  MetricPopover,
  MetricPopoverProps
} from '../metric-popover/metric-popover';
import NotAvailableMetric from '../not-available-metric/not-available-metric';
import FAQErrorDocumentationLink from '../../atoms/faq-error-documentation-link/faq-error-documentation-link';
import Metric, { MetricProps } from '../../atoms/metric/metric';
import ScorerTypePill from '../../atoms/scorer-type-pill/scorer-type-pill';

// Omit because usages of SuccessMetricStatusComponent outside of `MetricStatus`
// shouldn't require passing in the status_type
type SuccessMetricStatusProps<T> = Omit<SuccessMetricStatus, 'status_type'> &
  MetricProps &
  T;

export type BaseSuccessMetricStatusProps<T> = SuccessMetricStatusProps<T> & {
  dataTestId?: string;
  popoverProps?: MetricPopoverProps;
};

interface MetricStatusProps<T> extends MetricProps {
  metricStatus: MetricStatusType | null | undefined;
  SuccessMetricStatusComponent: React.ComponentType<
    BaseSuccessMetricStatusProps<T>
  >;
  successMetricStatusProps: T;
}

const MetricStatus = <T,>({
  metricStatus,
  SuccessMetricStatusComponent,
  successMetricStatusProps,
  ...metricProps
}: MetricStatusProps<T>) => {
  const theme = useMantineTheme();

  // Default to filled
  metricProps.variant = metricProps.variant ?? 'filled';

  if (metricStatus == null || metricStatus.status_type == null) {
    return (
      <Metric
        data-testid='null-metric-status'
        style={{ display: 'inline-flex' }}
        value={STRING_PLACEHOLDER}
        {...metricProps}
      />
    );
  }

  switch (metricStatus.status_type) {
    case 'computing':
      return (
        <Metric
          data-testid='computing-metric-status'
          status='in_progress'
          style={{ display: 'inline-flex' }}
          {...metricProps}
        />
      );
    case 'error':
    case 'failed':
      return (
        <MetricPopover
          body={
            <>
              <FAQErrorDocumentationLink />
              <Divider />
              <HoverCopyButtonBox
                buttonProps={{
                  bg: theme.colors.gray[9] + 'D6', // 84% transparency
                  c: 'white'
                }}
                copyText={metricStatus.message}
                px='md'
                py='sm'
              >
                <Text size='sm'>{metricStatus.message}</Text>
              </HoverCopyButtonBox>
            </>
          }
          header={
            <Group
              gap='md'
              justify='space-between'
              pr='0.5rem'
              w='100%'
              wrap='nowrap'
            >
              <Metric
                color='red'
                value={getJobStatusLabel(metricStatus.status_type)}
                variant='subtle'
              />
              {metricStatus.scorer_type != null && (
                <ScorerTypePill type={metricStatus.scorer_type} />
              )}
            </Group>
          }
        >
          <Metric
            color='red'
            data-testid='failed-metric-status'
            status={metricStatus.status_type}
            {...metricProps}
          />
        </MetricPopover>
      );
    case 'not_applicable':
      return (
        <NotAvailableMetric
          data-testid='not-applicable-metric-status'
          explanation={metricStatus.message}
          scorerType={metricStatus.scorer_type}
          {...metricProps}
        />
      );
    case 'not_computed':
      return (
        <NotAvailableMetric
          data-testid='not-computed-metric-status'
          explanation={metricStatus.message}
          scorerType={metricStatus.scorer_type}
          value={STRING_PLACEHOLDER}
          {...metricProps}
        />
      );
    case 'pending':
      return (
        <Metric
          data-testid='pending-metric-status'
          status='unstarted'
          style={{ display: 'inline-flex' }}
          {...metricProps}
        />
      );
    case 'success':
      return (
        <SuccessMetricStatusComponent
          dataTestId='success-metric-status'
          {...metricStatus}
          {...successMetricStatusProps}
          {...metricProps}
        />
      );
    default:
      return (
        <NotAvailableMetric
          data-testid='unknown-metric-status'
          // @ts-expect-error
          explanation={`Unknown metric status: ${metricStatus.status_type}`}
          {...metricProps}
        />
      );
  }
};

export default MetricStatus;
