import { ScrollArea, Space } from '@mantine/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import BarChart from '@/core/components/atoms/bar-chart';
import { OnClickReturn } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import {
  useActiveFilters,
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { NER_OPTIONS } from '@/fine-tune/constants/metric-and-grouped-selects.constants';
import { SCROLLBAR_SIZE } from '@/fine-tune/constants/misc.constants';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import { useMap } from '@/fine-tune/hooks/query-hooks/use-map/use-map';
import { useMetaColumns } from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';
import { getSortIcon } from '@/fine-tune/hooks/use-sort-by/use-sort-by';
import { colorMappings } from '@/fine-tune/utils/color-mappings/color-mappings';

import ChartHeader from '../grouped-by-charts/chart-header';
import { sortAndFilterData } from '../grouped-by-charts/grouped-by-charts.utils';

type SortDir = 'asc' | 'desc' | undefined;
/**
 * AveragePrecisionChart
 *
 */
const AveragePrecisionChart = () => {
  // Hooks
  const { metaColumnNames } = useMetaColumns();
  const activeFilters = useActiveFilters();
  const { data: labelsData } = useLabels() || {};
  const labels = labelsData?.labels || [];
  const { data: mapData } = useMap() || {};

  // Parameters Store
  const { groupedBy } = useParametersStore((s) => ({
    groupedBy: s.groupedBy,
    comparedTo: s.comparedTo
  }));

  const { setParameters, setMetaParameters } = useParametersStoreActions();

  // State
  const [sortDir, setSortDir] = useState<SortDir>(undefined);

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Refs
  const chartRef = useRef<HTMLDivElement>(null);

  // Computed
  const colorSchema = colorMappings(labels?.sort());

  const tooltipOffset = chartRef.current?.scrollLeft;

  const sortedData = useMemo(
    () =>
      sortAndFilterData({
        sortBy: 'metric',
        sortDir,
        data: mapData?.ap_per_class?.values || [],
        labels: mapData?.ap_per_class?.labels || [],
        support: mapData?.ap_per_class?.values || [],
        searchTerm
      }),
    [mapData, sortDir, labels, searchTerm]
  );

  const backgroundColors = sortedData?.labels?.map(
    (label) => colorSchema?.[label]?.bar
  );

  // Effects
  useEffect(() => {
    // Since bar chart is calculated inside the component, we need to force a re calculation of the width
    // when the search term changes
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Event handlers
  const handleBarClick = ({ label }: OnClickReturn) => {
    if (metaColumnNames?.includes(groupedBy)) {
      setMetaParameters([{ name: groupedBy, isin: [label] }]);
    } else {
      const filterKey = 'classFilter';

      const currentFilter = activeFilters?.find(
        ([filterName]) => filterName === filterKey
      );

      const newLabel = currentFilter?.[0] === label ? '' : label;

      setParameters({ [filterKey]: [newLabel] });
    }
  };

  const handleSortChange = () => {
    const newSort = sortDir === 'asc' ? 'desc' : undefined;
    if (!sortDir) {
      setSortDir('asc');
      return;
    }

    setSortDir(newSort);
  };

  const handleTermChange = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <SplitCard
      header={
        <ChartHeader
          copy='Average Precision (AP)'
          searchTerm={searchTerm}
          sortIcon={getSortIcon(sortDir)}
          onSortClick={handleSortChange}
          onTermChange={handleTermChange}
        />
      }
      mb='sm'
      mt='sm'
    >
      <Space h='sm' />
      <ScrollArea scrollbarSize={SCROLLBAR_SIZE} viewportRef={chartRef}>
        <BarChart
          backgroundColor={backgroundColors}
          data={sortedData.data}
          isLoading={isLoading}
          labels={sortedData.labels}
          stackedLabels={NER_OPTIONS.slice(1).map((option) => option.label)}
          tooltipOffset={tooltipOffset}
          onClick={handleBarClick}
        />
      </ScrollArea>
    </SplitCard>
  );
};

export default AveragePrecisionChart;
