export const LOGIN_PATH = '/login';
export const USERS_PATH = '/users';
export const SOCIAL_LOGIN_PATH = '/login/social';
export const CURRENT_USER_PATH = '/current_user';
export const ADMIN_USER_CHECK_PATH = '/users/admin';

// Human Feedback
export const HUMAN_FEEDBACK_TEMPLATES_PATH =
  '/projects/{project_id}/feedback/templates';

export const HUMAN_FEEDBACK_TEMPLATE_PATH =
  '/projects/{project_id}/feedback/templates/{template_id}';

export const HUMAN_FEEDBACK_RATING_PATH =
  '/projects/{project_id}/runs/{run_id}/feedback/templates/{template_id}/index/{index}/rating';

// Global Paths
export const PROJECT_RUN_PATH = '/projects/{project_id}/runs/{run_id}';
export const PROJECT_RUN_METRICS_PATH =
  '/projects/{project_id}/runs/{run_id}/metrics';

// Evaluate Paths
export const EVALUATE_RUN_PATH = '/projects/{project_id}/prompts/runs/{run_id}';

export const EVALUATE_METRICS_PATH =
  '/projects/{project_id}/runs/{run_id}/prompts/metrics';

export const EVALUATE_COLUMNAR_RUNS_PATH =
  '/projects/{project_id}/prompts/runs/columnar';

export const EVALUATE_COLUMNAR_RUN_PATH =
  '/projects/{project_id}/runs/{run_id}/prompts/rows/columnar';
export const EVALUATE_COLUMNAR_RUNS_ROWS_PATH =
  '/projects/{project_id}/runs/prompts/rows/columnar';
export const EVALUATE_RUNS_PATH = '/projects/{project_id}/prompts/runs';

export const EVALUATE_CHAINS_ROWS_PATH = `/projects/{project_id}/runs/{run_id}/prompts/rows/chains/{chain_id}/columnar`;
export const EVALUATE_CHAINS_ROWS_PATH_FULL = `/projects/{project_id}/runs/{run_id}/prompts/rows/chains/{chain_id}/columnar-full-chain`;
export const EVALUATE_CHAINS_ROWS_PATH_PARTIAL = `/projects/{project_id}/runs/{run_id}/prompts/rows/chains/{chain_id}/columnar-partial-chain`;
