import { Divider, Group, Text, useMantineTheme } from '@mantine/core';
import { IconCurrencyDollar, IconWand } from '@tabler/icons-react';

import HoverCopyButtonBox from '../hover-copy-button-box/hover-copy-button-box';

const MetricExplanationBody = ({
  explanation,
  rationale,
  rationaleLabel = 'Generated Reasoning',
  cost,
  formatCost
}: {
  explanation: string | null | undefined;
  rationale: string | null | undefined;
  rationaleLabel?: string;
  cost: number | null | undefined;
  formatCost?: (cost: number) => string | number | JSX.Element;
}) => {
  const theme = useMantineTheme();
  return (
    <>
      {explanation && (
        <HoverCopyButtonBox
          boxStyle={{ overflowY: 'auto' }}
          buttonProps={{
            bg: theme.colors.gray[9] + 'D6', // 84% transparency
            c: 'white'
          }}
          copyText={explanation}
          data-testid='metric-explanation'
          mah='150px'
          px='md'
          py='sm'
        >
          <Text size='sm'>{explanation}</Text>
        </HoverCopyButtonBox>
      )}
      {rationale && (
        <>
          <Divider />
          <HoverCopyButtonBox
            boxStyle={{ overflowY: 'auto' }}
            buttonProps={{
              bg: theme.colors.gray[9] + 'D6', // 84% transparency
              c: 'white'
            }}
            copyText={rationale}
            data-testid='metric-rationale'
            mah='150px'
          >
            <Group px='md' py='sm'>
              <IconWand size='1rem' />
              <Text size='sm'>{rationaleLabel}</Text>
            </Group>
            <Text pb='sm' px='md' size='sm'>
              {rationale}
            </Text>
          </HoverCopyButtonBox>
        </>
      )}
      {cost && (
        <>
          <Divider />
          <Group data-testid='metric-cost' px='md' py='sm'>
            <IconCurrencyDollar size='1rem' />
            <Text size='sm'>
              {formatCost != null ? formatCost(cost) : cost}
            </Text>
          </Group>
        </>
      )}
    </>
  );
};

export default MetricExplanationBody;
