import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';

// Query key
export const GET_TASKS_KEY = '/projects/{project_id}/runs/{run_id}/tasks';

export const useTasks = () => {
  const { runId, projectId } = usePathParameters();

  const { task, actions } = useParametersStore((s) => ({
    task: s.task,
    actions: s.actions
  }));
  const { isMltc } = useComputedParameters();

  const fetchTasks = async () => {
    const res = await api.GET(GET_TASKS_KEY, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string
        }
      }
    });
    return res?.data;
  };

  const response = useQuery(
    [GET_TASKS_KEY, { projectId, runId }],
    () => fetchTasks(),
    { enabled: Boolean(projectId && runId && isMltc) }
  );

  if (!task && response?.data?.tasks?.length) {
    actions.setParameters({ task: response.data.tasks[0] });
  }

  return response;
};
