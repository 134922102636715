import { Box, Group, Text, UnstyledButton } from '@mantine/core';
import { NextUrlWithParsedQuery } from 'next/dist/server/request-meta';
import Link from 'next/link';

import { Paths } from '@/core/constants/routes.constants';
import { PROJECT_TYPE } from '@/core/types/projects.types';

import { CustomAction } from '../spotlight-provider';

interface ActionItemProps {
  action: CustomAction;
}
const ActionItem = ({ action }: ActionItemProps) => {
  // Computed
  let url: Partial<NextUrlWithParsedQuery> = {
    pathname: Paths.NOT_FOUND
  };

  if (action.projectType === PROJECT_TYPE.TRAINING_INFERENCE) {
    url = {
      pathname: Paths.INSIGHTS,
      query: {
        projectId: action?.run?.project_id!,
        runId: action?.run.id,
        taskType: action?.run?.task_type?.toString()
      }
    };
  }

  if (action.projectType === PROJECT_TYPE.PROMPT_EVALUATION) {
    url = {
      pathname: Paths.PROMPT_PROMPTS,
      query: {
        projectId: action?.run?.project_id!,
        runId: action?.run.id
      }
    };
  }

  if (action.projectType === PROJECT_TYPE.OBSERVE) {
    url = {
      pathname: Paths.OBSERVE,
      query: {
        projectId: action?.id
      }
    };
  }

  return (
    <Link
      className='unstyled-anchor'
      href={url}
      style={{
        width: '100%'
      }}
    >
      <UnstyledButton
        component='div'
        data-testid='action-item'
        style={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          padding: '10px 12px',
          borderRadius: 8
        }}
        // @ts-expect-error
        onClick={action.onClick}
      >
        <Group className='w-100' wrap='nowrap'>
          <Box className='flex-1 flex-column'>
            <Text>{action?.title}</Text>

            {action?.description && (
              <Text c='dimmed' size='xs'>
                {action.description}
              </Text>
            )}
          </Box>
        </Group>
      </UnstyledButton>
    </Link>
  );
};

export default ActionItem;
