import { ScrollArea, Space } from '@mantine/core';
import { useMemo, useRef, useState } from 'react';
import React from 'react';

import BarChart from '@/core/components/atoms/bar-chart';
import { OnClickReturn } from '@/core/components/atoms/bar-chart/bar-chart';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import {
  useActiveFilters,
  useParametersStore,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { SSMetric } from '@/core/stores/parameters-store/parameters.store.types';
import {
  NER_OPTIONS,
  SS_METRICS
} from '@/fine-tune/constants/metric-and-grouped-selects.constants';
import { SCROLLBAR_SIZE } from '@/fine-tune/constants/misc.constants';
import { useMetaColumns } from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';
import { useSemsegMetrics } from '@/fine-tune/hooks/query-hooks/use-semseg-metrics/use-semseg-metrics';
import { getSortIcon } from '@/fine-tune/hooks/use-sort-by/use-sort-by';
import { colorMappings } from '@/fine-tune/utils/color-mappings/color-mappings';

import ChartHeader from '../grouped-by-charts/chart-header';
import { sortAndFilterData } from '../grouped-by-charts/grouped-by-charts.utils';

const getMetricKey = (
  metric: SSMetric
): 'boundary_iou_per_class' | 'mean_iou_per_class' | 'dice_per_class' => {
  if (metric === 'dice_coefficient') {
    return 'dice_per_class';
  }
  return `${metric}_per_class`;
};

type SortDir = 'asc' | 'desc' | undefined;
/**
 * IoUChart
 *
 */
const IoUChart = () => {
  // State
  const [sortDir, setSortDir] = useState<SortDir>(undefined);

  // Hooks
  const { metaColumnNames } = useMetaColumns();
  const activeFilters = useActiveFilters();
  const { data, isLoading } = useSemsegMetrics() || {};

  // Parameters Store
  const { groupedBy, metric } = useParametersStore((s) => ({
    groupedBy: s.groupedBy,
    metric: s.metric
  }));

  const key = getMetricKey(metric as SSMetric);
  const { labels = [], values = [] } = data?.[key] || {};

  const { setParameters, setMetaParameters } = useParametersStoreActions();

  // Refs
  const chartRef = useRef<HTMLDivElement>(null);

  // Computed
  const colorSchema = colorMappings(labels);

  const tooltipOffset = chartRef.current?.scrollLeft;

  const sortedData = useMemo(
    () =>
      sortAndFilterData({
        sortBy: 'metric',
        sortDir,
        data: values,
        labels,
        support: [],
        searchTerm: ''
      }),
    [data, sortDir, labels]
  );

  const backgroundColors = sortedData?.labels?.map(
    (label) => colorSchema?.[label]?.bar
  );

  // Event handlers
  const handleBarClick = ({ label }: OnClickReturn) => {
    if (metaColumnNames?.includes(groupedBy)) {
      setMetaParameters([{ name: groupedBy, isin: [label] }]);
    } else {
      const filterKey = 'classFilter';

      const currentFilter = activeFilters?.find(
        ([filterName]) => filterName === filterKey
      );

      const newLabel = currentFilter?.[0] === label ? '' : label;

      setParameters({ [filterKey]: [newLabel] });
    }
  };

  const handleSortChange = () => {
    const newSort = sortDir === 'asc' ? 'desc' : undefined;
    if (!sortDir) {
      setSortDir('asc');
      return;
    }

    setSortDir(newSort);
  };

  return (
    <SplitCard
      header={
        <ChartHeader
          copy={SS_METRICS.find((m) => m.value === metric)?.label || ''}
          sortIcon={getSortIcon(sortDir)}
          onSortClick={handleSortChange}
        />
      }
      mb='sm'
      mt='sm'
      testId='iou-chart'
    >
      <Space h='sm' />
      <ScrollArea scrollbarSize={SCROLLBAR_SIZE} viewportRef={chartRef}>
        <BarChart
          backgroundColor={backgroundColors}
          data={sortedData.data}
          isLoading={isLoading}
          labels={sortedData.labels}
          stackedLabels={NER_OPTIONS.slice(1).map((option) => option.label)}
          tooltipOffset={tooltipOffset}
          onClick={handleBarClick}
        />
      </ScrollArea>
    </SplitCard>
  );
};

export default IoUChart;
