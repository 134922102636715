import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import {
  EVALUATE_COLUMNAR_RUNS_PATH,
  EVALUATE_RUNS_PATH,
  PROJECT_RUN_PATH
} from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { GET_CURRENT_PROJECT } from '../use-current-project/use-current-project';

export const useUpdateRun = () => {
  // Parameters
  const { projectId, runId } = usePathParameters();

  // Hooks
  const queryClient = useQueryClient();

  const handleUpdateRun = (body: components['schemas']['RunUpdateRequest']) => {
    return api.PUT(PROJECT_RUN_PATH, {
      body,
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!
        }
      }
    });
  };

  const mutation = useMutation(handleUpdateRun, {
    onSuccess: () => {
      queryClient.invalidateQueries([EVALUATE_RUNS_PATH]);
      queryClient.invalidateQueries([EVALUATE_COLUMNAR_RUNS_PATH]);
      queryClient.invalidateQueries([PROJECT_RUN_PATH, { runId }]);
      queryClient.invalidateQueries([GET_CURRENT_PROJECT, { projectId }]);
    },
    onError: () => {
      showNotification({
        title: 'Error',
        message: 'Run could not be updated',
        type: 'error'
      });
    }
  });

  return mutation;
};
