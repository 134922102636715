import { Box } from '@mantine/core';

const VIDEOS_BASE_URL = 'https://www.loom.com/embed/';

const GuideVideoModal = ({
  innerProps
}: {
  innerProps: {
    videoURL?: string;
  };
}) => {
  const fullVideoUrl = `${VIDEOS_BASE_URL}${innerProps.videoURL}`;

  return (
    <Box id='guide-video-modal' mb='xs'>
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
        <iframe
          allowFullScreen
          role='presentation'
          src={fullVideoUrl}
          style={{
            position: 'absolute',
            border: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          title='Guide Video'
        ></iframe>
      </div>
    </Box>
  );
};

export default GuideVideoModal;
