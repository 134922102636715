import {
  Box,
  Group,
  Modal,
  ModalProps,
  Text,
  useMantineTheme
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { IconAlertTriangle, IconUserCheck } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { PanelGroup } from '@/core/components/organisms/panel-group/panel-group';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import {
  templateToPostBody,
  useCreateFeedbackTemplate
} from '@/core/hooks/query-hooks/use-feedback-templates/use-create-feedback-template';
import { useEditFeedbackTemplate } from '@/core/hooks/query-hooks/use-feedback-templates/use-edit-feedback-template';
import {
  toFeedbackTemplateInputs,
  useGetFeedbackTemplates
} from '@/core/hooks/query-hooks/use-feedback-templates/use-get-feedback-templates';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { FeedbackType } from '@/core/types/human-feedback.types';
import { RatingConfigurationStack } from '@/evaluate/page-components/rating-configuration-stack/rating-configuration-stack';

import { ConfigureRatingsTemplateForm } from './configure-ratings-template-form';

interface ConfigureRatingsModalProps extends ModalProps {}

export const ConfigureRatingsModal = ({
  ...props
}: ConfigureRatingsModalProps) => {
  // Refs
  const isFirstRenderRef = useRef(true);
  const { ref: modalRef, height: modalHeight } = useElementSize();

  // QueryParams
  const { projectId } = usePathParameters();

  // Hooks
  const { colors } = useMantineTheme();

  const { data: templatesData, isLoading: isTemplatesLoading } =
    useGetFeedbackTemplates();

  const templates = templatesData?.templates ?? [];

  const createRatingConfiguration = useCreateFeedbackTemplate();
  const editRatingConfiguration = useEditFeedbackTemplate();

  // Local State
  const [cachedTemplates, setCachedTemplates] = useState<
    FeedbackTemplate<FeedbackType>[]
  >([]);

  const [selectedTemplate, setSelectedTemplate] = useState<
    FeedbackTemplate<FeedbackType> | undefined
  >();
  const [savingKeys, setSavingKeys] = useState<string[]>([]);

  // Computed
  const scrollAreaHeight = modalHeight - 140; // 140 is the height of the header + padding;

  const templatesWithChanges = cachedTemplates.filter((template) =>
    template.isModified()
  );

  const hasUnsavedChanges = templatesWithChanges.length > 0;

  const isFirstRender = isFirstRenderRef.current;

  // Effects
  useEffect(() => {
    if (props.opened) {
      if (isTemplatesLoading || !isFirstRender) {
        return;
      }

      if (templates.length > 0) {
        // Only set the cached templates on the first render.
        // Subsequent renders will have state managed locally.
        isFirstRenderRef.current = false;

        setCachedTemplates(templates);
        setSelectedTemplate(templates[0]);
      }
    }
  }, [props.opened, isTemplatesLoading, templates, projectId]);

  useEffect(() => {
    isFirstRenderRef.current = true;
  }, [props.opened, projectId]);

  // Handlers
  const handleClose = () => {
    if (hasUnsavedChanges) {
      openConfirmModal({
        zIndex: Z_INDEX.TOOLTIPS,
        title: (
          <Group>
            <IconAlertTriangle color='red' />
            <Text c='gray.7' fw={700}>
              You have unsaved changes
            </Text>
          </Group>
        ),
        variant: 'filled',
        children: (
          <Text c='gray.6' fw={500}>
            Are you sure you want to leave this page? Changes you made will not
            be saved.
          </Text>
        ),
        cancelProps: {
          color: 'red.2',
          c: 'red.6',
          autoContrast: false,
          variant: 'filled'
        },
        labels: {
          cancel: 'Close without saving',
          confirm: 'Save all & close'
        },
        onConfirm: async () => {
          await handleSaveAllTemplates().then(() => {
            props.onClose();
          });
        },
        onCancel: async () => {
          props.onClose();
        }
      });
    } else {
      props.onClose();
    }
    isFirstRenderRef.current = true;
  };

  const handleSaveTemplate = async (
    template: FeedbackTemplate<FeedbackType>
  ) => {
    if (template.isInProduction()) {
      if (template.name != null && template.id != null) {
        setSavingKeys([...savingKeys, template.getKey()]);
        await editRatingConfiguration
          .mutateAsync({
            templateId: template.id,
            name: template.name,
            criteria:
              typeof template.criteria === 'string' &&
              template.criteria.length > 0
                ? template.criteria
                : null
          })
          .then((res) => {
            if (res) {
              const newInputs = toFeedbackTemplateInputs(res);
              template.from(newInputs);
            }

            setSavingKeys(savingKeys.filter((x) => x !== template.getKey()));

            // If there are no more templates in draft state, close the modal.
            if (!cachedTemplates.some((template) => template.isModified())) {
              props.onClose();
            }
          });
      }
    } else {
      const values = templateToPostBody(template);
      if (values != null) {
        setSavingKeys([...savingKeys, template.getKey()]);
        await createRatingConfiguration.mutateAsync(values).then((res) => {
          if (res) {
            const newInputs = toFeedbackTemplateInputs(res);
            template.from(newInputs);
          }

          setSavingKeys(savingKeys.filter((x) => x !== template.getKey()));

          // If there are no more templates in draft state, close the modal.
          if (!cachedTemplates.some((template) => template.isModified())) {
            props.onClose();
          }
        });
      }
    }
  };

  const handleTemplateChange = (template: FeedbackTemplate<any>) => {
    const configs = [...cachedTemplates];
    const index = configs.findIndex((x) => x.getKey() === template.getKey());
    configs[index] = template;
    setCachedTemplates(configs);
  };

  const handleSaveAllTemplates = async () => {
    const templates = cachedTemplates.filter((template) =>
      template.isModified()
    );
    setSavingKeys(templates.map((template) => template.getKey()));
    await Promise.all(
      templates.map((template) => handleSaveTemplate(template))
    ).then(() => {
      setSavingKeys([]);
      props.onClose();
    });
  };

  const templateNames = cachedTemplates
    .filter((template) => template.id !== selectedTemplate?.id)
    .filter((template) => template.name !== undefined)
    .map((template) => template.name?.toLowerCase() as string);

  return (
    <Modal.Root
      centered
      withinPortal
      className='no-padding-modal'
      data-testid='configure-ratings-modal'
      m={0}
      padding={0}
      size={800}
      withCloseButton={false}
      zIndex={Z_INDEX.MODALS}
      {...props}
      keepMounted={false}
      onClose={handleClose}
    >
      <Modal.Overlay />
      <Modal.Content
        h='80vh'
        mah='100vh'
        ref={modalRef}
        style={{
          overflow: 'hidden'
        }}
      >
        <Modal.Header style={{ borderBottom: `1px solid ${colors.gray[2]}` }}>
          <Modal.Title p='md'>
            <Group gap={8}>
              <IconUserCheck size={18} />
              <Text c='gray.6' fw={600} size='sm'>
                Configure Human Ratings
              </Text>
            </Group>
          </Modal.Title>
          <Modal.CloseButton
            aria-label='Close configure human ratings modal'
            c='gray.4'
            m='sm'
            size='md'
          />
        </Modal.Header>
        <Modal.Body p={0}>
          <PanelGroup mih='75vh' widths={[30, 70]}>
            <PanelGroup.Panel
              resizeRange={[25, 45]}
              style={{ borderRight: '1px solid var(--mantine-color-gray-2)' }}
            >
              <Box p='lg'>
                <RatingConfigurationStack
                  configurations={cachedTemplates}
                  maxHeight={scrollAreaHeight}
                  savingKeys={savingKeys}
                  selectedConfiguration={selectedTemplate}
                  setConfigurations={setCachedTemplates}
                  setSavingKeys={setSavingKeys}
                  setSelectedConfiguration={setSelectedTemplate}
                />
              </Box>
            </PanelGroup.Panel>
            <PanelGroup.Panel bg='gray.0' h='100%'>
              {!selectedTemplate && (
                <Text c='gray.8' m='lg'>
                  Click on &quot;+ New Rating Type&quot; to set up your human
                  ratings.
                </Text>
              )}
              {selectedTemplate && (
                <ConfigureRatingsTemplateForm
                  configuration={selectedTemplate}
                  maxHeight={scrollAreaHeight}
                  templateNames={templateNames}
                  onChange={handleTemplateChange}
                  onSave={() => handleSaveTemplate(selectedTemplate)}
                />
              )}
            </PanelGroup.Panel>
          </PanelGroup>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
