import {
  ALERTS_COLUMN_KEY,
  ParametersKeys,
  ParametersValues
} from '@/core/stores/parameters-store/parameters.store.types';
import { PROJECT_TYPE } from '@/core/types/projects.types';

import { TASK_TYPE } from './tasks-and-frameworks.constants';

// GLOBALS
export const ASC = 'asc';
export const DESC = 'desc';

export enum Tab {
  DATAFRAME = 'df',
  EMBEDDINGS = 'em',
  DATA_VIEW = 'dv',
  FEATURE_AGGREGATES = 'fd',
  SINGLE_FEATURE = 'fi',
  PROMPT_CHART_VIEW = 'pcv',
  PROMPT_LIST_VIEW = 'plv',
  OBSERVE_METRIC_VIEW = 'pmmv',
  OBSERVE_DATA_VIEW = 'pmdv'
}
// END GLOBALS

// INSIGHTS
export const DEFAULT_INSIGHTS_PARAMS: Partial<ParametersValues> = {
  buildGrayPoints: false,
  classFilter: [],
  clusterIds: [],
  comparedTo: null,
  correctlyClassified: false,
  dataframeColumns: [],
  depHigh: 1,
  depLow: 0,
  driftScoreThreshold: 0.95,
  email: '',
  fromSplit: '',
  goldFilter: [],
  groupedBy: 'gold',
  inferenceName: '',
  isDataView: false,
  isDrifted: false,
  isMacro: false,
  isOnTheBoundary: false,
  isRegex: false,
  isSpanRegex: false,
  likelyMislabelled: false,
  lmPercentile: 0,
  mapThreshold: 0.5,
  metaFilter: [],
  metric: 'f1',
  misclassifiedOnly: false,
  predFilter: [],
  projectType: PROJECT_TYPE.TRAINING_INFERENCE,
  searchTerm: '',
  similarTo: [],
  slice: '',
  sortBy: 'data_error_potential',
  sortDirection: DESC,
  spanSearch: '',
  split: 'training',
  tab: Tab.DATAFRAME,
  insightsTab: ALERTS_COLUMN_KEY,
  task: '',
  taskType: undefined
};

export const INSIGHTS_NON_CLEARABLE_PARAMETERS: ParametersKeys[] = [
  'dataframeColumns',
  'mapThreshold',
  'taskType',
  'tab',
  'insightsTab'
];

// Query params that should not be rendered as filters in the UI
export const INSIGHTS_HIDDEN_FILTERS = [
  'comparedTo',
  'dataframeColumns',
  'driftScoreThreshold',
  'fromSplit',
  'groupedBy',
  'inferenceName',
  'insightsTab',
  'isMacro',
  'isRegex',
  'isSpanRegex',
  'likelyMislabelled',
  'mapThreshold',
  'metaFilter',
  'metric',
  'projectId',
  'runId',
  'slice',
  'sortBy',
  'sortDirection',
  'split',
  'tab',
  'task',
  'taskType',
  'promptMonitorFilters'
];
// END INSIGHTS

// PROMPT
export const DEFAULT_PROMPT_PARAMS: Partial<ParametersValues> = {
  chainNodeId: undefined,
  chainRootId: undefined,
  compareIds: [],
  datasetId: undefined,
  projectType: PROJECT_TYPE.PROMPT_EVALUATION,
  chainsRunsColumns: [
    'name',
    'total_responses',
    'average_cost',
    'total_cost',
    'average_latency',
    '_ratings',
    'average_factuality',
    'average_groundedness',
    'created_at',
    'created_by'
  ],
  chainsColumns: [
    'prompt',
    'response',
    '_ratings',
    'latency',
    'factuality',
    'groundedness'
  ],
  promptEvalFilters: [],
  promptColumns: [
    'prompt',
    'response',
    'target',
    'factuality',
    'groundedness',
    'toxicity',
    'context_relevance',
    'pii',
    'tone',
    'sexist',
    'latency',
    '_rating'
  ],
  rowId: undefined,
  runColumns: [
    'name',
    'model_alias',
    'template_name_with_version',
    'total_responses',
    'average_cost',
    'total_cost',
    'average_factuality',
    'average_groundedness',
    'average_toxicity',
    'average_relevance',
    'average_latency',
    'ratings',
    'created_at',
    'created_by'
  ],
  runId: undefined,
  selectedMetrics: [
    'latency',
    'cost',
    'bleu',
    'rouge',
    'factuality',
    'context_adherence',
    'completeness'
  ],
  showRawValues: false,
  sortBy: 'created_at',
  sortDirection: DESC,
  tab: Tab.PROMPT_LIST_VIEW,
  taskType: TASK_TYPE.PE,
  templateId: undefined,
  templateVersionId: undefined,
  templateVersionNum: 0
};

// OBSERVE
export const DEFAULT_OBSERVE_PARAMS: Partial<ParametersValues> = {
  chainNodeId: undefined,
  chainRootId: undefined,
  chartsPerRow: 3,
  expandedChart: undefined,
  groupedBy: '',
  hiddenPromptMonitorCharts: [],
  hiddenPromptMonitorGroups: [],
  interval: 0,
  pageSize: 25,
  projectType: PROJECT_TYPE.OBSERVE,
  promptMonitorFilters: [],
  promptMonitorChartOrder: {},
  promptMonitorGroupOrder: [],
  refreshInterval: 15000, // Every 15 seconds
  searchTerm: '',
  showChains: true,
  showContinuousCharts: false,
  sortBy: 'created_at',
  sortDirection: DESC,
  tab: Tab.OBSERVE_METRIC_VIEW,
  taskType: TASK_TYPE.PM,
  timeRange: 'last3Hours'
};
