import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';

export const POST_COMPARE_RUNS =
  '/projects/{project_id}/split/{split}/insights/compare';

export const useCompareRuns = () => {
  const { projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  const fetchCompareRuns = async () => {
    const res = await api.POST(POST_COMPARE_RUNS, {
      params: {
        path: {
          project_id: projectId as string,
          split: split
        }
      }
    });

    return res.data;
  };

  const response = useQuery(
    [POST_COMPARE_RUNS, { projectId, split }],
    fetchCompareRuns,
    { enabled: Boolean(projectId && split) }
  );

  return { ...response, data: response?.data?.runs?.reverse() };
};
