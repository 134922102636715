import { ASC, DESC, Tab } from '@/core/constants/query-params.constants';
import { TaskType } from '@/core/constants/tasks-and-frameworks.constants';
import { MetricsGroupId } from '@/core/hooks/use-metrics-group/use-metrics-group';
import { components } from '@/core/types/api';
import { QueryFilter } from '@/core/types/filter.types';
import { ProjectType } from '@/core/types/projects.types';
import { PromptEvalFilter } from '@/evaluate/stores/prompt-store/prompt.store.types';
import { FilterParams, MetaFilter } from '@/fine-tune/types/query.types';

export const ALERTS_COLUMN_KEY = 'alerts';
export const METRICS_COLUMN_KEY = 'metrics';
export const CLUSTERS_COLUMN_KEY = 'clusters';

export type ComparedToOptions = components['schemas']['Split'] | null;

export type ChartOrder = {
  [key in MetricsGroupId]?: string[];
};

export type InsightsTabOptions =
  | typeof ALERTS_COLUMN_KEY
  | typeof METRICS_COLUMN_KEY
  | typeof CLUSTERS_COLUMN_KEY
  | null;

// Keep it in alphabetical order
export interface FilterParametersValues {
  classFilter: string[];
  clusterIds: number[];
  depHigh: number;
  depLow: number;
  goldFilter: string[];
  isRegex: boolean;
  isSpanRegex: boolean;
  likelyMislabelled: boolean;
  misclassifiedOnly: boolean;
  predFilter: string[];
  searchTerm: string;
  sortBy: string;
  sortDirection: typeof ASC | typeof DESC;
  spanSearch: string;
  isOnTheBoundary: boolean;
}

// Keep it in alphabetical order
export interface ParametersValues extends FilterParametersValues {
  buildGrayPoints: boolean;
  chainRootId: string;
  chainNodeId: string;
  chainsColumns: string[];
  chainsRunsColumns: string[];
  chartsPerRow?: number;
  comparedTo: ComparedToOptions;
  confidence?: string;
  compareIds?: string[];
  correctlyClassified: boolean;
  dataframeColumns: string[];
  datasetId: string;
  driftScoreThreshold: number | null;
  email?: string;
  expandedChart?: string | null;
  fromSplit: Splits | string | '';
  groupedBy: string;
  hiddenPromptMonitorCharts: string[];
  hiddenPromptMonitorGroups: MetricsGroupId[];
  inferenceName: string;
  insightsTab: InsightsTabOptions;
  interval: number;
  isDataView: boolean;
  isDrifted: boolean;
  isMacro: boolean;
  isOnTheBoundary: boolean;
  lmPercentile: number;
  mapThreshold: number;
  metaFilter: MetaFilter[];
  metric: Metric | NerMetric | SSMetric | S2SMetric;
  pageSize: number;
  projectId: string;
  project_id: string;
  projectType: string;
  promptColumns: string[];
  promptMonitorFilters: QueryFilter[];
  promptEvalFilters: PromptEvalFilter[];
  promptMonitorChartOrder: ChartOrder;
  promptMonitorGroupOrder: MetricsGroupId[];
  ratingTemplateId: string;
  refreshInterval?: number;
  rowId?: string;
  inputIndex?: number;
  runColumns?: string[];
  runId: string;
  run_id: string;
  selectedMetrics: string[];
  similarTo?: number[];
  showChains: boolean;
  showContinuousCharts: boolean;
  showRawValues: boolean;
  slice: string;
  split: Splits;
  tab: Tab;
  task: string;
  taskType: TaskType;
  templateId: string;
  templateVersionId: string;
  templateVersionNum: number;
  timeRange: string;
}

export type ParametersKeys = keyof ParametersValues;

export type PartialParameters = Partial<ParametersValues>;

export interface ParametersState extends ParametersValues {
  actions: {
    resetStore: ({
      defaults,
      paramsToPersist,
      projectType
    }: {
      defaults: PartialParameters;
      paramsToPersist?: ParametersKeys[];
      projectType: ProjectType;
    }) => Promise<void>;
    clearParameters: (preserveKeys?: ParametersKeys[]) => void;
    clearAndUpdateParameters: (
      params: PartialParameters,
      clearAll?: boolean,
      preserveKeys?: ParametersKeys[]
    ) => void;
    setMetaParameters: (filters: MetaFilter[]) => void;
    setParameters: (params: Partial<ParametersState>) => void;
  };
  computed: ComputedData;
  differs: Differs;
}

export interface ComputedData {
  buildGrayPoints: () => boolean;
  defaultValues: () => PartialParameters | undefined;
  nonClearableValues: () => ParametersKeys[];
  isDataView: () => boolean;
  isIc: () => boolean;
  isInference: () => boolean;
  isInferenceNer: () => boolean;
  isMltc: () => boolean;
  isNer: () => boolean;
  isOoc: () => boolean;
  isOd: () => boolean;
  isPrompt: () => boolean;
  isObserve: () => boolean;
  isSD: () => boolean;
  isSS: () => boolean;
  isS2S: () => boolean;
  isTestOrValidation: () => boolean;
  isTextClassification: () => boolean;
  params: () => PartialParameters;
}

export interface Differs {
  getDiff: () => PartialParameters;
  // TODO: Update typing
  getActiveFilters: (asObj?: boolean) => [ParametersKeys, any][];
}

type FiltersKeys = keyof FilterParametersValues;

export type CamelToSnakeCaseMap = Record<FiltersKeys, string>;

export type ComputedValue = keyof ComputedData;

export interface SnakeCaseParameters extends FilterParams {
  lm_percentile?: number;
}

export type Metric =
  | 'f1'
  | 'precision'
  | 'recall'
  | 'accuracy'
  | 'confidence'
  | 'data_error_potential';

export type Splits = 'training' | 'test' | 'validation' | 'inference';

export type NerMetric =
  | 'total_errors'
  | 'missed_label'
  | 'span_shift'
  | 'wrong_tag'
  | 'ghost_span';

export type SSMetric = 'boundary_iou' | 'mean_iou' | 'dice_coefficient';
export type S2SMetric =
  | 'average_bleu'
  | 'average_data_error_potential'
  | 'average_rouge';

export const ERRORS_DICT = {
  ghost_span: 'Ghost Span',
  missed_label: 'Missed Label',
  span_shift: 'Span Shift',
  wrong_tag: 'Wrong Tag'
};
