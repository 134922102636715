import { useParametersStore } from '@/core/stores/parameters-store';
import { InsightsRow } from '@/fine-tune/types/query.types';

export const getTotalSamplesIds = (
  rowsData: InsightsRow[],
  selectedRows: number[],
  selectedSpans: number[]
): number[] => {
  const { computed } = useParametersStore.getState();
  const { isNer, isOd } = computed;

  if (isNer()) {
    return rowsData
      ?.filter((row: { id: number }) => selectedRows?.includes(row.id))
      .flatMap((row) => row.spans)
      .reduce((filtered, span) => {
        if (span?.is_active && span?.id) {
          filtered.push(span.id);
        }
        return filtered;
      }, [] as number[])
      .concat(selectedSpans);
  }

  if (isOd()) {
    return rowsData
      ?.filter((row: { id: number }) => selectedRows?.includes(row.id))
      .flatMap((row) => row.boxes)
      .reduce((filtered, box) => {
        if (box?.is_active && box?.id) {
          filtered.push(box.id);
        }
        return filtered;
      }, [] as number[]);
  }

  return selectedRows;
};
