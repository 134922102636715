import {
  Box,
  Divider,
  Grid,
  Group,
  Indicator,
  Select,
  Skeleton,
  Text,
  UnstyledButton
} from '@mantine/core';
import {
  IconChartPie,
  IconClipboardList,
  IconDatabase,
  IconDownload,
  IconShoppingCart,
  IconTelescope
} from '@tabler/icons-react';

import ProjectLockButton from '@/core/components/atoms/project-lock-button/project-lock-button';
import NavActionIcon from '@/core/components/molecules/nav-action-icon/nav-action-icon';
import { ASC, DESC } from '@/core/constants/query-params.constants';
import { TaskType } from '@/core/constants/tasks-and-frameworks.constants';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { useCurrentRun } from '@/core/hooks/query-hooks/use-current-run/use-current-run';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import {
  useActiveFilters,
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import {
  ParametersValues,
  Splits
} from '@/core/stores/parameters-store/parameters.store.types';
import { components } from '@/core/types/api';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import InferenceSelects from '@/fine-tune/components/inference-selects/inference-selects';
import { parseApiResponseToParams } from '@/fine-tune/data-parsers/parse-api-response-to-params/parse-api-response-to-params';
import { useEdits } from '@/fine-tune/hooks/query-hooks/use-edits/use-edits';
import { useEditsContent } from '@/fine-tune/hooks/query-hooks/use-edits-content/use-edits-content';
import { useInferenceNames } from '@/fine-tune/hooks/query-hooks/use-inference-names/use-inference-names';
import { useInsightAlerts } from '@/fine-tune/hooks/query-hooks/use-insights-alerts/use-insights-alerts';
import { useInsightsIds } from '@/fine-tune/hooks/query-hooks/use-insights-ids/use-insights-ids';
import {
  getFirstFiveUserLoggedMetaCols,
  useMetaColumns
} from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';
import { useSlices } from '@/fine-tune/hooks/query-hooks/use-slices/use-slices';
import { useTasks } from '@/fine-tune/hooks/query-hooks/use-tasks/use-tasks';
import { getDefaultColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import { useInsightsStore } from '@/fine-tune/stores/insights-store/insights.store';
import useStore from '@/fine-tune/stores/store';
import { getInitialMetric } from '@/fine-tune/utils/parse-run-response/parse-run-response';

const SPLIT_SELECT_DATA: { label: string; value: Splits }[] = [
  { label: 'Training', value: 'training' },
  { label: 'Validation', value: 'validation' },
  { label: 'Test', value: 'test' },
  { label: 'Inference', value: 'inference' }
];
type Slices = components['schemas']['SliceDB'][];

/**
 * HeaderBar
 *
 *
 *
 * @returns {React.Component} HeaderBar
 */
const HeaderBar = () => {
  // Query Hooks
  const { data: runData, isFetching: runIsFetching } = useCurrentRun();
  const { data: projectData, isFetching: projectIsFetching } =
    useCurrentProject();
  const { data: sliceData } = useSlices();
  const { data: inferenceNames } = useInferenceNames();
  const { data: metaCols } = useMetaColumns();
  const { data: tasksData } = useTasks();
  const { data: insightIdsData } = useInsightsIds();
  const { data: edits } = useEdits();
  // Prefetch edits content
  useEditsContent({});

  // Utility Hooks
  const { openModal } = useModals();

  // Store
  const { slice, split, taskType, actions, task, tab } = useParametersStore(
    (s) => ({
      slice: s.slice,
      split: s.split,
      taskType: s.taskType,
      task: s.task,
      actions: s.actions,
      tab: s.tab
    })
  );

  const { clearAndUpdateParameters, clearParameters } = actions;

  const activeFilters = useActiveFilters();
  const { isInference, isMltc, isOoc, isIc, isSS, isS2S } =
    useComputedParameters();
  const { clearSelectionState, setMlcTaskAndIds, setSimilarToIds } = useStore(
    (state) => state.actions
  );

  const isProjectMenuOpen = useGlobalStore((s) => s.isProjectMenuOpen);
  const { setIsProjectMenuOpen } = useGlobalStore((s) => s.actions);

  const { shapeSelection, setShapeSelection } = useEmbeddingsStore((s) => ({
    shapeSelection: s.shapeSelection,
    setShapeSelection: s.actions.setShapeSelection
  }));

  const isInsightsColumnVisible = useInsightsStore(
    (s) => s.isInsightsColumnVisible
  );
  const { setIsInsightColumnVisible } = useInsightsStore((s) => s.actions);

  const { alertCount } = useInsightAlerts();

  const handleToggleInsightsColumn = () => {
    if (isProjectMenuOpen) {
      setIsInsightColumnVisible(true);
      setIsProjectMenuOpen(false);
    } else {
      setIsInsightColumnVisible(!isInsightsColumnVisible);
    }
  };

  // Utility Functions
  const handleSplitChange = (selected: Splits) => {
    setShapeSelection(null);
    clearSelectionState();

    const newSplitIsInference = selected === 'inference';
    const newSplitIsTestOrValidation = ['test', 'validation'].includes(
      selected
    );

    let inferenceName = '';

    if (newSplitIsInference) {
      inferenceName = inferenceNames?.inference_names?.[0] || '';
    }

    let query: Partial<ParametersValues> = {};

    const runHasTrainingSplit = runData?.logged_splits?.includes('training');

    if (newSplitIsInference) {
      query = {
        inferenceName,
        split: selected,
        groupedBy: 'pred',
        metric: 'confidence',
        sortBy: 'confidence',
        dataframeColumns: ['pred', 'confidence'],
        sortDirection: ASC,
        comparedTo: runHasTrainingSplit ? 'training' : null
      };
    } else {
      const colsWithMeta = getFirstFiveUserLoggedMetaCols(
        metaCols?.meta,
        getDefaultColumns(taskType as TaskType),
        undefined,
        isS2S
      );

      query = {
        split: selected,
        groupedBy: 'gold',
        metric: getInitialMetric(isInference, isIc, isSS, isS2S),
        sortBy: 'data_error_potential',
        sortDirection: DESC,
        dataframeColumns: colsWithMeta ?? [],
        comparedTo: null,
        tab
      };
    }

    if (!newSplitIsTestOrValidation && isOoc) {
      query.isDrifted = false;
    }

    clearAndUpdateParameters(query, false, ['task']);
  };

  const handleTaskChange = async (value: string) => {
    if (activeFilters?.length || shapeSelection?.x?.length) {
      const { task: taskName, ids } = insightIdsData || {};

      if (taskName && ids) {
        setMlcTaskAndIds({ task: taskName, ids });
      }
    }

    setShapeSelection(null);

    clearAndUpdateParameters(
      {
        task: value,
        dataframeColumns: getDefaultColumns(taskType as TaskType)
      },
      false,
      ['split']
    );
  };

  const handleSliceChange = (id: string) => {
    if (!id) {
      return clearParameters(['split', 'dataframeColumns']);
    }

    const selectedSlice = (sliceData as Slices)?.find(
      (slice: { id: string }) => slice.id === id
    )?.logic;

    const { params } = parseApiResponseToParams(selectedSlice);

    if (selectedSlice?.similar_to?.length) {
      setSimilarToIds(selectedSlice.similar_to);
    }

    clearAndUpdateParameters({
      ...params,
      slice: id
    });
  };

  // Computed
  const splitData = SPLIT_SELECT_DATA.map((split) => {
    return {
      ...split,
      disabled: !runData?.logged_splits?.includes(split.value)
    };
  });

  const totalEditsAmount = edits?.reduce(
    (total, edit) => total + (edit?.sample_ids?.length || 0),
    0
  );

  const isFetchingNames = runIsFetching || projectIsFetching;

  return (
    <>
      <Grid data-testid='header-bar' p={10}>
        <Grid.Col className='align-self-center' span={6}>
          <Group gap={4} wrap='nowrap'>
            {!isInsightsColumnVisible && (
              <UnstyledButton
                px={8}
                py={6}
                style={{
                  display: 'flex',
                  border: '1px solid #E9E8ED',
                  borderRadius: 8
                }}
                onClick={handleToggleInsightsColumn}
              >
                <IconTelescope color='#4A2FF9' />
                <Box
                  ml={4}
                  mt={2}
                  px={6}
                  py={1}
                  style={{
                    backgroundColor: '#ED0707',
                    borderRadius: 50
                  }}
                >
                  <Text c='white' size='xs'>
                    {alertCount}
                  </Text>
                </Box>
              </UnstyledButton>
            )}

            {isFetchingNames ? (
              <Skeleton height={25} width={200} />
            ) : (
              <>
                <ProjectLockButton />
                <Text truncate color='brand' fw={700} size='sm'>
                  {projectData?.name}
                </Text>
              </>
            )}
            <Text fw={700}>/</Text>
            {isFetchingNames ? (
              <Skeleton height={25} width={200} />
            ) : (
              <Text
                truncate
                fw={700}
                maw={isInsightsColumnVisible ? 300 : 400}
                size='sm'
              >
                {runData?.name}
              </Text>
            )}
          </Group>
        </Grid.Col>

        <Grid.Col span={6}>
          <Group gap='xs' justify='flex-end' wrap='nowrap'>
            {isMltc && (
              <Select
                searchable
                data={tasksData?.tasks ?? []}
                leftSection={<IconClipboardList size={18} />}
                size='xs'
                value={task}
                onChange={(val) => handleTaskChange(val as string)}
              />
            )}

            <Box className='align-center no-wrap'>
              {isInference && <InferenceSelects />}

              <Select
                searchable
                data={splitData ?? []}
                leftSection={<IconDatabase size={18} />}
                size='xs'
                value={split}
                onChange={(val) => handleSplitChange(val as Splits)}
              />
            </Box>

            <Select
              searchable
              data={
                (sliceData as Slices)?.map((data) => ({
                  label: data.name,
                  value: data.id
                })) ?? []
              }
              disabled={!(sliceData as Slices)?.length}
              leftSection={<IconChartPie size={18} />}
              placeholder='Slices'
              size='xs'
              value={slice}
              onChange={(val) => handleSliceChange(val as string)}
            />

            <NavActionIcon
              color='white'
              icon={<IconDownload color='black' size={18} />}
              label='Export'
              position='bottom'
              size='lg'
              variant='subtle'
              onClick={() => openModal(Modals.EXPORTS)}
            />

            <Indicator
              withBorder
              color='red'
              disabled={!totalEditsAmount}
              label={toHumanReadableNumber(totalEditsAmount, { fallback: 0 })}
              mr='xs'
              size={18}
            >
              <NavActionIcon
                color='brand'
                icon={<IconShoppingCart size={18} />}
                label={isInference ? 'Labels Cart' : 'Edits Cart'}
                position='bottom'
                size='lg'
                variant='filled'
                onClick={() => openModal(Modals.EDITS_CART)}
              />
            </Indicator>
          </Group>
        </Grid.Col>
      </Grid>

      <Divider color='contrast.7' />
    </>
  );
};

export default HeaderBar;
