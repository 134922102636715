import { useComputedParameters } from '@/core/stores/parameters-store';
import { getProcDataMetrics } from '@/fine-tune/data-parsers/get-proc-data-metrics/get-proc-data-metrics';
import useStore from '@/fine-tune/stores/store';
import { getTotalSamplesIds } from '@/fine-tune/utils/get-total-samples-ids/get-total-samples-ids';

import { useInsightsRows } from '../query-hooks/use-insights-rows/use-insights-rows';
import { usePercentageSample } from '../query-hooks/use-percentage-sample/use-percentage-sample';

export const useSelectedSamplesAmount = (rowId?: number) => {
  const isNer = useComputedParameters('isNer');
  const selectedRows = useStore((state) => state.selectedRows);
  const allRowsSelected = useStore((state) => state.allRowsSelected);
  const selectedSpans = useStore((state) => state.selectedSpans);

  const insightsRows = useInsightsRows() || {};
  // @ts-expect-error - FIXME
  const { data } = getProcDataMetrics(insightsRows);
  const sample = usePercentageSample();

  const { sample_count, span_count, percentage, span_percentage } =
    sample?.data ?? {
      sample_count: 0,
      span_count: 0,
      percentage: 0,
      span_percentage: 0
    };

  const getSelectedCount = () => {
    const fullCount = isNer ? span_count : sample_count;
    const totalSelectedRows = getTotalSamplesIds(
      data || [],
      rowId ? [rowId] : selectedRows,
      selectedSpans.map(({ id }: { id: number }) => id)
    )?.length;

    if (!totalSelectedRows) {
      return fullCount;
    } else {
      return allRowsSelected
        ? (fullCount as number) - totalSelectedRows
        : totalSelectedRows;
    }
  };

  const getTotalCount = () =>
    isNer
      ? (span_count as number) / (span_percentage as number)
      : sample_count / percentage;

  return { getSelectedCount, getTotalCount };
};
