// Dev
export const DEV_DOMAIN = 'console.dev.rungalileo.io';
export const ENT_DEV_DOMAIN = 'console.entdev.rungalileo.io';
export const LOCAL_DOMAIN = 'localhost';
export const ENTERPRISE_DOMAIN = 'console.enterprise.rungalileo.io'; // Old preprod, CI env

// Staging
export const STAGING_DOMAIN = 'console.staging.rungalileo.io';

// Preview
export const PREVIEW_DOMAIN = '-galileo-ui.netlify.app';

// Prod
export const SPECTRUM_DOMAIN = 'console.galileo.proto.getspectrum.io';
export const UNIPHORE_DOMAIN = 'console.uniphore.rungalileo.io';

// Sandbox
export const SANDBOX_DOMAIN = 'console.sandbox.rungalileo.io';
