import { Button, ButtonProps, CopyButton, Text } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

interface HoverCopyButtonProps extends ButtonProps {
  copyText?: string;
  copiedText?: string;
  copyIcon?: JSX.Element;
  copiedIcon?: JSX.Element;
  value: string;
  isVisible: boolean;
}

export const HoverCopyButton = ({
  copyText = 'Copy',
  copiedText = 'Copied',
  copyIcon = <IconCopy size={14} />,
  copiedIcon = <IconCheck size={14} />,
  value,
  isVisible,
  ...others
}: HoverCopyButtonProps) => {
  // Computed
  const label = (copied: boolean) => (copied ? copiedText : copyText);
  const icon = (copied: boolean) => (copied ? copiedIcon : copyIcon);

  return (
    <CopyButton value={value}>
      {({ copied, copy }) => (
        <Button
          data-testid='copy-button'
          h='1.5rem'
          leftSection={icon(copied)}
          style={{
            transition: '.15s ease-in-out',
            visibility: isVisible ? 'visible' : 'hidden',
            zIndex: Z_INDEX.TOOLTIPS
          }}
          variant='unstyled'
          onClick={copy}
          {...others}
        >
          <Text size='xs'>{label(copied)}</Text>
        </Button>
      )}
    </CopyButton>
  );
};
