import { Modals } from '@/core/hooks/use-modals/use-modals';
import InviteUserModal from '@/core/modals/invite-user-modal/invite-user-modal';
import KeyboardShortcutsModal from '@/core/modals/keyboard-shortcuts-modal/keyboard-shortcuts-modal';
import ResetPasswordModal from '@/core/modals/reset-password-modal/reset-password-modal';
import ShareProjectModal from '@/core/modals/share-project-modal/share-project-modal';

import AWSIntegrationsModal from './aws-integrations-modal/aws-integrations-modal';
import CreateAPIKeyModal from './create-api-key-modal/create-api-key-modal';
import IntegrationsModal from './integrations-modal/integrations-modal';
import TransferUserDataModal from './transfer-user-data-modal/transfer-user-data-modal';

/**
 * List definition for modal id & modal components to be passed to ModalProvider
 * Must live outside useModals - will cause circular dep issue in tests if in useModals 😭
 */
export const modals = {
  [Modals.AWS_INTEGRATIONS]: AWSIntegrationsModal,
  [Modals.INTEGRATIONS]: IntegrationsModal,
  [Modals.INVITE_USER]: InviteUserModal,
  [Modals.KEYBOARD_SHORTCUTS]: KeyboardShortcutsModal,
  [Modals.CREATE_API_KEY]: CreateAPIKeyModal,
  [Modals.RESET_PASSWORD]: ResetPasswordModal,
  [Modals.SHARE_PROJECT]: ShareProjectModal,
  [Modals.TRANSFER_USER_DATA]: TransferUserDataModal
};
