import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { USERS_KEY } from '../use-users/use-users';
export const DELETE_USER = '/users/{user_id}';

import { defaultErrorHandler } from '@/core/api/utils';

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const handleDeleteUser = (userId: string) => {
    return api.DELETE(DELETE_USER, {
      params: {
        path: {
          user_id: userId
        }
      }
    });
  };

  const deleteUser = useMutation(handleDeleteUser, {
    onSuccess: (_, userId) => {
      queryClient.setQueryData([USERS_KEY], (oldData?: any[]) => {
        return oldData?.filter((user) => user.id !== userId);
      });

      showNotification({
        title: 'Success!',
        message: 'User successfully removed',
        type: 'success'
      });
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error deleting user')
  });

  return deleteUser;
};
