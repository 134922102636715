import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { components } from '@/core/types/api';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import { FilterParams } from '@/fine-tune/types/query.types';

import { useFilterParams } from '../../use-filters-params/use-filter-params';

const POST_INSIGHTS_IDS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/ids';

export type InsightsIds = components['schemas']['SummaryIDResponse'];
interface Body {
  include_emb: boolean;
  hard_easy_threshold: boolean;
  filter_params: Partial<FilterParams>;
}

export const SUMMARY_IDS = 'SUMMARY_IDS';

export const useInsightsIds = () => {
  const { runId, projectId } = usePathParameters();

  const { split, sortBy, sortDirection, task, inferenceName } =
    useParametersStore((s) => ({
      split: s.split,
      sortBy: s.sortBy,
      sortDirection: s.sortDirection,
      task: s.task,
      inferenceName: s.inferenceName
    }));
  const isPrompt = useComputedParameters('isPrompt');

  const filterParams = useFilterParams();

  const shapeSelection = useEmbeddingsStore((state) => state.shapeSelection);
  const setShapeIds = useEmbeddingsStore((state) => state.actions.setShapeIds);

  const body: Body = {
    include_emb: false,
    hard_easy_threshold: false,
    filter_params: filterParams
  };

  const fetchInsightsIds = async () => {
    const res = await api.POST(POST_INSIGHTS_IDS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body
    });

    return res?.data;
  };

  const response = useQuery(
    [
      SUMMARY_IDS,
      {
        inferenceName,
        projectId,
        runId,
        shapeSelection,
        sortBy,
        sortDirection,
        split,
        task,
        ...filterParams
      }
    ],
    () =>
      fetchInsightsIds().then((res) => {
        setShapeIds(res?.ids || []);
        return res;
      }),
    {
      enabled: Boolean(projectId && runId && !isPrompt)
    }
  );

  return response;
};
