import {
  Box,
  MantineStyleProp,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core';

import { useComputedParameters } from '@/core/stores/parameters-store';
import { useColors } from '@/fine-tune/hooks/use-colors/use-colors';
import { PointRow } from '@/fine-tune/types/embeddings.types';
import { InsightsRow } from '@/fine-tune/types/query.types';

import DataNotAvailable from '../../../core/components/atoms/accessibility/data-not-available/data-not-available';

interface DepOrConfidenceProps {
  color?: string;
  component?: 'span' | 'div';
  sample: Partial<PointRow | InsightsRow>;
  variant?: 'filled';
  accessor?: 'confidence' | 'data_error_potential';
}

const DepOrConfidence = ({
  accessor,
  sample,
  color = 'dark',
  component = 'span',
  variant
}: DepOrConfidenceProps) => {
  const { data_error_potential, confidence } = sample || {};

  // Hooks
  const { getDepOrConfidenceColor } = useColors();
  const theme = useMantineTheme();

  // Computed
  const isDep = !accessor || accessor === 'data_error_potential';
  const { isInference, isOd } = useComputedParameters();

  let depOrConfLabel = isInference ? 'Confidence ' : 'DEP ';
  let depOrConfValue = isInference ? confidence : data_error_potential;

  let depOrConfColor = getDepOrConfidenceColor(depOrConfValue);
  let depOrConfBackground = theme?.colors?.[depOrConfColor]?.[0] || 'white';

  let tooltip;

  if (accessor) {
    depOrConfLabel = isDep ? 'DEP ' : 'Confidence ';
    depOrConfValue = sample?.[accessor];
  }

  if (isDep && isOd) {
    tooltip =
      'Data Error Potential (DEP) is a score measuring the misfit of the sample to the model. A higher score indicates a higher likelihood of errors such as erroneous annotations, noisy/corrupt samples, out-of-distribution samples, etc. In object detection, DEP is calculated for each object in an image and then combined for the entire image, taking into account filters.';
  }

  if (!isDep && isOd) {
    depOrConfColor = 'black';
    depOrConfBackground = 'white';
  }

  const filledStyle: MantineStyleProp = {
    background: depOrConfBackground,
    borderRadius: 5,
    fontWeight: 700,
    padding: 5,
    textAlign: 'center'
  };

  const DepOrConfidenceEl = (
    <Box style={variant === 'filled' ? filledStyle : {}}>
      <Text c={color} size='sm'>
        {depOrConfLabel}
        <Text c={depOrConfColor} component={component} fw={400}>
          {depOrConfValue?.toFixed(3) ?? <DataNotAvailable />}
        </Text>
      </Text>
    </Box>
  );

  if (tooltip) {
    return (
      <Tooltip multiline withinPortal label={tooltip} w={300} zIndex={9999}>
        {DepOrConfidenceEl}
      </Tooltip>
    );
  }

  return DepOrConfidenceEl;
};

export default DepOrConfidence;
