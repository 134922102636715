import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';

export const GET_EDIT = '/edits/{edit_id}/content';

/**
 * useEditById
 */
export const useEditById = (
  editId: string,
  searchTerm?: string,
  isRegex?: boolean
) => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  const fetchEditById = async () => {
    const res = await api.GET(GET_EDIT, {
      params: {
        path: { edit_id: editId },
        query: {
          text_pat: searchTerm,
          regex: isRegex
        }
      }
    });

    return res.data;
  };

  const response = useQuery(
    [GET_EDIT, { projectId, runId, split, editId, searchTerm, isRegex }],
    fetchEditById,
    {
      enabled: Boolean(editId)
    }
  );

  return response;
};
