import { Group, MantineSize, Text } from '@mantine/core';
import React from 'react';

import { useUserById } from '@/core/hooks/query-hooks/use-user-by-id/use-user-by-id';

import Avatar from '../avatar/avatar';
import SkeletonLoader from '../skeleton-loader/skeleton-loader';

interface UserNameProps {
  id: string;
  size?: MantineSize;
  spacing?: string | number;
}

const UserName = ({ id, size = 'sm', spacing = 'xs' }: UserNameProps) => {
  const { data, isLoading } = useUserById(id);

  const { first_name, last_name, email } = data || {};

  const name = first_name && last_name ? `${first_name} ${last_name}` : email;

  if (isLoading) {
    return (
      <Group gap={spacing} wrap='nowrap'>
        <SkeletonLoader
          height={size || 14}
          length={1}
          variant='circle'
          width={size || 14}
        />
        <SkeletonLoader height={14} length={1} width={75} />
      </Group>
    );
  }

  return (
    <Group gap={spacing} wrap='nowrap'>
      <Avatar isLoading={isLoading} name={name || ''} size={size} />
      <Text component='span' size='sm' style={{ whiteSpace: 'nowrap' }}>
        {name}
      </Text>
    </Group>
  );
};

export default UserName;
