import { Center, Group, Image, Text } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import ClampedLine from '@/core/components/atoms/clamped-line/clamped-line';
import HighlightWithReplace from '@/core/components/atoms/highlight-with-replace/highlight-with-replace';
import { useParametersStore } from '@/core/stores/parameters-store';
import { components } from '@/core/types/api';
import GoldSpanHighlighter, {
  GoldSpanHighlighterData
} from '@/fine-tune/components/gold-span-highlighter/gold-span-highlighter';
import Label from '@/fine-tune/components/label/label';
import ShiftSpanHighlighter from '@/fine-tune/components/shift-span-highlighter/shift-span-highlighter';
import SpanHighlight from '@/fine-tune/components/span-highlight/span-highlight';
import { INPUT_COLUMN } from '@/fine-tune/constants/base-columns.constants';
import { EDIT_ACTIONS } from '@/fine-tune/constants/data-edits.constants';
import { EditWithContent } from '@/fine-tune/types/response.types';
import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';
import { Column } from '@/fine-tune/types/table.types';

export interface NerColumnData {
  id: number;
  gold: string;
  pred: string;
  text: string;
  meta: null;
  spans: SpanHighlightData[];
  new_label: string;
}

export const CV_COLUMNS = () => [
  {
    label: <Text fw={600}>Sample</Text>,
    accessor: 'image',
    width: 175,
    cell: ({ value }: { value: number | string }) => (
      <Center
        style={{
          borderRadius: 85,
          margin: 'auto',
          width: 150
        }}
      >
        <Image
          alt='Image classification sample'
          radius='md'
          role='img'
          src={value.toString()}
        />
      </Center>
    ),
    isSticky: true
  }
];

export const CREATE_LABEL_COLUMN = (detail: EditWithContent) => ({
  label: 'Assigned Label',
  accessor: '',
  width: 200,
  cell: ({ data }: { data: { pred: string } }) => {
    const value = detail?.new_label ?? data?.pred;
    if (!value) {
      return <DataNotAvailable />;
    }
    return <Label labelName={value} size='sm' />;
  }
});

export const NER_COLUMNS = (isRelabelAction: boolean) => [
  {
    label: 'Ground Truth',
    accessor: 'gold',
    width: 420,
    cell: ({ data }: { data: NerColumnData }) => {
      return (
        <SpanHighlight
          allowSelection={false}
          columnId='gold'
          newLabel={data?.new_label || ''}
          spans={data.spans}
          text={data.text}
        />
      );
    }
  },
  ...(isRelabelAction
    ? []
    : [
        {
          label: 'Predicted',
          accessor: 'pred',
          width: 420,
          cell: ({ data }: { data: NerColumnData }) => {
            return (
              <SpanHighlight
                allowSelection={false}
                columnId='pred'
                newLabel={data?.new_label || ''}
                spans={data.spans}
                text={data.text}
              />
            );
          }
        }
      ])
];

const TARGET_OUTPUT_COLUMN = {
  label: 'Target Output',
  accessor: 'target',
  width: 350,
  maxWidth: 450,
  cell: ({ value }: { value: number | string }) => (
    <Text fw={500} size='sm'>
      {value}
    </Text>
  )
};

const S2S_COLUMNS = (hasGeneratedOutput: boolean) => [
  { ...INPUT_COLUMN, isSticky: false },
  { ...TARGET_OUTPUT_COLUMN, width: hasGeneratedOutput ? 350 : 450 },
  ...(hasGeneratedOutput
    ? [
        {
          label: 'Generated Output',
          accessor: 'generated_output',
          width: 350,
          maxWidth: 350,
          cell: ({ value }: { value: number | string }) => (
            <Text fw={500} size='sm'>
              {value}
            </Text>
          )
        }
      ]
    : [])
];

export const NER_RELABEL_COLUMN = (
  detail: EditWithContent,
  width: number | string = 420
) => ({
  label: 'Predicted',
  accessor: 'pred',
  width,
  cell: ({ data }: { data: NerColumnData }) => {
    return (
      <SpanHighlight
        allowSelection={false}
        columnId='pred'
        newLabel={detail?.new_label || ''}
        spans={data.spans}
        text={data.text}
      />
    );
  }
});

export const ID_COLUMN = {
  label: 'Id',
  accessor: 'id',
  width: 50,
  cell: ({ value }: { value: number | string }) => (
    <Text fw={500} size='sm'>
      {value}
    </Text>
  )
};

export const TC_COLUMNS = (isRelabelAction: boolean) => [
  {
    label: <Text fw={600}>Sample</Text>,
    accessor: 'text',
    width: isRelabelAction ? 450 : 500,
    cell: ({ value }: { value: number | string }) => (
      <ClampedLine size='sm' text={value?.toString()} />
    ),
    isSticky: true
  }
];

export const TC_GOLD_COL = (
  detail: EditWithContent,
  isRelabelAction: boolean
) => {
  return {
    label: isRelabelAction ? (
      <div className='text-center'>
        <Text fw={600} size='sm'>
          GROUND TRUTH
        </Text>
        <Group justify='space-between'>
          <Text component='span' ml={12} size='xs'>
            From
          </Text>
          <IconArrowNarrowRight size={16} />
          <Text component='span' mr={12} size='xs'>
            To
          </Text>
        </Group>
      </div>
    ) : (
      <Text fw={600}>Ground Truth</Text>
    ),
    accessor: 'gold',
    width: isRelabelAction ? 400 : 250,
    cell: ({
      value,
      data
    }: {
      value: string | number;
      data: { pred: string };
    }) => (
      <Group
        justify={isRelabelAction ? 'space-between' : 'center'}
        style={{ flexWrap: 'inherit' }}
      >
        <Label labelName={value as string} size='sm' />
        {isRelabelAction && (
          <Label labelName={detail?.new_label || data.pred} size='sm' />
        )}
      </Group>
    )
  };
};

export const TC_PRED_COL = (isRelabelAction: boolean) => ({
  label: (
    <div className='text-center'>
      <Text fw={600}>Predicted</Text>
    </div>
  ),
  width: isRelabelAction ? 200 : 250,
  accessor: 'pred',
  cell: ({ value }: { value: number | string }) => (
    <Label labelName={value.toString()} size='sm' />
  )
});

export const UPDATE_TEXT_COL = (
  searchTerm: string,
  replaceTerm: string,
  isS2S: boolean
) => ({
  label: (
    <div className='text-center'>
      <Text fw={600}>{isS2S ? 'Edited' : 'SAMPLE'}</Text>
    </div>
  ),
  accessor: 'text',
  width: '100%',
  cell: ({ value }: { value: number | string }) =>
    isS2S ? (
      <Text fw={500} size='sm'>
        {replaceTerm}
      </Text>
    ) : (
      <HighlightWithReplace
        find={searchTerm}
        fullText={value as string}
        replace={replaceTerm}
      />
    )
});

export const SHIFT_SPAN_COL = (
  searchString: string,
  shiftStart: number,
  shiftEnd: number
) => ({
  label: (
    <div className='text-center'>
      <Text fw={600}>SAMPLE</Text>
    </div>
  ),
  accessor: 'text',
  width: 700,
  cell: ({ data }: { data: GoldSpanHighlighterData }) => (
    <ShiftSpanHighlighter
      data={data}
      searchTerm={searchString}
      shiftEnd={shiftEnd}
      shiftStart={shiftStart}
    />
  )
});

export const ADD_SPAN_COL = (newLabel: string, searchString: string) => ({
  label: (
    <div className='text-center'>
      <Text fw={600}>SAMPLE</Text>
    </div>
  ),
  accessor: 'text',
  width: '100%',
  cell: ({ data }: { data: components['schemas']['DataRow'] }) => (
    <GoldSpanHighlighter
      changeLabelTo={newLabel ?? ''}
      data={data}
      searchTerm={searchString}
    />
  )
});

export const getColumns = (detail: EditWithContent | undefined) => {
  if (!detail) return [];

  const { computed } = useParametersStore.getState();
  const { isIc, isNer, isInference, isInferenceNer, isS2S } = computed;
  const {
    edit_action,
    new_label,
    search_string,
    text_replacement,
    shift_span_end_num_words,
    shift_span_start_num_words
  } = detail || {};

  const _isS2S = isS2S();

  switch (edit_action) {
    case EDIT_ACTIONS.UPDATE_TEXT:
      return [
        ID_COLUMN,
        ...(_isS2S ? [TARGET_OUTPUT_COLUMN] : []),
        UPDATE_TEXT_COL(search_string || '', text_replacement || '', _isS2S)
      ];
    case EDIT_ACTIONS.ADD_SPAN:
      return [ID_COLUMN, ADD_SPAN_COL(new_label || '', search_string || '')];
    case EDIT_ACTIONS.SHIFT_SPAN:
      return [
        ID_COLUMN,
        SHIFT_SPAN_COL(
          search_string || '',
          shift_span_start_num_words || 0,
          shift_span_end_num_words || 0
        )
      ];

    default:
      break;
  }
  const isRelabelAction = [
    EDIT_ACTIONS.CREATE_NEW_LABEL,
    EDIT_ACTIONS.RELABEL,
    EDIT_ACTIONS.RELABEL_AS_PRED
  ].includes(edit_action);

  let columns: Column[] = [ID_COLUMN, ...TC_COLUMNS(isRelabelAction)];

  if (isNer()) {
    columns = NER_COLUMNS(isRelabelAction);
  }

  if (isS2S()) {
    const hasGeneratedOutput = Boolean(detail.content?.[0]?.generated_output);
    return S2S_COLUMNS(hasGeneratedOutput);
  }

  if (isIc()) {
    columns = CV_COLUMNS();
  }

  if (isInference() && !isNer() && isRelabelAction) {
    columns.push(CREATE_LABEL_COLUMN(detail));
  }

  if (isInferenceNer()) {
    return [NER_RELABEL_COLUMN(detail, '100%')];
  }

  if (isNer()) {
    isRelabelAction && columns.push(NER_RELABEL_COLUMN(detail));
  } else {
    if (!isInference()) {
      columns.push(TC_GOLD_COL(detail, isRelabelAction));
    }
    columns.push(TC_PRED_COL(isRelabelAction));
  }

  return columns;
};
