import { Box, useMantineTheme } from '@mantine/core';
import { useRouter } from 'next/router';

import NavBar from '@/core/components/organisms/nav-bar/nav-bar';
import { Paths } from '@/core/constants/routes.constants';
import { useOnMount } from '@/core/hooks/use-on-mount/use-on-mount';
import HeaderBar from '@/fine-tune/components/header-bar/header-bar';
import InsightsColumn from '@/fine-tune/components/insights-column/insights-column';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import { buildBucketRanges } from '@/fine-tune/utils/build-bucket-ranges/build-bucket-ranges';

interface InsightsPageLayoutProps {
  children: React.ReactNode;
}

const ROUTES_WITH_INSIGHTS_COLUMN = [Paths.INSIGHTS];
const ROUTES_WITHOUT_HEADER_BAR = [
  Paths.COMMAND_CENTER,
  Paths.NEW_PROJECT,
  Paths.CREATE_NEW_PROJECT,
  Paths.INTEGRATIONS,
  Paths.GET_TOKEN,
  Paths.COMPARE_RUNS,
  Paths.ROOT,
  Paths.CREATE_NEW_RUN,
  Paths.API_KEYS
];

/**
 * InsightsPageLayout
 *
 *
 *
 * @returns {React.Component} InsightsPageLayout
 */
const InsightsPageLayout = ({ children }: InsightsPageLayoutProps) => {
  const router = useRouter();

  const isInsightsVisible = Boolean(
    ROUTES_WITH_INSIGHTS_COLUMN.find((path) => path === router?.pathname)
  );

  const isHeaderBarVisible = Boolean(
    !ROUTES_WITHOUT_HEADER_BAR.find((path) => path === router?.pathname)
  );

  // initialize color bucket range for all insights tabs
  const theme = useMantineTheme();
  const setBucketRange = useEmbeddingsStore((s) => s.actions.setBucketRange);
  useOnMount(() => {
    if (theme.colors) {
      const buckets = buildBucketRanges(theme.colors);
      setBucketRange(buckets);
    }
  });

  return (
    <Box
      className='flex-column'
      data-testid='insights-page-layout'
      h='100vh'
      mah='100vh'
      maw='100vw'
    >
      <Box className='flex' h='100vh' maw='100vw'>
        <NavBar />

        {isInsightsVisible && <InsightsColumn />}

        <Box className='flex-column flex-1' px={0}>
          {isHeaderBarVisible && <HeaderBar />}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default InsightsPageLayout;
