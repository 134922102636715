import {
  Badge,
  Box,
  Card,
  Collapse,
  ColorSwatch,
  Group,
  ScrollArea,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBulb, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import _ from 'lodash';
import { MouseEventHandler, useEffect } from 'react';

import { INSIGHTS_HIDDEN_FILTERS } from '@/core/constants/query-params.constants';
import {
  useActiveFilters,
  useComputedParameters,
  useParametersStoreActions
} from '@/core/stores/parameters-store';
import { useParametersStore } from '@/core/stores/parameters-store/parameters.store';
import { ParametersValues } from '@/core/stores/parameters-store/parameters.store.types';
import Button from '@/fine-tune/components/button/button';
import { parseApiResponseToParams } from '@/fine-tune/data-parsers/parse-api-response-to-params/parse-api-response-to-params';
import { InsightsAlertResponse } from '@/fine-tune/hooks/query-hooks/use-insights-alerts/use-insights-alerts';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import { useInsightsStore } from '@/fine-tune/stores/insights-store/insights.store';
import { parsePythonString } from '@/fine-tune/utils/parse-python-string/parse-python-string';

interface InsightsCardProps {
  insight: InsightsAlertResponse;
  withClose?: boolean;
}

const COMMON_PRESERVE_KEYS: (keyof ParametersValues)[] = [
  'split',
  'task',
  'inferenceName',
  'groupedBy',
  'sortBy',
  'comparedTo',
  'mapThreshold'
];

/**
 * InsightsCard
 *
 *
 *
 * @returns {React.Component} InsightsCard
 */
const InsightsCard = ({ insight, withClose }: InsightsCardProps) => {
  // Insights Store
  const {
    setDisabledInsightsCards,
    setActiveInsightsCard,
    activeInsightsCard,
    disabledInsightsCards
  } = useInsightsStore((s) => ({
    setDisabledInsightsCards: s.actions.setDisabledInsightsCards,
    setActiveInsightsCard: s.actions.setActiveInsightsCard,
    activeInsightsCard: s.activeInsightsCard,
    disabledInsightsCards: s.disabledInsightsCards
  }));
  const dataframeColumns = useParametersStore(
    (state) => state.dataframeColumns
  );

  const { isInference } = useComputedParameters();

  // Parameters Store
  const { setParameters, clearParameters } = useParametersStoreActions();
  const { defaultValues } = useComputedParameters();

  // Embeddings Store
  const updateEmbeddings = useEmbeddingsStore(
    (s) => s.actions.updateEmbeddingsStore
  );

  // Hooks
  const activeFilters = useActiveFilters(true);
  const theme = useMantineTheme();
  const [opened, { toggle }] = useDisclosure(Boolean(withClose));

  // Computed values
  // TODO: Clean this up. This fixes a bug where hidden filters were causing alerts cards to gray out
  const filteredLogic = Object.keys(activeInsightsCard?.logic || {})
    .filter(
      (key) =>
        !INSIGHTS_HIDDEN_FILTERS.includes(key) || key === 'likelyMislabelled'
    )
    .reduce((obj, k) => {
      // @ts-expect-error FIXME:
      let value = activeInsightsCard?.logic[k];
      let key = k;
      if (key === 'likelyMislabelled') {
        key = 'lmPercentile';
        value = 100;
      }

      return { ...obj, [key]: value };
    }, {});

  const isInitialLogic = _.isEqual(activeFilters, filteredLogic);

  const isInsightsCardDisabled = disabledInsightsCards?.includes(insight?.id);

  const backgroundColor = isInsightsCardDisabled
    ? theme.colors.contrast[8]
    : theme.colors.contrast[9];
  const color = insight?.importance === 'low' ? 'yellow' : 'red';

  const indicatorColors = theme.colors[color][5];

  // Effects
  useEffect(() => {
    if (!activeInsightsCard) {
      return;
    }

    if (isInitialLogic && disabledInsightsCards.length) {
      setDisabledInsightsCards([]);
    }

    if (
      !isInitialLogic &&
      !disabledInsightsCards?.includes(activeInsightsCard?.id)
    ) {
      setDisabledInsightsCards([activeInsightsCard?.id]);
    }
  }, [isInitialLogic]);

  // Event Handlers
  const handleApplyInsight = () => {
    if (insight?.logic) {
      const { params, embeddings } = parseApiResponseToParams(insight?.logic);

      const truthyValues = _.pickBy(
        { ...params, ...embeddings },
        (value, key) => {
          const isTruthy = Array.isArray(value) ? value.length : Boolean(value);

          if (isTruthy) {
            // @ts-expect-error FIXME:
            return defaultValues[key] !== value;
          }
        }
      ) as Partial<ParametersValues>;

      const preserveKeys = [...COMMON_PRESERVE_KEYS];
      if (truthyValues.isDrifted || truthyValues.isOnTheBoundary) {
        preserveKeys.push('metric');
      }

      clearParameters(preserveKeys);
      setDisabledInsightsCards([]);
      setActiveInsightsCard({ id: insight?.id, logic: truthyValues });

      if (truthyValues) {
        if (truthyValues.isDrifted) {
          truthyValues.dataframeColumns = ['drift_score', ...dataframeColumns];
        }
        setParameters(truthyValues);
      }

      if (embeddings) {
        updateEmbeddings(embeddings);
      }
    }
  };

  const handleCloseInsight: MouseEventHandler = (e) => {
    e.stopPropagation();
    const preserveKeys = [...COMMON_PRESERVE_KEYS];
    if (isInference) {
      preserveKeys.push('comparedTo', 'metric');
    }
    clearParameters(preserveKeys);
    setActiveInsightsCard(null);
    setDisabledInsightsCards([]);
  };

  const handleCollapse: MouseEventHandler = (e) => {
    e?.stopPropagation();
    toggle();
  };

  return (
    <Card
      withBorder
      data-testid='insights-card'
      mb='xs'
      radius='md'
      shadow='sm'
      style={{
        borderColor: 'var(--mantine-color-contrast-7)',
        cursor: 'pointer',
        backgroundColor,
        opacity: isInsightsCardDisabled ? 0.75 : 1
      }}
      onClick={handleApplyInsight}
    >
      <Group wrap='nowrap'>
        <Box className='align-center w-90'>
          <ColorSwatch
            color={indicatorColors}
            h={24}
            mr={4}
            radius='xs'
            size={4}
          />

          {insight.pct_samples && (
            <Badge
              color={`${color}.2`}
              h={24}
              mr={4}
              p={4}
              radius='xs'
              size='md'
              style={{
                backgroundColor: theme.colors[color][2]
              }}
            >
              <Text c={`${color}.8`} fw={700} size='sm'>
                {insight.pct_samples?.toFixed(2)}%
              </Text>
            </Badge>
          )}

          <Text c='contrast.2' fw={700} lineClamp={1} size='sm'>
            {insight.xray_classname}
          </Text>
        </Box>

        <Box
          style={{
            position: 'absolute',
            right: 4,
            top: 15
          }}
        >
          <Tooltip
            multiline
            withArrow
            withinPortal
            label={insight?.suggestion}
            maw={250}
          >
            <ThemeIcon color='gray' mr={0} size='sm' variant='light'>
              <IconBulb />
            </ThemeIcon>
          </Tooltip>
          <UnstyledButton color='gray' mr={4} onClick={handleCollapse}>
            {opened ? (
              <IconChevronUp color='gray' size={20} />
            ) : (
              <IconChevronDown color='gray' size={20} />
            )}
          </UnstyledButton>
        </Box>
      </Group>
      <Collapse in={opened}>
        <ScrollArea.Autosize mah={250}>
          <Box
            ml='xs'
            my='sm'
            style={{
              display: 'inline-block',
              fontSize: 14,
              color: '#706C89'
            }}
          >
            {parsePythonString(insight.message ?? '', insight.message_fields)}
          </Box>
        </ScrollArea.Autosize>
      </Collapse>

      {withClose && (
        <Button
          copy='Close'
          mt={6}
          variant='white'
          onClick={handleCloseInsight}
        />
      )}
    </Card>
  );
};

export default InsightsCard;
