import { ScrollArea } from '@mantine/core';

import InsightsCard from '@/fine-tune/components/insights-card/insights-card';
import { useInsightAlerts } from '@/fine-tune/hooks/query-hooks/use-insights-alerts/use-insights-alerts';
import { useInsightsColumnHeight } from '@/fine-tune/hooks/use-element-height/use-element-height';

/**
 * AlertColumnContent
 *
 *
 *
 * @returns {React.Component} AlertColumnContent
 */
const AlertColumnContent = () => {
  const { data: insightsAlertData } = useInsightAlerts();

  const maxHeight = useInsightsColumnHeight();

  return (
    <ScrollArea.Autosize mah={maxHeight}>
      {insightsAlertData?.map((insight) => (
        <InsightsCard insight={insight} key={insight.id} />
      ))}
    </ScrollArea.Autosize>
  );
};

export default AlertColumnContent;
