import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';

interface GetTopFiveMisclassifiedArgs {
  top_misclassified_pairs: {
    classes: [string, string];
    count: number;
  }[];
  top_misclassified_pairs_percentage: number;
}

/**
 * getTopFiveMisclassified
 *
 * Aux function to structure the data to build the Top five misclassified label pairs
 *
 */
export const getTopFiveMisclassified = (
  splitRunResults: GetTopFiveMisclassifiedArgs
) => {
  const { top_misclassified_pairs, top_misclassified_pairs_percentage } =
    splitRunResults || {};

  if (!Array.isArray(top_misclassified_pairs)) {
    return {};
  }

  // TODO: Work with API team to update API res to return friendlier schema
  const rows = top_misclassified_pairs?.slice(0, 5);
  const overall = top_misclassified_pairs?.reduce(
    (sum, { count }) => sum + count,
    0
  );

  return {
    rows,
    overall,
    percentage: parsePercentage(top_misclassified_pairs_percentage)
  };
};
