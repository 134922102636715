import { Box, Group, Text, Tooltip, UnstyledButton } from '@mantine/core';
import _uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';

import { useParametersStoreActions } from '@/core/stores/parameters-store';
import OdLabelBadge from '@/fine-tune/components/label-badge/od-label-badge/od-label-badge';
import useStore from '@/fine-tune/stores/store';
import { SemanticRow } from '@/fine-tune/types/query.types';

interface LabelsWithAmounts {
  [key: string]: [number, number];
}

export const getLabelsFromSample = (
  sample: SemanticRow,
  hideFilteredPolygons?: boolean
): LabelsWithAmounts => {
  return sample?.polygons
    ?.filter((pol) => pol.is_active || !hideFilteredPolygons)
    .reduce((labels, polygon) => {
      const label = polygon.gold || polygon.pred;
      const isGold = Boolean(polygon.gold);
      if (!labels[label]) {
        labels[label] = [Number(isGold), Number(!isGold)];
      } else {
        labels[label][Number(!isGold)] += 1;
      }
      return labels;
    }, {} as LabelsWithAmounts);
};

export const useLabelsToggle = (
  sample: SemanticRow,
  errors: string[] = [],
  isBadge?: boolean,
  hideFilteredPolygons?: boolean
) => {
  const [hiddenLabels, setHiddenLabels] = useState<string[]>([]);
  const [hiddenErrors, setHiddenErrors] = useState<string[]>([]);

  const colorMap = useStore((state) => state.colorMap);
  const { setParameters } = useParametersStoreActions();

  useEffect(() => {
    setHiddenLabels([]);
    setHiddenErrors([]);
  }, [sample?.id]);

  const sampleErrors = errors.filter((key) => {
    return Boolean(sample?.polygons.find((pol) => pol.error_type === key));
  });

  const labelsWithAmounts =
    getLabelsFromSample(sample, hideFilteredPolygons) || {};

  const labels = Object.keys(labelsWithAmounts);

  const toggleLabel = (label: string) => {
    // Always reset hidden errors on object click
    setHiddenErrors([]);
    // Click on an object and none or all are hidden, only show that object
    if (!hiddenLabels.length) {
      if (labels.length === 1) {
        setHiddenLabels([label]);
      } else {
        setHiddenLabels(labels?.filter((lbl) => lbl !== label));
      }
      return;
    }
    // Show all objects if there's only one visible object and you click it again

    if (hiddenLabels.includes(label)) {
      setHiddenLabels(hiddenLabels.filter((lbl) => lbl !== label));
    } else {
      const newLabels =
        hiddenLabels.length === labels.length - 1
          ? []
          : [...hiddenLabels, label];
      setHiddenLabels(newLabels);
    }
  };

  const toggleAllLabels = () => {
    if (hiddenLabels.length === 0) {
      setHiddenLabels(labels);
    } else {
      setHiddenLabels([]);
      setHiddenErrors([]);
    }
  };

  const toggleError = (errorType: string) => {
    const labelsWithError = _uniq(
      sample.polygons
        .filter((box) => box.error_type === errorType)
        .map((box) => box.gold || box.pred)
    );
    // Click on an error and none are hidden or all are hidden, only show that error
    if (!hiddenErrors.length || hiddenErrors.length === sampleErrors.length) {
      setHiddenErrors(sampleErrors.filter((err) => err !== errorType));

      setHiddenLabels(
        labels.filter((label) => !labelsWithError.includes(label))
      );
      return;
    }

    // Show all errors if there's only one visible and you click it again
    if (
      hiddenErrors.length === sampleErrors.length - 1 &&
      !hiddenErrors.includes(errorType)
    ) {
      setHiddenErrors([]);
      setHiddenLabels([]);
      return;
    }

    // Toggle object on/off if one or more object is visible

    if (hiddenErrors.length < sampleErrors.length) {
      // if hidden, show. Remove hidden error, filter boxes with errors for all hidden errors
      if (hiddenErrors.includes(errorType)) {
        const _hiddenErrors = hiddenErrors.filter((err) => err !== errorType);
        const visibleLabels = _uniq(
          sample.polygons
            .filter((box) => !_hiddenErrors.includes(box.error_type))
            .map((box) => box.gold || box.pred)
        );
        setHiddenErrors(_hiddenErrors);
        setHiddenLabels(labels.filter((lbl) => !visibleLabels.includes(lbl)));
      } else {
        // if visible, hide. Add error type to hidden errors. Add boxes with error type to hidden objects.
        const visibleErrors = sampleErrors.filter(
          (err) => err !== errorType && !hiddenErrors.includes(err)
        );

        const visibleLabels = _uniq(
          sample.polygons
            .filter((box) => visibleErrors.includes(box.error_type))
            .map((box) => box.gold || box.pred)
        );
        setHiddenErrors([...hiddenErrors, errorType]);
        setHiddenLabels(
          _uniq([
            ...hiddenLabels,
            ...labels.filter((lbl) => !visibleLabels.includes(lbl))
          ])
        );
      }
    }
  };

  const toggleAllErrors = () => {
    if (hiddenErrors.length === 0) {
      setHiddenErrors(sampleErrors);
      setHiddenLabels(
        sample.polygons
          ?.filter((poly) => poly.error_type !== 'None')
          ?.map((poly) => poly.gold || poly.pred) || []
      );
    } else {
      setHiddenErrors([]);
      setHiddenLabels([]);
    }
  };

  const filterByLabel = (label: string) => {
    setParameters({
      classFilter: [label]
    });
  };

  const delayedOnLabelClick = (label: string) => {
    setTimeout(() => {
      toggleLabel(label);
    }, 100);
  };

  const objects = labels?.map((lbl) => {
    const label = lbl || '';
    const [gtAmount, predAmount] = labelsWithAmounts[label];
    const color = colorMap?.[label]?.background;
    const colorDot = <Box bg={color} p={5} style={{ borderRadius: '50%' }} />;

    return (
      <UnstyledButton
        aria-label={label}
        key={lbl}
        opacity={hiddenLabels.includes(lbl) ? 0.5 : 1}
        onClick={() => delayedOnLabelClick(lbl)}
        onDoubleClick={() => isBadge && filterByLabel(lbl)}
      >
        {isBadge ? (
          <OdLabelBadge disabled={hiddenLabels.includes(lbl)} label={label} />
        ) : (
          <Group data-testid='label-with-dot' gap='xs' wrap='nowrap'>
            {colorDot}
            <Tooltip.Group>
              <Tooltip label={label} position='top-start'>
                <Text size='sm'>
                  {label}
                  {gtAmount && (
                    <Text c='gray.5' component='span' ml={3} size='xs'>
                      {gtAmount} GT
                    </Text>
                  )}
                  {gtAmount && predAmount ? (
                    <Text c='gray.5' component='span' size='xs'>
                      ,
                    </Text>
                  ) : null}
                  {predAmount ? (
                    <Text c='gray.5' component='span' ml={3} size='xs'>
                      {predAmount} Pred
                    </Text>
                  ) : null}
                </Text>
              </Tooltip>
            </Tooltip.Group>
          </Group>
        )}
      </UnstyledButton>
    );
  });
  return {
    hiddenLabels,
    hiddenErrors,
    toggleError,
    toggleLabel,
    toggleAllLabels,
    toggleAllErrors,
    objects
  };
};
