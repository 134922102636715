import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';
import { Splits } from '@/core/stores/parameters-store/parameters.store.types';
import useStore from '@/fine-tune/stores/store';

import { useInferenceNames } from '../use-inference-names/use-inference-names';

// Query key
const POST_SIMILAR_TO_TEXT =
  '/projects/{project_id}/runs/{run_id}/split/{split}/embeddings/point';

export const useSimilarToText = () => {
  const { runId, projectId } = usePathParameters();

  const fromSplit = useParametersStore((state) => state.fromSplit);
  const split = useParametersStore((state) => state.split);

  const similarToIds = useStore((state) => state.similarToIds);

  const { data, isFetching } = useInferenceNames();

  const _split = fromSplit || split;

  const isFromInference = data?.inference_names.includes(_split);

  const fetchSimilarToText = async () => {
    const res = await api.POST(POST_SIMILAR_TO_TEXT, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: isFromInference ? 'inference' : (_split as Splits)
        },
        query: {
          inference_name: isFromInference ? _split : undefined
        }
      },
      body: {
        ids: similarToIds.slice(0, 1)
      }
    });

    return res.data;
  };

  const response = useQuery(
    [POST_SIMILAR_TO_TEXT, { projectId, runId, similarToIds }],
    () => fetchSimilarToText(),
    { enabled: Boolean(similarToIds?.length) && !isFetching }
  );

  return response;
};
