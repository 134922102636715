import { MetricsGroupId } from '@/core/hooks/use-metrics-group/use-metrics-group';
import { components } from '@/core/types/api';
import { MetricsRow } from '@/core/types/metrics-table.types';
import { UnitFormatType } from '@/core/utils/unit-conversions';

/** A mapping to static metric identifiers (NLI and non-NLI are joined together) */
export const METRIC_ID = {
  COMPLETENESS_GPT: 'completeness_gpt',
  CONTEXT_ADHERENCE: 'context_adherence',
  CORRECTNESS: 'correctness',
  INPUT_PII: 'input_pii',
  INPUT_SEXIST: 'input_sexist',
  INPUT_TONE: 'input_tone',
  INPUT_TOXICITY: 'input_toxicity',
  PII: 'pii',
  PROMPT_INJECTION: 'prompt_injection',
  PROMPT_PERPLEXITY: 'prompt_perplexity',
  RETRIEVER_ATTRIBUTION: 'retriever_attribution',
  RETRIEVER_UTILIZATION: 'retriever_utilization',
  TOXICITY: 'toxicity',
  UNCERTAINTY: 'uncertainty',
  SEXIST: 'sexist',
  TONE: 'tone'
} as const;

export type MetricId = (typeof METRIC_ID)[keyof typeof METRIC_ID];

/** A common interface for consolidating shared fields between settings and charts and columns */
export interface MetricConfig {
  metricId: MetricId;
  label: string;
  metricsGroupId: MetricsGroupId | undefined;
  projectSettingAccessor:
    | keyof components['schemas']['ScorersConfiguration']
    | undefined;

  // OPTIONAL FIELDS
  // TODO: temporary, should combine with `projectSettingAccessor` in the future
  /** A placeholder for multi accessors that don't require integrations */
  nliProjectSettingAccessor?: keyof components['schemas']['ScorersConfiguration'];
  isCustomMetric?: boolean;
  /** A settings based description */
  description?: string;
  /** When provided, handles confirmation on toggle and displays warning when no integrations are found */
  requiresIntegration?: string[];
}

/** Keeping ids of non-metrics consistent */
const COMMON_FIELD_ID = {
  COST: 'cost',
  LATENCY: 'latency'
} as const;

/** Extends `METRIC_ID` with static charts  */
export const CHART_ID = {
  ...METRIC_ID,
  ...COMMON_FIELD_ID,
  FAILURES: 'failures',
  REQUESTS: 'requests'
} as const;

export type ChartId = (typeof CHART_ID)[keyof typeof CHART_ID];

export interface ChartConfig extends Omit<MetricConfig, 'metricId'> {
  metricId?: MetricId;
  type: 'line' | 'bar';
  tooltip?: string;
  aggregateMetric?: {
    label?: string;
    accessor: string;
    /** Used to show an additional aggregation for nli */
    nliAccessor?: string;
    formatValue: (value: number) => React.ReactNode;
  };
  chartAccessor: string;
  /** Used to show an additional dataset for nli within this chart */
  nliChartAccessor?: string;
  yAxis: {
    label: string;
    units: UnitFormatType;
  };
  sortData?: boolean;
}

export function isChartId(
  chartId: string | null | undefined
): chartId is ChartId {
  return chartId != null && chartId !== '';
}

/** Extends `METRIC_ID` with static columns  */
export const COLUMN_ID = {
  ...METRIC_ID,
  ...COMMON_FIELD_ID,
  NODE_TYPE: 'node_type',
  CREATED_AT: 'created_at',
  INPUT_TEXT: 'input_text',
  INPUT_TOKENS: 'num_input_tokens',
  OUTPUT_TEXT: 'output_text',
  OUTPUT_TOKENS: 'num_output_tokens',
  TEMPERATURE: 'temperature',
  TAGS: 'tags',
  MODEL: 'model',
  PROTECT_STATUS: 'protect_status',
  STATUS_CODE: 'status_code',
  SYSTEM_VERSION: 'version'
} as const;

export type ColumnId = (typeof COLUMN_ID)[keyof typeof COLUMN_ID];

export interface ColumnConfig extends Omit<MetricConfig, 'metricId'> {
  metricId?: MetricId;
  /** Defines the field key to access this value */
  accessor: string;
  /** Defines the root level object field the accessor lives under */
  objectAccessor: 'metrics' | 'user_metadata' | undefined;
  filterType: ObserveFilterType | undefined;
  alertConditionType: components['schemas']['AlertConditionType'] | undefined;
  /** Custom formatters to display the table cell contents */
  format?: (options: { value: any; row: MetricsRow }) => React.ReactNode;
}

export type ObserveFilterType =
  | 'string'
  | 'string-array'
  | 'integer'
  | 'float'
  | 'whole-number'
  | 'dollars'
  | 'milliseconds'
  | 'score'
  | 'multi-chooser'
  | 'multi-chooser-metric-array'
  | 'node-type'
  | 'tags';

export interface ObserveStoreState {
  /** time when data was fetched, in milliseconds */
  lastUpdatedTime: number;
  groupVisibility: { [groupName: string]: boolean };
  selectedMetricsRows: MetricsRow[];

  actions: {
    setLastUpdatedTime: (lastUpdatedTime: number) => void;
    setGroupVisibility: (hiddenGroups: string[]) => void;
    setSelectedMetricsRows: (metricsRows: MetricsRow[]) => void;
  };
}
