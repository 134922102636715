/**
 * PanelGroup component represents a group of independently scrolling flex panels with an optional header.
 *
 * @component
 * @example
 * ```tsx
 * import { PanelGroup } from './panel-group';
 *
 * const MyComponent = () => {
 *   return (
 *     <PanelGroup>
 *       <PanelGroup.Panel>
 *         {/* Content of the panel */ /**}
 *       </PanelGroup.Panel>
 *      {/* Additional panels */ /**}
 *     </PanelGroup>
 *   );
 * };
 * ```
 */
import { Box, BoxProps, Group, GroupProps, ScrollArea } from '@mantine/core';
import { useElementSize, useMove } from '@mantine/hooks';
import _omit from 'lodash/omit';
import {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useState
} from 'react';

import {
  distributeRemainingSpace,
  panelWidthToValue,
  valueToPanelWidth
} from './panel-group-utils';

interface PanelGroupProps extends GroupProps {
  header?: JSX.Element | null;
  widths: ('auto' | number)[]; // Should total to 100. Auto will distribute the remaining space.
}

export const PanelGroup = ({
  header = null,
  widths,
  ...props
}: PanelGroupProps) => {
  // Local State
  const [panelWidths, setPanelWidths] = useState(
    distributeRemainingSpace(widths)
  );

  // Refs
  const { height: panelGroupHeight, ref: panelGroupRef } = useElementSize();
  const { height: headerHeight, ref: headerRef } = useElementSize();

  // Computed
  const maxHeight = panelGroupHeight - headerHeight;

  const onResize = (newWidth: number, index: number) => {
    // panelWidth is the new width of the panel.
    // Difference is the change in width from the previous width, which will adjust the neighboring panel.
    const currentWidth = panelWidths?.[index] ?? 0;
    const difference = currentWidth - newWidth;

    const neighborIndex = index + 1;
    const currentNeighborWidth = panelWidths?.[neighborIndex] ?? 0;

    const newNeighborWidth = currentNeighborWidth + difference;

    // Create a new array with the updated width for the specified panel
    const newPanelWidths = panelWidths.map((width, i) => {
      if (i === index) {
        return newWidth;
      }

      if (i === neighborIndex) {
        return newNeighborWidth;
      }

      return width;
    });

    // Update the state
    setPanelWidths(newPanelWidths);
  };

  useEffect(() => {
    if (widths) {
      setPanelWidths(distributeRemainingSpace(widths));
    }
  }, [widths]);

  // This will be used to set the width of the panels
  return (
    <Group
      align='flex-start'
      data-testid='panel-group'
      gap={0}
      h='100%'
      ref={panelGroupRef}
      w='100%'
      {...props}
    >
      {header && (
        <Box ref={headerRef} w='100%'>
          {header}
        </Box>
      )}
      {Children.map(props.children, (child, index) =>
        // Clone the child and pass the max height.
        // This is so that the child can be scrollable automatically with or without a header.
        cloneElement(child as ReactElement, {
          maxHeight,
          onResize,
          width: panelWidths[index],
          index
        })
      )}
    </Group>
  );
};

type InjectedPanelProps = {
  maxHeight: number;
  onResize: (width: number, index: number) => void;
  width: number;
  index: number;
};

export interface PanelProps extends BoxProps {
  header?: JSX.Element | null;
  resizeRange?: [number, number];
  useScrollArea?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const Panel = (props: PanelProps) => {
  // Get around the types for the injected props when using the <Panel/> component
  const {
    header,
    maxHeight,
    onResize,
    width,
    index,
    resizeRange,
    useScrollArea = true
  } = props as PanelProps & InjectedPanelProps;

  // Refs
  const { height: panelHeaderHeight, ref: panelHeaderRef } = useElementSize();

  // Computed
  const scrollAreaHeight = maxHeight ? maxHeight - panelHeaderHeight : '100%';
  const [minWidth, maxWidth] = resizeRange ?? [0, 0];

  // Get distance from panel edge to max width
  const maxWidthDiff = maxWidth ? maxWidth - width : 0;

  // Get distance from panel edge to min width
  const minWidthDiff = minWidth ? width - minWidth : 0;

  // This is the width of the 'track' for the handler
  const handlerWidth = maxWidthDiff + minWidthDiff;

  // This is the left position of the 'track' for the handler
  const handlerLeft = minWidth;

  // Handlers
  const onDrag = (x: number) => {
    const numberToPercent = x * 100;
    const normalizedWidth = valueToPanelWidth(
      numberToPercent,
      resizeRange as [number, number]
    );

    onResize?.(normalizedWidth, index);
  };

  const handlerValue = panelWidthToValue(
    width ?? 0,
    resizeRange as [number, number]
  );

  return (
    <>
      <Box
        data-testid='panel-group-panel'
        h={maxHeight}
        pos='relative'
        w={`${width}%`}
        // prevent spread of props that BoxProps doesn't have since we're injecting prop types above
        {..._omit(props, ['resizeRange', 'maxHeight', 'useScrollArea'])}
      >
        <Box data-testid='panel-group-panel-header' ref={panelHeaderRef}>
          {header && header}
        </Box>
        {useScrollArea ? (
          <ScrollArea
            h={scrollAreaHeight}
            maw='100%'
            mih={scrollAreaHeight}
            pr={10}
          >
            {props.children}
          </ScrollArea>
        ) : (
          props.children
        )}
      </Box>
      {resizeRange && (
        <Handler
          left={`${handlerLeft}%`}
          parentHeight={maxHeight}
          value={handlerValue}
          w={`${handlerWidth}%`}
          onDrag={onDrag}
        />
      )}
    </>
  );
};

PanelGroup.Panel = Panel;

interface HandlerProps extends Omit<GroupProps, 'onDrag'> {
  onDrag: (x: number) => void;
  parentHeight?: number;
  value: number;
}

const Handler = ({ onDrag, parentHeight, value, ...others }: HandlerProps) => {
  // Hooks
  const { ref } = useMove(({ x }) => onDrag(x));

  return (
    <Group ta='center' {...others} justify='center' pos='absolute'>
      <Box
        h={1} // Removing this will break the useMove hook!
        pos='relative'
        ref={ref}
        style={{
          zIndex: 1
        }}
        w='100%'
      >
        {/* Thumb */}
        <Box
          h={parentHeight ?? 'auto'}
          left={`${value}%`}
          ml='-.7rem'
          pos='absolute'
          style={{ zIndex: 1, cursor: 'col-resize' }}
          w='20px'
        />
      </Box>
    </Group>
  );
};
