import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

export const POST_CLUSTERS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/cartograph_clusters';

const CLUSTER_SUMMARY_KEY = 'CLUSTER_SUMMARY_KEY';

export const useCartographClusters = () => {
  const { projectId, runId } = usePathParameters();

  const { split, inferenceName } = useParametersStore((s) => ({
    split: s.split,
    inferenceName: s.inferenceName
  }));
  const enabled = Boolean(projectId && runId && split);
  const filter_params = useFilterParams();

  const fetchCartographClusters = async () => {
    const res = await api.POST(POST_CLUSTERS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split: split
        }
      },
      // @ts-expect-error
      body: { filter_params }
    });

    return res.data;
  };

  const response = useQuery(
    [
      CLUSTER_SUMMARY_KEY,
      { projectId, runId, inferenceName, split, ...filter_params }
    ],
    fetchCartographClusters,
    // TODO: Handle errors
    { enabled, onError: () => null }
  );

  const clustersAmount = response?.data?.cartograph_clusters?.length;

  return {
    ...response,
    disableClustersTab: !clustersAmount,
    clustersAmount
  };
};
