import { Box, Group, GroupProps, Text } from '@mantine/core';

import Metric, { MetricProps } from '@/core/components/atoms/metric/metric';

import { MetricPopover } from '../metric-popover/metric-popover';

interface TagsRatingProps extends GroupProps {
  explanation?: string;
  tags: string[];
  title?: string;
  metricProps?: MetricProps;
}

export const TagsRating = ({
  explanation,
  tags = [],
  title,
  metricProps,
  ...props
}: TagsRatingProps) => {
  const popoverBody = (
    <>
      <Box p='sm'>
        <Text c='gray.2' fw={600} pb='sm' size='sm'>
          {title ?? 'Categorical Rating'}
        </Text>
        <Group gap='xs'>
          {tags.map((tag) => (
            <Metric key={tag} value={tag} variant='filled' />
          ))}
        </Group>
      </Box>
      {explanation && (
        <Text c='gray.2' p='sm' size='sm'>
          {explanation}
        </Text>
      )}
    </>
  );

  return (
    <MetricPopover body={popoverBody}>
      <Group gap='xs' wrap='nowrap' {...props}>
        <Metric
          color='gray'
          maxWidth={150}
          value={tags[0]}
          variant='filled'
          {...metricProps}
        />
        {tags.length > 1 && (
          <Metric
            color='gray'
            value={`+${tags.length - 1}`}
            variant='filled'
            {...metricProps}
          />
        )}
      </Group>
    </MetricPopover>
  );
};
