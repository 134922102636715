import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import {
  EVALUATE_COLUMNAR_RUN_PATH,
  EVALUATE_COLUMNAR_RUNS_PATH,
  HUMAN_FEEDBACK_TEMPLATE_PATH,
  HUMAN_FEEDBACK_TEMPLATES_PATH
} from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

export const useDeleteFeedbackTemplate = () => {
  // Path Params
  const { projectId } = usePathParameters();

  // Query Client
  const queryClient = useQueryClient();

  // Action
  const deleteTemplate = async (templateId: string) => {
    if (templateId == null) {
      return Promise.reject(`Invalid templateId: ${templateId}`);
    }

    const res = await api
      .DELETE(HUMAN_FEEDBACK_TEMPLATE_PATH, {
        params: {
          path: {
            project_id: projectId!,
            template_id: templateId
          }
        }
      })
      ?.then((res) => {
        queryClient.invalidateQueries([HUMAN_FEEDBACK_TEMPLATES_PATH]);
        queryClient.invalidateQueries([EVALUATE_COLUMNAR_RUN_PATH]);
        queryClient.invalidateQueries([EVALUATE_COLUMNAR_RUNS_PATH]);
        return res?.data;
      })
      ?.catch((err) =>
        defaultErrorHandler(
          err,
          `Unable to delete human feedback configuration`
        )
      );

    return res;
  };

  return useMutation(deleteTemplate);
};
