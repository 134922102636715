import {
  ActionIcon,
  Box,
  Card,
  Center,
  Grid,
  Group,
  ScrollArea,
  Text,
  Tooltip
} from '@mantine/core';
import { useState } from 'react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import SplitCard from '@/core/components/atoms/split-card/split-card';
import { TruncatedGroup } from '@/core/components/atoms/truncated-group/truncated-group';
import { useCurrentRun } from '@/core/hooks/query-hooks/use-current-run/use-current-run';
import { useParametersStoreActions } from '@/core/stores/parameters-store';
import { parsePercentage } from '@/core/utils/parse-percentage/parse-percentage';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import { getTopCommunities } from '@/fine-tune/data-parsers/get-top-communities/get-top-communities';
import { useClassCommunities } from '@/fine-tune/hooks/query-hooks/use-class-communities/use-class-communities';
import { useThresholds } from '@/fine-tune/hooks/query-hooks/use-thresholds/use-thresholds';
import { useColors } from '@/fine-tune/hooks/use-colors/use-colors';

const OVERLAPPING_CLASSES_TOOLTIP =
  'Classes with significant sample level similarity and label confusion - High class overlap leads to overlapping model decision boundaries, potentially confusing the model and affecting model performance';

const COUNT_TOOLTIP =
  'The number of samples misclassified between the overlapping classes';

const SCORE_TOOLTIP =
  'The degree of overlap between classes measured on a 1-5 scale. A higher score indicates greater overlap and a higher potential for class confusion';

const OverlappingClasses = () => {
  // Parameter Store
  const { setParameters } = useParametersStoreActions();

  // State
  const [activePanel, setActivePanel] = useState<string>();
  const [showAllRows, setShowAllRows] = useState(false);

  // Hooks
  const { data: communities, isFetching } = useClassCommunities();
  const { getClassOverlapColor } = useColors();

  const { data: thresholdsData } = useThresholds() || {};
  const hardThreshold = thresholdsData?.hard_samples_threshold || 0;

  const { data: runData } = useCurrentRun();
  const totalSamples = runData?.num_samples;

  // Computed
  const { overall, rows: allCommunities } =
    getTopCommunities(communities) || [];

  const topFiveCommunities = allCommunities?.slice(0, 5);
  const rows = showAllRows ? allCommunities : topFiveCommunities;

  const renderShowAllCommunitiesBtn =
    Array.isArray(allCommunities) && allCommunities?.length > 5;

  const percentage =
    overall && totalSamples && parsePercentage(overall / totalSamples);

  if (!isFetching && !communities?.length) {
    return null;
  }

  const togglePanel = (id: string) => {
    if (id === activePanel) {
      setActivePanel('');
    } else {
      setActivePanel(id);
    }
  };

  return (
    <SplitCard
      header={
        <Tooltip
          multiline
          withArrow
          label={OVERLAPPING_CLASSES_TOOLTIP}
          position='right-end'
          w={240}
        >
          <Text component='span'>Overlapping Classes</Text>
        </Tooltip>
      }
      isLoading={isFetching}
      mt='xs'
      subheader={
        <Text size='xs'>
          Confused samples:
          <Text c='brand.4' component='span' fw={700} ml={2} mr={2}>
            {overall ? toHumanReadableNumber(overall) : <DataNotAvailable />}
          </Text>
          <Text c='brand.4' component='span' fw={300} size='xs'>
            ({percentage ?? <DataNotAvailable />})
          </Text>
        </Text>
      }
    >
      <Card.Section>
        <Grid
          grow
          p='sm'
          style={{
            backgroundColor: 'var(--mantine-color-gray-0)',
            borderBottom: `1px solid var(--mantine-color-gray-2)`
          }}
        >
          <Grid.Col span={{ md: 6 }}>
            <Text size='xs'>Classes</Text>
          </Grid.Col>
          <Grid.Col span='auto'>
            <Tooltip multiline withArrow label={SCORE_TOOLTIP} w={200}>
              <Text size='xs' ta='center'>
                Score
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span='auto'>
            <Tooltip multiline withArrow label={COUNT_TOOLTIP} w={200}>
              <Text size='xs' ta='center'>
                Count
              </Text>
            </Tooltip>
          </Grid.Col>
        </Grid>
      </Card.Section>
      <Card.Section>
        <ScrollArea.Autosize mah={600} mt='xs'>
          {rows?.map((row, i) => {
            const { labels, num_samples, score } = row;
            const id = JSON.stringify(labels);
            const isTruncated = id !== activePanel;
            const overflowCount = labels?.length - 3;

            const toggleText =
              id === activePanel
                ? 'Hide'
                : `Show ${overflowCount} more label${
                    overflowCount > 1 ? 's' : ''
                  }`;

            if (!labels?.length) {
              return;
            }

            const mappedLabels = labels.map((label: string) => (
              <LabelBadge
                key={label}
                maxWidth={!isTruncated ? 'auto' : '225px'}
                size='md'
                value={label}
              />
            ));

            const toggleButton = labels?.length > 3 && id !== activePanel && (
              <Center w='100%'>
                <ActionIcon w='100%' onClick={() => togglePanel(id)}>
                  <Text size='xs'>{toggleText}</Text>
                </ActionIcon>
              </Center>
            );

            return (
              <Box
                key={id}
                style={{
                  borderBottom:
                    i === rows.length - 1
                      ? 'unset'
                      : `1px solid var(--mantine-color-gray-2)`
                }}
              >
                <Grid
                  data-testid='class-community'
                  p='0px 12px'
                  onClick={() =>
                    setParameters({
                      goldFilter: labels,
                      predFilter: labels,
                      depHigh: 1,
                      depLow: hardThreshold
                    })
                  }
                >
                  <Grid.Col span={8} style={{ cursor: 'pointer' }}>
                    <Group gap='xs'>
                      <TruncatedGroup
                        elements={mappedLabels}
                        isTruncated={isTruncated}
                        labels={labels}
                      />
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text c={getClassOverlapColor(parseInt(score))} ta='center'>
                      {score}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text ta='center'>
                      {toHumanReadableNumber(num_samples)}
                    </Text>
                  </Grid.Col>
                </Grid>
                {toggleButton}
              </Box>
            );
          })}
        </ScrollArea.Autosize>
        {renderShowAllCommunitiesBtn && (
          <Center w='100%'>
            <ActionIcon w='100%' onClick={() => setShowAllRows(!showAllRows)}>
              <Text size='xs'>
                {showAllRows ? 'Hide' : 'Show all communities'}
              </Text>
            </ActionIcon>
          </Center>
        )}
      </Card.Section>
    </SplitCard>
  );
};

export default OverlappingClasses;
