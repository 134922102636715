import { HotkeyItem } from '@mantine/hooks';
import _compact from 'lodash/compact';

import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE } from '@/core/types/projects.types';
import { isFirefox } from '@/core/utils/is-firefox/is-firefox';
// eslint-disable-next-line
import { useEvaluateHotkeys } from '@/evaluate/hooks/use-evaluate-hotkeys/use-evaluate-hotkeys';
// eslint-disable-next-line
import { useFineTuneHotkeys } from '@/fine-tune/hooks/use-fine-tune-hotkeys/use-fine-tune-hotkeys';
// eslint-disable-next-line
import { useObserveHotkeys } from '@/observe/hooks/use-observe-hotkeys/use-observe-hotkeys';

export const useGlobalHotkeys = () => {
  const { hotkeys: evaluateHotkeys } = useEvaluateHotkeys();
  const { hotkeys: fineTuneHotkeys } = useFineTuneHotkeys();
  const { hotkeys: observeHotkeys } = useObserveHotkeys();

  const isProjectMenuOpen = useGlobalStore((s) => s.isProjectMenuOpen);

  const { setIsProjectMenuOpen } = useGlobalStore((s) => s.actions);

  const { openModal } = useModals();

  const globalHotkeys = [
    {
      id: 'keyboard-shortcuts',
      title: 'Open Keyboard Shortcuts Modal',
      description:
        'Open modal with a full list of keyboard shortcuts available in the Galileo console',
      binding: 'mod+/',
      callback: () => openModal(Modals.KEYBOARD_SHORTCUTS)
    },
    {
      id: 'project-menu',
      title: 'Toggle Project Menu',
      description:
        'Open or close the left hand drawer with all projects and runs',
      binding: isFirefox() ? 'alt+shift+p' : 'mod+shift+p',
      callback: () =>
        setIsProjectMenuOpen(
          isProjectMenuOpen === PROJECT_TYPE.TRAINING_INFERENCE
            ? false
            : PROJECT_TYPE.TRAINING_INFERENCE
        )
    }
  ];

  const hotkeys = _compact([
    ...globalHotkeys,
    ...fineTuneHotkeys,
    ...evaluateHotkeys,
    ...observeHotkeys
  ]);

  const parsedHotkeys: HotkeyItem[] = hotkeys.map(({ binding, callback }) => [
    binding,
    callback
  ]);

  const getBinding = (id: string): string => {
    if (id) {
      const { binding } = hotkeys.find((hotkey) => hotkey?.id === id) || {};

      return binding ?? '';
    }

    return '';
  };

  return { hotkeys, parsedHotkeys, getBinding };
};
