import { MetricsRow } from '@/core/types/metrics-table.types';
import { PromptRun } from '@/evaluate/types/prompt.types';

import {
  getCellNumber,
  getCellString,
  getCellValue
} from '../query-hooks/use-metrics/utils';
import { useGetPromptTemplates } from '../query-hooks/use-prompt-template/use-get-prompt-templates/use-get-prompt-templates';

// Model
export interface IPromptRunParameters {
  configuration: {
    Model?: string | null;
    Template?: string;
    'Max Length'?: number;
    Temperature?: number;
    'Top-P'?: number;
    'Stop Sequences'?: string[];
    'Frequency Penalty'?: number;
    'Presence Penalty'?: number;
  };
  meta: {
    'Created on'?: string;
    'Created by'?: string;
  };
  internal: {
    datasetId?: string;
    runId?: string;
    templateId?: string;
    templateVersion?: number;
    templateVersionId?: string | number;
    templateVersionSelected?: boolean;
  };
}

export const usePromptRunParameters = () => {
  const { data: templates } = useGetPromptTemplates();

  const columnarToParameters = (row: MetricsRow): IPromptRunParameters => {
    return {
      configuration: {
        Model: getCellString(row?.model_alias),
        Template: getCellString(row?.template_name),
        'Max Length': getCellNumber(row?.max_tokens),
        Temperature: getCellNumber(row?.temperature),
        'Top-P': getCellNumber(row?.top_p) as number,
        'Stop Sequences': Array.isArray(getCellValue(row?.stop_sequences))
          ? (getCellValue(row.stop_sequences) as string[])
          : undefined,
        'Frequency Penalty': getCellNumber(row?.frequency_penalty),
        'Presence Penalty': getCellNumber(row?.presence_penalty)
      },
      meta: {
        'Created on': getCellString(row?.created_at),
        'Created by': getCellString(row?.created_by)
      },
      internal: {
        datasetId: getCellString(row?.dataset_id),
        runId: row.id,
        templateId: getCellString(row?.template_id),
        templateVersion: getCellNumber(row?.template_version),
        templateVersionId: getCellString(row?.template_version_id),
        templateVersionSelected: Boolean(
          getCellValue(row?.template_version_selected)
        )
      }
    };
  };

  const runToParameters = (run: PromptRun): IPromptRunParameters => {
    const { temperature, frequency_penalty, max_tokens, presence_penalty } =
      run?.prompt_settings ?? {};
    const { top_p, stop_sequences } = run?.prompt_settings ?? {};

    return {
      configuration: {
        Model: run?.model_alias ?? undefined,
        Template: templates?.find(
          (template) => template?.id === run?.template_id
        )?.name,
        'Max Length': max_tokens,
        Temperature: temperature,
        'Top-P': top_p,
        'Stop Sequences': Array.isArray(stop_sequences)
          ? stop_sequences
          : undefined,
        'Frequency Penalty': frequency_penalty,
        'Presence Penalty': presence_penalty
      },
      meta: {
        'Created on': run?.created_at,
        'Created by': run?.created_by
      },
      internal: {
        datasetId: run?.dataset_id ?? undefined,
        runId: run?.id,
        templateId: run?.template_id ?? undefined,
        templateVersion: run?.template_version ?? undefined,
        templateVersionId: run?.template_version_id ?? undefined,
        templateVersionSelected: Boolean(run?.template_version_selected)
      }
    };
  };

  return {
    columnarToParameters,
    runToParameters
  };
};
