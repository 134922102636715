import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import useStore from '@/fine-tune/stores/store';

import { useTasks } from '../use-tasks/use-tasks';

const path =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/co_occurrence';

export const useOverlappingLabels = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((s) => s.split);

  const { isCoOccurrence, task } = useStore((state) => state.mlcTaskAndIds);

  const isMltc = useComputedParameters('isMltc');
  const filter_params = useFilterParams();

  const { isLoading: tasksIsLoading } = useTasks();

  const enabled = Boolean(!tasksIsLoading && isMltc && projectId && runId);

  const fetchOverlappingLabels = async () => {
    const res = await api.POST(path, {
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!,
          split
        },
        query: {
          starting_label: (isCoOccurrence && task) || undefined
        }
      },
      body: {
        filter_params
      }
    });

    return res.data;
  };

  const response = useQuery(
    [path, { projectId, runId, split, ...filter_params }],
    fetchOverlappingLabels,
    { enabled }
  );

  return response;
};
