import { Box, Text } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';

import { useComputedParameters } from '@/core/stores/parameters-store';
import Cart, { ICartElement } from '@/fine-tune/components/cart/cart';
import { EDIT_ACTIONS } from '@/fine-tune/constants/data-edits.constants';
import { useEditById } from '@/fine-tune/hooks/query-hooks/use-edit-by-id/use-edit-by-id';
import { useEditRemove } from '@/fine-tune/hooks/query-hooks/use-edit-remove/use-edit-remove';
import { useEditReview } from '@/fine-tune/hooks/query-hooks/use-edit-review/use-edit-review';
import { useEdits } from '@/fine-tune/hooks/query-hooks/use-edits/use-edits';
import { useEditsContent } from '@/fine-tune/hooks/query-hooks/use-edits-content/use-edits-content';
import { EditAction } from '@/fine-tune/stores/data-edits-store/data-edits.store.types';

import CvEditsDetail from './cv-edits-detail/cv-edits-detail';
import EditsCartEmptyState from './edits-cart-empty-state';
import EditsDetail from './edits-detail/edits-detail';
import OdEditsDetail from './od-edits-detail/od-edits-detail';

interface AmountsPerEditType {
  [key: string]: number;
}

interface ApiEdit {
  id: string;
  edit_action: EditAction;
  sample_ids?: number[];
}

const {
  RELABEL,
  RELABEL_AS_PRED,
  DELETE,
  ADD_SPAN,
  SELECT_FOR_LABEL,
  SHIFT_SPAN,
  UPDATE_TEXT
} = EDIT_ACTIONS;

const DEFAULT_FILTERABLE_ACTIONS = [RELABEL, DELETE, UPDATE_TEXT];
const INFERENCE_FILTERABLE_ACTIONS = [SELECT_FOR_LABEL, RELABEL];
const NER_FILTERABLE_ACTIONS = [RELABEL, DELETE, SHIFT_SPAN, ADD_SPAN];

const DEFAULT_FILTER_COPY = {
  [RELABEL]: 'relabeled',
  [DELETE]: 'removed',
  [UPDATE_TEXT]: 'updated',
  [SHIFT_SPAN]: 'shifted',
  [ADD_SPAN]: 'added'
};
const INFERENCE_FILTER_COPY = {
  [RELABEL]: 'assigned',
  [SELECT_FOR_LABEL]: 'added'
};

const EditsCartModal = ({ id }: ContextModalProps) => {
  const [currentEditId, setCurrentEditId] = useState('');
  const [isRegexSearch, setIsRegexSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [editsFilter, setEditsFilter] = useState<string | EditAction>('all');

  const { isIc, isInference, isNer, isOd } = useComputedParameters();
  const { closeModal, openConfirmModal } = useModals();

  const { data: allEditsData, isFetching: isAllFetching } = useEditsContent({
    searchQuery,
    isRegexSearch
  });

  const currentEditData = useEditById(
    currentEditId,
    searchQuery,
    isRegexSearch
  );

  const { data: editsData = [], count } = useEdits();
  const editRemove = useEditRemove();
  const editReview = useEditReview();

  let filteredEdits = [
    RELABEL,
    DELETE,
    SELECT_FOR_LABEL,
    UPDATE_TEXT,
    SHIFT_SPAN,
    ADD_SPAN
  ].includes(editsFilter as EditAction)
    ? editsData?.filter((edit: ApiEdit) =>
        editsFilter === 'relabel'
          ? [RELABEL, RELABEL_AS_PRED].includes(edit.edit_action)
          : edit.edit_action === editsFilter
      )
    : editsData;

  if (searchQuery) {
    filteredEdits = editsData?.filter((edit) =>
      Boolean(allEditsData?.find((content) => content.id === edit.id))
    );
  }

  useEffect(() => {
    if (filteredEdits?.length) {
      setCurrentEditId(filteredEdits[0]?.id);
    } else {
      setCurrentEditId('');
    }
  }, [filteredEdits?.length, editsFilter]);

  let amountsPerEditType: AmountsPerEditType = {};

  editsData?.forEach((edit: ApiEdit) => {
    const action =
      edit.edit_action === RELABEL_AS_PRED ? RELABEL : edit.edit_action;
    if (!amountsPerEditType[action]) {
      amountsPerEditType[action] = 0;
    }

    amountsPerEditType[action] += edit.sample_ids?.length ?? 0;
  });

  const handleEditChange = (editId: string) => {
    if (editId === 'all') {
      setEditsFilter('all');
    } else {
      setCurrentEditId(editId);
    }
  };

  const handleEditRemove = async (editId: string) => {
    const editSamplesIds =
      editsData.find((edit) => edit.id === editId)?.sample_ids || [];
    editRemove.mutate({ editId, editSamplesIds });

    if (editId === currentEditId) {
      // If I'm deleting the current edit, select any other
      if (editsData.length > 1) {
        const { id } =
          editsData.find((edit: { id: string }) => edit.id !== editId) || {};

        if (id) {
          setCurrentEditId(id);
        }
      } else {
        // If I'm deleting the last edit, close modal
        closeModal(id);
      }
    }
  };

  const handleEditApprove = async () => {
    editReview.mutate(currentEditId);
  };

  const handleAllEditsRemove = () => {
    openConfirmModal({
      title: 'Clear all edits',
      children: (
        <Text size='sm'>Are you sure you want to clear all edits?</Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        editsData.forEach((edit: ApiEdit) => {
          const editSamplesIds =
            editsData.find((ed) => ed.id === edit.id)?.sample_ids || [];
          editRemove.mutate({ editId: edit.id, editSamplesIds });
        });

        closeModal(id);
      },
      closeOnCancel: true
    });
  };

  // NER takes priority for now. In future, filters should only be based on split type
  const filterOptions = isNer
    ? NER_FILTERABLE_ACTIONS
    : isInference
      ? INFERENCE_FILTERABLE_ACTIONS
      : DEFAULT_FILTERABLE_ACTIONS;

  const filters = filterOptions.map((editAction) => ({
    value: editAction,
    label: isNer
      ? DEFAULT_FILTER_COPY[editAction]
      : isInference
        ? INFERENCE_FILTER_COPY[editAction]
        : DEFAULT_FILTER_COPY[editAction],
    amount: amountsPerEditType?.[editAction] || 0,
    isActive: editAction === editsFilter,
    disabled: !amountsPerEditType?.[editAction],
    onClick: () => setEditsFilter(editAction)
  }));

  const cartIsEmpty = !editsData?.length;

  const Detail = isIc ? CvEditsDetail : isOd ? OdEditsDetail : EditsDetail;

  const amountComponent = (
    <>
      <Text component='span' fw={700} mr={4}>
        {count}
      </Text>
      <Text c='gray.5' component='span' size='sm'>{`${
        isNer ? 'span' : 'sample'
      }(s)`}</Text>
    </>
  );

  const handleSearchEnter = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <Box data-testid='edits-cart-modal' h={648}>
      <Cart
        isEditsCart
        amountComponent={amountComponent}
        // text search queries do not work for images
        canSearch={!isIc}
        currentElement={currentEditId}
        elements={filteredEdits as ICartElement[]}
        filters={filters}
        isEmpty={cartIsEmpty}
        isRegexSearch={isRegexSearch}
        searchTerm={searchQuery}
        title={isInference ? 'Labels Cart' : 'Edits Cart'}
        onAllRemove={handleAllEditsRemove}
        onElementChange={handleEditChange}
        onElementRemove={handleEditRemove}
        onModalClose={() => closeModal(id)}
        onRegexToggle={() => setIsRegexSearch(!isRegexSearch)}
        onSearchEnter={handleSearchEnter}
      >
        {cartIsEmpty ? (
          <EditsCartEmptyState onModalClose={() => closeModal(id)} />
        ) : (
          <Detail
            detail={currentEditData?.data}
            isLoading={currentEditData.isFetching || isAllFetching}
            reviewers={
              editsData?.find((edit) => edit.id === currentEditId)?.reviewers
            }
            onEditApprove={handleEditApprove}
          />
        )}
      </Cart>
    </Box>
  );
};

export default EditsCartModal;
