import Router from 'next/router';
import { getSession } from 'next-auth/react';
import { Middleware } from 'openapi-fetch';

import { ADMIN_USER_CHECK_PATH } from '@/core/constants/api.constants';
import { Paths } from '@/core/constants/routes.constants';
import { TASK_TYPE } from '@/core/constants/tasks-and-frameworks.constants';
import { useParametersStore } from '@/core/stores/parameters-store';

const publicUrls: string[] = [
  Paths.FORGOT_PASSWORD,
  Paths.SIGN_IN,
  Paths.SIGN_UP,
  Paths.RESET_PASSWORD,
  Paths.REQUEST_INVITE
];

export const authMiddleware: Middleware = {
  async onRequest(req) {
    if (req?.url === ADMIN_USER_CHECK_PATH) {
      return undefined;
    }

    const isInPublicUrl = publicUrls.includes(Router.pathname);

    // Fetch session data
    const session = await getSession();

    const tokenExpirationDate = new Date(session?.expires ?? 0)?.toISOString();
    const currentDate = new Date().toISOString();

    const isTokenExpired = tokenExpirationDate < currentDate;

    if ((!session || isTokenExpired) && !isInPublicUrl) {
      Router.push(Paths.SIGN_IN);
    }

    req.headers.set('Authorization', `Bearer ${session?.accessToken}`);

    return req;
  }
};

export const NlpRequestMiddleware: Middleware = {
  async onRequest(req) {
    const isNLP = Router.pathname === Paths.INSIGHTS;
    const isMltc = useParametersStore.getState().taskType === TASK_TYPE.MLTC;
    const inferenceName = useParametersStore.getState().inferenceName;

    const isGetOrDelete = req.method === 'GET' || req.method === 'DELETE';

    if (!isNLP || (isGetOrDelete && !inferenceName)) {
      return undefined;
    }

    const _body = isGetOrDelete ? null : await req.clone().json().catch();

    if (isMltc && _body != null) {
      const _req = req.clone();
      const task = useParametersStore.getState().task;
      const body = _body ? JSON.stringify({ ..._body, task }) : undefined;

      const newReq = new Request(_req, {
        body
      });

      return newReq;
    }

    if (inferenceName) {
      const url = new URL(req.url);
      url.searchParams.set('inference_name', inferenceName);
      const body = _body ? JSON.stringify(_body) : undefined;
      const newReq = new Request(url, {
        body,
        method: req.method,
        headers: req.headers,
        credentials: req.credentials,
        redirect: req.redirect,
        referrerPolicy: req.referrerPolicy,
        mode: req.mode,
        cache: req.cache,
        integrity: req.integrity,
        keepalive: req.keepalive,
        signal: req.signal
      });

      return newReq;
    }

    return req;
  },
  async onResponse(res) {
    if (res.status < 200 || res.status >= 300) {
      return Promise.reject(res);
    }

    return res;
  }
};
