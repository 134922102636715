import {
  ActionIcon,
  Box,
  Grid,
  Group,
  Select,
  Text,
  UnstyledButton
} from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { Dispatch, SetStateAction } from 'react';

import SimpleNumberInput from '@/core/components/atoms/simple-number-input/simple-number-input';
import { useComputedParameters } from '@/core/stores/parameters-store';
import { parseMetaColumnName } from '@/fine-tune/data-parsers/parse-meta-column-name/parse-meta-column-name';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useMetaColumns } from '@/fine-tune/hooks/query-hooks/use-meta-columns/use-meta-columns';
import { useDataframeColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { S2S_EXTRA_COLUMNS } from '@/fine-tune/types/s2s.types';

import { Filter } from './create-slice-modal';

export enum COMPARATORS {
  GREATER = 'greater',
  LESS = 'less',
  BETWEEN = 'between',
  EQUAL = 'equal'
}

const COMPARATOR_OPTIONS = [
  { label: 'is greater than', value: COMPARATORS.GREATER },
  { label: 'is less than', value: COMPARATORS.LESS },
  { label: 'is between', value: COMPARATORS.BETWEEN },
  { label: 'is equal to', value: COMPARATORS.EQUAL }
];

interface MetaFiltersFormProps {
  metaFilters: Filter[];
  setMetaFilters: Dispatch<SetStateAction<Filter[]>>;
}

const getValue = (meta: Filter) => {
  switch (meta.comparator) {
    case COMPARATORS.LESS:
      return meta.less_than;
    case COMPARATORS.EQUAL:
      return meta.is_equal;
    default:
      return meta.greater_than;
  }
};

const MetaFiltersForm = ({
  metaFilters,
  setMetaFilters
}: MetaFiltersFormProps) => {
  const { continuousFilters, isContinuousAndNotCategorical } = useMetaColumns();
  const { allColumns } = useDataframeColumns();

  const { hasS2SExtraColumns } = useInsightsRows();
  const { isS2S } = useComputedParameters();

  const smartColumns = allColumns.filter(
    ({ isSmartColumn, isSortable }) => isSmartColumn && isSortable
  );

  const baseColumns = allColumns.filter(
    ({ isSmartColumn, accessor, isSortable }) => {
      let condition =
        !isSmartColumn && isSortable && accessor !== 'data_error_potential';

      if (isS2S && S2S_EXTRA_COLUMNS.includes(accessor)) {
        condition = condition && hasS2SExtraColumns;
      }
      return condition;
    }
  );

  const defaultFilters = [...baseColumns, ...smartColumns].map((column) => ({
    label: column.label,
    value: column.accessor
  }));

  const allFilters = [
    ...defaultFilters,
    // Remove filters that are already in the list
    ...continuousFilters.filter((filter) =>
      defaultFilters.every(
        (defaultFilter) => defaultFilter.value !== filter.value
      )
    )
  ];

  const updateMetaFilterName = (index: number, name: string) => {
    setMetaFilters((old) => {
      const newMetaFilters = [...old];
      newMetaFilters[index].name = name;
      newMetaFilters[index].comparator = 'between';
      return newMetaFilters;
    });
  };

  const updateMetaFilterComparator = (
    index: number,
    comparator: COMPARATORS
  ) => {
    setMetaFilters((old) => {
      const newMetaFilters = [...old];
      newMetaFilters[index] = { comparator, name: newMetaFilters[index].name };
      return newMetaFilters;
    });
  };

  const updateMetaFilterValue = (
    index: number,
    value: number,
    comparator: COMPARATORS,
    isMin: boolean
  ) => {
    const isBetween = comparator === COMPARATORS.BETWEEN;
    const isGreaterThan = comparator === COMPARATORS.GREATER;
    const isLessThan = comparator === COMPARATORS.LESS;
    const isEqual = comparator === COMPARATORS.EQUAL;
    setMetaFilters((old) => {
      const newMetaFilters = [...old];

      if (isMin && (isBetween || isGreaterThan)) {
        newMetaFilters[index].greater_than = value;
      }
      if ((isMin && isLessThan) || !isMin) {
        newMetaFilters[index].less_than = value;
      }
      if (isEqual) {
        newMetaFilters[index].is_equal = value;
      }

      return newMetaFilters;
    });
  };

  const isButtonDisabled =
    !!metaFilters.length && !metaFilters[metaFilters.length - 1]?.name;

  return (
    <Box mt='md'>
      <Box
        mah={200}
        style={{
          overflowY: 'auto'
        }}
      >
        {metaFilters.length > 0 && (
          <Grid columns={24} gutter={0} my={6} p={0}>
            {metaFilters.map((meta, index) => {
              const isNameEmpty = !meta?.name;
              const isBetween = meta?.comparator === COMPARATORS.BETWEEN;

              const availableFilters = allFilters.filter(
                (filter) =>
                  !metaFilters.find(
                    (metaFilter, i) =>
                      index !== i && metaFilter.name === filter.value
                  )
              );

              // We fetch meta columns dynamically when params change so we need to add active filters to the list
              // when they are not available in the list of all meta columns so they can be removed
              if (
                !isNameEmpty &&
                !availableFilters.find((filter) => filter.value === meta.name)
              ) {
                availableFilters.push({
                  label: parseMetaColumnName(meta.name || ''),
                  value: meta.name
                });
              }

              // We don't want to allow equal comparator for continuous decimal columns
              const hideEqual =
                isContinuousAndNotCategorical(meta.name!) ||
                meta.name === 'perplexity';

              return (
                <>
                  <Grid.Col key={`${index}-name`} mb={6} p={2} span={8}>
                    <Select
                      comboboxProps={{
                        position: 'bottom',
                        zIndex: 10000,
                        withinPortal: false
                      }}
                      data={availableFilters}
                      role='combobox'
                      size='sm'
                      styles={{
                        input: {
                          fontWeight: 600,
                          color: '#383645'
                        }
                      }}
                      value={isNameEmpty ? '' : meta.name}
                      onChange={(name) =>
                        updateMetaFilterName(index, name as string)
                      }
                    />
                  </Grid.Col>
                  {!isNameEmpty && (
                    <>
                      <Grid.Col key={`${index}-comparator`} p={2} span={8}>
                        <Select
                          comboboxProps={{
                            position: 'bottom',
                            zIndex: 10000
                          }}
                          data={COMPARATOR_OPTIONS.filter(
                            (c) => !hideEqual || c.value !== COMPARATORS.EQUAL
                          )}
                          role='combobox'
                          size='sm'
                          styles={{
                            input: {
                              fontWeight: 600,
                              color: '#383645'
                            }
                          }}
                          value={meta.comparator}
                          onChange={(name) =>
                            updateMetaFilterComparator(
                              index,
                              name as COMPARATORS
                            )
                          }
                        />
                      </Grid.Col>
                      <Grid.Col
                        key={`${index}-min-val`}
                        p={2}
                        span={isBetween ? 3 : 6}
                      >
                        <SimpleNumberInput
                          defaultValue={getValue(meta)}
                          h={36}
                          placeholder={isBetween ? 'Min' : 'value'}
                          px={2}
                          size='sm'
                          styles={{
                            input: {
                              color: '#383645'
                            }
                          }}
                          onChange={(
                            _nextValue: string,
                            nextValueAsNumber: number
                          ) =>
                            updateMetaFilterValue(
                              index,
                              nextValueAsNumber,
                              meta.comparator as COMPARATORS,
                              true
                            )
                          }
                        />
                      </Grid.Col>
                      {isBetween && (
                        <Grid.Col key={`${index}-max-val`} p={2} span={3}>
                          <SimpleNumberInput
                            defaultValue={meta.less_than}
                            h={36}
                            placeholder='Max'
                            size='sm'
                            styles={{
                              input: {
                                color: '#383645'
                              }
                            }}
                            onChange={(
                              _nextValue: string,
                              nextValueAsNumber: number
                            ) =>
                              updateMetaFilterValue(
                                index,
                                nextValueAsNumber,
                                meta.comparator as COMPARATORS,
                                false
                              )
                            }
                          />
                        </Grid.Col>
                      )}
                    </>
                  )}
                  <Grid.Col key={`${index}-delete`} p={2} span={2}>
                    <ActionIcon
                      color='gray'
                      mt={6}
                      size='sm'
                      variant='subtle'
                      onClick={() =>
                        setMetaFilters((old) =>
                          old.filter((_, i) => i !== index)
                        )
                      }
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Grid.Col>
                </>
              );
            })}
          </Grid>
        )}
      </Box>
      <Group gap={4}>
        <UnstyledButton
          disabled={isButtonDisabled}
          mx={4}
          role='button'
          onClick={() => setMetaFilters((old) => [...old, {}])}
        >
          <Group gap={4}>
            <IconPlus color={isButtonDisabled ? 'gray' : '#4A2FF9'} size={16} />
            <Text
              c={isButtonDisabled ? 'gray.4' : 'brand.6'}
              fw={600}
              size='sm'
            >
              Add meta filter
            </Text>
          </Group>
        </UnstyledButton>
      </Group>
    </Box>
  );
};

export default MetaFiltersForm;
