import { Text, useMantineTheme } from '@mantine/core';

import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';

import HoverCopyButtonBox from '../hover-copy-button-box/hover-copy-button-box';
import DataNotAvailable from '../../atoms/accessibility/data-not-available/data-not-available';

interface TextRatingProps {
  rating: string;
  explanation?: string | null;
  title?: string;
  maxWidth?: number;
  showPopover?: boolean;
}
export const TextRating = ({
  rating,
  explanation,
  maxWidth,
  showPopover = true
}: TextRatingProps) => {
  const { colors } = useMantineTheme();

  if (!rating) {
    return <DataNotAvailable />;
  }

  return (
    <MetricPopover
      body={
        <>
          {showPopover && (
            <HoverCopyButtonBox
              buttonProps={{
                bg: colors.gray[9] + 'D6', // 84% transparency
                c: 'white'
              }}
              copyText={rating}
            >
              <Text pt='sm' px='md' size='sm'>
                {rating}
              </Text>
              <Text px='md' py='sm' size='sm'>
                {explanation}
              </Text>
            </HoverCopyButtonBox>
          )}
        </>
      }
    >
      <Text truncate data-testid='text-rating' maw={maxWidth}>
        {rating}
      </Text>
    </MetricPopover>
  );
};
