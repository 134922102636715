import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/core/stores/parameters-store';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';

const POST_INSIGHTS_DISTRIBUTION =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/distribution';

/**
 * useInsightsDistribution
 */
export const useInsightsDistribution = (col: string, nbins?: number) => {
  // Parameters
  const { runId, projectId } = usePathParameters();

  const { inferenceName, split, task } = useParametersStore((s) => ({
    inferenceName: s.inferenceName,
    split: s.split,
    task: s.task
  }));

  // Computed Params
  const { isMltc, isInference, isPrompt } = useComputedParameters();

  const filter_params = useFilterParams();

  let enabled = Boolean(projectId && runId && col && !isPrompt);

  if (isMltc) {
    enabled = Boolean(enabled && task);
  }

  if (isInference) {
    enabled = Boolean(
      enabled && inferenceName && col !== 'data_error_potential'
    );
  }

  const fetchInsightsDistribution = async () => {
    const res = await api.POST(POST_INSIGHTS_DISTRIBUTION, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body: {
        filter_params,
        task,
        col,
        nbins
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [
      POST_INSIGHTS_DISTRIBUTION,
      { projectId, runId, split, col, task, inferenceName, ...filter_params }
    ],
    fetchInsightsDistribution,
    { enabled, onError: () => {} }
  );

  return response;
};
