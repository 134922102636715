import { Box, ScrollArea, Skeleton, Text } from '@mantine/core';
import { Spotlight } from '@mantine/spotlight';
import { SpotlightActionData } from '@mantine/spotlight/lib/Spotlight';
import { IconSearch } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';

import { useProjects } from '@/core/hooks/query-hooks/use-projects/use-projects';
import { useAdvancedRunSearchStore } from '@/core/stores/advanced-run-search-store/advanced-run-search.store';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { Run } from '@/core/types/projects.types';

import ActionItem from './action-item/action-item';
import ActionsWrapper from './actions-wrapper/actions-wrapper';
import { createActions } from './create-actions/create-actions';
import { filterRuns } from './filter-runs/filter-runs';

export interface CustomAction extends SpotlightActionData {
  id: string;
  projectType?: string;
  run: Run;
}

/**
 * SpotlightProvider
 *
 *
 *
 * @returns {React.Component} SpotlightProvider
 */
const AdvancedSearch = () => {
  const [query, setQuery] = useState('');
  const { getSpotlightFlag } = useFeatureFlagsStore((s) => s.computed);
  const enableSpotlight = getSpotlightFlag();

  const { data: projectsData, isFetching: projectsIsFetching } = useProjects();

  const {
    inProjects,
    withinRange,
    byTaskTypes,
    numSamples,
    excludeZeroSampleRuns,
    isQueryPresent,
    actions,
    noMatchingRuns,
    bySplitType,
    byCollaborator
  } = useAdvancedRunSearchStore((s) => ({
    inProjects: s.inProjects,
    withinRange: s.withinRange,
    byTaskTypes: s.byTaskTypes,
    numSamples: s.numSamples,
    excludeZeroSampleRuns: s.excludeZeroSampleRuns,
    isQueryPresent: s.isQueryPresent,
    actions: s.actions,
    noMatchingRuns: s.noMatchingRuns,
    bySplitType: s.bySplitType,
    byCollaborator: s.byCollaborator
  }));

  const filteredRuns = useMemo(() => {
    const runs = filterRuns(projectsData, query);

    return runs;
  }, [
    inProjects,
    withinRange,
    byTaskTypes,
    numSamples,
    excludeZeroSampleRuns,
    projectsIsFetching,
    isQueryPresent,
    bySplitType,
    byTaskTypes,
    byCollaborator,
    query
  ]);
  const actionItems = createActions(filteredRuns);

  const shortcut = enableSpotlight ? '/' : null;

  useEffect(() => {
    if (!actionItems.length) {
      actions.setNoMatchingRuns(true);
    } else if (noMatchingRuns) {
      actions.setNoMatchingRuns(false);
    }
  }, [actionItems.length]);

  return (
    <Spotlight.Root
      scrollable
      maxHeight={450}
      query={query}
      shortcut={shortcut}
      styles={{
        content: {
          padding: 8
        }
      }}
      onQueryChange={(q) => {
        setQuery(q);
        actions.setIsQueryPresent(Boolean(q));
      }}
      onSpotlightOpen={actions.resetStore}
    >
      <Spotlight.Search
        leftSection={<IconSearch />}
        placeholder='Search for run...'
        style={{
          borderBottom: '1px solid var(--mantine-color-gray-2)'
        }}
        variant='unstyled'
      />
      <ActionsWrapper />

      <Spotlight.ActionsList>
        {projectsIsFetching ? (
          <Box px='sm'>
            {[1, 2, 3, 4, 5].map((i) => (
              <Skeleton height={35} key={i} my='sm' />
            ))}
          </Box>
        ) : (
          <ScrollArea.Autosize mah='30vh'>
            {actionItems.length > 0 ? (
              <Spotlight.ActionsList>
                {actionItems.map((groupedAction) => (
                  <Spotlight.ActionsGroup
                    key={groupedAction.group}
                    mt={12}
                    title={groupedAction.group}
                  >
                    <Text c='gray.6' fw={700} pl={10} size='xs'>
                      {groupedAction.group}
                    </Text>
                    {groupedAction.actions.map((a) => (
                      <Spotlight.Action
                        closeSpotlightOnTrigger
                        key={a.id}
                        w='100%'
                      >
                        <ActionItem action={a} key={a.id} />
                      </Spotlight.Action>
                    ))}
                  </Spotlight.ActionsGroup>
                ))}
              </Spotlight.ActionsList>
            ) : (
              <Spotlight.Empty c='gray.7' p={10} ta='center'>
                No runs matching query...
              </Spotlight.Empty>
            )}
          </ScrollArea.Autosize>
        )}
      </Spotlight.ActionsList>
    </Spotlight.Root>
  );
};

export default AdvancedSearch;
