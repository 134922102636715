import { Box, Button, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconAlertTriangle, IconPlus, IconTrash } from '@tabler/icons-react';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useDeleteFeedbackTemplate } from '@/core/hooks/query-hooks/use-feedback-templates/use-delete-feedback-template';
import { FeedbackType } from '@/core/types/human-feedback.types';

import { RatingConfigurationPopover } from './rating-configuration-popover/rating-configuration-popover';
import { RatingStackConfigurationItem } from './rating-configuration-stack-item/rating-configuration-stack-item';

interface RatingConfigurationStackProps {
  configurations: FeedbackTemplate<FeedbackType>[];
  maxHeight: number;
  savingKeys: string[];
  selectedConfiguration?: FeedbackTemplate<FeedbackType>;
  setConfigurations: React.Dispatch<
    React.SetStateAction<FeedbackTemplate<FeedbackType>[]>
  >;
  setSavingKeys: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedConfiguration: Dispatch<
    SetStateAction<FeedbackTemplate<FeedbackType> | undefined>
  >;
}

export const RatingConfigurationStack = ({
  configurations,
  maxHeight,
  savingKeys = [],
  selectedConfiguration,
  setConfigurations,
  setSavingKeys,
  setSelectedConfiguration
}: RatingConfigurationStackProps) => {
  // Local State
  const [hovered, setHovered] = useState<string | null>(null);

  // Refs
  const viewportRef = useRef<HTMLDivElement>(null);

  // Hooks
  const deleteRatingConfig = useDeleteFeedbackTemplate();

  // Handlers
  const handleDeleteConfig = async (template: FeedbackTemplate<any>) => {
    const deleteRatingTitle = (
      <Group>
        <IconAlertTriangle color='red' />
        <Text c='gray.7' fw={700}>
          Delete Rating for {template.name}?
        </Text>
      </Group>
    );

    const deleteConfirmProps = {
      color: 'red.2',
      c: 'red.6',
      leftSection: <IconTrash color='red' size='1.25rem' />,
      autoContrast: false
    };

    if (template.isInProduction() && template.id != null) {
      openConfirmModal({
        zIndex: Z_INDEX.TOOLTIPS,
        title: deleteRatingTitle,
        children: (
          <Text c='gray.6' fw={500}>
            Are you sure you want to delete {template.name}? Deleting this
            rating type will also erase all previously entered values.
          </Text>
        ),
        confirmProps: deleteConfirmProps,
        labels: {
          confirm: 'Delete',
          cancel: 'Cancel'
        },
        onConfirm: async () => {
          if (template.id != null) {
            setSavingKeys([...savingKeys, template.getKey()]);
            await deleteRatingConfig.mutateAsync(template.id).then(() => {
              const filteredConfigs = configurations.filter(
                (x) => x.getKey() !== template.getKey()
              );
              setConfigurations(filteredConfigs);
              setSavingKeys(savingKeys.filter((x) => x !== template.getKey()));

              // If the template being deleted is the only template, clear the selected template
              if (filteredConfigs.length === 0) {
                setSelectedConfiguration(undefined);
              }

              // If the template being deleted is the selected template, select the first template in the list
              if (template.getKey() === selectedConfiguration?.getKey()) {
                setSelectedConfiguration(filteredConfigs[0]);
              }
            });
          }
        }
      });
    } else {
      openConfirmModal({
        zIndex: Z_INDEX.TOOLTIPS,
        title: deleteRatingTitle,
        confirmProps: deleteConfirmProps,
        labels: {
          confirm: 'Delete',
          cancel: 'Cancel'
        },
        onConfirm: () => {
          const filteredConfigs = configurations.filter(
            (x) => x.getKey() !== template.getKey()
          );
          setConfigurations(filteredConfigs);

          // If the template being deleted is the selected template, select the first template in the list
          if (template.getKey() === selectedConfiguration?.getKey()) {
            setSelectedConfiguration(filteredConfigs[0]);
          }

          // If the template being deleted is the only template, clear the selected template
          if (!filteredConfigs.length) {
            setSelectedConfiguration(undefined);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (configurations.length > 0 && viewportRef?.current != null) {
      viewportRef.current.scrollTo({
        behavior: 'smooth',
        top: viewportRef.current.scrollHeight
      });
    }
  }, [configurations]);

  return (
    <Box>
      <Text
        c='gray.8'
        fw={600}
        p='xs'
        style={{
          fontSize: 12,
          textTransform: 'uppercase',
          letterSpacing: 1
        }}
      >
        Rating Types
      </Text>
      <ScrollArea.Autosize
        offsetScrollbars
        mah={maxHeight ?? 'auto'}
        viewportRef={viewportRef}
      >
        <Stack
          data-testid='rating-config-stack'
          gap={2}
          my={configurations.length > 0 ? 8 : 0}
        >
          {configurations?.map((config) => {
            const isDraft = !config.isInProduction() || config.isModified();
            const isHovered = hovered === config.getKey();
            const isSaving = savingKeys.includes(config.getKey());
            const isSelected =
              selectedConfiguration?.getKey() === config.getKey();

            const onDelete = () => handleDeleteConfig(config);

            const popoverMenu = (
              <RatingConfigurationPopover
                configuration={config}
                isHovered={isHovered}
                onDelete={onDelete}
              />
            );
            return (
              <RatingStackConfigurationItem
                data-list-item
                isDraft={isDraft}
                isSaving={isSaving}
                isSelected={isSelected}
                key={config.getKey()}
                label={config.name ?? 'New Rating Type'}
                rightSection={popoverMenu}
                // @ts-expect-error
                onClick={() => setSelectedConfiguration(config)}
                onMouseLeave={() => setHovered(null)}
                onMouseOver={() => setHovered(config.getKey())}
              />
            );
          })}
        </Stack>
      </ScrollArea.Autosize>
      <Button
        fullWidth
        color='gray.7'
        size='sm'
        style={{ border: '1px solid var(--mantine-color-gray-2)' }}
        variant='outline'
        onClick={() => {
          const newConfiguration =
            new FeedbackTemplate<FeedbackType.LikeDislike>({
              name: 'Untitled',
              withExplanation: false,
              criteria: '',
              constraints: {
                feedback_type: 'like_dislike'
              }
            });
          setConfigurations([...configurations, newConfiguration]);
          setSelectedConfiguration(newConfiguration);
        }}
      >
        <Box mr='4px'>
          <IconPlus size='1rem' />
        </Box>
        <Text fw={600}>New Rating Type</Text>
      </Button>
    </Box>
  );
};
