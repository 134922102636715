import { PREVIEW_DOMAIN } from '@/core/constants/domains.constants';
import { isWindowDefined } from '@/core/utils/is-window-defined/is-window-defined';

export const isDomain = (domain: string | string[]) => {
  if (isWindowDefined()) {
    if (typeof domain === 'string') {
      if (domain === PREVIEW_DOMAIN) {
        return window.location.hostname.includes(domain);
      }

      return window.location.hostname === domain;
    }

    return domain.includes(window.location.hostname);
  }
  return false;
};
