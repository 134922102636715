import { components } from './api';

export const PROJECT_TYPE = {
  TRAINING_INFERENCE: 'training_inference',
  PROMPT_EVALUATION: 'prompt_evaluation',
  OBSERVE: 'llm_monitor'
} as const;

export type ProjectType = (typeof PROJECT_TYPE)[keyof typeof PROJECT_TYPE];

export interface Project {
  created_at: string;
  created_by: string;
  id: string;
  is_public: boolean;
  name: string;
  runs: Run[];
  updated_at: string;
  type: ProjectType;
}

export type Run = components['schemas']['RunDB'];
